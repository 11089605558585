import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { getActiveOtherLoanType,deleteOtherLoanRepayFromBankTransaction,getBankDetails} from "../../../actions/shg";
import { Modal } from "react-bootstrap";

export const BatchOLRepayment = ({
  getActiveOtherLoanType,
  NextBackBtn,
  activeBank,
  formData,
  setFormData,
  allPermission,
  getBankDetails,
  deleteOtherLoanRepayFromBankTransaction,
  auth: { isAuthenticated, user, users },
  shg: { activeOtherLoanTypes,newBankDetails },
}) => {
  let [OLTypeId, setOLTypeId] = useState(null);

  let [OLRepaymentObject, setOLRepaymentObject] = useState({
    otherLoanTypeId: null,
    otherLoanType: null,

    otherLoanSanctionedToShgCash: null,
    otherLoanSanctionedToShgBank: null,
    otherLoanSanctionedToShgBankName: null,
    otherLoanSanctionedToShgBankId:"",

    otherLoanPaidToShgCash: null,
    otherLoanPaidToShgBank: null,
    otherLoanPaidToShgBankName: null,
    otherLoanPaidToShgBankId:"",

    otherLoanInterestShgCash: null,
    otherLoanInterestShgBank: null,
    otherLoanInterestShgBankName: null,
    otherLoanInterestShgBankId:"",

    subsidyDistributedCash: null,
    subsidyDistributedBank: null,
    subsidyDistributedBankName: null,
    subsidyDistributedBankId:"",

    //Inputs
    subsidyReceivedCash: 0,
    subsidyReceivedBank: 0,
    subsidyReceivedBankName: null,
    subsidyReceivedBankId:"",

    otherLoanPaidToDonorCash: 0,
    otherLoanPaidToDonorBank: 0,
    otherLoanPaidToDonorBankName: null,
    otherLoanPaidToDonorBankId:"",

    otherLoanInterestDonorCash: 0,
    otherLoanInterestDonorBank: 0,
    otherLoanInterestDonorBankName: null,
    otherLoanInterestDonorBankId:""
  });

  let {
    otherLoanTypeId,
    otherLoanType,

    otherLoanSanctionedToShgCash,
    otherLoanSanctionedToShgBank,
    otherLoanSanctionedToShgBankName,
    otherLoanSanctionedToShgBankId,

    otherLoanPaidToShgCash,
    otherLoanPaidToShgBank,
    otherLoanPaidToShgBankName,
    otherLoanPaidToShgBankId,

    otherLoanInterestShgCash,
    otherLoanInterestShgBank,
    otherLoanInterestShgBankName,
    otherLoanInterestShgBankId,

    subsidyDistributedCash,
    subsidyDistributedBank,
    subsidyDistributedBankName,
    subsidyDistributedBankId,

    subsidyReceivedCash,
    subsidyReceivedBank,
    subsidyReceivedBankName,
    subsidyReceivedBankId,

    otherLoanPaidToDonorCash,
    otherLoanPaidToDonorBank,
    otherLoanPaidToDonorBankName,
    otherLoanPaidToDonorBankId,

    otherLoanInterestDonorCash,
    otherLoanInterestDonorBank,
    otherLoanInterestDonorBankName,
    otherLoanInterestDonorBankId
  } = OLRepaymentObject;

  useEffect(() => {
    getActiveOtherLoanType();
  }, [getActiveOtherLoanType]);

  const activeOtherLoan = [];

  activeOtherLoanTypes &&
    activeOtherLoanTypes.map((otherLoanType) =>
      activeOtherLoan.push({
        otherLoanTypeId: otherLoanType._id,
        label: otherLoanType.otherLoanType,
        value: otherLoanType.otherLoanType,
      })
    );

  const funcKeyDown = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  const InputChange = (e, type) => {
    if (type == "select") {
      setOLRepaymentObject({
        ...OLRepaymentObject,
        otherLoanType: e.label,
        otherLoanTypeId: e.otherLoanTypeId,
      });
    } else if (type == "") {
      setOLRepaymentObject({
        ...OLRepaymentObject,
        [e.target.name]: e.target.value,
      });
    } else {
      setOLRepaymentObject({
        ...OLRepaymentObject,
        [type]: e,
      });
    }
  };

  let [OLRepaymentArray, setOLRepaymentArray] = useState(
    formData.otherLoanBatchTransaction || []
  );

  const [olRepaymentBankArray,setOlRepaymentBankArray]= useState(formData.olRepaymentBankArray ?formData.olRepaymentBankArray : [] );
  const onOLRepaymentAdd = () => {
    // OLRepaymentObject;

    let OLRepaymentFinalArray = {
      otherLoanTypeId: otherLoanTypeId,
      otherLoanType: otherLoanType,

      otherLoanPaidToShg: {
        cash: Number(otherLoanPaidToShgCash),
        bankAmt: Number(otherLoanPaidToShgBank),
        bankName:otherLoanPaidToShgBankName ?  otherLoanPaidToShgBankName.label : "",
        bankId:otherLoanPaidToShgBankName ? otherLoanPaidToShgBankName.bankId : ""
      },

      otherLoanInterestShg: {
        cash: Number(otherLoanInterestShgCash),
        bankAmt: Number(otherLoanInterestShgBank),
        bankName:otherLoanInterestShgBankName ? otherLoanInterestShgBankName.label : "",
        bankId :otherLoanInterestShgBankName && otherLoanInterestShgBankName.bankId ? otherLoanInterestShgBankName.bankId: ""
      },

      subsidyDistributed: {
        cash: Number(subsidyDistributedCash),
        bankAmt: Number(subsidyDistributedBank),
        bankName: subsidyDistributedBankName ? subsidyDistributedBankName.label : "",
        bankId:subsidyDistributedBankName && subsidyDistributedBankName ? subsidyDistributedBankName.bankId :""
      },

      otherLoanSanctionedToShg: {
        cash: Number(otherLoanSanctionedToShgCash),
        bankAmt: Number(otherLoanSanctionedToShgBank),
        bankName: otherLoanSanctionedToShgBankName ? otherLoanSanctionedToShgBankName.bankName : "",
      },

      otherLoanPaidToDonor: {
        cash: Number(otherLoanPaidToDonorCash),
        bankAmt: Number(otherLoanPaidToDonorBank),
        bankName: otherLoanPaidToDonorBankName
          ? otherLoanPaidToDonorBankName.label
          : "",
          bankId:otherLoanPaidToDonorBankName && otherLoanPaidToDonorBankName ? otherLoanPaidToDonorBankName.bankId :  ""
      },

      otherLoanInterestDonor: {
        cash: Number(otherLoanInterestDonorCash),
        bankAmt: Number(otherLoanInterestDonorBank),
        bankName: otherLoanInterestDonorBankName
          ? otherLoanInterestDonorBankName.label
          : "",
          bankId:otherLoanInterestDonorBankName && otherLoanInterestDonorBankName ? otherLoanInterestDonorBankName.bankId :  ""
      },

      subsidyReceived: {
        cash: Number(subsidyReceivedCash),
        bankAmt: Number(subsidyReceivedBank),
        bankName: subsidyReceivedBankName ? subsidyReceivedBankName.label : "",
        bankId:subsidyReceivedBankName && subsidyReceivedBankName ? subsidyReceivedBankName.bankId : ""
      },
    };

    const totalMap = {};
   const addToTotals= (bankId,bankName,amount,isIncome)=>{
    if(!bankId)return;   
    if(!totalMap[bankId]){
     totalMap[bankId]={
      batchBankName: bankName,
      batchBankId: bankId,
      indBankInterest: 0,
      indBankCommission: 0,
      indBatchBankDeposit: 0,
      indBatchBankWithdrawals: 0,
      transactionType:"B (OL)",
      otherLoanTypeId:otherLoanTypeId
     }
    };

    if(isIncome){
      totalMap[bankId].indBatchBankDeposit += Number(amount)
    }else{
      totalMap[bankId].indBatchBankWithdrawals += Number(amount)
    }
    return totalMap;
   }
   // Define income and expense keys
    const incomeKeys = [
      "otherLoanPaidToShg",
      "otherLoanInterestShg",
      "subsidyReceived",
      
    ];

    incomeKeys.forEach(key=>{
    const entry= OLRepaymentFinalArray[key];
    if(entry && entry.bankId){
      addToTotals(entry.bankId, entry.bankName, entry.bankAmt, true);
    }
    });
   
    const expenseKeys = [
      "subsidyDistributed",
      "otherLoanPaidToDonor",
      "otherLoanInterestDonor"
    ];
    expenseKeys.forEach(key=>{
      const entry = OLRepaymentFinalArray[key];
      if(entry && entry.bankId){
        addToTotals(entry.bankId, entry.bankName, entry.bankAmt, false);
  
      }
    })

    const OlRepayArray = Object.values(totalMap);
        
   // Update the OlRepaymentBankArray state
   setOlRepaymentBankArray((prev) => [...prev, ...OlRepayArray]);

   // Update the formData to retain previous records
   setFormData((prev) => ({
     ...prev,
     olRepaymentBankArray: [...(prev.olRepaymentBankArray || []), ...OlRepayArray],
   }));
    setOLRepaymentArray([...OLRepaymentArray, OLRepaymentFinalArray]);
    setOLRepaymentObject({
      otherLoanTypeId: null,
      otherLoanType: null,

      otherLoanSanctionedToShgCash: null,
      otherLoanSanctionedToShgBank: null,
      otherLoanSanctionedToShgBankName: null,

      otherLoanPaidToShgCash: null,
      otherLoanPaidToShgBank: null,
      otherLoanPaidToShgBankName: null,

      otherLoanInterestShgCash: null,
      otherLoanInterestShgBank: null,
      otherLoanInterestShgBankName: null,

      subsidyDistributedCash: null,
      subsidyDistributedBank: null,
      subsidyDistributedBankName: null,

      subsidyReceivedCash: 0,
      subsidyReceivedBank: 0,
      subsidyReceivedBankName: null,

      otherLoanPaidToDonorCash: 0,
      otherLoanPaidToDonorBank: 0,
      otherLoanPaidToDonorBankName: null,

      otherLoanInterestDonorCash: 0,
      otherLoanInterestDonorBank: 0,
      otherLoanInterestDonorBankName: null,
    });

    setOLTypeId(null);
  };

  const [showDeleteLoanModal,setShowDeleteLoanModal]=useState(false);
  const [otherLoanDeletionDetails,setOtherLoanDeletionDetails] = useState("")
  const [deletedTrue,setDeletedTrue] = useState(false);
  const DeleteLoan =()=>{
    setDeletedTrue(true)
    deleteOtherLoanRepayFromBankTransaction(otherLoanDeletionDetails)
    setShowDeleteLoanModal(false)
    setOLRepaymentArray(
          OLRepaymentArray.filter((ele) => ele.otherLoanTypeId !== otherLoanDeletionDetails.otherLoanTypeId)
        );
    setOLTypeId("");
    setOLRepaymentObject({
      otherLoanTypeId: null,
      otherLoanType: null,

      otherLoanSanctionedToShgCash: null,
      otherLoanSanctionedToShgBank: null,

      otherLoanPaidToShgCash: null,
      otherLoanPaidToShgBank: null,

      otherLoanInterestShgCash: null,
      otherLoanInterestShgBank: null,

      subsidyDistributedCash: null,
      subsidyDistributedBank: null,

      subsidyReceivedCash: 0,
      subsidyReceivedBank: 0,
      subsidyReceivedBankName: null,

      otherLoanPaidToDonorCash: 0,
      otherLoanPaidToDonorBank: 0,
      otherLoanPaidToDonorBankName: null,

      otherLoanInterestDonorCash: 0,
      otherLoanInterestDonorBank: 0,
      otherLoanInterestDonorBankName: null,
    });
  }
  const onOLRepaymentDelete = (loanTypeId) => {

    setShowDeleteLoanModal(true)
    let finalData = {
      otherLoanTypeId : loanTypeId,
      batchId :formData.batchId,
      batchMeetingHeldOnDate:formData.batchMeetingHeldOnDate
    }
    
    setOtherLoanDeletionDetails(finalData)

    // setOLRepaymentArray(
    //   OLRepaymentArray.filter((ele) => ele.otherLoanTypeId != loanTypeId)
    // );
  };

  const nextOfOLRepayment = () => {
   
    setFormData({
      ...formData,
      otherLoanBatchTransaction: OLRepaymentArray,
      olRepaymentBankArray:olRepaymentBankArray,
      bankTransactions :newBankDetails && newBankDetails.bankTransactions,
      subsidyReceived: OLRepaymentArray.reduce(
        (acu, cur) =>
          (acu +=
            Number(cur.subsidyReceived.cash) +
            Number(cur.subsidyReceived.bankAmt)),
        0
      ),
      batchOtherLoanInterestDonor: OLRepaymentArray.reduce(
        (acu, cur) =>
          (acu +=
            Number(cur.otherLoanInterestDonor.cash) +
            Number(cur.otherLoanInterestDonor.bankAmt)),
        0
      ),
      otherLoanPaidToDonor: OLRepaymentArray.reduce(
        (acu, cur) =>
          (acu +=
            Number(cur.otherLoanPaidToDonor.cash) +
            Number(cur.otherLoanPaidToDonor.bankAmt)),
        0
      ),
      otherLoanSanctionedToShg: OLRepaymentArray.reduce(
        (acu, cur) =>
          (acu +=
            Number(cur.otherLoanSanctionedToShg.cash) +
            Number(cur.otherLoanSanctionedToShg.bankAmt)),
        0
      ),
      subsidyDistributed: OLRepaymentArray.reduce(
        (acu, cur) =>
          (acu +=
            Number(cur.subsidyDistributed.cash) +
            Number(cur.subsidyDistributed.bankAmt)),
        0
      ),
      otherLoanPaidToShg: OLRepaymentArray.reduce(
        (acu, cur) =>
          (acu +=
            Number(cur.otherLoanPaidToShg.cash) +
            Number(cur.otherLoanPaidToShg.bankAmt)),
        0
      ),
      batchOtherLoanInterestShg: OLRepaymentArray.reduce(
        (acu, cur) =>
          (acu +=
            Number(cur.otherLoanInterestShg.cash) +
            Number(cur.otherLoanInterestShg.bankAmt)),
        0
      ),
    });
    NextBackBtn(6);
  };

  // console.log("OLRepaymentArray",OLRepaymentArray)
  //////////////////////////call for database//////////////////////////////////
useEffect(()=>{
  getBankDetails({mdBatchId:formData.batchId, mdDate:formData.batchMeetingHeldOnDate})
 
},[deletedTrue])

////////////////////////call for database end /////////////////////////////

  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        <>
          <div className="row card-new  py-3">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <h5>Other Loan Sanctioned and Repayment</h5>
            </div>

            <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
              <label
                className="label-control"
                //style={otherLoanErrorStyle}
              >
                OL Types* :
              </label>
              <Select
                name="otherLoanTypes"
                options={activeOtherLoan}
                value={OLTypeId}
                onChange={(e) => {
                  setOLTypeId(e);
                  InputChange(e, "select");
                }}
                isSearchable={true}
                placeholder="Select Bank"
                theme={(theme) => ({
                  ...theme,
                  height: 26,
                  minHeight: 26,
                  borderRadius: 1,
                  colors: {
                    ...theme.colors,
                    primary: "black",
                  },
                })}
              />

              <label className="label-control">OL Sanctioned To SHG :</label>
              {allPermission && allPermission.includes("batchCashBank") && (
                <>
                  <div className="row col-12">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      Bank List{" "}
                      <Select
                        name="otherLoanSanctionedToShgBankName"
                        options={activeBank}
                        value={otherLoanSanctionedToShgBankName}
                        onChange={(e) =>
                          InputChange(e, "otherLoanSanctionedToShgBankName")
                        }
                        // onChange={(e) => onotherLoanTypeChange(e)}

                        isSearchable={true}
                        placeholder="Select Bank"
                        theme={(theme) => ({
                          ...theme,
                          height: 26,
                          minHeight: 26,
                          borderRadius: 1,
                          colors: {
                            ...theme.colors,
                            primary: "black",
                          },
                        })}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      Bank Amount{" "}
                      <input
                        type="number"
                        name="otherLoanSanctionedToShgBank"
                        value={otherLoanSanctionedToShgBank}
                        onChange={(e) => {
                          InputChange(e, "");
                        }}
                        onWheel={(e) => e.currentTarget.blur()} 
                        className="form-control"
                        disabled={
                          otherLoanSanctionedToShgBankName ? false : true
                        }
                        onKeyDown={(e) => funcKeyDown(e)}
                        required
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="row col-12">
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  Cash{" "}
                  <input
                    type="number"
                    name="otherLoanSanctionedToShgCash"
                    value={otherLoanSanctionedToShgCash}
                    onChange={(e) => {
                      InputChange(e, "");
                    }}
                    onWheel={(e) => e.currentTarget.blur()} 
                    className="form-control"
                    onKeyDown={(e) => funcKeyDown(e)}
                    required
                  />
                </div>
              </div>

              <label className="label-control">
                OL Principal Paid To SHG :
              </label>
              {allPermission && allPermission.includes("batchCashBank") && (
                <>
                  <div className="row col-12">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      Bank List{" "}
                      <Select
                        name="otherLoanPaidToShgBankName"
                        options={activeBank}
                        value={otherLoanPaidToShgBankName}
                        onChange={(e) =>
                          InputChange(e, "otherLoanPaidToShgBankName")
                        }
                        isSearchable={true}
                        placeholder="Select Bank"
                        theme={(theme) => ({
                          ...theme,
                          height: 26,
                          minHeight: 26,
                          borderRadius: 1,
                          colors: {
                            ...theme.colors,
                            primary: "black",
                          },
                        })}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      Bank Amount{" "}
                      <input
                        type="number"
                        name="otherLoanPaidToShgBank"
                        value={otherLoanPaidToShgBank}
                        onChange={(e) => {
                          InputChange(e, "");
                        }}
                        onWheel={(e) => e.currentTarget.blur()} 
                        className="form-control"
                        onKeyDown={(e) => funcKeyDown(e)}
                        disabled={otherLoanPaidToShgBankName ? false : true}
                        required
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="row col-12">
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  Cash
                  <input
                    type="number"
                    name="otherLoanPaidToShgCash"
                    value={otherLoanPaidToShgCash}
                    onChange={(e) => {
                      InputChange(e, "");
                    }}
                    onWheel={(e) => e.currentTarget.blur()} 
                    className="form-control"
                    onKeyDown={(e) => funcKeyDown(e)}
                    required
                  />
                </div>
              </div>

              <label className="label-control">OL Interest Paid To SHG :</label>
              {allPermission && allPermission.includes("batchCashBank") && (
                <>
                  <div className="row col-12">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      Bank List
                      <Select
                        name="otherLoanInterestShgBankName"
                        options={activeBank}
                        onChange={(e) =>
                          InputChange(e, "otherLoanInterestShgBankName")
                        }
                        value={otherLoanInterestShgBankName}
                        // onChange={(e) => onotherLoanTypeChange(e)}
                        isSearchable={true}
                        placeholder="Select Bank"
                        theme={(theme) => ({
                          ...theme,
                          height: 26,
                          minHeight: 26,
                          borderRadius: 1,
                          colors: {
                            ...theme.colors,
                            primary: "black",
                          },
                        })}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      Bank Amount{" "}
                      <input
                        type="number"
                        name="otherLoanInterestShgBank"
                        value={otherLoanInterestShgBank}
                        disabled={otherLoanInterestShgBankName ? false : true}
                        onChange={(e) => {
                          InputChange(e, "");
                        }}
                        onWheel={(e) => e.currentTarget.blur()} 
                        className="form-control"
                        onKeyDown={(e) => funcKeyDown(e)}
                        required
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="row col-12">
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  Cash{" "}
                  <input
                    type="number"
                    name="otherLoanInterestShgCash"
                    value={otherLoanInterestShgCash}
                    onChange={(e) => {
                      InputChange(e, "");
                    }}
                    className="form-control"
                    onWheel={(e) => e.currentTarget.blur()} 
                    onKeyDown={(e) => funcKeyDown(e)}
                    required
                  />
                </div>
              </div>

              <label className="label-control">Subsidy Received To SHG :</label>
              {allPermission && allPermission.includes("batchCashBank") && (
                <>
                  <div className="row col-12">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      Bank List{" "}
                      <Select
                        name="subsidyReceivedBankName"
                        options={activeBank}
                        value={subsidyReceivedBankName}
                        onChange={(e) =>
                          InputChange(e, "subsidyReceivedBankName")
                        }
                        isSearchable={true}
                        placeholder="Select Bank"
                        theme={(theme) => ({
                          ...theme,
                          height: 26,
                          minHeight: 26,
                          borderRadius: 1,
                          colors: {
                            ...theme.colors,
                            primary: "black",
                          },
                        })}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      Bank Amount{" "}
                      <input
                        type="number"
                        name="subsidyReceivedBank"
                        value={subsidyReceivedBank}
                        onChange={(e) => {
                          InputChange(e, "");
                        }}
                        className="form-control"
                        onWheel={(e) => e.currentTarget.blur()} 
                        disabled={subsidyReceivedBankName ? false : true}
                        onKeyDown={(e) => funcKeyDown(e)}
                        required
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="row col-12">
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  Cash{" "}
                  <input
                    type="number"
                    name="subsidyReceivedCash"
                    value={subsidyReceivedCash}
                    onChange={(e) => {
                      InputChange(e, "");
                    }}
                    className="form-control"
                    onWheel={(e) => e.currentTarget.blur()} 
                    onKeyDown={(e) => funcKeyDown(e)}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
              <label className="label-control">Subsidy Distributed :</label>
              {allPermission && allPermission.includes("batchCashBank") && (
                <>
                  <div className="row col-12">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      Bank List{" "}
                      <Select
                        name="subsidyDistributedBankName"
                        options={activeBank}
                        value={subsidyDistributedBankName}
                        onChange={(e) =>
                          InputChange(e, "subsidyDistributedBankName")
                        }
                        isSearchable={true}
                        placeholder="Select Bank"
                        theme={(theme) => ({
                          ...theme,
                          height: 26,
                          minHeight: 26,
                          borderRadius: 1,
                          colors: {
                            ...theme.colors,
                            primary: "black",
                          },
                        })}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      Bank Amount{" "}
                      <input
                        type="number"
                        name="subsidyDistributedBank"
                        value={subsidyDistributedBank}
                        onChange={(e) => {
                          InputChange(e, "");
                        }}
                        className="form-control"
                        disabled={subsidyDistributedBankName ? false : true}
                        onWheel={() => document.activeElement.blur()}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="row col-12">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  Cash
                  <input
                    type="number"
                    name="subsidyDistributedCash"
                    value={subsidyDistributedCash}
                    onChange={(e) => {
                      InputChange(e, "");
                    }}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
              </div>

              <label className="label-control">
                OL Principal Paid To Donor :
              </label>

              {allPermission && allPermission.includes("batchCashBank") && (
                <>
                  <div className="row col-12">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      Bank List
                      <Select
                        name="otherLoanPaidToDonorBankName"
                        options={activeBank}
                        value={otherLoanPaidToDonorBankName}
                        onChange={(e) => {
                          InputChange(e, "otherLoanPaidToDonorBankName");
                        }}
                        isSearchable={true}
                        placeholder="Select Bank"
                        theme={(theme) => ({
                          ...theme,
                          height: 26,
                          minHeight: 26,
                          borderRadius: 1,
                          colors: {
                            ...theme.colors,
                            primary: "black",
                          },
                        })}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      {" "}
                      Bank Amount{" "}
                      <input
                        type="number"
                        name="otherLoanPaidToDonorBank"
                        value={otherLoanPaidToDonorBank}
                        onChange={(e) => {
                          InputChange(e, "");
                        }}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        disabled={otherLoanPaidToDonorBankName ? false : true}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="row col-12">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {" "}
                  Cash{" "}
                  <input
                    type="number"
                    name="otherLoanPaidToDonorCash"
                    value={otherLoanPaidToDonorCash}
                    onChange={(e) => {
                      InputChange(e, "");
                    }}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
              </div>

              <label className="label-control">
                OL Interest Paid To Donor&nbsp;:
              </label>
              {allPermission && allPermission.includes("batchCashBank") && (
                <>
                  <div className="row col-12">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      {" "}
                      Bank List
                      <Select
                        name="otherLoanInterestDonorBankName"
                        options={activeBank}
                        value={otherLoanInterestDonorBankName}
                        onChange={(e) => {
                          InputChange(e, "otherLoanInterestDonorBankName");
                        }}
                        isSearchable={true}
                        placeholder="Select Bank"
                        theme={(theme) => ({
                          ...theme,
                          height: 26,
                          minHeight: 26,
                          borderRadius: 1,
                          colors: {
                            ...theme.colors,
                            primary: "black",
                          },
                        })}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      Bank Amount
                      <input
                        type="number"
                        name="otherLoanInterestDonorBank"
                        onChange={(e) => {
                          InputChange(e, "");
                        }}
                        value={otherLoanInterestDonorBank}
                        disabled={otherLoanInterestDonorBankName ? false : true}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="row col-12">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  Cash
                  <input
                    type="number"
                    name="otherLoanInterestDonorCash"
                    value={otherLoanInterestDonorCash}
                    onChange={(e) => {
                      InputChange(e, "");
                    }}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
              </div>

              <div>
                <button
                  className="btn sub_form btn_continue Save float-right"
                  type="button"
                  disabled={
                    OLRepaymentArray.map((ele) => ele.otherLoanTypeId).includes(
                      OLTypeId ? OLTypeId.otherLoanTypeId : ""
                    ) || !OLTypeId
                  }
                  onClick={() => (OLTypeId ? onOLRepaymentAdd() : "")}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <div className="body-inner no-padding table-responsive">
            <table
              className="tabllll table table-bordered table-striped table-hover"
              id="datatable2"
            >
              <thead>
                <tr>
                  <th>OL Type</th>
                  <th>OL Sanctioned To SHG</th>
                  <th>OL Principal Paid To SHG</th>
                  <th>OL Interest Paid SHG</th>
                  <th>Subsidy Received</th>
                  <th>Subsidy Distributed</th>
                  <th>OL Principal Paid To Donor</th>
                  <th>OL Interest Paid To Donor</th>
                  <th>OP</th>
                </tr>
              </thead>
              <tbody>
                {OLRepaymentArray &&
                  OLRepaymentArray.map((OLdata, idx) => {
                   
                    return (
                      <tr key={idx}>
                        <td>
                          {
                            activeOtherLoan.find(
                              (ele) =>
                                ele.otherLoanTypeId == OLdata.otherLoanTypeId
                            ).label
                          }
                        </td>
                        <td>
                          {Number(OLdata.otherLoanSanctionedToShg ?OLdata.otherLoanSanctionedToShg.cash : 0) +
                            Number(OLdata.otherLoanSanctionedToShg ? OLdata.otherLoanSanctionedToShg.bankAmt : 0)}
                        </td>
                        <td>
                          {Number(OLdata.otherLoanPaidToShg ? OLdata.otherLoanPaidToShg.cash : 0) +
                            Number(OLdata.otherLoanPaidToShg ? OLdata.otherLoanPaidToShg.bankAmt : 0)}
                        </td>

                        <td>
                          {Number(OLdata.otherLoanInterestShg ? OLdata.otherLoanInterestShg.cash : 0) +
                            Number(OLdata.otherLoanInterestShg ? OLdata.otherLoanInterestShg.bankAmt : 0)}
                        </td>

                        <td>
                          {Number(OLdata.subsidyReceived ? OLdata.subsidyReceived.cash : 0) +
                            Number(OLdata.subsidyReceived ? OLdata.subsidyReceived.bankAmt : 0)}
                        </td>

                        <td>
                          {Number(OLdata.subsidyDistributed ? OLdata.subsidyDistributed.cash :0) +
                            Number(OLdata.subsidyDistributed ? OLdata.subsidyDistributed.bankAmt :0)}
                        </td>

                        <td>
                          {Number(OLdata.otherLoanPaidToDonor ? OLdata.otherLoanPaidToDonor.cash :0) +
                            Number(OLdata.otherLoanPaidToDonor ? OLdata.otherLoanPaidToDonor.bankAmt : 0)}
                        </td>

                        <td>
                          {Number(OLdata.otherLoanInterestDonor ? OLdata.otherLoanInterestDonor.cash : 0) +
                            Number(OLdata.otherLoanInterestDonor ? OLdata.otherLoanInterestDonor.bankAmt : 0)}
                        </td>

                        <td>
                          <img
                            className="img_icon_size log"
                            onClick={() =>
                              onOLRepaymentDelete(OLdata.otherLoanTypeId)
                            }
                            src={require("../../../static/images/delete.png")}
                            alt="Remove"
                            title="Remove"
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </>
      </div>

      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        <button
          className="btn sub_form btn_continue Save float-right"
          onClick={() => nextOfOLRepayment()}
        >
          Next
        </button>

        <button
          className="btn sub_form btn_continue Save float-right"
          onClick={() => NextBackBtn(4)}
        >
          Previous
        </button>
      </div>


{/* other Loan repayment deleting start */}
<Modal
        show={showDeleteLoanModal}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="col-lg-11 ">
            <h3 className="modal-title ">Attention</h3>
          </div>
          <div className="col-lg-1 mr-3">
            <button
              onClick={() => setShowDeleteLoanModal(false)}
              className="close"
            >
              <img
                src={require("../../../static/images/close.png")}
                alt="X"
                style={{ height: "20px", width: "20px" }}
              />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
        <div className="text-center">
          <h5>Are you sure you want to delete the loan details and repayment records?
             This action cannot be reversed.</h5>
          {/* <span> Note: If applicable, please ensure that any consecutive repayments related to this loan in the upcoming batch meetings are deleted as well.</span>  */}
        </div>
        <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
                <input
                  type="button"
                  onClick={() => {DeleteLoan()}}
                  name="submit"
                  value="Delete"
                  className="btn sub_form btn_continue Save float-right"
               
                />
                <input
                  type="button"
                  name="Prtev"
                  value="Cancel"
                  onClick={() => setShowDeleteLoanModal(false)}
                  className="btn sub_form btn_continue Save float-right"
                />
              </div>
        </Modal.Body>
      </Modal>
{/* other loan repayment deleting end */}



    </>
  );
};

BatchOLRepayment.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

const mapDispatchToProps = {
  getActiveOtherLoanType,deleteOtherLoanRepayFromBankTransaction,getBankDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(BatchOLRepayment);
