import React, { useState, useEffect } from "react";

const LoanPayment = ({
  setShowpayment,
  internalTypeName,
  setPaymentArray,
  paymentArray,
  meetingHeldOnDate,
  selectedBank,
  internalLoanId,
  curMemMettingLoanSanc,
  clickedInternalLoan,
}) => {
  // const loanDet = curMemMettingLoanSanc.find((e) => e._id == internalLoanId);
  let pending = 0;
  if (clickedInternalLoan && clickedInternalLoan._id) {
    const loanDet = clickedInternalLoan;
    pending =
      (loanDet.loanPayablePrincipal || 0) -
      loanDet.loanRepayment.reduce(
        (acu, cur) => (acu += cur.loanPrincipalPaid || 0),
        0
      );
  } else {
    pending =
      Number(clickedInternalLoan.loanPayablePrincipal.bankAmt) +
      Number(clickedInternalLoan.loanPayablePrincipal.cash);
  }

  const [internalPaymentData, setInternalPaymentData] = useState({
    internalLoanId: internalLoanId,

    internalLoanType: internalTypeName,
    internalPrincipalPaidCash: 0,
    internalPrincipalPaidBank: 0,
    internalIntrestPaidCash: 0,
    internalIntrestPaidBank: 0,

    paidDate: meetingHeldOnDate,
    paidMonth: meetingHeldOnDate.split("-")[1],
    paidYear: meetingHeldOnDate.split("-")[0],
    penalty: 0,
  });

  const {
    internalLoanType,
    internalPrincipalPaidCash,
    internalPrincipalPaidBank,
    internalIntrestPaidCash,
    internalIntrestPaidBank,
    penalty,
  } = internalPaymentData;

  const onInputChange = (e) => {
    setInternalPaymentData({
      ...internalPaymentData,
      [e.target.name]: e.target.value,
    });

    // if (internalPrincipalPaid != "" && internalIntrestPaid != "") {
    //   setAllValid(false);
    // }
  };

  const addRepayment = () => {
    let {
      internalPrincipalPaidCash,
      internalPrincipalPaidBank,
      internalIntrestPaidCash,
      internalIntrestPaidBank,
      ...paymentData
    } = internalPaymentData;

    setPaymentArray([
      ...paymentArray,
      {
        ...paymentData,
        internalPrincipalPaid: {
          cash: Number(internalPrincipalPaidCash || 0) || 0,
          bankAmt: Number(internalPrincipalPaidBank || 0) || 0,
          bankName: (selectedBank && selectedBank.value) || "",
        },
        internalIntrestPaid: {
          cash: Number(internalIntrestPaidCash || 0) || 0,
          bankAmt: Number(internalIntrestPaidBank || 0) || 0,
          bankName: (selectedBank && selectedBank.value) || "",
        },
      },
    ]);

    setShowpayment(false);
  };

  useEffect(() => {}, [internalLoanType]);
  const infoIcon = (titleValue) => {
    return (
      <>
        <img
          className="img_icon_size log mx-2"
          src={require("../../static/images/info.png")}
          alt="Reason"
          style={{ height: "18px", width: "18px" }}
          title={titleValue}
        />
      </>
    );
  };
  const getSum = (cash, bank) => {
    const cashValue = isNaN(cash) ? 0 : Number(cash);
    const bankValue = isNaN(bank) ? 0 : Number(bank);
    return cashValue + bankValue;
  };

  const funcKeyDown = (e) => {
    ["e", "E", "+", "-" ,"."].includes(e.key) && e.preventDefault();
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          addRepayment();
        }}
      >
        <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <button
              type="button"
              className="btn sub_form btn_continue Save"
              onClick={() => setShowpayment(false)}
            >
              Back
            </button>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <span className=" text-bold h5 mt-5">
              Repayment For LoanType:
              <span className="text-danger mx-1">{internalLoanType}</span>
            </span>
          </div>
        </div>

        <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
          <div className="col-lg-6  col-md-12 col-sm-12 col-12 cardUpdate">
            <label className="label-control">
              Internal Principal Paid{" "}
              {infoIcon("Internal Principal Paid Cash and Bank Total value")}:&nbsp;(
              {getSum(internalPrincipalPaidCash, internalPrincipalPaidBank)})
              <i className="colorRed ">*</i>
            </label>
            <div className="row col-12">
              <div className=" row col-lg-6">
                <div className="col-lg-2 mt-2">Bank </div>
                <div className="col-lg-10">
                  <input
                    type="number"
                    name="internalPrincipalPaidBank"
                    value={internalPrincipalPaidBank}
                    disabled={(selectedBank && selectedBank.length == 0) ? true : false}
                    required={selectedBank ? true : false}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
              </div>
              <div className=" row col-lg-6">
                <div className="col-lg-2 mt-2">Cash </div>
                <div className="col-lg-10">
                  <input
                    type="number"
                    name="internalPrincipalPaidCash"
                    value={internalPrincipalPaidCash}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    title="Enter Amount Greater Than 0"
                    //pattern="[1-9][0-9]*"
                    // min={internalPrincipalPaidBank ? "0" : "1"}
                    required
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
              </div>
            </div>
            <label className="label-control">Total Paid :</label>
            <div className="row col-12">
              <input
                type="number"
                name="totalPaid"
                value={
                  Number(internalPrincipalPaidCash) +
                  Number(internalPrincipalPaidBank) +
                  Number(internalIntrestPaidCash) +
                  Number(internalIntrestPaidBank)
                }
                className="form-control"
                onWheel={() => document.activeElement.blur()}
                disabled
              />
            </div>
          </div>

          <div className="col-lg-6  col-md-12 col-sm-12 col-12 cardUpdate">
            <label className="label-control">
              Internal Interest Paid{" "}
              {infoIcon("Internal Interest Paid Cash and Bank Total value")}:&nbsp;(
              {getSum(internalIntrestPaidCash, internalIntrestPaidBank)})
              <i className="colorRed ">*</i>
            </label>
            <div className="row col-12">
              <div className=" row col-lg-6">
                <div className="col-lg-2 mt-2">Bank </div>
                <div className="col-lg-10">
                  <input
                    type="number"
                    name="internalIntrestPaidBank"
                    value={internalIntrestPaidBank}
                    className="form-control"
                    disabled={(selectedBank && selectedBank.length == 0) ? true : false}
                    required={selectedBank ? true : false}
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
              </div>
              <div className=" row col-lg-6">
                <div className="col-lg-2 mt-2">Cash </div>{" "}
                <div className="col-lg-10">
                  <input
                    type="number"
                    name="internalIntrestPaidCash"
                    value={internalIntrestPaidCash}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onChange={(e) => onInputChange(e)}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
              </div>
            </div>
            {/* <label className="label-control">Penalty :</label>
          <div className="row col-12">
            
            <input
              type="number"
              name="penalty"
              value={penalty}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              onChange={(e) => onInputChange(e)}
              // onKeyDown={(e) => funcKeyDown(e)}
            />
          </div> */}
          </div>
        </div>

        <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            {Number(internalPrincipalPaidCash) +
              Number(internalPrincipalPaidBank) <=
            pending ? (
              ""
            ) : (
              <span className="text-danger">
                Cannot pay More then sanctioned Amount
              </span>
            )}
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <button
              // type="button"
              type="submit"
              className="btn sub_form btn_continue Save"
              // onClick={() => addRepayment()}
              disabled={
                Number(internalPrincipalPaidCash) +
                  Number(internalPrincipalPaidBank) <=
                pending
                  ? false
                  : true
              }
            >
              Add
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default LoanPayment;
