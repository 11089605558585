import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import { AddUserDetailsform } from "../../actions/auth";
import { editUserDetails } from "../../actions/user";
import { getActiveInstitutions, UserCoordinator } from "../../actions/shg";
import Spinner from "../layout/Spinner";
const EditUserDetails = ({
  auth: { isAuthenticated, user, users, loading },
  shg: { activeInstitutions, allbatchesdropdown, IsCordinator },
  user: { userGroups },
  AddUserDetailsform,
  editUserDetails,
  errorResponse,
  allusersdata,
  UserCoordinator,
  onUpdateModalChange,
}) => {
  useEffect(() => {
    getActiveInstitutions();
    UserCoordinator({ CoordinatorId: allusersdata._id });
  }, [getActiveInstitutions]);

  //formData
  const [formData, setFormData] = useState({
    userFullName:
      allusersdata && allusersdata.userFullName
        ? allusersdata.userFullName
        : "",
    userName:
      allusersdata && allusersdata.userName ? allusersdata.userName : "",
    userEmail:
      allusersdata && allusersdata.userEmail ? allusersdata.userEmail : "",
    userPhone:
      allusersdata && allusersdata.userPhone ? allusersdata.userPhone : "",
    usergroup:
      allusersdata && allusersdata.usergroup ? allusersdata.usergroup : "",
    userAddress:
      allusersdata && allusersdata.userAddress ? allusersdata.userAddress : "",
    password:
      allusersdata && allusersdata.password ? allusersdata.password : "",
    userGroupId:
      allusersdata && allusersdata.userGroupId ? allusersdata.userGroupId : "",
    userGroupName:
      allusersdata && allusersdata.userGroupName
        ? allusersdata.userGroupName
        : "",
    userBatchId:
      allusersdata && allusersdata.userBatchId ? allusersdata.userBatchId : "",

    isSubmitted: false,
  });

  const {
    userName,
    userFullName,
    userEmail,
    userPhone,
    userAddress,
  } = formData;

  const allbatchesList = [];

  allbatchesdropdown.map((batchs) =>
    allbatchesList.push({
      batchId: batchs._id,
      label: batchs.batchName + " (" + batchs.batchCode + ")",
      value: batchs.batchName,
    })
  );

  const [batchs, getbatchsData] = useState(
    allusersdata
      ? allbatchesList &&
          allbatchesList.filter(
            (x) => x.batchId === allusersdata.userBatchId
          )[0]
      : ""
  );
  const [batchId, setbatchId] = useState();
  const [batchName, setbatchName] = useState();

  const onBatchChange = (e) => {
    var batchId = "";
    var batchName = "";
    getbatchsData(e);

    batchId = e.batchId;
    batchName = e.value;

    setbatchId(batchId);
    setbatchName(batchName);
  };
  const onInputChange2 = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );

  let intPermissions = loggedUserInstitutionData.permission.map(
    (e) => e.permissionName
  );
  let boolpermisionCor = intPermissions.includes("Co-ordinator");

  let selUserGroupData = JSON.parse(localStorage.getItem("selUserGroupData"));
  const allusergroups = [];
  selUserGroupData &&
    selUserGroupData.map((usergrp) => {
      if (usergrp.userGroupName == "Co-ordinator") {
        if (boolpermisionCor) {
          allusergroups.push({
            userGroupId: usergrp._id,
            label: usergrp.userGroupName,
            value: usergrp.userGroupName,
          });
        }
      } else {
        allusergroups.push({
          userGroupId: usergrp._id,
          label: usergrp.userGroupName,
          value: usergrp.userGroupName,
        });
      }
    });

  const [showHide, setShowHide] = useState({
    showBatchSection:
      allusersdata && allusersdata.userGroupName === "Member" ? true : false,
    showIsInchargeSection:
      allusersdata && allusersdata.userGroupName === "Co-ordinator"
        ? true
        : false,
    // showIsInchargeSection: false,
  });
  const { showBatchSection, showIsInchargeSection } = showHide;

  const [showHideen, setShowHideen] = useState({
    showinstiSection:
      allusersdata && allusersdata.userGroupName === "Super Admin"
        ? false
        : true,
    // showIsInchargeSection: false,
  });
  const { showinstiSection } = showHideen;

  const [showHiden, setShowHiden] = useState({
    showUserGroupSection:
      allusersdata && allusersdata.userGroupName === "Super Admin"
        ? false
        : true,
    // showIsInchargeSection: false,
  });
  const { showUserGroupSection } = showHiden;

  const [usergrp, getGroups] = useState(
    allusersdata
      ? allusergroups &&
          allusergroups.filter(
            (x) => x.userGroupId === allusersdata.userGroupId
          )[0]
      : ""
  );

  const [userGroupId, setusergroupId] = useState();
  const [userGroupName, setuserGroupName] = useState();
  const [error, SetError] = useState("");

  const onUserGroupChange = (e) => {
    if (IsCordinator && (e.value !== "Co-ordinator")) {
      SetError("Co-ordinator is already Allocated to Batch");
      var userGroupId = "";
      var userGroupName = "";
      setusergroupId("");
      setuserGroupName("");
      getGroups("");
    } else {
      SetError("");

      //UserCoordinator({})
      getGroups(e);
      var userGroupId = e.userGroupId;
      var userGroupName = e.value;

      setusergroupId(userGroupId);
      setuserGroupName(userGroupName);

      if (e.value === "Member") {
        setShowHide({
          ...showHide,
          showBatchSection: true,
          showIsInchargeSection: false,
        });
        setIsChecked(false);
      } else if (e.value === "Co-ordinator") {
        setShowHide({
          ...showHide,
          showBatchSection: false,
          showIsInchargeSection: true,
        });
      } else {
        setShowHide({
          ...showHide,
          showBatchSection: false,
          showIsInchargeSection: false,
        });
        setIsChecked(false);
        // setbatchId("");
      }
    }
  };
  const [isChecked, setIsChecked] = useState(
    allusersdata && allusersdata.isIncharge ? allusersdata.isIncharge : false
  );

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  const funcKeyDown = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  const allinstitutions = [];
  let activeInstitutionData = JSON.parse(
    localStorage.getItem("activeInstitutionData")
  );
  activeInstitutionData &&
    activeInstitutionData.map((institutions) =>
      allinstitutions.push({
        institutionId: institutions._id,
        label: institutions.institutionName,
        value: institutions.institutionName,
      })
    );

  const [institutions, getinstitutionsData] = useState(
    allusersdata
      ? allinstitutions &&
          allinstitutions.filter(
            (x) => x.institutionId === allusersdata.institutionId
          )[0]
      : ""
  );

  const [institutionId, setinstitutionId] = useState();

  const [institutionName, setinstitutionName] = useState();

  const onInstitutionChange = (e) => {
    getinstitutionsData(e);

    var institutionId = e.institutionId;
    var institutionName = e.value;
    setinstitutionId(institutionId);
    setinstitutionName(institutionName);
  };

  const onUpdate = (e) => {
    e.preventDefault();
    const finalData = {
      recordId: allusersdata ? allusersdata._id : "",
      userFullName: userFullName,
      userName: userName,
      userEmail: userEmail,
      userPhone: userPhone,
      userAddress: userAddress,
      institutionId: allusersdata && allusersdata.institutionId,
      institutionName: allusersdata && allusersdata.institutionName,
      isIncharge: isChecked,
      userEditById: user && user._id,
      userEditByName: user && user.userName,
      userGroupName: usergrp.value,
      userGroupId: usergrp.userGroupId,
      userBatchId: batchId ? batchId : null,
    };

    editUserDetails(finalData);
    onUpdateModalChange(true);
  };

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      {errorResponse && <p style={{ color: "red" }}>{errorResponse}</p>}

      <div className="row col-lg-12 col-md-9 col-sm-9 col-12 py-12">
        <form className="row" onSubmit={(e) => onUpdate(e)}>
          <div className="col-lg-6  col-md-12 col-sm-12 col-12">
            <label className="label-control">Full Name* :</label>
            <input
              type="text"
              name="userFullName"
              value={userFullName}
              className="form-control"
              onChange={(e) => onInputChange2(e)}
              required
            />
          </div>

          <div className="col-lg-6  col-md-12 col-sm-12 col-12">
            <label className="label-control">Email :</label>
            <input
              type="text"
              name="userEmail"
              value={userEmail}
              className="form-control"
              onChange={(e) => onInputChange2(e)}
            />
          </div>

          <div className="row col-lg-12 col-md-9 col-sm-9 col-12 py-12">
            <div className="col-lg-6  col-md-12 col-sm-12 col-12 ">
              <label className="label-control"> Phone* :</label>
              <input
                type="Number"
                name="userPhone"
                value={userPhone}
                className="form-control"
                onChange={(e) => onInputChange2(e)}
                onKeyDown={(e) => funcKeyDown(e)}
                required
              />
            </div>
            {showUserGroupSection && (
              <>
                <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                  <label className="label-control">User Group* :</label>
                  <Select
                    name="userGroupName"
                    options={allusergroups}
                    isSearchable={true}
                    value={usergrp}
                    placeholder="Select User Group"
                    onChange={(e) => onUserGroupChange(e)}
                    theme={(theme) => ({
                      ...theme,
                      height: 26,
                      minHeight: 26,
                      borderRadius: 1,
                      colors: {
                        ...theme.colors,
                        primary: "black",
                      },
                    })}
                  />
                </div>
              </>
            )}
            {showIsInchargeSection && (
              <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                <label className="label-control">
                  Is the Co-ordinator also an Incharge? :
                </label>
                <input
                  style={{
                    height: "20px",
                    width: "20px",
                    borderRadius: "50%",
                    display: "block",
                  }}
                  type="checkbox"
                  checked={isChecked}
                  id="Unconfirmed"
                  onChange={handleOnChange}
                />
              </div>
            )}
          </div>

          <div className="row col-lg-12 col-md-9 col-sm-9 col-12 py-12">
            <div className="col-lg-6  col-md-12 col-sm-12 col-12">
              <label className="label-control"> Address :</label>
              <textarea
                name="userAddress"
                id="userAddress"
                value={userAddress}
                className="textarea form-control"
                rows="3"
                placeholder="Address"
                onChange={(e) => onInputChange2(e)}
                style={{ width: "100%" }}
              ></textarea>
            </div>
            <div className="col-lg-6  col-md-12 col-sm-12 col-12">
              <label className="label-control"> User Name :</label>
              <input
                type="userName"
                name="userName"
                value={userName}
                className="form-control"
                onChange={(e) => onInputChange2(e)}
                required
              />
            </div>
            {showBatchSection && (
              <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                <label className="label-control"> SHG :</label>
                <Select
                  name="batchName"
                  options={allbatchesList}
                  isSearchable={true}
                  value={batchs}
                  placeholder="Select SHG"
                  onChange={(e) => onBatchChange(e)}
                  theme={(theme) => ({
                    ...theme,
                    height: 26,
                    minHeight: 26,
                    borderRadius: 1,
                    colors: {
                      ...theme.colors,
                      primary: "black",
                    },
                  })}
                />
              </div>
            )}
          </div>

          {user.userGroupName === "Super Admin" && showinstiSection && (
            <div className="row col-lg-12 col-md-9 col-sm-9 col-12 py-12">
              <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                <label className="label-control"> Institution :</label>
                <Select
                  name="institutionName"
                  options={allinstitutions}
                  isSearchable={true}
                  value={institutions}
                  placeholder="Select Institution"
                  onChange={(e) => onInstitutionChange(e)}
                  theme={(theme) => ({
                    ...theme,
                    height: 26,
                    minHeight: 26,
                    borderRadius: 1,
                    colors: {
                      ...theme.colors,
                      primary: "black",
                    },
                  })}
                />
              </div>
            </div>
          )}

          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <label className="label-control colorRed">
              * Indicates mandatory fields, Please fill mandatory fields before
              Submit
            </label>
            <h6 className="label-control colorRed">{error}</h6>
          </div>

          <div className="col-lg-12 Savebutton " size="lg">
            {loading ? (
              <button
                className="btn sub_form btn_continue blackbrd Save float-right"
                disabled
              >
                Loading...
              </button>
            ) : (
              <input
                type="submit"
                name="Submit"
                value="Submit"
                className="btn sub_form btn_continue blackbrd Save float-right"
              />
            )}
          </div>
        </form>
      </div>
    </Fragment>
  );
};

EditUserDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  AddUserDetailsform: PropTypes.func.isRequired,
  editUserDetails: PropTypes.func.isRequired,
  errorResponse: PropTypes.string,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
  user: state.user,
  errorResponse: state.auth.errorResponse,
});

export default connect(mapStateToProps, {
  AddUserDetailsform,
  editUserDetails,
  UserCoordinator,
})(EditUserDetails);
