import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import EditMemberModel from "./memberDetModel/EditMemberModel";
import SummaryMemTab from "./memBatchMeetingTabs/SummaryMemTab.js";
import AllMemberTab from "./memBatchMeetingTabs/AllMemberTab";
import IncomeTab from "./memBatchMeetingTabs/IncomeTab";
import ExpenseTab from "./memBatchMeetingTabs/ExpenseTab";
import LoanDetTab from "./memBatchMeetingTabs/LoanDetTab";
import OLRepaymentTab from "./memBatchMeetingTabs/OLRepaymentTab";
import BankDetTab from "./memBatchMeetingTabs/BankDetTab";
import EODSummaryTab from "./memBatchMeetingTabs/EODSummaryTab";

import {
  BatchTransaction,
  getParticularLoanReason,
  getMembersMeetingData,
  getMeetingSummary,
  UpdateMeetingStatus,
  getEditMemberMeetingData,
  getMeetingCategory,
  getActiveOtherLoanType,
  getActiveBankData,
  sendMettingFile,
  getMemberMeetingSummary,
  updateAttendenceDetails
} from "../../actions/shg";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Spinner from "../layout/Spinner";
import EditMembermeetingDetailModal from "./EditMembermeetingDetailModal";
import FileBase64 from "react-file-base64";
import PdfPreview from "./PdfPreview";
import ImgPreview from "./ImgPreview";
import { EODMeetingSummary } from "./EODMeetingSummary.js";

const AddMemberBatchMeetingDetails = ({
  auth: { isAuthenticated, user, users },
  shg: {
    membersMeetingData,
    summaryData,
    summaryDataOp,
    memberClosingAmt,
    memberClosingAmtAll,
    batchMeetingCategory,
    activeOtherLoanTypes,
    activeBankData,
    memSummaryData,
    storage_info,
    loadStatus
  },
  BatchTransaction,
  getParticularLoanReason,
  getMembersMeetingData,
  getMeetingSummary,
  getMemberMeetingSummary,
  UpdateMeetingStatus,
  getEditMemberMeetingData,
  getMeetingCategory,
  getActiveOtherLoanType,
  getActiveBankData,
  sendMettingFile,
  updateAttendenceDetails
}) => {
  let batchIdValue = JSON.parse(localStorage.getItem("batchIdValue"));
  let meetingBatchInfo = JSON.parse(localStorage.getItem("meetingBatchInfo"));

  let batchTransactionData = JSON.parse(
    localStorage.getItem("batchTransactionData")
  );


  let selBatchData = JSON.parse(localStorage.getItem("selBatchData"));

  useEffect(() => {
    getMemberMeetingSummary(meetingBatchInfo);
  }, [getMemberMeetingSummary]);
  useEffect(() => {
    getParticularLoanReason();
  }, []);
  useEffect(() => {
    getMembersMeetingData(meetingBatchInfo);
  }, []);
  useEffect(() => {
    getMeetingSummary(meetingBatchInfo);
  }, []);
  useEffect(() => {
    getMeetingCategory(meetingBatchInfo);
  }, []);
  useEffect(() => {
    getActiveBankData(batchIdValue);
  }, []);
  useEffect(() => {
    getActiveOtherLoanType();
  }, []);

  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );

  const allPermission = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.permission &&
    loggedUserInstitutionData.permission.map((permission) =>
      allPermission.push(permission.permissionName)
    );

  const allRights = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.groupRights &&
    loggedUserInstitutionData.groupRights.map((groupRights) =>
      allRights.push(groupRights)
    );

  const activeBank = [];
  activeBankData &&
    activeBankData.map((bank) =>
      activeBank.push({
        bankId: bank._id,
        label: bank.batchBankName,
        value: bank.batchBankName,
      })
    );

  const [showView, setShowView] = useState(false);

  const [formData, setFormData] = useState({
    batchId: meetingBatchInfo.mdBatchId,
    batchName: meetingBatchInfo.mdBatchName,
    batchMeetingHeldOnDate: meetingBatchInfo.mdDate,
    batchMeetingMonth: meetingBatchInfo.mdDate.split("-")[1],
    batchMeetingYear: meetingBatchInfo.mdDate.split("-")[2],
    batchTransactionType: "Memberwise",

    meetingCategory: batchMeetingCategory.length == 0 ? "OP" : "M", //req flag
    meetingNotes: null,
    // meetingFileName: null,
    meetingFile: null,
    batchSavingAmt: { cash: 0, bankAmt: 0, bankName: "" }, //BC
    batchSubAmt: { cash: 0, bankAmt: 0, bankName: "" }, //BC
    batchOtherContribution: { cash: 0, bankAmt: 0, bankName: "" }, //BC
    batchMembershipFees: { cash: 0, bankAmt: 0, bankName: "" }, //BC
    deactiveMemberSaving: { cash: 0, bankAmt: 0, bankName: "" }, //BC
    batchOtherIncome: { cash: 0, bankAmt: 0, bankName: "" }, //BC
    seedMoney: { cash: 0, bankAmt: 0, bankName: "" }, //BC
    batchSecurityDepositMemToShg: { cash: 0, bankAmt: 0, bankName: "" }, //BC
    sdUnionToSHG: { cash: 0, bankAmt: 0, bankName: "" }, //BC
    loanRepaidByOtherSHG: { cash: 0, bankAmt: 0, bankName: "" }, //BC
    travellingExpenses: { cash: 0, bankAmt: 0, bankName: "" }, //BC
    stationaryExpenses: { cash: 0, bankAmt: 0, bankName: "" }, //bc,
    paidToMahasangha: { cash: 0, bankAmt: 0, bankName: "" }, //bc

    serviceCharges: { cash: 0, bankAmt: 0, bankName: "" }, //bc
    batchDividendDistributed: { cash: 0, bankAmt: 0, bankName: "" }, //bc
    otherExpenses: { cash: 0, bankAmt: 0, bankName: "" }, //bc
    batchSavingWithdrawals: { cash: 0, bankAmt: 0, bankName: "" }, //bc
    batchSecurityDeposit: { cash: 0, bankAmt: 0, bankName: "" }, //bc
    donationToOutsider: { cash: 0, bankAmt: 0, bankName: "" }, //bc
    sdSHGToUnion: { cash: 0, bankAmt: 0, bankName: "" }, //bc
    batchLoanAmt: { cash: 0, bankAmt: 0, bankName: "" }, //bc
    batchLoanPaid: { cash: 0, bankAmt: 0, bankName: "" }, //bc
    batchInterestPaid: { cash: 0, bankAmt: 0, bankName: "" }, //bc
    otherLoanSanctionedToMember: { cash: 0, bankAmt: 0, bankName: "" }, //bc

    bankSDSUId: null,
    bankSDSU: "",
    selectionSDSUType: "",

    otherLoanSanctionedToShg: 0, //no
    loanGivenToOtherSHG: 0, //no
    subsidyDistributed: 0, //no
    subsidyReceived: 0, //NO
    bankInterest: 0, //NO
    otherLoanPaidToShg: 0, //no
    otherLoanPaidToDonor: 0, //no
    batchOtherLoanInterestShg: 0, // no
    batchOtherLoanInterestDonor: 0, //no

    otherLoanBatchTransaction: [], // [], //Array
    bankTransactions: [], //[] //array
    cashInHand: 0, //no
    bankCommission: 0, //no
    batchBankDeposit: 0, //no
    batchBankWithdrawals: 0, //no
    institutionId: loggedUserInstitutionData.institutionId ? loggedUserInstitutionData.institutionId : loggedUserInstitutionData._id,

    // verifiedByDateAndTime: {
    //   $date: "2023-11-18T11:24:19.893Z",
    // },
  });

  useEffect(() => {
    setFormData({
      ...formData,
      meetingCategory: batchMeetingCategory.length == 0 ? "OP" : "M",
    });
  }, [batchMeetingCategory]);

  let {
    meetingNotes,

    batchSavingAmt, //BC
    batchSubAmt, //BC
    batchOtherContribution, //BC
    batchMembershipFees, //BC

    deactiveMemberSaving, //BC

    batchOtherIncome, //BC
    seedMoney, //BC
    batchSecurityDepositMemToShg, //BC
    sdUnionToSHG, //BC

    loanRepaidByOtherSHG, //BC
    travellingExpenses, //BC
    stationaryExpenses, //bc,
    paidToMahasangha, //bc

    serviceCharges, //bc
    batchDividendDistributed, //bc
    otherExpenses, //bc
    batchSavingWithdrawals, //bc
    batchSecurityDeposit, //bc

    donationToOutsider, //bc
    sdSHGToUnion, //bc

    batchLoanAmt, //bc
    batchLoanPaid, //bc
    batchInterestPaid, //bc

    otherLoanSanctionedToMember,

    bankInterest,
    bankCommission,
    batchBankDeposit,
    batchBankWithdrawals,
  } = formData;

  //bankTransactions

 

  const bankBalance = (memSummaryData) => {
    try {
      let {
        batchBankDeposit,
        batchBankWithdrawals,
        bankInterest,
        bankCommission,
        subsidyReceivedBank,
        otherLoanSanctionedToShgBank,
      } = memSummaryData;

      return Number(
        Number(batchBankDeposit || 0) -
          Number(batchBankWithdrawals || 0) +
          Number(bankInterest|| 0) -
          Number(bankCommission || 0) +
          Number(subsidyReceivedBank || 0) +
          Number(otherLoanSanctionedToShgBank || 0)
      ).toFixed(2);

    } catch (er) {
      return 0;
    }
  };
const newBankBalanceTotal = 
(
  Number(bankBalance(memSummaryData)) +
  Number(bankInterest) +
  Number(batchBankDeposit) -
  (Number(bankCommission) +
    Number(batchBankWithdrawals))
).toFixed(2) || 0;

////////////////////////////////new code for attendence start/////////////////////////////////////////
const getMonthAndDate = (date) => {
  let day = date.split("-")[0];
  let month = date.split("-")[1];
  switch (month) {
    case "01":
      return "JAN" + "-" + day;

    case "02":
      return "FEB" + "-" + day;
    case "03":
      return "MAR" + "-" + day;

    case "04":
      return "APR" + "-" + day;

    case "05":
      return "MAY" + "-" + day;

    case "06":
      return "JUN" + "-" + day;

    case "07":
      return "JULY" + "-" + day;

    case "08":
      return "AUG" + "-" + day;

    case "09":
      return "SEPT" + "-" + day;

    case "10":
      return "OCT" + "-" + day;

    case "11":
      return "NOV" + "-" + day;

    case "12":
      return "DEC" + "-" + day;

    default:
      return "unknows";
  }
};

const [selectedDates, setSelectedDates] = useState([]);
const [formattedDates, setFormattedDates] = useState(meetingBatchInfo &&  meetingBatchInfo.formattedDates);



const [tabIndex, setTabIndex] = useState(0);
let formatedDates = JSON.parse(localStorage.getItem("meetingBatchInfo"));
const NextBackBtn = (tabIndex) => {
    // Get the first record
const firstRecord = membersMeetingData[0];

const selectedDates =firstRecord && firstRecord.selectedDates
// setFormattedDates( Object.keys(selectedDates || {}))

  if (tabIndex === 1) {
    setNewTrailBankBalance(newBankBalanceTotal)
    setFormattedDates(
      meetingBatchInfo
        ? meetingBatchInfo.formattedDates
          ? meetingBatchInfo.formattedDates
          : Object.keys(selectedDates || {})
        : formatedDates.formatedDates
    );
  }

  if (tabIndex === 3) {
    updateAttendenceDetails({ selectedDates: formData.selectedDates,mdDate:meetingBatchInfo.mdDate,batchId:meetingBatchInfo.mdBatchId });
  }

  setTabIndex(tabIndex);
};

useEffect(() => {
  const extractMemberAttendance = (membersData) => {

    return membersData.map((member) => ({
      memberId: member.memberId,
      dates:
        member.selectedDates ||
        formattedDates && formattedDates.reduce((acc, date) => {
          acc[date] = "Absent"; // Default attendance is 'Absent' for each date
          return acc;
        }, {}),
    }));
  };

  const memberAttendanceArray = extractMemberAttendance(membersMeetingData);
  setSelectedDates(memberAttendanceArray);

  setFormData({ ...formData, selectedDates: memberAttendanceArray });


}, [membersMeetingData, formattedDates]); // Add formattedDates as a dependency to update when it changes

const [editForMember,setEditForMember] = useState("");
const onChangeEdit=(index)=>{
  setEditForMember(index)
  // setCanEdit()
}

const handleDateChange = (memberId, date, isChecked) => {
  setSelectedDates((prevSelectedDates) => {
    const updatedDates = prevSelectedDates.map((member) => {
      if (member.memberId === memberId) {
        const updatedMemberDates = { ...member.dates };
        updatedMemberDates[date] = isChecked ? "Attended" : "Absent"; // Update the attendance
        return { ...member, dates: updatedMemberDates };
      }
      return member; // If not the correct member, return unchanged
    });
    setFormData({ ...formData, selectedDates: updatedDates });

    return updatedDates;
  });
};

////////////////////////////////////new code for attendence end ////////////////////////////////////////////////////////
const [newTrailBankBalance,setNewTrailBankBalance] = useState(newBankBalanceTotal);
useEffect(()=>{

},[newTrailBankBalance])

const groupByBankId = (bankTransactions) => {
    
  let data = bankTransactions.flat();
  return Object.values(
    data.reduce((result, current) => {
      const key = current.batchBankNameId;
      const balance =
        Number(current.indBatchBankDeposit) +
        Number(current.indBankInterest) -
        Number(current.indBatchBankWithdrawals) -
        Number(current.indBankCommission);

      // const bankWithdrawal=current.indBatchBankWithdrawals;
      result[key] = result[key] || {
        bankName: current.batchBankName,
        bankId:current.batchBankNameId,
        bankWithdrawal: 0,
        totalBalance: 0,
      };
      result[key].bankWithdrawal += Number(current.indBatchBankWithdrawals);
      result[key].totalBalance += Number(balance);

      return result;
    }, {})
  );
};

const bankArray = groupByBankId(memSummaryData && memSummaryData.bankTransactions || []);


  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [editModel, setEditModel] = useState(false);
  const [impMemdata, setImpmemData] = useState(null);

  const onOpenEditModal = (memTransactionData) => {
    setImpmemData({
      memberId: memTransactionData.memberId,
      meetingHeldOnDate: memTransactionData.meetingHeldOnDate,
      batchId: memTransactionData.batchId,
    });

    setEditModel(true);
    // setMemberMeetingDetailModals(true);
  };

  //PDF upload
  const [selectedFile, setSelectedFile] = useState(null);
  const [pdfFormData, setPdfFormData] = useState("");

  const [prevBankBalance, setPrevBankBalance] = useState(0);

  const [newBkBal, setnewBkBal] = useState(0);

  const handleFileChange = async (event) => {
    
    let size = 0;
    // console.log(event.target.files.length)
    if(event.target.files.length > 0){
       size = event.target.files[0].size ;
    }else{
      size = 0
    }
    
    if( size > 1000000){
      event.target.value = null
      alert("File size too large")
  }else{
   
    setSelectedFile(event.target.files[0]);
    var ED = meetingBatchInfo.mdDate.split(/\D/g);
    var selDate = [ED[2], ED[1], ED[0]].join("-");
    const formDatais = new FormData();
    formDatais.append("file", event.target.files[0]);
    formDatais.append("batchName", batchIdValue.batchNameVal);
    formDatais.append("batchMeetingHeldOnDate", selDate);
    setPdfFormData(formDatais);
    setFormData({ ...formData, meetingFile: formDatais });
   }
  };

  let meetingHeldOnDate =
    meetingBatchInfo &&
    meetingBatchInfo.mdDate
      .split("-")
      .reverse()
      .join("-");

  const getAddCurBankBalance = () => {
    return (
      Number(batchSavingAmt.bankAmt) +
      Number(batchSubAmt.bankAmt) +
      Number(batchOtherContribution.bankAmt) +
      Number(batchMembershipFees.bankAmt) +
      Number(batchLoanPaid.bankAmt) +
      Number(sdUnionToSHG.bankAmt) +
      Number(batchInterestPaid.bankAmt) +
      Number(batchOtherIncome.bankAmt) +
      Number(seedMoney.bankAmt) +
      Number(batchSecurityDepositMemToShg.bankAmt) +
      Number(deactiveMemberSaving.bankAmt) +
      Number(loanRepaidByOtherSHG.bankAmt)
    );
  };

  const getSubCurBankBalance = () => {
    return (
      Number(travellingExpenses.bankAmt) +
      Number(stationaryExpenses.bankAmt) +
      Number(paidToMahasangha.bankAmt) +
      Number(serviceCharges.bankAmt) +
      Number(batchDividendDistributed.bankAmt) +
      Number(otherExpenses.bankAmt) +
      Number(batchSavingWithdrawals.bankAmt) +
      Number(batchSecurityDeposit.bankAmt) +
      Number(donationToOutsider.bankAmt) +
      Number(sdSHGToUnion.bankAmt) +
      Number(batchLoanAmt.bankAmt) +
      Number(otherLoanSanctionedToMember.bankAmt)
    );
  };

  // const getCurBankBalance = () => {
  //   return (
  //     Number(batchSavingAmt.bankAmt) +
  //     Number(batchSubAmt.bankAmt) +
  //     Number(batchOtherContribution.bankAmt) +
  //     Number(batchMembershipFees.bankAmt) +
  //     Number(deactiveMemberSaving.bankAmt) +
  //     Number(batchOtherIncome.bankAmt) +
  //     Number(seedMoney.bankAmt) +
  //     Number(batchSecurityDepositMemToShg.bankAmt) +
  //     Number(sdUnionToSHG.bankAmt) +
  //     Number(loanRepaidByOtherSHG.bankAmt) +
  //     Number(travellingExpenses.bankAmt) +
  //     Number(stationaryExpenses.bankAmt) +
  //     Number(paidToMahasangha.bankAmt) +
  //     Number(serviceCharges.bankAmt) +
  //     Number(batchDividendDistributed.bankAmt) +
  //     Number(otherExpenses.bankAmt) +
  //     Number(batchSavingWithdrawals.bankAmt) +
  //     Number(batchSecurityDeposit.bankAmt) +
  //     Number(donationToOutsider.bankAmt) +
  //     Number(sdSHGToUnion.bankAmt) +
  //     Number(batchLoanAmt.bankAmt) +
  //     Number(batchLoanPaid.bankAmt) +
  //     Number(batchInterestPaid.bankAmt)
  //   );
  // };
  ////////////////////storage % calculation///////////////////////////
  const isThisFirstMeeting = !memSummaryData ? true : false;

const totalCashAmt = ((memSummaryData && Number(memSummaryData.cashInHand || 0)));
  let storage = 0;
  let details = ""
  if(storage_info > 0){
   storage =  (storage_info/15360)*100;
    details = storage_info+"MB"+" "+"("+storage.toFixed(2)+"%"+")"
  }else{
    details =  storage_info
  }  ///////////////////////////////////////
  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      {loadStatus ? <Spinner /> : <div className="container container_align">
        <div className="col-lg-11 col-md-11 col-sm-12 col-12">
          <h2 className="heading_color">
            Add Batch Meeting Details of  {batchIdValue.batchNameVal}
          </h2>
          <hr />
        </div>


        <div className="row col-lg-12 col-md-12 col-sm-12 col-12 no_padding">
          <div className="col-lg-3 col-md-4 col-sm-11 col-11 ">
            <h4 className="heading_color">Meeting Date: {meetingHeldOnDate}</h4>
          </div>
          <div className="col-lg-8 col-md-4 col-sm-11 col-11">
            <Link to="/add-memberwise-batchdetails-meeting">
              <img
                className="img_icon_size log float-right"
                src={require("../../static/images/back.png")}
                alt="Back"
                title="Back"
              />
            </Link>
          </div>
        </div>

        <section className="sub_reg">
          <Tabs selectedIndex={tabIndex}>
            <div className="row col-lg-11 col-md-11 col-sm-12 col-12">
              <TabList>
                <Tab tabfor="0"> Previous Summary </Tab>
                {allPermission &&
                allPermission.includes("Attendance") ?  
                <Tab tabfor="1"> Attendence </Tab>
                 : 
                <Tab tabfor="1" style={{ display: "none" }}>
               Attendence
              </Tab> }
               
                {allPermission &&
                allPermission.includes("Screenshot") &&
                batchMeetingCategory.length > 0 && (batchTransactionData && batchTransactionData.meetingCategory !== "OP") ? (
                  <Tab tabfor="2">MOM</Tab>
                ) : (
                  <Tab tabfor="2" style={{ display: "none" }}>
                    MOM
                  </Tab>
                )}
                <Tab tabfor="3">All Members</Tab>
                <Tab tabfor="4">Income</Tab>
                <Tab tabfor="5">Expense</Tab>
                <Tab tabfor="6">Loan Details</Tab>
                <Tab tabfor="7">Other loan details</Tab>
                <Tab tabfor="8">Bank Details</Tab>
                <Tab tabfor="9">EOD Summary</Tab>
              </TabList>
            </div>
            <TabPanel tabId="0">
              <div className="row col-lg-11 col-md-11 col-sm-11 col-11">
                <SummaryMemTab
                  memSummaryData={memSummaryData || ""}
                  allPermission={allPermission}
                  memberClosingAmtAll={
                    memberClosingAmtAll && memberClosingAmtAll[0]
                  }
                  setPrevBankBalance={setPrevBankBalance}
                />

                <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
                  <button
                    className="btn sub_form btn_continue Save float-right"
                    //     allPermission &&
                    // allPermission.includes("Screenshot") &&
                    // batchMeetingCategory.length > 0 ? (
                    //   <Tab tabfor="1">MOM</Tab>
                    // ) : (
                    //   <Tab tabfor="1" style={{ display: "none" }}>
                    //     MOM
                    //   </Tab>
                    // )

                    onClick={() =>
                      NextBackBtn(
                       
                        allPermission &&
                        allPermission.includes("Attendance") ?  1 : 
                          allPermission &&
                          allPermission.includes("Screenshot") &&
                          batchMeetingCategory.length > 0 && (batchTransactionData && batchTransactionData.meetingCategory !== "OP")
                        ? 2:3
                         
                      )
                    }
                  >
                    Next
                  </button>
                </div>
              </div>
            </TabPanel>
            {tabIndex !== 0 && (
              <div className="row col-lg-11 col-md-12 col-sm-12 col-12">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12" style={{ backgroundColor: "#144c5a",marginBottom:"1px" }} >
                  <div className="row card-new  py-1" style={{ backgroundColor: "#144c5a" }}>
                  <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                      <label style={{ color: "white" }}>
                        <b>
                          Previous Cash in Hand&nbsp;:&nbsp;
                          {(memSummaryData && memSummaryData.cashInHand) || 0}
                        </b>
                      </label>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                      <label style={{ color: "white" }}>
                        <b>
                          Previous Bank Balance&nbsp;:&nbsp;{prevBankBalance.toFixed(2)}
                        </b>
                      </label>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                      <label style={{ color: "white" }}>
                        <b>
                          New Bank Balance&nbsp;:&nbsp;
                          {
                            newTrailBankBalance
                          // (
                          //   Number(prevBankBalance) +
                          //   Number(bankInterest) +
                          //   Number(batchBankDeposit) -
                          //   (Number(bankCommission) +
                          //     Number(batchBankWithdrawals))
                          // ).toFixed(2)
                          }
                        </b>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            )}

             <TabPanel tabId="1">
                <div className="row col-lg-11 col-md-12 col-sm-12 col-12">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="body-inner no-padding table-responsive table-container ">
                      <table
                        className="table table-bordered table-striped table-hover"
                        id="datatable2"
                      >
                        <thead>
                          <tr>
                            <th>SHG Members</th>

                            {formattedDates &&
                              formattedDates.map((ele) => {
                                return <th>{getMonthAndDate(ele)}</th>;
                              })}
                            <th>Edit</th>
                          </tr>
                        </thead>

                        <tbody>
                          {membersMeetingData &&
                            membersMeetingData.map((memberBatch, idx) => {
                              // Find the member's selectedDates object based on their memberId
                              const memberSelectedDates = selectedDates.find(
                                function(member) {
                                  return (
                                    member.memberId === memberBatch.memberId
                                  );
                                }
                              );

                              // Safeguard against undefined `memberSelectedDates`
                              const isMemberAttendanceAvailable =
                                memberSelectedDates &&
                                memberSelectedDates.dates;

                              return (
                                <tr key={idx}>
                                  <td>{memberBatch.memberName}</td>

                                  {/* Iterate over formattedDates and add checkboxes */}
                                  {formattedDates && formattedDates.map(function(ele, dateIdx) {
                                    // Safeguard for when `memberSelectedDates` might be undefined or not yet populated
                                    const isAttended =
                                      isMemberAttendanceAvailable &&
                                      memberSelectedDates.dates[ele] ===
                                        "Attended";

                                        return (
                                          editForMember === idx ? (
                                            <td key={dateIdx} className="text-center">
                                              <button
                                                style={{
                                                  width: "24px", 
                                                  height: "24px", 
                                                  cursor: "pointer", 
                                                  backgroundImage: `url(${
                                                    isAttended
                                                      ? require("../../static/images/present.png")
                                                      : require("../../static/images/absent.png")
                                                  })`,
                                                  backgroundSize: "cover",   
                                                  backgroundColor:"transparent",
                                                  border:"none",   
                                                  backgroundPosition: "center",                                             }}
                                                onClick={() => {
                                                  handleDateChange(
                                                    memberBatch.memberId, 
                                                    ele,
                                                    !isAttended 
                                                  );
                                                }}
                                              />
                                            </td>
                                          ) : (
                                            <td key={dateIdx} className="text-center" style={{opacity:"50%"}}>
                                              <button
                                                style={{
                                                  width: "24px", 
                                                  height: "24px", 
                                                  cursor: "not-allowed", 
                                                  backgroundImage: `url(${
                                                    isAttended
                                                      ? require("../../static/images/present.png")
                                                      : require("../../static/images/absent.png")
                                                  })`,
                                                  backgroundSize: "cover",
                                                  backgroundColor:"transparent",
                                                  backgroundPosition: "center",
                                                  border:"none",
                                                 }}
                                                disabled                                                
                                              />
                                            </td>
                                          )
                                        );
                                  })}

                                  <td>
                                  <div className="text-center">
                                    
                                    <button style={{background:"none",border:"none",cursor:"pointer"}} title="Edit"  onClick={()=>onChangeEdit(idx)}>
                                    <img src={require("../../static/images/edit_icon.png")}></img>
                                    </button>
                                  </div>
                                    </td>

                                </tr>
                              );
                            })}
                        </tbody>
                      </table>

                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <button
                          className="btn sub_form btn_continue Save float-right"
                          onClick={() =>
                            NextBackBtn(
                              allPermission.includes("Screenshot") &&
                                batchTransactionData &&
                                batchMeetingCategory.length > 0 &&
                                batchTransactionData.meetingCategory !== "OP"
                                ? 2
                                : 3
                            )
                          }
                        >
                          Next
                        </button>

                        <button
                          className="btn sub_form btn_continue Save float-right"
                          onClick={() => NextBackBtn(0)}
                        >
                          Previous
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>

            <TabPanel tabId="2">
              <form onSubmit={(e) => NextBackBtn(3)}>
                <div className="row col-lg-11 col-md-12 col-sm-12 col-12">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="row card-new py-3 ">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <h5>MOM</h5>
                      </div>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="row">
                          <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              Meeting Notes* :
                            </label>
                            <textarea
                              name="meetingNotes"
                              value={meetingNotes}
                              id="meetingNotes"
                              className="textarea form-control"
                              rows="4"
                              placeholder="Meeting Notes"
                              onChange={(e) => onInputChange(e)}
                              style={{ width: "100%" }}
                              required
                            ></textarea>
                          </div>

                          <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                            <label className="label-control">
                              <b>Upload only PDF</b>
                            </label>
                            <input
                              type="file"
                              accept="application/pdf"
                              multiple={false}
                              onChange={(event) => handleFileChange(event)}
                            />

                            {!showView ? (
                              <></>
                            ) : (
                              <a
                                // href={`${pdfBase64}`}
                                target="_blank"
                                className="text-primary font-weight-bold h6 text-underline"
                              >
                                View PDF
                              </a>
                            )}
                             <label  className="text-primary">
                              (Max limit 4MB)
                              <br/>
                              Avaiable Storage: {details} </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <input
                          type="submit"
                          name="submit"
                          value="Next"
                          className="btn sub_form btn_continue Save float-right"
                        />
                        <button
                          className="btn sub_form btn_continue Save float-right"
                          onClick={() => NextBackBtn(0)}
                        >
                          Previous
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </TabPanel>

            <TabPanel tabId="3">
              <div className="col-lg-11 col-md-11 col-sm-11 col-11 text-center ">
                <section className="body">
                  <AllMemberTab
                    membersMeetingData={membersMeetingData}
                    batchTransactionData={batchTransactionData}
                    allRights={allRights}
                    user={user}
                    onOpenEditModal={onOpenEditModal}
                  />
                  <div className="col-md-12 col-lg-12 col-sm-12 col-12 text-left">
                    <button
                      className="btn sub_form btn_continue Save float-right"
                      onClick={() => NextBackBtn(4)}
                    >
                      Next
                    </button>
                    <button
                      className="btn sub_form btn_continue Save float-right"
                      onClick={() =>
                        NextBackBtn(
                          allPermission.includes("Screenshot") &&
                            batchTransactionData &&
                            batchMeetingCategory.length > 0 &&
                            batchTransactionData.meetingCategory !== "OP"
                            ? 2
                            :
                            allPermission &&
                            allPermission.includes("Attendance") ? 1 : 0 
                        )
                      }
                    >
                      Previous
                    </button>
                  </div>
                </section>
              </div>
            </TabPanel>

            <TabPanel tabId="4">
              <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
                <IncomeTab
                  membersMeetingData={membersMeetingData}
                  allPermission={allPermission}
                  activeBank={activeBank}
                  formData={formData}
                  setFormData={setFormData}
                  NextBackBtn={NextBackBtn}
                  newBkBal={newBkBal}
                  setnewBkBal={setnewBkBal}
                />
              </div>
            </TabPanel>

            <TabPanel tabId="5">
              <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
                <ExpenseTab
                  formData={formData}
                  setFormData={setFormData}
                  membersMeetingData={membersMeetingData}
                  allPermission={allPermission}
                  activeBank={activeBank}
                  NextBackBtn={NextBackBtn}
                  isThisFirstMeeting={isThisFirstMeeting}
                  totalBankAndCashAmt={totalCashAmt}
                  newBkBal={newBkBal}
                  bankAmount={bankArray}
                  setnewBkBal={setnewBkBal}
                />
              </div>
            </TabPanel>

            <TabPanel tabId="6">
              <div className="row col-md-12 col-lg-11 col-sm-12 col-12 ">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 py-3">
                  <LoanDetTab
                    membersMeetingData={membersMeetingData}
                    NextBackBtn={NextBackBtn}
                    formData={formData}
                    setFormData={setFormData}
                  />
                </div>
              </div>
            </TabPanel>

            <TabPanel tabId="7">
              <div className="row col-lg-11 col-md-12 col-sm-12 col-12">
                <OLRepaymentTab
                  membersMeetingData={membersMeetingData}
                  activeBank={activeBank}
                  NextBackBtn={NextBackBtn}
                  formData={formData}
                  setFormData={setFormData}
                />
              </div>
            </TabPanel>

            <TabPanel tabId="8">
              <div className="row col-lg-11 col-md-12 col-sm-12 col-12">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
                  <BankDetTab
                    membersMeetingData={membersMeetingData}
                    activeBank={activeBank}
                    batchData={null}
                    NextBackBtn={NextBackBtn}
                    formData={formData}
                    setNewTrailBankBalances={setNewTrailBankBalance}
                    PrevCashInHand={memSummaryData && memSummaryData.cashInHand}
                    setFormData={setFormData}
                    newBankBalanceTotal={newBankBalanceTotal}
                    prevBankBalance={prevBankBalance}
                  />
                </div>
              </div>
            </TabPanel>

            <TabPanel tabId="9">
              <div>
                <EODSummaryTab
                  NextBackBtn={NextBackBtn}
                  meetingBatchInfo={meetingBatchInfo}
                  formData={formData}
                  memSummaryData={memSummaryData || ""}
                  allPermission={allPermission}
                  memberClosingAmtAll={
                    memberClosingAmtAll && memberClosingAmtAll[0]
                  }
                  fromPage={"AddMeeting"}
                  batchTransactionData={batchTransactionData}
                  formattedDates={formattedDates}

                />
              </div>
            </TabPanel>
          </Tabs>
        </section>
      </div>}
      


      <Modal
        show={editModel}
        backdrop="static"
        keyboard={false}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="col-lg-10">
            <h3 className="modal-title text-center">Edit Meeting Details</h3>
          </div>
          <div className="col-lg-2">
            <button onClick={() => setEditModel(false)} className="close">
              <img
                src={require("../../static/images/close.png")}
                alt="X"
                style={{ height: "20px", width: "20px" }}
              />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <EditMemberModel
            memberData={impMemdata}
            allPermission={allPermission}
            selectedDatesArray={formattedDates}
            activeBank={activeBank}
            bankAmount = {bankArray}
            totalBankAndCashAmt={totalCashAmt}
            isThisFirstMeeting={isThisFirstMeeting}
            handleMemberMeetingModalCloses={() => setEditModel(false)}
          />
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

AddMemberBatchMeetingDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  BatchTransaction: PropTypes.func.isRequired,
  getParticularLoanReason: PropTypes.func.isRequired,
  getMembersMeetingData: PropTypes.func.isRequired,
  getMeetingSummary: PropTypes.func.isRequired,
  UpdateMeetingStatus: PropTypes.func.isRequired,
  getActiveOtherLoanType: PropTypes.func.isRequired,
  getActiveBankData: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  BatchTransaction,
  getParticularLoanReason,
  getMembersMeetingData,
  getMeetingSummary,
  UpdateMeetingStatus,
  getEditMemberMeetingData,
  getMeetingCategory,
  getActiveOtherLoanType,
  getActiveBankData,
  sendMettingFile,
  getMemberMeetingSummary,
  updateAttendenceDetails
})(AddMemberBatchMeetingDetails);
