import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { deactiveMember, getToBeDeativeMemberData } from "../../actions/shg";
import Select from "react-select";

const DeactiveMember = ({
  auth: { isAuthenticated, user, users, loading },
  shg: { ToBeDevMemSvgData, ToBeDevMemLoanData },
  deactiveMember,
  shgMembers,
  onDeactiveModalChange,
  getToBeDeativeMemberData,
}) => {
  const [formData, setFormData] = useState({
    memberDeactiveReason: "",
    memberClossingAmt: "",
    isSubmitted: false,
  });
  const [memberClossingType, setMemberClossingType] = useState("");
  const { memberDeactiveReason, memberClossingAmt } = formData;

  const [savingAmt, setSavingAmt] = useState("");
  const [ILAmt, setILAmt] = useState("");
  const [OLAmt, setOLAmt] = useState("");

  useEffect(() => {
    setSavingAmt(ToBeDevMemSvgData);
    setILAmt(
      ToBeDevMemLoanData.filter((ele) => ele.isLoanType == "Internal").length
    );
    setOLAmt(
      ToBeDevMemLoanData.filter((ele) => ele.isLoanType != "Internal").length
    );
  }, [ToBeDevMemSvgData, ToBeDevMemLoanData]);

  useEffect(() => {
    const sndData = {
      MemId: shgMembers._id,
    };

    getToBeDeativeMemberData(sndData);
  }, []);

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const funcKeyDown = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  //For setting mindate as todays date
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  var todayDateymd = yyyy + "-" + mm + "-" + dd;

  //For setting mindate as todays date

  const onSubmit = (e) => {
    e.preventDefault();
    if (!memberClossingType) {
      setMemberClossingTypeValid(false);
      return; // Prevent form submission
    }
    const finalData = {
      recordId: shgMembers ? shgMembers._id : "",
      memberReasonStatus: "Deactive",
      memberDeactiveReason: memberDeactiveReason,
      memberClossingAmt: memberClossingAmt,
      memberClossingType: memberClossingType.value,
      memberDeactiveById: user && user._id,
      memberDeactiveDate: todayDateymd,
    };

    deactiveMember(finalData);
    onDeactiveModalChange(true);
    // getAllTenants();
  };
  //validation
  const [memberClossingTypeValid, setMemberClossingTypeValid] = useState(true);
  const onMemberClossingTypeChange = (selectedOption) => {
    setMemberClossingType(selectedOption);
    setMemberClossingTypeValid(true); // Reset validation status
  };
  return !isAuthenticated || !user || !users ? (
    <Fragment></Fragment>
  ) : (
    <Fragment>
      <div className="col-lg-12 col-md-9 col-sm-9 col-12 ">
        <form className="row" onSubmit={(e) => onSubmit(e)}>
          <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
            <div className="row container">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div>
                  <b>Savings Amount&nbsp;:&nbsp;</b>
                  {savingAmt}
                </div>
                <div>
                  <button
                    type="button"
                    className={
                      ILAmt != 0
                        ? "btn btn-success mx-3         "
                        : "btn btn-danger mx-3"
                    }
                  ></button>
                  <b>Internal Loan </b>
                </div>
                <div>
                  <button
                    type="button"
                    className={
                      OLAmt != 0
                        ? "btn btn-success mx-3"
                        : "btn btn-danger mx-3"
                    }
                  ></button>
                  <b>Other loan </b>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6  text-right">
                <span>Active Loan&nbsp;:&nbsp;
                <button
                    type="button"
                    className={"btn btn-success"}
                  ></button>
                </span>
                <span>No Active Loan&nbsp;:&nbsp;
                <button
                    type="button"
                    className={"btn btn-danger"}
                  ></button>
                </span>
              </div>
            </div>
            <div className="col-lg-12 col-md-4 col-sm-4 col-12 mt-3">
              <label>Reason For Deactivation* :</label>
            </div>
            <div className="col-lg-12  col-md-4 col-sm-4 col-12">
              <textarea
                name="memberDeactiveReason"
                id="memberDeactiveReason"
                className="textarea form-control"
                rows="3"
                placeholder="Deactive Reason"
                onChange={(e) => onInputChange(e)}
                style={{ width: "100%" }}
                required
              ></textarea>
            </div>
            <div className="col-lg-6  col-md-4 col-sm-4 col-12 py-2">
              <label>Member Closing Amount* : </label>
              <input
                type="number"
                name="memberClossingAmt"
                value={memberClossingAmt}
                className="form-control"
                onWheel={() => document.activeElement.blur()}
                onChange={(e) => onInputChange(e)}
                onKeyDown={(e) => funcKeyDown(e)}
                required
              />
            </div>
            <div className="col-lg-6  col-md-4 col-sm-4 col-12 py-2">
              <label>Cash or Bank* : </label>
              <Select
                name="memberClossingType"
                options={[
                  { label: "Cash", value: "Cash" },
                  { label: "Bank", value: "Bank" },
                ]}
                value={memberClossingType}
                placeholder="Select Cash or Bank"
                onChange={onMemberClossingTypeChange}
                // onChange={(e) => setMemberClossingType(e)}
                theme={(theme) => ({
                  ...theme,
                  height: 26,
                  minHeight: 26,
                  borderRadius: 1,
                  colors: {
                    ...theme.colors,
                    primary: "black",
                  },
                })}
              />
              {!memberClossingTypeValid && (
                <p className="error-message h6" style={{ color: "red" }}>
                  Please select Cash or Bank.
                </p>
              )}
            </div>
            <div className="col-lg-12 col-md-4 col-sm-4 col-12 py-2">
              <label>Are You Sure You Want to Deactivate ?</label>
              {ILAmt == 0 && OLAmt == 0 ? <></>
              :
              <> 
              <br/>
              <label className="" style={{color:"red"}}>
              Please close the loan to deactivate the member.
            </label>
            </> }
            </div>
            <div
              className="col-lg-12 col-md-9 col-sm-9 col-12 Savebutton"
              size="lg"
            >
              {loading ? (
                <button
                  className="btn sub_form btn_continue blackbrd Save float-right"
                  disabled
                >
                  Loading...
                </button>
              ) : (
                <>
                  {ILAmt == 0 && OLAmt == 0 ? (
                    <>
                      <input
                        type="submit"
                        name="Save"
                        value="Submit"
                        className="btn sub_form btn_continue Save float-right"
                      />
                    </>
                  ) : (
                    <input
                    type="button"
                    disabled
                    name="Save"
                    value="Submit"
                    className="btn sub_form btn_continue Save float-right"
                  />
                  )}
                </>
              )}
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

DeactiveMember.propTypes = {
  auth: PropTypes.object.isRequired,
  deactiveMember: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  deactiveMember,
  getToBeDeativeMemberData,
})(DeactiveMember);
