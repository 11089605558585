import React from "react";
import Select from "react-select";
import { useState } from "react";
import { isEmpty } from "lodash";
const BatchIncomeTab = ({
  NextBackBtn,
  formData,
  setFormData,
  activeBank,
  allPermission,
   batchTransactionData
}) => {
  const [incomeFormData, setIncomeFormData] = useState({
    batchSavingAmtCash: formData.batchSavingAmt.cash || 0,
    batchSavingAmtBank: formData.batchSavingAmt.bankAmt || 0,
    batchSavingAmtBankName: formData.batchSavingAmt.bankName
      ? {
          label: formData.batchSavingAmt.bankName,
          value: formData.batchSavingAmt.bankName,
        }
      : "",
    batchSavingAmtBankId:formData.batchSavingAmt.bankId,
    batchSubAmtCash: formData.batchSubAmt.cash || 0,
    batchSubAmtBank: formData.batchSubAmt.bankAmt || 0,
    batchSubAmtBankName: formData.batchSubAmt.bankName
      ? {
          label: formData.batchSubAmt.bankName,
          value: formData.batchSubAmt.bankName,
        }
      : "",
    batchSubAmtBankId:formData.batchSubAmt.bankId,
    batchLoanPaidCash: formData.batchLoanPaid.cash || 0,
    batchLoanPaidBank: formData.batchLoanPaid.bankAmt || 0,
    batchLoanPaidBankName: formData.batchLoanPaid.bankName
      ? {
          label: formData.batchLoanPaid.bankName,
          value: formData.batchLoanPaid.bankName,
        }
      : "",
    batchLoanPaidBankId :formData.batchLoanPaid.bankId,
    batchOtherIncomeCash: formData.batchOtherIncome.cash || 0,
    batchOtherIncomeBank: formData.batchOtherIncome.bankAmt || 0,
    batchOtherIncomeBankName: formData.batchOtherIncome.bankName
      ? {
          label: formData.batchOtherIncome.bankName,
          value: formData.batchOtherIncome.bankName,
        }
      : "",
    batchOtherIncomeBankId:formData.batchOtherIncome.bankId,
    batchOtherContributionCash: formData.batchOtherContribution.cash || 0,
    batchOtherContributionBank: formData.batchOtherContribution.bankAmt || 0,
    batchOtherContributionBankName: formData.batchOtherContribution.bankName
      ? {
          label: formData.batchOtherContribution.bankName,
          value: formData.batchOtherContribution.bankName,
        }
      : "",
    batchOtherContributionBankId:formData.batchOtherContribution.bankId,
    batchMembershipFeesCash: formData.batchMembershipFees.cash || 0,
    batchMembershipFeesBank: formData.batchMembershipFees.bankAmt || 0,
    batchMembershipFeesBankName: formData.batchMembershipFees.bankName
      ? {
          label: formData.batchMembershipFees.bankName,
          value: formData.batchMembershipFees.bankName,
        }
      : "",
    batchMembershipFeesBankId:formData.batchMembershipFees.bankId,
    seedMoneyCash: formData.seedMoney.cash || 0,
    seedMoneyBank: formData.seedMoney.bankAmt || 0,
    seedMoneyBankName: formData.seedMoney.bankName
      ? {
          label: formData.seedMoney.bankName,
          value: formData.seedMoney.bankName,
        }
      : "",
    seedMoneyBankId:formData.seedMoney.bankId,
    deactiveMemberSavingCash: formData.deactiveMemberSaving.cash || 0,
    deactiveMemberSavingBank: formData.deactiveMemberSaving.bankAmt,
    deactiveMemberSavingBankName: formData.deactiveMemberSaving.bankName
      ? {
          label: formData.deactiveMemberSaving.bankName,
          value: formData.deactiveMemberSaving.bankName,
        }
      : "",
    deactiveMemberSavingBankId :formData.deactiveMemberSaving.bankId,
    batchInterestPaidCash: formData.batchInterestPaid.cash || 0,
    batchInterestPaidBank: formData.batchInterestPaid.bankAmt || 0,
    batchInterestPaidBankName: formData.batchInterestPaid.bankName
      ? {
          label: formData.batchInterestPaid.bankName,
          value: formData.batchInterestPaid.bankName,
        }
      : 0,
    batchInterestPaidBankId:formData.batchInterestPaid.bankId,
    // batchSecurityDepositCash: 0, //shg to mem
    // batchSecurityDepositBank: 0,
    // batchSecurityDepositBankName: null,

    // mem to SHG
    batchSecurityDepositMemToShgCash:
      formData.batchSecurityDepositMemToShg.cash || 0,
    batchSecurityDepositMemToShgBank:
      formData.batchSecurityDepositMemToShg.bankAmt || 0,
    batchSecurityDepositMemToShgBankName: formData.batchSecurityDepositMemToShg
      .bankName
      ? {
          label: formData.batchSecurityDepositMemToShg.bankName,
          value: formData.batchSecurityDepositMemToShg.bankName,
        }
      : "",
    batchSecurityDepositMemToShgBankId:formData.batchSecurityDepositMemToShg.bankId,
    //union to SHG
    sdUnionToSHGCash: formData.sdUnionToSHG.cash || 0,
    sdUnionToSHGBank: formData.sdUnionToSHG.bankAmt || 0,
    sdUnionToSHGBankName: formData.sdUnionToSHG.bankName
      ? {
          label: formData.sdUnionToSHG.bankName,
          value: formData.sdUnionToSHG.bankName,
        }
      : "",
    sdUnionToSHGBankId :formData.sdUnionToSHG.bankId,
    loanRepaidByOtherSHGCash: formData.loanRepaidByOtherSHG.cash || 0,
    loanRepaidByOtherSHGBank: formData.loanRepaidByOtherSHG.bankAmt || 0,
    loanRepaidByOtherSHGBankName: formData.loanRepaidByOtherSHG.bankName
      ? {
          label: formData.loanRepaidByOtherSHG.bankName,
          value: formData.loanRepaidByOtherSHG.bankName,
        }
      : "",
    loanRepaidByOtherSHGBankId:formData.loanRepaidByOtherSHG.bankId
  });
  let {
    batchSavingAmtCash,
    batchSavingAmtBank,
    batchSavingAmtBankName,
    batchSavingAmtBankId,

    batchSubAmtCash,
    batchSubAmtBank,
    batchSubAmtBankName,
    batchSubAmtBankId,

    batchLoanPaidCash,
    batchLoanPaidBank,
    batchLoanPaidBankName,
    batchLoanPaidBankId,

    batchOtherIncomeCash,
    batchOtherIncomeBank,
    batchOtherIncomeBankName,
    batchOtherIncomeBankId,

    batchOtherContributionCash,
    batchOtherContributionBank,
    batchOtherContributionBankName,
    batchOtherContributionBankId,

    batchMembershipFeesCash,
    batchMembershipFeesBank,
    batchMembershipFeesBankName,
    batchMembershipFeesBankId,

    seedMoneyCash,
    seedMoneyBank,
    seedMoneyBankName,
    seedMoneyBankId,

    deactiveMemberSavingCash,
    deactiveMemberSavingBank,
    deactiveMemberSavingBankName,
    deactiveMemberSavingBankId,

    batchInterestPaidCash,
    batchInterestPaidBank,
    batchInterestPaidBankName,
    batchInterestPaidBankId,

    batchSecurityDepositMemToShgCash,
    batchSecurityDepositMemToShgBank,
    batchSecurityDepositMemToShgBankName,
    batchSecurityDepositMemToShgBankId,

    sdUnionToSHGCash,
    sdUnionToSHGBank,
    sdUnionToSHGBankName,
    sdUnionToSHGBankId,

    loanRepaidByOtherSHGCash,
    loanRepaidByOtherSHGBank,
    loanRepaidByOtherSHGBankName,
    loanRepaidByOtherSHGBankId,
  } = incomeFormData;

  const onInputIncomeChange = (e, labelName = "") => {
    
    if (labelName == "") {
      setIncomeFormData({
        ...incomeFormData,
        [e.target.name]: e.target.value,
      });
    } else {
      setIncomeFormData({ ...incomeFormData, [labelName]: e });
    }
  };

  const incomeNextTab = (e) => {
    e.preventDefault();
    const incomeMap = {};

    // let finalIncomeData = {
    //   batchSavingAmt: {
    //     cash: Number(batchSavingAmtCash),
    //     bankAmt: Number(batchSavingAmtBank),
    //     bankName: batchSavingAmtBankName.label || "",
    //     bankId:batchSavingAmtBankName.bankId ||""
      
    //   },

    //   batchSubAmt: {
    //     cash: Number(batchSubAmtCash),
    //     bankAmt: Number(batchSubAmtBank),
    //     bankName: batchSubAmtBankName.label || "",
    //     bankId:batchSubAmtBankName.bankId || ""
    //   },

    //   batchLoanPaid: {
    //     cash: Number(batchLoanPaidCash),
    //     bankAmt: Number(batchLoanPaidBank),
    //     bankName: batchLoanPaidBankName.label || "",
    //     bankId: batchLoanPaidBankName.bankId || ""
    //   },

    //   batchOtherIncome: {
    //     cash: Number(batchOtherIncomeCash),
    //     bankAmt: Number(batchOtherIncomeBank),
    //     bankName: batchOtherIncomeBankName.label || "",
    //     bankId:batchOtherIncomeBankName.bankId || ""
    //   },

    //   batchOtherContribution: {
    //     cash: Number(batchOtherContributionCash),
    //     bankAmt: Number(batchOtherContributionBank),
    //     bankName: batchOtherContributionBankName.label || "",
    //     bankId:batchOtherContributionBankName.bankId || ""
    //   },

    //   batchMembershipFees: {
    //     cash: Number(batchMembershipFeesCash),
    //     bankAmt: Number(batchMembershipFeesBank),
    //     bankName: batchMembershipFeesBankName.label || "",
    //     bankId:batchMembershipFeesBankName.bankId || ""
    //   },

    //   seedMoney: {
    //     cash: Number(seedMoneyCash),
    //     bankAmt: Number(seedMoneyBank),
    //     bankName: seedMoneyBankName.label || "",
    //     bankId :seedMoneyBankName.bankId || ""
    //   },

    //   deactiveMemberSaving: {
    //     cash: Number(deactiveMemberSavingCash),
    //     bankAmt: Number(deactiveMemberSavingBank),
    //     bankName: deactiveMemberSavingBankName.label || "",
    //     bankId :deactiveMemberSavingBankName.bankId || ""
    //   },

    //   batchInterestPaid: {
    //     cash: Number(batchInterestPaidCash),
    //     bankAmt: Number(batchInterestPaidBank),
    //     bankName: batchInterestPaidBankName.label || "",
    //     bankId :batchInterestPaidBankName.bankId || ""
    //   },

    //   batchSecurityDepositMemToShg: {
    //     cash: Number(batchSecurityDepositMemToShgCash),
    //     bankAmt: Number(batchSecurityDepositMemToShgBank),
    //     bankName: batchSecurityDepositMemToShgBankName.label || "",
    //     bankId :batchSecurityDepositMemToShgBankName.bankId || ""

    //   },

    //   // sdUnionToSHGBankName
    //   sdUnionToSHG: {
    //     cash: Number(sdUnionToSHGCash),
    //     bankAmt: Number(sdUnionToSHGBank),
    //     bankName: sdUnionToSHGBankName.label || "",
    //     bankId :sdUnionToSHGBankName.bankId || ""
    //   },

    //   loanRepaidByOtherSHG: {
    //     cash: Number(loanRepaidByOtherSHGCash),
    //     bankAmt: Number(loanRepaidByOtherSHGBank),
    //     bankName: loanRepaidByOtherSHGBankName.label || "", // req flag
    //      bankId :loanRepaidByOtherSHGBankName.bankId || ""
    //   },

    // };
    ////////////////////////////////////NEW FINAL INCOME DATA///////////////////////////////////////////////
    let NEWfinalIncomeData = {
      batchSavingAmt: {
        cash: Number(batchSavingAmtCash),
        bankAmt: Number(batchSavingAmtBank)  ,
        bankName: batchSavingAmtBankName.label || "",
        bankId: batchSavingAmtBankName.bankId ? batchSavingAmtBankName.bankId : batchSavingAmtBankId ||""
      
      },

      batchSubAmt: {
        cash: Number(batchSubAmtCash),
        bankAmt: Number(batchSubAmtBank),
        bankName: batchSubAmtBankName.label || "",
        bankId: batchSubAmtBankName.bankId ?batchSubAmtBankName.bankId : batchSubAmtBankId||""
      },

      batchLoanPaid: {
        cash: Number(batchLoanPaidCash),
        bankAmt: Number(batchLoanPaidBank),
        bankName: batchLoanPaidBankName.label || "",
        bankId: batchLoanPaidBankName.bankId ? batchLoanPaidBankName.bankId : batchLoanPaidBankId||""
      },

      batchOtherIncome: {
        cash: Number(batchOtherIncomeCash),
        bankAmt: Number(batchOtherIncomeBank),
        bankName: batchOtherIncomeBankName.label || "",
        bankId:batchOtherIncomeBankName.bankId ? batchOtherIncomeBankName.bankId : batchOtherIncomeBankId||""
      },

      batchOtherContribution: {
        cash: Number(batchOtherContributionCash),
        bankAmt: Number(batchOtherContributionBank),
        bankName: batchOtherContributionBankName.label || "",
        bankId:batchOtherContributionBankName.bankId ? batchOtherContributionBankName.bankId : batchOtherContributionBankId||""
      },

      batchMembershipFees: {
        cash: Number(batchMembershipFeesCash),
        bankAmt: Number(batchMembershipFeesBank),
        bankName: batchMembershipFeesBankName.label || "",
        bankId:batchMembershipFeesBankName.bankId ? batchMembershipFeesBankName.bankId :batchMembershipFeesBankId||""
      },

      seedMoney: {
        cash: Number(seedMoneyCash),
        bankAmt: Number(seedMoneyBank),
        bankName: seedMoneyBankName.label || "",
        bankId :seedMoneyBankName.bankId ? seedMoneyBankName.bankId : seedMoneyBankId||""
      },

      deactiveMemberSaving: {
        cash: Number(deactiveMemberSavingCash),
        bankAmt: Number(deactiveMemberSavingBank),
        bankName: deactiveMemberSavingBankName.label || "",
        bankId :deactiveMemberSavingBankName.bankId ?deactiveMemberSavingBankName.bankId  : deactiveMemberSavingBankId ||""
      },

      batchInterestPaid: {
        cash: Number(batchInterestPaidCash),
        bankAmt: Number(batchInterestPaidBank),
        bankName: batchInterestPaidBankName.label || "",
        bankId :batchInterestPaidBankName.bankId ? batchInterestPaidBankName.bankId : batchInterestPaidBankId || ""
      },

      batchSecurityDepositMemToShg: {
        cash: Number(batchSecurityDepositMemToShgCash),
        bankAmt: Number(batchSecurityDepositMemToShgBank),
        bankName: batchSecurityDepositMemToShgBankName.label || "",
        bankId :batchSecurityDepositMemToShgBankName.bankId ? batchSecurityDepositMemToShgBankName.bankId : batchSecurityDepositMemToShgBankId ||""

      },

      // sdUnionToSHGBankName
      sdUnionToSHG: {
        cash: Number(sdUnionToSHGCash),
        bankAmt: Number(sdUnionToSHGBank),
        bankName: sdUnionToSHGBankName.label || "",
        bankId :sdUnionToSHGBankName.bankId ? sdUnionToSHGBankName.bankId : sdUnionToSHGBankId || ""
      },

      loanRepaidByOtherSHG: {
        cash: Number(loanRepaidByOtherSHGCash),
        bankAmt: Number(loanRepaidByOtherSHGBank),
        bankName: loanRepaidByOtherSHGBankName.label || "", // req flag
         bankId :loanRepaidByOtherSHGBankName.bankId ? loanRepaidByOtherSHGBankName.bankId :loanRepaidByOtherSHGBankId || ""
      },

    };
    for(const cat in NEWfinalIncomeData){
      const {bankId,bankName,bankAmt} = NEWfinalIncomeData[cat];
      if (!bankId) continue;
      if(!incomeMap[bankId]){
        incomeMap[bankId] = {
          batchBankName:NEWfinalIncomeData[cat].bankName ,
          batchBankNameId:NEWfinalIncomeData[cat].bankId,
          indBankInterest: 0,
          indBankCommission: 0,
          indBatchBankDeposit: 0,
          indBatchBankWithdrawals: 0,
        }
      }

      //calculate the total of bankAmts
      incomeMap[bankId].indBatchBankDeposit +=bankAmt;
    }
    //convert the object to array form
    const incomeArray = Object.keys(incomeMap).map(bankId=>({
      batchBankName:incomeMap[bankId].batchBankName ,
      batchBankNameId:bankId,
      indBankInterest:incomeMap[bankId].indBankInterest,
      indBankCommission: incomeMap[bankId].indBankCommission,
      indBatchBankDeposit: incomeMap[bankId].indBatchBankDeposit,
      indBatchBankWithdrawals: incomeMap[bankId].indBatchBankWithdrawals,
    }));

    setFormData({ ...formData, ...NEWfinalIncomeData,batchIncomeArray:incomeArray });
   
    NextBackBtn(4);
  };
  const funcKeyDown = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  
  return (
    <>
      <form onSubmit={(e) => incomeNextTab(e)}>
        <div className="row card-new py-3 col-12">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <h5>Income</h5>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
            <label className="label-control">SHG Saving Amount*&nbsp;:&nbsp;</label>
            {allPermission && allPermission.includes("batchCashBank") && (
              <>
                <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank List
                    <Select
                      name="batchSavingAmtBankName"
                      options={activeBank}
                      value={batchSavingAmtBankName}
                      onChange={(e) =>
                        onInputIncomeChange(e, "batchSavingAmtBankName")
                      }
                      isSearchable={true}
                      placeholder="Select Bank"
                      theme={(theme) => ({
                        ...theme,
                        height: 26,
                        minHeight: 26,
                        borderRadius: 1,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank Amount{" "}
                    <input
                      type="number"
                      name="batchSavingAmtBank"
                      value={batchSavingAmtBank}
                      onChange={(e) => onInputIncomeChange(e, "")}
                      className="form-control"
                      onKeyDown={(e) => funcKeyDown(e)}
                      onWheel={(e) => e.currentTarget.blur()} 
                      disabled={batchSavingAmtBankName ? false : true}
                      required
                    />
                  </div>
                </div>
              </>
            )}
            <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-6 col-md-12 col-sm-12">
                Cash
                <input
                  type="number"
                  name="batchSavingAmtCash"
                  value={batchSavingAmtCash}
                  onChange={(e) => onInputIncomeChange(e, "")}
                  className="form-control"
                  onWheel={(e) => e.currentTarget.blur()} 
                  onKeyDown={(e) => funcKeyDown(e)}
                  required
                />
              </div>
            </div>
            <label className="label-control">Subscription*&nbsp;:&nbsp;</label>
            {allPermission && allPermission.includes("batchCashBank") && (
              <>
                <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank List
                    <Select
                      name="batchSubAmtBankName"
                      options={activeBank}
                      value={batchSubAmtBankName}
                      onChange={(e) =>
                        onInputIncomeChange(e, "batchSubAmtBankName")
                      }
                      isSearchable={true}
                      placeholder="Select Bank"
                      theme={(theme) => ({
                        ...theme,
                        height: 26,
                        minHeight: 26,
                        borderRadius: 1,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank Amount{" "}
                    <input
                      type="number"
                      name="batchSubAmtBank"
                      value={batchSubAmtBank}
                      onChange={(e) => onInputIncomeChange(e, "")}
                      className="form-control"
                      onKeyDown={(e) => funcKeyDown(e)}
                      onWheel={(e) => e.currentTarget.blur()} 
                      disabled={batchSubAmtBankName ? false : true}
                      required
                    />
                  </div>
                </div>
              </>
            )}
            <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-6 col-md-12 col-sm-12">
                Cash
                <input
                  type="number"
                  name="batchSubAmtCash"
                  value={batchSubAmtCash}
                  onChange={(e) => onInputIncomeChange(e, "")}
                  className="form-control"
                  onWheel={(e) => e.currentTarget.blur()} 
                  onKeyDown={(e) => funcKeyDown(e)}
                  required
                />
              </div>
            </div>
            <label className="label-control">Other Contribution&nbsp;:&nbsp;</label>
            {allPermission && allPermission.includes("batchCashBank") && (
              <>
                <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank List
                    <Select
                      name="batchOtherContributionBank"
                      options={activeBank}
                      value={batchOtherContributionBankName}
                      onChange={(e) =>
                        onInputIncomeChange(e, "batchOtherContributionBankName")
                      }
                      isSearchable={true}
                      placeholder="Select Bank"
                      theme={(theme) => ({
                        ...theme,
                        height: 26,
                        minHeight: 26,
                        borderRadius: 1,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 ">
                    Bank Amount{" "}
                    <input
                      type="number"
                      name="batchOtherContributionBank"
                      value={batchOtherContributionBank}
                      disabled={batchOtherContributionBankName ? false : true}
                      onChange={(e) => onInputIncomeChange(e)}
                      className="form-control"
                      onWheel={(e) => e.currentTarget.blur()} 
                      onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-6 col-md-12 col-sm-12">
                Cash{" "}
                <input
                  type="number"
                  name="batchOtherContributionCash"
                  value={batchOtherContributionCash}
                  onChange={(e) => onInputIncomeChange(e)}
                  className="form-control"
                  onWheel={(e) => e.currentTarget.blur()} 
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>
            <label className="label-control">Membership Fees&nbsp;:&nbsp;</label>

            {allPermission && allPermission.includes("batchCashBank") && (
              <>
                <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank List
                    <Select
                      name="bankList"
                      options={activeBank}
                      value={batchMembershipFeesBankName}
                      onChange={(e) =>
                        onInputIncomeChange(e, "batchMembershipFeesBankName")
                      }
                      isSearchable={true}
                      placeholder="Select Bank"
                      theme={(theme) => ({
                        ...theme,
                        height: 26,
                        minHeight: 26,
                        borderRadius: 1,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank Amount{" "}
                    <input
                      type="number"
                      name="batchMembershipFeesBank"
                      value={batchMembershipFeesBank}
                      onChange={(e) => onInputIncomeChange(e)}
                      disabled={batchMembershipFeesBankName ? false : true}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-6 col-md-12 col-sm-12">
                Cash{" "}
                <input
                  type="number"
                  name="batchMembershipFeesCash"
                  value={batchMembershipFeesCash}
                  onChange={(e) => onInputIncomeChange(e)}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>
            <label className="label-control">
              IL Repayment to SHG{" "}
              <img
                className="img_icon_size log "
                src={require("../../../static/images/info.png")}
                alt="Reason"
                style={{ height: "18px", width: "18px" }}
                title="Internal Loan Repayment to SHG from the Members"
              />{" "}
              :
            </label>
            {allPermission && allPermission.includes("batchCashBank") && (
              <>
                <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank List
                    <Select
                      name="batchLoanPaidBankName"
                      options={activeBank}
                      value={batchLoanPaidBankName}
                      onChange={(e) =>
                        onInputIncomeChange(e, "batchLoanPaidBankName")
                      }
                      isSearchable={true}
                      placeholder="Select Bank"
                      theme={(theme) => ({
                        ...theme,
                        height: 26,
                        minHeight: 26,
                        borderRadius: 1,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank Amount{" "}
                    <input
                      type="number"
                      name="batchLoanPaidBank"
                      value={batchLoanPaidBank}
                      onChange={(e) => onInputIncomeChange(e)}
                      onWheel={(e) => e.currentTarget.blur()} 
                      disabled={batchLoanPaidBankName ? false : true}
                      className="form-control"
                      onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-6 col-md-12 col-sm-12">
                Cash
                <input
                  type="number"
                  name="batchLoanPaidCash"
                  value={batchLoanPaidCash}
                  className="form-control"
                  onKeyDown={(e) => funcKeyDown(e)}
                  onWheel={(e) => e.currentTarget.blur()} 
                  onChange={(e) => onInputIncomeChange(e)}
                />
              </div>
            </div>
            <label className="label-control">
              SD Union To SHG{" "}
              <img
                className="img_icon_size log "
                src={require("../../../static/images/info.png")}
                alt="Reason"
                style={{ height: "18px", width: "18px" }}
                title=" Security Deposit Amount Received from the Union to the SHG"
              />{" "}
              :
            </label>

            {allPermission && allPermission.includes("batchCashBank") && (
              <>
                <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank List
                    <Select
                      name="sdUnionToSHGBankName"
                      options={activeBank}
                      value={sdUnionToSHGBankName}
                      onChange={(e) =>
                        onInputIncomeChange(e, "sdUnionToSHGBankName")
                      }
                      isSearchable={true}
                      placeholder="Select Bank"
                      theme={(theme) => ({
                        ...theme,
                        height: 26,
                        minHeight: 26,
                        borderRadius: 1,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank Amount{" "}
                    <input
                      type="number"
                      name="sdUnionToSHGBank"
                      value={sdUnionToSHGBank}
                      disabled={sdUnionToSHGBankName ? false : true}
                      onChange={(e) => onInputIncomeChange(e)}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-6 col-md-12 col-sm-12">
                Cash{" "}
                <input
                  type="number"
                  name="sdUnionToSHGCash"
                  value={sdUnionToSHGCash}
                  onChange={(e) => onInputIncomeChange(e)}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
            <label className="label-control">
              IL Interest Paid to SHG{" "}
              <img
                className="img_icon_size log "
                src={require("../../../static/images/info.png")}
                alt="Reason"
                style={{ height: "18px", width: "18px" }}
                title="Internal Loan Interest Repayment to SHG from the Members"
              />{" "}
              :
            </label>

            {allPermission && allPermission.includes("batchCashBank") && (
              <>
                <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank List
                    <Select
                      name="batchInterestPaidBankName"
                      options={activeBank}
                      value={batchInterestPaidBankName}
                      onChange={(e) =>
                        onInputIncomeChange(e, "batchInterestPaidBankName")
                      }
                      isSearchable={true}
                      placeholder="Select Bank"
                      theme={(theme) => ({
                        ...theme,
                        height: 26,
                        minHeight: 26,
                        borderRadius: 1,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank Amount{" "}
                    <input
                      type="number"
                      name="batchInterestPaidBank"
                      value={batchInterestPaidBank}
                      disabled={batchInterestPaidBankName ? false : true}
                      onChange={(e) => onInputIncomeChange(e)}
                      className="form-control"
                      onWheel={(e) => e.currentTarget.blur()} 
                      onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-6 col-md-12 col-sm-12">
                Cash{" "}
                <input
                  type="number"
                  name="batchInterestPaidCash"
                  value={batchInterestPaidCash}
                  onChange={(e) => onInputIncomeChange(e)}
                  className="form-control"
                  onWheel={(e) => e.currentTarget.blur()} 
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>

            <label className="label-control">Other Income :</label>

            {allPermission && allPermission.includes("batchCashBank") && (
              <>
                <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank List
                    <Select
                      name="bankList"
                      options={activeBank}
                      value={batchOtherIncomeBankName}
                      onChange={(e) =>
                        onInputIncomeChange(e, "batchOtherIncomeBankName")
                      }
                      isSearchable={true}
                      placeholder="Select Bank"
                      theme={(theme) => ({
                        ...theme,
                        height: 26,
                        minHeight: 26,
                        borderRadius: 1,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank Amount{" "}
                    <input
                      type="number"
                      name="batchOtherIncomeBank"
                      value={batchOtherIncomeBank}
                      onChange={(e) => onInputIncomeChange(e)}
                      disabled={batchOtherIncomeBankName ? false : true}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-6 col-md-12 col-sm-12">
                Cash{" "}
                <input
                  type="number"
                  name="batchOtherIncomeCash"
                  value={batchOtherIncomeCash}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onKeyDown={(e) => funcKeyDown(e)}
                  onChange={(e) => onInputIncomeChange(e)}
                />
              </div>
            </div>

            <label className="label-control">
              Seed Money{" "}
              {/* <img
                className="img_icon_size log "
                src={require("../../../static/images/info.png")}
                alt="Reason"
                style={{ height: "18px", width: "18px" }}
                title="Government Grant For Shg Development (Non-Returnable)
      "
              />{" "} */}
              :
            </label>

            {allPermission && allPermission.includes("batchCashBank") && (
              <>
                <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank List
                    <Select
                      name="seedMoneyBank"
                      options={activeBank}
                      value={seedMoneyBankName}
                      onChange={(e) =>
                        onInputIncomeChange(e, "seedMoneyBankName")
                      }
                      isSearchable={true}
                      placeholder="Select Bank"
                      theme={(theme) => ({
                        ...theme,
                        height: 26,
                        minHeight: 26,
                        borderRadius: 1,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank Amount{" "}
                    <input
                      type="number"
                      name="seedMoneyBank"
                      value={seedMoneyBank}
                      disabled={seedMoneyBankName ? false : true}
                      onChange={(e) => onInputIncomeChange(e)}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-6 col-md-12 col-sm-12">
                Cash{" "}
                <input
                  type="number"
                  name="seedMoneyCash"
                  value={seedMoneyCash}
                  onChange={(e) => onInputIncomeChange(e)}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>

            <label className="label-control">
              SD Member To SHG{" "}
              <img
                className="img_icon_size log "
                src={require("../../../static/images/info.png")}
                alt="Reason"
                style={{ height: "18px", width: "18px" }}
                title="Security Deposit Amount Collected from the Members to SHG"
              />{" "}
              :
            </label>

            {allPermission && allPermission.includes("batchCashBank") && (
              <>
                <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank List
                    <Select
                      name="batchSecurityDepositMemToShgBankName"
                      options={activeBank}
                      value={batchSecurityDepositMemToShgBankName}
                      onChange={(e) =>
                        onInputIncomeChange(
                          e,
                          "batchSecurityDepositMemToShgBankName"
                        )
                      }
                      isSearchable={true}
                      placeholder="Select Bank"
                      theme={(theme) => ({
                        ...theme,
                        height: 26,
                        minHeight: 26,
                        borderRadius: 1,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank Amount{" "}
                    <input
                      type="number"
                      name="batchSecurityDepositMemToShgBank"
                      value={batchSecurityDepositMemToShgBank}
                      disabled={
                        batchSecurityDepositMemToShgBankName ? false : true
                      }
                      onChange={(e) => onInputIncomeChange(e)}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-6 col-md-12 col-sm-12">
                Cash{" "}
                <input
                  type="number"
                  name="batchSecurityDepositMemToShgCash"
                  value={batchSecurityDepositMemToShgCash}
                  onChange={(e) => onInputIncomeChange(e)}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>

            <label className="label-control">
              Deactive Member charity Amount{" "}
              <img
                className="img_icon_size log "
                src={require("../../../static/images/info.png")}
                alt="Reason"
                style={{ height: "18px", width: "18px" }}
                title="Any Amount Received from the Deactivated Member"
              />{" "}
              :
            </label>

            <div className="container">
              {allPermission && allPermission.includes("batchCashBank") && (
                <>
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12  d-flex flex-column">
                      <label htmlFor="bankList">Bank List</label>
                      <Select
                        name="deactiveMemberSavingBankName"
                        options={activeBank}
                        value={deactiveMemberSavingBankName}
                        onChange={(e) =>
                          onInputIncomeChange(e, "deactiveMemberSavingBankName")
                        }
                        isSearchable={true}
                        placeholder="Select Bank"
                        theme={(theme) => ({
                          ...theme,
                          height: 26,
                          minHeight: 26,
                          borderRadius: 1,
                          colors: {
                            ...theme.colors,
                            primary: "black",
                          },
                        })}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 d-flex flex-column">
                      <label htmlFor="deactiveMemberSavingBank">
                        Bank Amount
                      </label>
                      <input
                        type="number"
                        name="deactiveMemberSavingBank"
                        value={deactiveMemberSavingBank}
                        disabled={deactiveMemberSavingBankName ? false : true}
                        onChange={(e) => onInputIncomeChange(e)}
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        // disabled
                        onKeyDown={(e) => funcKeyDown(e)}
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12 d-flex flex-column">
                  <label htmlFor="deactiveMemberSavingCash">Cash</label>
                  <input
                    type="number"
                    name="deactiveMemberSavingCash"
                    value={deactiveMemberSavingCash}
                    onChange={(e) => onInputIncomeChange(e)}
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    onKeyDown={(e) => funcKeyDown(e)}
                  />
                </div>
              </div>
            </div>

            {allPermission && allPermission.includes("SHG-to-SHGLoan") ? (
              <>
                <label className="label-control">
                  Loan Repaid By Other SHG :
                </label>
                {allPermission && allPermission.includes("batchCashBank") && (
                  <>
                    <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        Bank List
                        <Select
                          name="loanRepaidByOtherSHGBankName"
                          options={activeBank}
                          value={loanRepaidByOtherSHGBankName}
                          onChange={(e) =>
                            onInputIncomeChange(
                              e,
                              "loanRepaidByOtherSHGBankName"
                            )
                          }
                          isSearchable={true}
                          placeholder="Select Bank"
                          theme={(theme) => ({
                            ...theme,
                            height: 26,
                            minHeight: 26,
                            borderRadius: 1,
                            colors: {
                              ...theme.colors,
                              primary: "black",
                            },
                          })}
                        />
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        Bank Amount{" "}
                        <input
                          type="number"
                          name="loanRepaidByOtherSHGBank"
                          value={loanRepaidByOtherSHGBank}
                          disabled={loanRepaidByOtherSHGBankName ? false : true}
                          onChange={(e) => onInputIncomeChange(e)}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onKeyDown={(e) => funcKeyDown(e)}
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Cash{" "}
                    <input
                      type="number"
                      name="loanRepaidByOtherSHGCash"
                      value={loanRepaidByOtherSHGCash}
                      onChange={(e) => onInputIncomeChange(e)}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <button
            type="submit"
            className="btn sub_form btn_continue Save float-right"
            // onClick={() => }
          >
            Next
          </button>
          <button
            type="button"
            className="btn sub_form btn_continue Save float-right"
            onClick={() => NextBackBtn(
              allPermission.includes("Screenshot")  ? 
              2 
              :
              allPermission &&
              allPermission.includes("Attendance") ?  1 : 0
            )}
          >
            Previous
          </button>
        </div>
      </form>
    </>
  );
};

export default BatchIncomeTab;
