import PropTypes, { number } from "prop-types";
import React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import {
  addBatchMeetingData,
  editBatchMeetingData,
  getEODMemberMeetingSummary
} from "../../../actions/shg";
import { Link, Redirect, useHistory } from "react-router-dom";

export const EODSummaryTab = ({
  shg: {
    memEODSummary,
  },
  addBatchMeetingData,
  memSummaryData,
  meetingBatchInfo,
  getMemberMeetingSummary,
  editBatchMeetingData,
  NextBackBtn,
  formData,
  allPermission,
  memberClosingAmtAll,
  batchTransactionData,
  getEODMemberMeetingSummary,
  allRights,
  user,
  fromPage,
  formattedDates
}) => {
  const {
    batchId,
    batchName,
    batchMeetingHeldOnDate,
    batchMeetingMonth,
    batchMeetingYear,
    batchTransactionType,
    meetingCategory,
    meetingNotes,

    batchSavingAmt, //BC

    batchSubAmt, //BC

    batchOtherContribution, //BC

    batchMembershipFees, //BC

    bankInterest, //NO

    deactiveMemberSaving, //BC

    subsidyReceived, //NO

    batchOtherIncome, //BC
    seedMoney, //BC
    batchSecurityDepositMemToShg, //BC
    sdUnionToSHG, //BC

    bankSDUSId,
    bankSDUS,
    selectionSDUSType,

    loanRepaidByOtherSHG, //BC
    travellingExpenses, //BC
    stationaryExpenses, //bc,
    paidToMahasangha, //bc
    bankCommission, //no
    serviceCharges, //bc
    batchDividendDistributed, //bc
    otherExpenses, //bc
    batchSavingWithdrawals, //bc
    batchSecurityDeposit, //bc
    subsidyDistributed, //no
    donationToOutsider, //bc
    sdSHGToUnion, //bc

    bankSDSUId,

    loanGivenToOtherSHG, //no
    batchLoanAmt, //bc
    batchLoanPaid, //bc
    batchInterestPaid, //bc
    otherLoanSanctionedToShg, //no
    otherLoanSanctionedToMember, //bc
    otherLoanPaidToShg, //no
    otherLoanPaidToDonor, //no
    batchOtherLoanInterestShg, //no
    batchOtherLoanInterestDonor, //no

    otherLoanBatchTransaction, // [], //Array
    bankTransactions = [], //[] //array

    cashInHand, //no
    batchBankDeposit, //no
    batchBankWithdrawals, //no
    institutionId,
    meetingFileName,
  } = formData;

  useEffect(() => {
    getEODMemberMeetingSummary({mdBatchId:formData.batchId, mdDate:formData.batchMeetingHeldOnDate});
  }, []);

  const history = useHistory();

  const fianlTabFinish = async (meetingStatus, submitStatus = "null") => {
    if (submitStatus === "Move to Verify") {

      // console.log("meetingBatchInfo",meetingBatchInfo)
      await addBatchMeetingData({ mdStatus: "Submitted", ...formData,meetingDates:formattedDates });
      history.push("/landing-page");
    } else {
      if (meetingStatus == "Verified") {
        editBatchMeetingData({
          mdStatus: "Verified",
          verifiedById: user._id,
          verifiedByDateAndTime: new Date(),
          meetingDates:formattedDates,
          ...formData,
        });
       
      } else {
        // alert("Edit ");
        editBatchMeetingData({ mdStatus: "Submitted", ...formData,meetingDates:formattedDates });
      }

      history.push("/landing-page");
    }
  };


// ///////////////////////////////////new code start/////////////////////////////////////
//   const groupByBankId = (bankTransactions) => {
//     // let data = bankTransactions.flat();
//     // let finData = [...data, ...localBankTransactions];
//     return Object.values(
//       bankTransactions.reduce((result, current) => {
//         const key = current.batchBankNameId;
//         const balance =
//           Number(current.indBatchBankDeposit) +
//           Number(current.indBankInterest) -
//           Number(current.indBatchBankWithdrawals) -
//           Number(current.indBankCommission);

//         // const bankWithdrawal=current.indBatchBankWithdrawals;

//         result[key] = result[key] || {
//           bankName: current.batchBankName,
//           bankWithdrawal: 0,
//           totalBalance: 0,
//         };
//         result[key].bankWithdrawal += Number(current.indBatchBankWithdrawals);
//         result[key].totalBalance += Number(balance);

//         return result;
//       }, {})
//     );
//   };
//   const bankArray = groupByBankId(
//     formData.bankTransactions || []
//   );
  ////////////////////////////////new code end////////////////////////////////////////
  /////////////////////////////////old code start////////////////////////////////////////
  const groupByBankId = (bankTransactions, localBankTransactions) => {
    let data = bankTransactions.flat();
    let finData = [...data, ...localBankTransactions];
    return Object.values(
      finData.reduce((result, current) => {
        const key = current.batchBankNameId;
        const balance =
          Number(current.indBatchBankDeposit) +
          Number(current.indBankInterest) -
          Number(current.indBatchBankWithdrawals) -
          Number(current.indBankCommission);

        // const bankWithdrawal=current.indBatchBankWithdrawals;

        result[key] = result[key] || {
          bankName: current.batchBankName,
          bankWithdrawal: 0,
          totalBalance: 0,
        };
        result[key].bankWithdrawal += Number(current.indBatchBankWithdrawals);
        result[key].totalBalance += Number(balance);

        return result;
      }, {})
    );
  };

  const bankArray = groupByBankId(
    memSummaryData.bankTransactions || [],
    bankTransactions || []
  );
  ///////////////////////////////old code end////////////////////////////////////////////////
  const totOtherContribution = (
    (memSummaryData && memSummaryData.batchOtherContribution ? Number(memSummaryData && memSummaryData.batchOtherContribution) : 0 ) + 
  (batchOtherContribution.cash ?   Number(batchOtherContribution.cash) : 0 + 
  batchOtherContribution.bankAmt ? Number(batchOtherContribution.bankAmt) : 0))
  return (
    <>
      {memEODSummary ? (
        <>
          <div className="row col-lg-11 col-md-11 col-sm-11 col-11">
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <h6>
                Total Savings&nbsp;:&nbsp;
                 {
                //  Number(memEODSummary.batchSavingAmt) +
                (Number(memSummaryData && memSummaryData.batchSavingAmt) || 0)+
                  Number(batchSavingAmt.cash) +
                  Number(batchSavingAmt.bankAmt)}
              </h6>
              <h6>
                Total Subscription&nbsp;:&nbsp;
                {
                Number(memSummaryData && memSummaryData.batchSubAmt) +
                  Number(batchSubAmt.cash) +
                  Number(batchSubAmt.bankAmt)}
              </h6>
              <h6>
                Total Other Contribution&nbsp;:&nbsp;
                {totOtherContribution}
              </h6>
              <h6>
                Total IL Interest Paid to SHG&nbsp;:&nbsp;
                {
                Number(memSummaryData && memSummaryData.batchInterestPaid) +
                  Number(batchInterestPaid.cash) +
                  Number(batchInterestPaid.bankAmt)}
              </h6>
              <h6>
                Total Bank Interest&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.bankInterest) + Number(bankInterest) ||
                  0}
              </h6>
              <h6>
                Subsidy Received&nbsp;:&nbsp;
                {
                 Number(memSummaryData && memSummaryData.subsidyReceived || 0) +
                  Number(subsidyReceived)}
              </h6>
              <h6>
                Total Membership Fees&nbsp;:&nbsp;
                {
                Number(memSummaryData && memSummaryData.batchMembershipFees || 0) +
                  Number(batchMembershipFees.cash || 0) +
                  Number(batchMembershipFees.bankAmt)}
              </h6>
              <h6>
                Total Other Income&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.batchOtherIncome) +
                  Number(batchOtherIncome.cash) +
                  Number(batchOtherIncome.bankAmt) || 0}
              </h6>
              <h6>
                Total Deactive Member charity Amount&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.deactiveMemberSaving) +
                  Number(deactiveMemberSaving.cash) +
                  Number(deactiveMemberSaving.bankAmt) || 0}
              </h6>
              <h6>
                Total Seed Money&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.seedMoney) +
                  Number(seedMoney.cash) +
                  Number(seedMoney.bankAmt) || 0}
              </h6>
              <h6>
                Total Security Deposit Member To Shg&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.batchSecurityDepositMemToShg) +
                  Number(batchSecurityDepositMemToShg.cash) +
                  Number(batchSecurityDepositMemToShg.bankAmt)}
              </h6>
              <h6>
                Total IL Repayment to SHG&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.batchLoanPaid) +
                  Number(batchLoanPaid.cash) +
                  Number(batchLoanPaid.bankAmt) || 0}
              </h6>
              <h6>
                Total Other Loan Sanctioned To SHG&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.otherLoanSanctionedToMember) +
                  Number(otherLoanSanctionedToMember.cash) +
                  Number(otherLoanSanctionedToMember.bankAmt) || 0}
              </h6>
              {allPermission && allPermission.includes("SHG-to-SHGLoan") ? (
                <h6>
                  Total Loan Repaid By Other SHG&nbsp;:&nbsp;
                  {Number(memSummaryData && memSummaryData.loanRepaidByOtherSHG) +
                    Number(loanRepaidByOtherSHG.cash) +
                    Number(loanRepaidByOtherSHG.bankAmt) || 0}
                </h6>
              ) : (
                <></>
              )}
              <h6>
                {/* <b>Total Income : {totalIncome(memSummaryData)}</b> */}
              </h6>
            </div>

            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <h6>
                Total Travelling Expenses&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.travellingExpenses) +
                  Number(travellingExpenses.cash) +
                  Number(travellingExpenses.bankAmt)}
              </h6>
              <h6>
                Total Stationary Expenses&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.stationaryExpenses) +
                  Number(stationaryExpenses.cash) +
                  Number(stationaryExpenses.bankAmt)}
              </h6>
              <h6>
                Total Paid To Union&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.paidToMahasangha) +
                  Number(paidToMahasangha.cash) +
                  Number(paidToMahasangha.bankAmt)}
              </h6>
              <h6>
                Total Bank Commission&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.bankCommission) +
                  Number(formData.bankCommission)}
              </h6>
              <h6>
                Total Service Charges&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.serviceCharges) +
                  Number(serviceCharges.cash) +
                  Number(serviceCharges.bankAmt)}
              </h6>
              <h6>
                Total Dividend Distributed&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.batchDividendDistributed) +
                  Number(batchDividendDistributed.cash) +
                  Number(batchDividendDistributed.bankAmt)}
              </h6>
              <h6>
                Total Other Expenses&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.otherExpenses) +
                  Number(otherExpenses.cash) +
                  Number(otherExpenses.bankAmt)}
              </h6>
              <h6>
                Total Saving Withdrawals&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.batchSavingWithdrawals) +
                  Number(batchSavingWithdrawals.cash) +
                  Number(batchSavingWithdrawals.bankAmt)}
              </h6>
              <h6>
                Total Security Deposit&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.batchSecurityDeposit) +
                  Number(batchSecurityDeposit.cash) +
                  Number(batchSecurityDeposit.bankAmt)}
              </h6>
              <h6>
                Total Donation To Outsider&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.donationToOutsider) +
                  Number(donationToOutsider.cash) +
                  Number(donationToOutsider.bankAmt)}
              </h6>
              <h6>
                Total Subsidy Distributed&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.subsidyDistributed) +
                  Number(subsidyDistributed)}
              </h6>
              <h6>
                Total OL Principal To Donor&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.otherLoanPaidToDonor) +
                  Number(otherLoanPaidToDonor)}
              </h6>
              <h6>
                Total OL Interest To Donor&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.batchOtherLoanInterestDonor) +
                  Number(batchOtherLoanInterestDonor)}
              </h6>
              <h6>
                Total Internal Loan Amount Given&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.batchLoanAmt) +
                  Number(batchLoanAmt.cash) +
                  Number(batchLoanAmt.bankAmt)}
              </h6>
              <h6>
                Total Other Loan Amount Given&nbsp;:&nbsp;
                {/* {memSummaryData.batchOtherLoanAmt} */}
                {Number(memSummaryData && memSummaryData.otherLoanSanctionedToMember) +
                  Number(otherLoanSanctionedToMember.cash) +
                  Number(otherLoanSanctionedToMember.bankAmt)}
              </h6>
              {allPermission && allPermission.includes("SHG-to-SHGLoan") ? (
                <h6>
                  Total Loan Given To Other SHG&nbsp;:&nbsp;
                  {Number(memSummaryData && memSummaryData.loanGivenToOtherSHG)}
                  {/* {Number(memSummaryData.loanGivenToOtherSHG) + Number(loanGivenToOtherSHG.cash) + Number(loanGivenToOtherSHG.bankAmt)} */}
                </h6>
              ) : (
                <></>
              )}
              <h6>
                {/* <b>Total Expense : {totalExpense(memSummaryData)}</b> */}
              </h6>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <h6>
                <b> Savings </b>
              </h6>
              <h6>
                {" "}
                After Deactivating Member&nbsp;:&nbsp;
                {
                 ((Number(memSummaryData && memSummaryData.batchSavingAmt) || 0)+    
                  Number(formData.batchSavingAmt.cash) +
                 Number(formData.batchSavingAmt.bankAmt)) -
                  (memberClosingAmtAll
                    ? memberClosingAmtAll.memberClossingAmt
                    : 0) 
                 }
              </h6>
              <h6>
                <b>Cash In Hand&nbsp;:&nbsp;{Number(formData.cashInHand || 0) }</b>
              </h6>
              <h6>
                <b>Bank Balance</b>
              </h6>
              {bankArray.map((ele, index) => {
                return (
                  <div key={index}>
                    <span>{ele.bankName}&nbsp;:&nbsp;</span>
                    {ele.totalBalance.toFixed(2)}
                  </div>
                );
              })}

              <h6>
                <b>Bank Withdrawals </b>
              </h6>

              <h6>
                {bankArray.map((ele, index) => {
                  return (
                    <div key={index}>
                      <span>{ele.bankName}&nbsp;:&nbsp;</span>
                      {ele.bankWithdrawal}
                    </div>
                  );
                })}
              </h6>

              {/* <h6>
                <b> Outstanding Amount </b>
              </h6>
              <h6 className="ml-3">
                Internal Loan Amount :{" "}
                {Number(memSummaryData.OutstandingBatchLoanAmt).toFixed(2)}
              </h6>
              <h6 className="ml-3">
                Other Loan Amount :{" "}
                {Number(memSummaryData.OutstandingBatchOtherLoanAmt).toFixed(2)}
              </h6> */}
            </div>
          </div>
        </>
      ) : (
       
         <div className="row col-lg-11 col-md-11 col-sm-11 col-11">
                   {/* /////////////new code for opening meeting statr/////////////////////////////////////////////////////////////////////////// */}

            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <h6>
                Total Savings&nbsp;:&nbsp;
                 {
                //  Number(memEODSummary.batchSavingAmt) +
                (Number(memSummaryData && memSummaryData.batchSavingAmt) || 0)+
                  Number(batchSavingAmt.cash) +
                  Number(batchSavingAmt.bankAmt)}
              </h6>
              <h6>
                Total Subscription&nbsp;:&nbsp;
                {
                Number(memSummaryData && memSummaryData.batchSubAmt) +
                  Number(batchSubAmt.cash) +
                  Number(batchSubAmt.bankAmt)}
              </h6>
              <h6>
                Total Other Contribution&nbsp;:&nbsp;
                {totOtherContribution}
              </h6>
              <h6>
                Total IL Interest Paid to SHG&nbsp;:&nbsp;
                {
                Number(memSummaryData && memSummaryData.batchInterestPaid) +
                  Number(batchInterestPaid.cash) +
                  Number(batchInterestPaid.bankAmt)}
              </h6>
              <h6>
                Total Bank Interest&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.bankInterest) + Number(bankInterest) ||
                  0}
              </h6>
              <h6>
                Subsidy Received&nbsp;:&nbsp;
                {
                 Number(memSummaryData && memSummaryData.subsidyReceived || 0) +
                  Number(subsidyReceived)}
              </h6>
              <h6>
                Total Membership Fees&nbsp;:&nbsp;
                {
                Number(memSummaryData && memSummaryData.batchMembershipFees || 0) +
                  Number(batchMembershipFees.cash || 0) +
                  Number(batchMembershipFees.bankAmt)}
              </h6>
              <h6>
                Total Other Income&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.batchOtherIncome) +
                  Number(batchOtherIncome.cash) +
                  Number(batchOtherIncome.bankAmt) || 0}
              </h6>
              <h6>
                Total Deactive Member charity Amount&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.deactiveMemberSaving) +
                  Number(deactiveMemberSaving.cash) +
                  Number(deactiveMemberSaving.bankAmt) || 0}
              </h6>
              <h6>
                Total Seed Money&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.seedMoney) +
                  Number(seedMoney.cash) +
                  Number(seedMoney.bankAmt) || 0}
              </h6>
              <h6>
                Total Security Deposit Member To Shg&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.batchSecurityDepositMemToShg) +
                  Number(batchSecurityDepositMemToShg.cash) +
                  Number(batchSecurityDepositMemToShg.bankAmt)}
              </h6>
              <h6>
                Total IL Repayment to SHG&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.batchLoanPaid) +
                  Number(batchLoanPaid.cash) +
                  Number(batchLoanPaid.bankAmt) || 0}
              </h6>
              <h6>
                Total Other Loan Sanctioned To SHG&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.otherLoanSanctionedToMember) +
                  Number(otherLoanSanctionedToMember.cash) +
                  Number(otherLoanSanctionedToMember.bankAmt) || 0}
              </h6>
              {allPermission && allPermission.includes("SHG-to-SHGLoan") ? (
                <h6>
                  Total Loan Repaid By Other SHG&nbsp;:&nbsp;
                  {Number(memSummaryData && memSummaryData.loanRepaidByOtherSHG) +
                    Number(loanRepaidByOtherSHG.cash) +
                    Number(loanRepaidByOtherSHG.bankAmt) || 0}
                </h6>
              ) : (
                <></>
              )}
              <h6>
                {/* <b>Total Income : {totalIncome(memSummaryData)}</b> */}
              </h6>
            </div>

            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <h6>
                Total Travelling Expenses&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.travellingExpenses) +
                  Number(travellingExpenses.cash) +
                  Number(travellingExpenses.bankAmt)}
              </h6>
              <h6>
                Total Stationary Expenses&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.stationaryExpenses) +
                  Number(stationaryExpenses.cash) +
                  Number(stationaryExpenses.bankAmt)}
              </h6>
              <h6>
                Total Paid To Union&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.paidToMahasangha) +
                  Number(paidToMahasangha.cash) +
                  Number(paidToMahasangha.bankAmt)}
              </h6>
              <h6>
                Total Bank Commission&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.bankCommission) +
                  Number(formData.bankCommission)}
              </h6>
              <h6>
                Total Service Charges&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.serviceCharges) +
                  Number(serviceCharges.cash) +
                  Number(serviceCharges.bankAmt)}
              </h6>
              <h6>
                Total Dividend Distributed&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.batchDividendDistributed) +
                  Number(batchDividendDistributed.cash) +
                  Number(batchDividendDistributed.bankAmt)}
              </h6>
              <h6>
                Total Other Expenses&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.otherExpenses) +
                  Number(otherExpenses.cash) +
                  Number(otherExpenses.bankAmt)}
              </h6>
              <h6>
                Total Saving Withdrawals&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.batchSavingWithdrawals) +
                  Number(batchSavingWithdrawals.cash) +
                  Number(batchSavingWithdrawals.bankAmt)}
              </h6>
              <h6>
                Total Security Deposit&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.batchSecurityDeposit) +
                  Number(batchSecurityDeposit.cash) +
                  Number(batchSecurityDeposit.bankAmt)}
              </h6>
              <h6>
                Total Donation To Outsider&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.donationToOutsider) +
                  Number(donationToOutsider.cash) +
                  Number(donationToOutsider.bankAmt)}
              </h6>
              <h6>
                Total Subsidy Distributed&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.subsidyDistributed) +
                  Number(subsidyDistributed)}
              </h6>
              <h6>
                Total OL Principal To Donor&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.otherLoanPaidToDonor) +
                  Number(otherLoanPaidToDonor)}
              </h6>
              <h6>
                Total OL Interest To Donor&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.batchOtherLoanInterestDonor) +
                  Number(batchOtherLoanInterestDonor)}
              </h6>
              <h6>
                Total Internal Loan Amount Given&nbsp;:&nbsp;
                {Number(memSummaryData && memSummaryData.batchLoanAmt) +
                  Number(batchLoanAmt.cash) +
                  Number(batchLoanAmt.bankAmt)}
              </h6>
              <h6>
                Total Other Loan Amount Given&nbsp;:&nbsp;
                {/* {memSummaryData.batchOtherLoanAmt} */}
                {Number(memSummaryData && memSummaryData.otherLoanSanctionedToMember) +
                  Number(otherLoanSanctionedToMember.cash) +
                  Number(otherLoanSanctionedToMember.bankAmt)}
              </h6>
              {allPermission && allPermission.includes("SHG-to-SHGLoan") ? (
                <h6>
                  Total Loan Given To Other SHG&nbsp;:&nbsp;
                  {Number(memSummaryData && memSummaryData.loanGivenToOtherSHG)}
                  {/* {Number(memSummaryData.loanGivenToOtherSHG) + Number(loanGivenToOtherSHG.cash) + Number(loanGivenToOtherSHG.bankAmt)} */}
                </h6>
              ) : (
                <></>
              )}
              <h6>
                {/* <b>Total Expense : {totalExpense(memSummaryData)}</b> */}
              </h6>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <h6>
                <b> Savings </b>
              </h6>
              <h6>
                {" "}
                After Deactivating Member&nbsp;:&nbsp;
                {
                 ((Number(memSummaryData && memSummaryData.batchSavingAmt) || 0)+    
                  Number(formData.batchSavingAmt.cash) +
                 Number(formData.batchSavingAmt.bankAmt)) -
                  (memberClosingAmtAll
                    ? memberClosingAmtAll.memberClossingAmt
                    : 0) 
                 }
              </h6>
              <h6>
                <b>Cash In Hand&nbsp;:&nbsp;{Number(formData.cashInHand || 0) }</b>
              </h6>
              <h6>
                <b>Bank Balance</b>
              </h6>
              {bankArray.map((ele, index) => {
                return (
                  <div key={index}>
                    <span>{ele.bankName}&nbsp;:&nbsp;</span>
                    {ele.totalBalance.toFixed(2)}
                  </div>
                );
              })}

              <h6>
                <b>Bank Withdrawals </b>
              </h6>

              <h6>
                {bankArray.map((ele, index) => {
                  return (
                    <div key={index}>
                      <span>{ele.bankName}&nbsp;:&nbsp;</span>
                      {ele.bankWithdrawal}
                    </div>
                  );
                })}
              </h6>

              {/* <h6>
                <b> Outstanding Amount </b>
              </h6>
              <h6 className="ml-3">
                Internal Loan Amount :{" "}
                {Number(memSummaryData.OutstandingBatchLoanAmt).toFixed(2)}
              </h6>
              <h6 className="ml-3">
                Other Loan Amount :{" "}
                {Number(memSummaryData.OutstandingBatchOtherLoanAmt).toFixed(2)}
              </h6> */}
            </div>
                     {/*/////////////new code for opening meeting end ////////////////////////////////////////////////////////////////////////////////*/}

          </div>

      
      )}

      <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
        {fromPage == "EditMeeting" ? (
          <>
            {batchTransactionData && batchTransactionData.verifiedById ? (
              <>
                {allRights &&
                allRights.includes(user.userGroupName + "EditReport") ? (
                  <input
                    type="button"
                    name="Submit"
                    value="Submit"
                    onClick={() => fianlTabFinish("Verified")}
                    className="btn sub_form btn_continue Save float-right"
                  />
                ) : (
                  ""
                )}
              </>
            ) : (
              <>
                {allRights &&
                allRights.includes(user.userGroupName + "VerifyMeeting") ? (
                  <>
                    <input
                      type="button"
                      onClick={() => fianlTabFinish("Verified")}
                      name="Verify"
                      value="Verify"
                      className="btn sub_form btn_continue Save float-right"
                    />
                  </>
                ) : (
                  ""
                )}

                {allRights &&
                allRights.includes(user.userGroupName + "EditMeeting") ? (
                  <>
                    <input
                      type="button"
                      onClick={() => fianlTabFinish("Submitted")}
                      name="Update"
                      value="Update"
                      className="btn sub_form btn_continue Save float-right"
                    />
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </>
        ) : (
          <>
            <input
              type="button"
              onClick={() => fianlTabFinish("Submitted", "Move to Verify")}
              name="Move to Verify"
              value="Move to Verify"
              className="btn sub_form btn_continue Save float-right"
            />
          </>
        )}

        <button
          type="button"
          className="btn sub_form btn_continue Save float-right"
          onClick={() => NextBackBtn(8)}
        >
          Previous
        </button>
      </div>
    </>
  );
};

EODSummaryTab.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

const mapDispatchToProps = { addBatchMeetingData, editBatchMeetingData,getEODMemberMeetingSummary };

export default connect(mapStateToProps, mapDispatchToProps)(EODSummaryTab);
