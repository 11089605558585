import {
  ALL_INSTITUTIONS,
  ACTIVE_INSTITUTIONS,
  ALL_LOAN_REASON,
  ALL_BATCHES,
  MEMBER_COUNT,
  ISCORDINATOR,
  MEMBER_GEN_COUNT,
  YEAR_COUNT,
  BATCH_COUNT,
  INSTITUTION_PROFILE,
  SHG_MEMBERS,
  SHG_MEMBERS_FOR_MEMBERS,
  LOAN_REASON,
  ALL_MEMBER_MEETING_BATCHES,
  ALL_BATCHES_DROPDOWN,
  ALL_MEMBER_OF_BATCH,
  SELECTED_BATCH_DATA,
  MEETINGMSG,
  MEETINGCHECKMSG,
  LAST_MEETING,
  BATCH_ID_DATA,
  PARTIAL_MEETINGS,
  VERIFICATION,
  VERIFICATION_DROPDOWN,
  PARTIAL_MEETINGS_DROPDOWN,
  BATCH_DROPDOWN,
  MEMBERS_MEETING,
  COMPLETED_MEETINGS,
  VERIFIED_MEETING_DETAILS,
  MEMBER_OR_BATCHWISE_BATCHES,
  MEMBER_OR_BATCH_BATCHES_DD,
  SHG_BATCH_INFO,
  PREVDATA,
  SUMMARY_DATA,
  SELECT_BATCH_MEMBER,
  BATCHWISE_REPORT,
  BATCHWISE_REPORT_SUM,
  INCOME_EXPENSE_REPORT,
  BATCH_SAVING_AMOUNT_COUNT,
  COMPLETED_MEETING_DETAILS,
  BATCHTRANSACTION,
  LOANSANCTIONEDDATA,
  MEMBERS_EDIT_DATA,
  BATCH_OF_PARISH,
  INCOME_EXPENSE_REPORT_FINYEAR,
  CLOSINGAMT_DATA,
  BATCH_OF_DISTRICT,
  ALL_INSTITUTIONS_AGGREMENT_DETAILS,
  INCOME_EXPENSE_OPBAL,
  SUMMARY_DATA_OP,
  MEETING_CATEGORY,
  INCHARGEREPORT,
  SHG_CODE_CHECK,
  ALL_LOAN_TYPES,
  ACTIVE_OTHER_LOANTYPE,
  GET_BANK_DATA,
  EXST_OL_DATA,
  EXST_MEM_OL_DATA,
  EXST_BANK_DATA,
  MEMBERS_FAMILY,
  MEMBERS_FAMILY_OCCUPATION,
  FAMILY_MEMBERS,
  ALL_OCCUPATION,
  DROPDOWN_OCCUPATION,
  OCC_MEMBERS,
  ALL_AGGREMENT_DETAILS,
  ALL_SHG_CATEGORY,
  DROPDOWN_ACTIVE_SHG_CATEGORY,
  BATCHES_MEMBER_COUNT,
  COUNT_INSTITUTIONS,
  MEETING_DATA,
  INCHARGE_COUNT,
  CORDINATOR_COUNT,
  OTHER_LOAN_TYPE_COUNT,
  ALL_SUB_OCCUPATION,
  SUB_OCCUPATION,
  FAMILY_SUB_OCCUPATION,
  VERIFIED_MEETING_DETAILS_SORT,
  EXST_MEM_LOAN_DATA,
  EDIT_OTHER_LOAN_TYPE_COUNT,
  ISSANCTIONED,
  BATCH_DETAILS,
  SET_LOADING_FALSE,
  SET_LOADING_TRUE,

  MEMBERS_PREVIOUS_MEETING,
  ALL_DEACTIVE_BATCHES,
  ALL_ACTIVE_BATCHES,
} from "../actions/types";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  loading: true,
  isSet: false,
  users: [],
  errorResponse: "",
  successResponse: "",
  allInstitutions: [],
  countInstitutions: [],
  activeInstitutions: [],
  allloanreason: [],
  allbatches: [],
  isloansanctioned: [],
  memberCount: [],
  memberGenCount: [],
  membersPreviousMeeting: [],
  batchCount: [],
  institutionprofile: [],
  shgMembers: [],
  shgMembersForMembers: [],
  loanreason: [],
  IsCordinator: [],
  memberSavingTotal:[],
  allmembermeetingbatches: [],
  allbatchesdropdown: [],
  membersOfBatch: [],
  selectedBatchData: [],
  meetingResponse: "",
  lastMeeting: [],
  batchIdValue: [],
  otherLoanBatchTransaction:[],
  partialmeeting: [],
  verification: [],
  verificationDropdown: [],
  batchNames:[],
  attendanceDetails:[],
  partialmeetingdropdown: [],
  batchdropdown: [],
  membersMeetingData: [],
  completedmeetings: [],
  memberorbatchwisebatches: null,
  memberorbatchwisebatchesDropdown: [],
  shgbatchinfo: [],
  prevData: [],
  summaryData: [],
  summaryDataOp: [],
  selBatchMem: [],
  batchwisereport: [],
  incomeandexpensereport: [],
  ASSAincomeandexpensereport: null,
  ASSAAssetAndLiability: null,
  batchsavingamountcount: [],
  completedmeetingdetails: [],
  verifiedmeetingdetails: [],
  batchTransactionData: [],
  loanSactionedData: [],
  memberEditData: [],
  batchOfParish: [],
  batchOfDistrict: [],
  batchOfVillage: [],
  incomeandexpensereportFinYear: [],
  memberClosingAmt: [],
  memberClosingAmtAll: [],
  allInstutionAggrementDetails: [],
  incomeExpenseOpBal: [],
  batchMeetingCategory: [],
  inchargeReport: [],
  shgcodeCheck: [],
  allLoanTypes: [],
  activeBankData: null,
  exstOLData: [],
  exstBankData: [],
  exstMemOLData: [],
  exstMemILData: [],
  membersFamily: [],
  closingAmt: {},
  membersFamilyOccupation: [],
  familyMembers: [],
  internalDel: [],
  allOccupation: [],
  activeOccupation: [],
  occupationMember: [],
  allAggrementDetails: [],
  allShgCategories: [],
  activeShgCategories: [],
  batchMemberCount: [],
  selMeetingData: [],
  inchargeCount: [],
  otherLoanTypeCount: [],
  allSubOccupation: [],
  getAllSubOccupation: [],
  getAllFamilySubOccupation: [],
  verifiedmeetingdetailssort: [],
  membersPreviousMeeting: [],
  allActiveBatches: [],
  allDeactiveBatches: [],
  getAllLoanSanctionedDetails: [],
  setOtherLoanTypeCount: [],
  memLoanReport: [],
  batchDetails: [],
  donorLoanReport: null,
  pdfBase64: "",
  editBatchDetailslist: [],
  remaingLoanData: {},
  curMemMettingLoanSanc: [],
  ToBeDevMemLoanData: [],
  ToBeDevMemSvgData: null,
  memSummaryData: null,
  batchData: null,
  storage_info:[],
  principal_interest_sum:[],
  memMeetingData: null,
  allMembers: null,
  allInstLoan: [],
  fileUploadSpinner:false,
  newBankDetails:[],
  memEODSummary :[],
  otherLoanTypeFromMember:[],
  loadStatus:true,
  loadAttendance:false
};

const shg = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ALL_INSTITUTIONS:
      return {
        ...state,
        allInstitutions: payload,
      };
      case "LOADING_TRUE":
        return {
          ...state,
          loadStatus:true
        };
        case "ATTENDENCEDETAILS":
          return {
            ...state,
            attendanceDetails:payload
          }
        case "LOADING_FALSE":
          return {
            ...state,
            loadStatus:false
          }
        case "LOAD_ATTENDANCE_FALSE":
          return {
            ...state,
            loadAttendance:false
          }
          case "LOAD_ATTENDANCE_TRUE":
            return {
              ...state,
              loadAttendance:true
            }
      case "ACTIVE-OTHERLOAN-FOR-BATCH":
        return{
          ...state,
          otherLoanTypeFromMember:payload
        }
    case "DELETED_INT_LOAN":
      return {
        ...state,
        internalDel: payload,
      };
      case "PRINCIPALINTEREST_REPORT_SUM":
        return {
          ...state,
          principal_interest_sum:payload
        };
    case "GET_DONOR_LOAN_REP":
      return {
        ...state,
        donorLoanReport: payload,
      };
    case "CLOSING_BANK_AMT":
      return {
        ...state,
        closingAmt: payload,
      };
      case "FILE_UPLOAD_SPINNER":
        return {
          ...state,
          fileUploadSpinner: payload,
        };
    case ISCORDINATOR:
      return {
        ...state,
        IsCordinator: payload,
      };
    case MEMBERS_PREVIOUS_MEETING: {
      return {
        ...state,
        membersPreviousMeeting: payload,
      };
    }
    case COUNT_INSTITUTIONS:
      return {
        ...state,
        countInstitutions: payload,
      };
    case YEAR_COUNT:
      return {
        ...state,
        yearcount: payload,
      };
    case ISSANCTIONED:
      return {
        ...state,
        isloansanctioned: payload,
      };
    case ACTIVE_INSTITUTIONS:
      return {
        ...state,
        activeInstitutions: payload,
      };
    case ALL_LOAN_REASON:
      return {
        ...state,
        allloanreason: payload,
      };
    case ALL_BATCHES:
      return {
        ...state,
        allbatches: payload,
      };
    case ALL_DEACTIVE_BATCHES:
      return {
        ...state,
        allDeactiveBatches: payload,
      };
    case ALL_ACTIVE_BATCHES:
      return {
        ...state,
        allActiveBatches: payload,
      };

    case MEMBER_COUNT:
      return {
        ...state,
        memberCount: payload,
      };
    case MEMBER_GEN_COUNT:
      return {
        ...state,
        memberGenCount: payload,
      };
    case BATCH_COUNT:
      return {
        ...state,
        batchCount: payload,
      };
    case INSTITUTION_PROFILE:
      return {
        ...state,
        institutionprofile: payload,
      };
    case SHG_MEMBERS:
      return {
        ...state,
        shgMembers: payload,
      };
    case SHG_MEMBERS_FOR_MEMBERS:
      return {
        ...state,
        shgMembersForMembers: payload,
      };
      case "STORAGE_INFO":
        return {
          ...state,
          storage_info : payload
        }
    case LOAN_REASON:
      return {
        ...state,
        loanreason: payload,
      };
    case ALL_MEMBER_MEETING_BATCHES:
      return {
        ...state,
        allmembermeetingbatches: payload,
      };
    case ALL_BATCHES_DROPDOWN:
      return {
        ...state,
        allbatchesdropdown: payload,
      };
    case ALL_MEMBER_OF_BATCH:
      return {
        ...state,
        membersOfBatch: payload,
      };

    case SELECTED_BATCH_DATA:
      return {
        ...state,
        selectedBatchData: payload,
      };
    case MEETINGMSG:
      return {
        ...state,
        meetingResponse: payload,
      };
    case MEETINGCHECKMSG:
      return {
        ...state,
        meetingCheckResponse: payload,
      };
    case LAST_MEETING:
      return {
        ...state,
        lastMeeting: payload,
      };
    case BATCH_ID_DATA:
      return {
        ...state,
        batchIdValue: payload,
      };
    case PARTIAL_MEETINGS:
      return {
        ...state,
        partialmeeting: payload,
      };
    case VERIFICATION:
      return {
        ...state,
        verification: payload,
      };
    case VERIFICATION_DROPDOWN:
      return {
        ...state,
        verificationDropdown: payload,
      };
    case PARTIAL_MEETINGS_DROPDOWN:
      return {
        ...state,
        partialmeetingdropdown: payload,
      };
    case BATCH_DROPDOWN:
      return {
        ...state,
        batchdropdown: payload,
      };
    case MEMBERS_MEETING:
      return {
        ...state,
        membersMeetingData: payload,
      };
    case COMPLETED_MEETINGS:
      return {
        ...state,
        completedmeetings: payload,
      };
    case MEMBER_OR_BATCHWISE_BATCHES:
      return {
        ...state,
        memberorbatchwisebatches: payload,
      };
    case "MEMBER_OR_BATCHWISE_BATCHES_LOADING":
      return {
        ...state,
        memberorbatchwisebatches: payload,
      };
    case MEMBER_OR_BATCH_BATCHES_DD:
      return {
        ...state,
        memberorbatchwisebatchesDropdown: payload,
      };
    case SHG_BATCH_INFO:
      return {
        ...state,
        shgbatchinfo: payload,
        isSet: true,
      };
    case PREVDATA:
      return {
        ...state,
        prevData: payload,
      };
    case "REMAINING_LOAN":
      return {
        ...state,
        remaingLoanData: payload,
      };
    case SUMMARY_DATA:
      return {
        ...state,
        summaryData: payload,
      };
    case SUMMARY_DATA_OP:
      return {
        ...state,
        summaryDataOp: payload,
      };

    case SELECT_BATCH_MEMBER:
      return {
        ...state,
        selBatchMem: payload,
      };
    case BATCHWISE_REPORT:
      return {
        ...state,
        batchwisereport: payload,
      };
    case BATCHWISE_REPORT_SUM:
      return {
        ...state,
        batchwisereportSum: payload,
      };
    case INCOME_EXPENSE_REPORT:
      return {
        ...state,
        incomeandexpensereport: payload,
      };
    case "ASSA_INCOME_EXPENSE_REPORT":
      return {
        ...state,
        ASSAincomeandexpensereport: payload,
      };

    case "ASSA_ASSETS_LIABILITY_REPORT":
      return {
        ...state,
        ASSAAssetAndLiability: payload,
      };
     case "OTHER_INTEREST_REPORT_SUM":
      return {
        ...state,
        other_interest_sum :payload
      };
    case BATCH_SAVING_AMOUNT_COUNT:
      return {
        ...state,
        batchsavingamountcount: payload,
      };
    case COMPLETED_MEETING_DETAILS:
      return {
        ...state,
        completedmeetingdetails: payload,
      };
    case VERIFIED_MEETING_DETAILS:
      return {
        ...state,
        verifiedmeetingdetails: payload,
      };
    case VERIFIED_MEETING_DETAILS_SORT:
      return {
        ...state,
        verifiedmeetingdetailssort: payload,
      };

    case BATCHTRANSACTION:
      return {
        ...state,
        batchTransactionData: payload,
        pdfBase64: payload.meetingFileName,
        isSet: true,
      };
    case LOANSANCTIONEDDATA:
      return {
        ...state,
        loanSactionedData: payload,
      };
    case MEMBERS_EDIT_DATA:
      return {
        ...state,
        memberEditData: payload,
      };
    case BATCH_OF_PARISH:
      return {
        ...state,
        batchOfParish: payload,
        allbatchesdropdown: payload,
      };
    case INCOME_EXPENSE_REPORT_FINYEAR:
      return {
        ...state,
        incomeandexpensereportFinYear: payload,
      };
      case "MEMBER_SAVING_TOTAL":
        return {
          ...state,
          memberSavingTotal:payload
        }
    case CLOSINGAMT_DATA:
      return {
        ...state,
        memberClosingAmt: payload,
      };
    case "CLOSINGAMT_DATA_ALL":
      return {
        ...state,
        memberClosingAmtAll: payload,
      };
    case BATCH_OF_DISTRICT:
      return {
        ...state,
        batchOfDistrict: payload,
      };
    case "BATCH_OF_VILLAGE":
      return {
        ...state,
        batchOfVillage: payload,
      };
    case ALL_INSTITUTIONS_AGGREMENT_DETAILS:
      return {
        ...state,
        allInstutionAggrementDetails: payload,
      };
    case INCOME_EXPENSE_OPBAL:
      return {
        ...state,
        incomeExpenseOpBal: payload,
      };
    case MEETING_CATEGORY:
      return {
        ...state,
        batchMeetingCategory: payload,
      };
    case INCHARGEREPORT:
      return {
        ...state,
        inchargeReport: payload,
      };
    case SHG_CODE_CHECK:
      return {
        ...state,
        shgcodeCheck: payload,
      };
    case ALL_LOAN_TYPES:
      return {
        ...state,
        allLoanTypes: payload,
      };
    case ACTIVE_OTHER_LOANTYPE:
      return {
        ...state,
        activeOtherLoanTypes: payload,
      };
    case GET_BANK_DATA:
      return {
        ...state,
        activeBankData: payload.banks,
      };
      case "MEM_EODSUMMARY_DATA":
        return {
          ...state,
          memEODSummary:payload
        }
      case "BATCHNAMES":
        return {
          ...state,
          batchNames:payload
        }
    case EXST_OL_DATA:
      return {
        ...state,
        exstOLData: payload,
      };
    case EXST_MEM_OL_DATA:
      return {
        ...state,
        exstMemOLData: payload,
      };
    case "EXST_MEM_IL_DATA":
      return {
        ...state,
        exstMemILData: payload,
      };
    case EXST_BANK_DATA:
      return {
        ...state,
        exstBankData: payload,
      };
    case MEMBERS_FAMILY:
      return {
        ...state,
        membersFamily: payload,
      };
    case MEMBERS_FAMILY_OCCUPATION:
      return {
        ...state,
        membersFamilyOccupation: payload,
      };
    case FAMILY_MEMBERS:
      return {
        ...state,
        familyMembers: payload,
      };
    case ALL_OCCUPATION:
      return {
        ...state,
        allOccupation: payload,
      };
    case DROPDOWN_OCCUPATION:
      return {
        ...state,
        activeOccupation: payload,
      };
    case OCC_MEMBERS:
      return {
        ...state,
        occupationMember: payload,
      };
    case ALL_AGGREMENT_DETAILS:
      return {
        ...state,
        allAggrementDetails: payload,
      };
    case ALL_SHG_CATEGORY:
      return {
        ...state,
        allShgCategories: payload,
      };
    case DROPDOWN_ACTIVE_SHG_CATEGORY:
      return {
        ...state,
        activeShgCategories: payload,
      };
    case BATCHES_MEMBER_COUNT:
      return {
        ...state,
        batchMemberCount: payload,
      };
    case MEETING_DATA:
      return {
        ...state,
        selMeetingData: payload,
      };
    case INCHARGE_COUNT:
      return {
        ...state,
        inchargeCount: payload,
      };
    case CORDINATOR_COUNT:
      return {
        ...state,
        cordinatorCount: payload,
      };
    case OTHER_LOAN_TYPE_COUNT:
      return {
        ...state,
        otherLoanTypeCount: payload,
      };
    case ALL_SUB_OCCUPATION: {
      return {
        ...state,
        allSubOccupation: payload,
      };
    }
    case SUB_OCCUPATION: {
      return {
        ...state,
        getAllSubOccupation: payload,
      };
    }
    case FAMILY_SUB_OCCUPATION: {
      return {
        ...state,
        getAllFamilySubOccupation: payload,
      };
    }
    case EXST_MEM_LOAN_DATA: {
      return {
        ...state,
        getAllLoanSanctionedDetails: payload,
      };
    }
    case EDIT_OTHER_LOAN_TYPE_COUNT: {
      return {
        ...state,
        setOtherLoanTypeCount: payload,
      };
    }
    case "GET_MEM_LOAN_REP": {
      return {
        ...state,
        memLoanReport: payload,
      };
    }
    case "GET_FILE_BASE64": {
      return {
        ...state,
        pdfBase64: payload,
      };
    }
    case "FILE_UPLOAD_SUCCESSFUL":{
      return {
        ...state,
        file_upload_successful:payload
      };
    }
    case "EDIT_BATCH_LIST": {
      return {
        ...state,
        editBatchDetailslist: payload,
      };
    }

    case "GET_CUR_MEM_METTING_LOANS": {
      return {
        ...state,
        curMemMettingLoanSanc: payload,
      };
    }

    case BATCH_DETAILS: {
      return {
        ...state,
        batchDetails: payload,
      };
    }
    case "ALL_MEMBERS": {
      return {
        ...state,
        allMembers: payload,
      };
    }

    case "GET_TOBE_DEV_MEMDATA": {
      return {
        ...state,
        ToBeDevMemLoanData: payload.cloneLoan,
        ToBeDevMemSvgData: payload.cloneSvngAmt,
      };
    }

    case "MEM_SUMMARY_DATA": {
      return {
        ...state,
        memSummaryData: payload,
      };
    }
    case "ALL_INST_LOAN": {
      return {
        ...state,
        allInstLoan: payload,
      };
    }

    case "BATCH_DATA": {
      return {
        ...state,
        batchData: payload,
      };
    }
    case "OTHER_lOAN_BATCH_TRANSACTION":{
      return {
        ...state,
        otherLoanBatchTransaction:payload
      }
    }
    case "BANK_DETAILS_DATA":{
      return {
        ...state,
        newBankDetails:payload
      }
    }
    case "INDI_MEM_DATA": {
      return {
        ...state,
        memMeetingData: {
          ...payload.memMeetingData,
          ...payload.memMeetingDataSum,
        },
      };
    }

    case "ADD_INDI_MEMBER_DATA": {
      return state;
    }

    case "EDIT_INDI_MEMBER_DATA": {
      return state;
    }

    default:
      return state;
  }
};

export default shg;
