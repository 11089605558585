import React, { useState, Fragment, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import {
  editBatch,
  getShgMembers,
  getSeletedBatchDetails,
  addNewBankDetails,
  getActiveBankData,
  getActiveShgCategory,
} from "../../actions/shg";
import { getAllIncharge, getAllCoordinators } from "../../actions/user";
import Spinner from "../layout/Spinner";
import { Modal } from "react-bootstrap";
import EditBank from "./EditBank";
import {
  getDistrict,
  getParish,
  getBlockDetails,
  getAllVillages,
  getAllTankName,
} from "../../actions/area";

const EditBatchDetails = ({
  auth: { isAuthenticated, user, users, loading },
  area: {
    varadosData,
    statesData,
    activeDistrict,
    activeParish,
    villageData,
    tankNameData,
  },
  user: { allincharge, allcoordinator },
  shg: { activeBankData, activeShgCategories },
  onUpdateModalChange,
  AddBatch,
  getStates,
  getAllIncharge,
  getAllCoordinators,
  getDistrict,
  editBatch,
  getParish,
  getShgMembers,
  getSeletedBatchDetails,
  addNewBankDetails,
  getActiveBankData,
  getBlockDetails,
  getActiveShgCategory,
  getAllVillages,
  getAllTankName,
}) => {
  let batchesdata = JSON.parse(localStorage.getItem("batchesdata"));

  useEffect(() => {
    getAllIncharge();
  }, []);
  useEffect(() => {
    getBlockDetails({
      districtIdVal: batchesdata.districtId ? batchesdata.districtId : null,
    });
  }, []);
  useEffect(() => {
    getActiveShgCategory();
  }, []);
  useEffect(() => {
    getAllCoordinators();
  }, []);
  useEffect(() => {
    getParish({
      varadoInfo:
        batchesdata && batchesdata.vId ? batchesdata && batchesdata.vId : "",
    });
  }, []);
  useEffect(() => {
    getActiveBankData({
      batchIdVal: batchesdata._id ? batchesdata._id : null,
    });
  }, []);

  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );
  let activeParishLocal = JSON.parse(localStorage.getItem("activeParishLocal"));
  let activeDistrictLocal = JSON.parse(
    localStorage.getItem("activeDistrictLocal")
  );
  let activeBlockLocal = JSON.parse(localStorage.getItem("activeBlockLocal"));

  const allPermission = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.permission &&
    loggedUserInstitutionData.permission.map((permission) =>
      allPermission.push(permission.permissionName)
    );

  ///////////////////////village and shg or jlj  dropdown start///////////////////////////////

  const [village, setVillage] = useState("");
  const [tank, setTankName] = useState("");

  useEffect(async () => {
    getAllVillages();
    getAllTankName();
  }, []);

  useEffect(() => {
    setVillage(villageData);
    setTankName(tankNameData);
  }, [villageData, tankNameData]);

  const villageDetail = [];
  village &&
    village.map((ele) => {
      villageDetail.push({
        label: ele.villageName,
        value: ele._id,
      });
    });
  const [villageName, onVillageChange] = useState(
    villageDetail.filter((ele) => ele.label === batchesdata.villageName)
  );

  const tankNameDetail = [];
  tank &&
    tank.map((ele) => {
      tankNameDetail.push({
        label: ele.tankName,
        value: ele._id,
      });
    });
  const [tankName, onTankNameChange] = useState(
    tankNameDetail.filter((ele) => ele.label === batchesdata.tankName)
  );

  const groupType = [
    {
      label: "SHG",
      value: "SHG",
    },
    {
      label: "JLG",
      value: "JLG",
    },
  ];
  const [group, setGroup] = useState(
    groupType.filter((ele) => ele.label === batchesdata.groupType)
  );

  /////////////////////village dropdown jlj end////////////////////////////////

  // code for next previous tabing starts
  const [tabIndex, setTabIndex] = useState(0);
  const NextBackBtn = (e, tabIndex) => {
    e.preventDefault();
    if (checkErrors()) {
      setTabIndex(tabIndex);
    }
  };
  //formData
  const [formData, setFormData] = useState({
    batchName:
      batchesdata && batchesdata.batchName ? batchesdata.batchName : "",
    startSelectedDate:
      batchesdata && batchesdata.batchFormationDate
        ? batchesdata.batchFormationDate
        : "",
    batchPhone:
      batchesdata && batchesdata.batchPhone ? batchesdata.batchPhone : "",
    batchPhone1:
      batchesdata && batchesdata.batchPhone1 ? batchesdata.batchPhone1 : "",
    batchInchargeName:
      batchesdata && batchesdata.batchInchargeName
        ? batchesdata.batchInchargeName
        : "",
    batchAddressLine1:
      batchesdata && batchesdata.batchAddressLine1
        ? batchesdata.batchAddressLine1
        : "",
    batchAddressLine2:
      batchesdata && batchesdata.batchAddressLine2
        ? batchesdata.batchAddressLine2
        : "",
    varadoNames:
      batchesdata && batchesdata.varadoName ? batchesdata.varadoName : "",
    parishNames:
      batchesdata && batchesdata.parishName ? batchesdata.parishName : "",
    ward: batchesdata && batchesdata.ward ? batchesdata.ward : "",
    city: batchesdata && batchesdata.city ? batchesdata.city : "",
    taluku: batchesdata && batchesdata.taluku ? batchesdata.taluku : "",
    districtName:
      batchesdata && batchesdata.districtName ? batchesdata.districtName : "",
    pincode: batchesdata && batchesdata.pincode ? batchesdata.pincode : "",
    batchCode:
      batchesdata && batchesdata.batchCode ? batchesdata.batchCode : "",
    batchContactPerson:
      batchesdata && batchesdata.batchContactPerson
        ? batchesdata.batchContactPerson
        : "",
    batchContactPerson1:
      batchesdata && batchesdata.batchContactPerson1
        ? batchesdata.batchContactPerson1
        : "",
    batchSavingAmt:
      batchesdata && batchesdata.batchSavingAmt
        ? batchesdata.batchSavingAmt
        : "",
    batchmeetingDateAddress:
      batchesdata && batchesdata.batchmeetingDateAddress
        ? batchesdata.batchmeetingDateAddress
        : "",
    batchPanCardNo:
      batchesdata && batchesdata.batchPanCardNo
        ? batchesdata.batchPanCardNo
        : "",
    batchRegistrationNo:
      batchesdata && batchesdata.batchRegistrationNo
        ? batchesdata.batchRegistrationNo
        : "",
    batchSubscriptionAmt:
      batchesdata && batchesdata.batchSubscriptionAmt
        ? batchesdata.batchSubscriptionAmt
        : "",
    batchMahasangaName:
      batchesdata && batchesdata.batchMahasangaName
        ? batchesdata.batchMahasangaName
        : "",
    meetingType:
      batchesdata && batchesdata.meetingType
        ? {
            value: batchesdata.meetingType,
            label: batchesdata.meetingType,
          }
        : "",
    batchTransactionType:
      batchesdata && batchesdata.batchTransactionType
        ? {
            value: batchesdata.batchTransactionType,
            label: batchesdata.batchTransactionType,
          }
        : "",
    isSubmitted: false,
  });
  const {
    varadoNames,
    parishNames,
    batchName,
    batchFormationDate,
    batchPhone,
    batchPhone1,
    batchContactPerson,
    batchContactPerson1,
    batchAddressLine1,
    batchAddressLine2,
    ward,
    city,
    taluku,
    InchargeName,
    pincode,
    batchCode,
    batchTransactionType,
    meetingType,
    batchSavingAmt,
    batchSubscriptionAmt,
    batchMahasangaName,
    batchmeetingDateAddress,
    batchPanCardNo,
    batchRegistrationNo,
    isSubmitted,
  } = formData;
  const [showHide, setShowHide] = useState({
    showBatchSection:
      loggedUserInstitutionData &&
      loggedUserInstitutionData.institutionType === "Zoned"
        ? true
        : false,
  });
  const { showBatchSection } = showHide;
  const TranscationMethods = [
    { value: "Memberwise", label: "Memberwise" },
    { value: "Batchwise", label: "Batchwise" },
  ];
  const MeetingTypeMethods = [
    { value: "Monthly", label: "Monthly" },
    { value: "Weekly", label: "Weekly" },
    { value: "Fortnightly", label: "Fortnightly" },
    { value: "Daily", label: "Daily" },
  ];
  const funcKeyDown = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };
  const onbatchTransactionTypeChange = (e) => {
    if (e) {
      setFormData({
        ...formData,
        batchTransactionType: e,
      });
    }
  };
  const onMeetingChange = (e) => {
    if (e) {
      setFormData({
        ...formData,
        meetingType: e,
      });
    }
  };
  const allvarado = [];
  varadosData.map((varado) =>
    allvarado.push({
      vId: varado._id,
      label: varado.varadoName,
      value: varado.varadoName,
    })
  );
  const [varado, getVaradoData] = useState(
    batchesdata
      ? allvarado && allvarado.filter((x) => x.vId === batchesdata.vId)[0]
      : ""
  );
  const [vId, setVaradoID] = useState(batchesdata && batchesdata.vId);
  const [varadoName, setvaradoName] = useState(
    batchesdata && batchesdata.varadoName
  );
  const onVaradoChange = (e) => {
    setError({
      ...error,
      ParishIdChecker: false,
      ParishErrorStyle: { color: "#000" },
    });
    var vId = "";
    var varadoName = "";
    getVaradoData(e);
    varadosData.map((varado) => {
      if (varado.varadoName === e.value) {
        vId = varado._id;
        varadoName = varado.varadoName;
      }
    });
    setVaradoID(vId);
    setvaradoName(varadoName);
    let varadoVal = {
      varadoInfo: vId,
    };
    getParish(varadoVal);
    // getParishData("");
  };
  //STATE
  const allstates = [];
  statesData.map((state) =>
    allstates.push({
      stateId: state._id,
      label: state.stateName,
      value: state.stateName,
    })
  );
  const [state, getStateData] = useState(
    batchesdata
      ? allstates &&
          allstates.filter((x) => x.stateId === batchesdata.stateId)[0]
      : ""
  );
  const [stateId, setStateID] = useState(batchesdata && batchesdata.stateId);
  const [stateName, setStateName] = useState(
    batchesdata && batchesdata.stateName
  );
  const alldistrict = [];
  activeDistrictLocal &&
    activeDistrictLocal.map((district) =>
      alldistrict.push({
        districtId: district._id,
        label: district.districtName,
        value: district.districtName,
      })
    );
  const [district, getdistrictData] = useState("");
  if (
    !district &&
    district !== null &&
    alldistrict.length > 0 &&
    batchesdata &&
    batchesdata.districtId
  ) {
    getdistrictData(
      batchesdata
        ? alldistrict &&
            alldistrict.filter(
              (x) => x.districtId === batchesdata.districtId
            )[0]
        : ""
    );
  }
  const onStateChange = (e) => {
    getStateData(e);
    setStateID(e.stateId ? e.stateId : null);
    setStateName(e.value ? e.value : "");
    getDistrict({
      stateInfo: e.stateId,
    });
    // ondistrictChange("");
  };
  const allSHGCategory = [];
  activeShgCategories.map((shgcategory) =>
    allSHGCategory.push({
      shgCategoryId: shgcategory._id,
      label: shgcategory.shgCategoryName,
      value: shgcategory.shgCategoryName,
    })
  );
  const [shgcategory, getShgcategoryData] = useState();
  if (
    !shgcategory &&
    batchesdata &&
    batchesdata.shgCategoryId &&
    allSHGCategory.length > 0
  ) {
    getShgcategoryData(
      batchesdata
        ? allSHGCategory &&
            allSHGCategory.filter(
              (x) => x.shgCategoryId === batchesdata.shgCategoryId
            )[0]
        : ""
    );
  }
  const [shgCategoryId, setShgCategoryID] = useState(
    batchesdata && batchesdata.shgCategoryId
  );
  const [shgCategoryName, setShgCategoryName] = useState(
    batchesdata && batchesdata.shgCategoryName
  );
  const onShgcategoryChange = (e) => {
    getShgcategoryData(e);
    setShgCategoryID(e.shgCategoryId ? e.shgCategoryId : null);
    setShgCategoryName(e.value ? e.value : "");
  };
  //INCHARGE
  const allincharges = [];
  allincharge &&
    allincharge.map((incharge) =>
      allincharges.push({
        batchInchargeId: incharge._id,
        label: incharge.userFullName,
        value: incharge.userFullName,
      })
    );
  const [incharge, getinchargeData] = useState();
  if (
    !incharge &&
    batchesdata &&
    batchesdata.batchInchargeId &&
    allincharges.length > 0
  ) {
    getinchargeData(
      batchesdata
        ? allincharges &&
            allincharges.filter(
              (x) => x.batchInchargeId === batchesdata.batchInchargeId
            )[0]
        : ""
    );
  }
  const [batchInchargeId, setinchargeID] = useState(
    batchesdata.batchInchargeId
  );
  const [userFullName, setinchargeName] = useState(
    batchesdata.batchInchargeName
  );
  const onInchargeChange = (e) => {
    getinchargeData(e);
    setinchargeID(e.batchInchargeId ? e.batchInchargeId : null);
    setinchargeName(e.value ? e.value : "");
  };
  //co-ordinator
  const allcoordinators = [];
  allcoordinator &&
    allcoordinator.map((coordinator) =>
      allcoordinators.push({
        batchcoordinatorId: coordinator._id,
        label: coordinator.userFullName,
        value: coordinator.userFullName,
      })
    );
  const [coordinator, getcoordinatorData] = useState();
  if (!coordinator && batchesdata.CoordinatorId && allcoordinators.length > 0) {
    getcoordinatorData(
      batchesdata
        ? allcoordinators &&
            allcoordinators.filter(
              (x) => x.batchcoordinatorId === batchesdata.CoordinatorId
            )[0]
        : ""
    );
  }

  const [batchcoordinatorId, setcoordinatorID] = useState(
    batchesdata.CoordinatorId
  );
  const [userFullName1, setcoordinatorName] = useState(
    batchesdata.CoordinatorName
  );
  const onCoordinatorsChange = (e) => {
    getcoordinatorData(e);
    setcoordinatorID(e.batchcoordinatorId ? e.batchcoordinatorId : null);
    setcoordinatorName(e.value ? e.value : "");
  };
  //PARISH
  const allparish = [];
  activeParishLocal &&
    activeParishLocal.map((parish) =>
      allparish.push({
        pId: parish._id,
        label: parish.parishName,
        value: parish.parishName,
      })
    );
  const [parish, getParishData] = useState();
  if (!parish && batchesdata.pId && allparish.length > 0) {
    getParishData(
      batchesdata
        ? allparish && allparish.filter((x) => x.pId === batchesdata.pId)[0]
        : ""
    );
  }
  const [pId, setParishID] = useState(batchesdata.pId);
  const [parishName, setParishName] = useState(
    batchesdata && batchesdata.parishName
  );
  const onParishChange = (e) => {
    setError({
      ...error,
      ParishIdChecker: true,
      ParishErrorStyle: { color: "#000" },
    });
    getParishData(e);
    setParishID(e.pId ? e.pId : null);
    setParishName(e.value ? e.value : "");
  };
  //DISTRICT
  const [districtId, setdistrictID] = useState(
    batchesdata && batchesdata.districtId
  );
  const [districtName, setdistrictName] = useState(
    batchesdata && batchesdata.districtName
  );
  const ondistrictChange = (e) => {
    setError({
      ...error,
      BlockIdChecker: false,
      BlockErrorStyle: {},
    });
    getdistrictData(e);
    setdistrictID(e.districtId ? e.districtId : "");
    setdistrictName(e.value ? e.value : "");
    // setBlock(null);
    getBlockDetails({ districtIdVal: e.districtId });
  };
  //BLOCK
  const allBlocks = [];
  activeBlockLocal &&
    activeBlockLocal.map((block) =>
      allBlocks.push({
        blockId: block._id,
        label: block.blockName,
        value: block.blockName,
      })
    );
  const [block, setBlock] = useState("");
  if (
    !block &&
    block !== null &&
    allBlocks.length > 0 &&
    batchesdata &&
    batchesdata.blockId
  ) {
    setBlock(
      batchesdata
        ? allBlocks &&
            allBlocks.filter((x) => x.blockId === batchesdata.blockId)[0]
        : ""
    );
  }
  const [blockId, setBlockID] = useState(batchesdata && batchesdata.blockId);
  const [blockName, setBlockName] = useState(
    batchesdata && batchesdata.blockName
  );
  const onBlockChange = (e) => {
    setError({
      ...error,
      BlockIdChecker: true,
      BlockErrorStyle: { color: "#000" },
    });
    setBlock(e);
    setBlockID(e.blockId ? e.blockId : null);
    setBlockName(e.value ? e.value : "");
  };
  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const [startSelectedDate, setBatchformDate] = useState(
    batchesdata.batchFormationDate
  );
  const onDateChange = (e) => {
    setBatchformDate(e.target.value);
  };
  const [error, setError] = useState({
    ParishIdChecker: batchesdata && batchesdata.pId ? true : false,
    ParishErrorStyle: {},
    bankChecker: false,
    bankErrorStyle: {},
    BlockIdChecker: batchesdata && batchesdata.blockId ? true : false,
    BlockErrorStyle: {},
  });
  const {
    ParishIdChecker,
    ParishErrorStyle,
    bankChecker,
    bankErrorStyle,
    BlockIdChecker,
    BlockErrorStyle,
  } = error;
  const checkErrors = () => {
    if (loggedUserInstitutionData.institutionType === "Zoned") {
      if (!ParishIdChecker) {
        setError({
          ...error,
          ParishErrorStyle: { color: "#F00" },
        });
        return false;
      }
    }
    if (allPermission && allPermission.includes("Blockwise")) {
      if (!BlockIdChecker) {
        setError({
          ...error,
          BlockErrorStyle: { color: "#F00" },
        });
        return false;
      }
    }
    return true;
  };
  const [isRedirectStatus, setRedirectStatus] = useState(false);
  // Bank details
  const [addData, setFormDatas] = useState({
    batchBankName: "",
    batchBankAccountNumber: "",
    batchBankBranch: "",
    batchBankIFSC: "",
  });


  const[errorBatchBankName,setErrorBatchBankName] = useState("");
  const[errorBatchBankAccountNumber,setErrorBatchBankAccountNumber] = useState("");
  const[errorBatchBankBranch,setErrorBatchBankBranch] = useState("");
  const[errorBatchBankIFSC,setErrorBatchBankIFSC] = useState("");


  const {
    batchBankName,
    batchBankAccountNumber,
    batchBankBranch,
    batchBankIFSC,
  } = addData;
  const onInputChange1 = (e) => {
    setFormDatas({ ...addData, [e.target.name]: e.target.value });
  };
  const [AddedDetails, AddDetails] = useState([]);

  const onAdd = (e) => {

 
    e.preventDefault();
    
     
    if(addData.batchBankBranch == "" ||
      addData.batchBankAccountNumber == "" || 
      addData.batchBankIFSC == "" ||
      addData.batchBankName == ""){  
      alert("Enter all bank details")
    }else{

      const newBankList = AddedDetails.filter(
        (AddDetails) => AddDetails.batchBankName === batchBankName
      );
  
  
      var banksDetails = activeBankData.banks;
      // Ensure banksDetails is properly initialized
      const existingBankList =
        (banksDetails &&
          banksDetails.filter(
            (bankDetail) => bankDetail.batchBankName === batchBankName
          )) ||
        [];
  
      if (
        newBankList &&
        existingBankList &&
        newBankList.length === 0 &&
        existingBankList.length === 0
      ) {
        if (addData && addData.batchBankName) {
          const allBankName = activeBankData.map((el) => el.batchBankName);
          if (allBankName.includes(batchBankName)) {
            //
          } else {
            const newData = {
              recordId: batchesdata && batchesdata._id,
              batchBankName: batchBankName,
              batchBankAccountNumber: batchBankAccountNumber,
              batchBankBranch: batchBankBranch,
              batchBankIFSC: batchBankIFSC,
            };
  
            setFormDatas({
              ...addData,
              batchBankName: "",
              batchBankAccountNumber: "",
              batchBankBranch: "",
              batchBankIFSC: "",
            });
            let temp = [];
            temp.push(...AddedDetails, newData);
  
            AddDetails(temp);
          }
        }
      }

    }

  
  };

  const onRemoveChange = (batchBankName) => {
    const removeList = AddedDetails.filter(
      (AddDetails) => AddDetails.batchBankName !== batchBankName
    );
    AddDetails(removeList);
  };

  const [showEditModal, setShowEditModal] = useState(false);
  const [userBankData, setUserBankData] = useState(null);
  const handleEditModalClose = () => setShowEditModal(false);
  const onEditModalChange = (e) => {
    if (e) {
      handleEditModalClose();
    }
  };
  const onUpdateBank = (bank, idx) => {
    setShowEditModal(true);
    setUserBankData(bank);
    // setUserDatas1(data.dctdata);
  };
  const onUpdate = (e) => {
    e.preventDefault();

    const finalData = {
      recordId: batchesdata ? batchesdata._id : "",
      batchName: batchName,
      batchFormationDate: startSelectedDate,
      batchPhone: batchPhone,
      batchPhone1: batchPhone1,
      batchInchargeId: batchInchargeId,
      batchInchargeName: userFullName,
      CoordinatorId: batchcoordinatorId,
      CoordinatorName: userFullName1,
      batchContactPerson: batchContactPerson,
      batchContactPerson1: batchContactPerson1,
      batchAddressLine1: batchAddressLine1,
      batchAddressLine2: batchAddressLine2,
      vId: vId,
      varadoName: varadoName,
      pId: pId,
      parishName: parishName,
      ward: ward,
      city: city,
      taluku: taluku,
      districtId: districtId,
      districtName: districtName,
      blockId: blockId,
      blockName: blockName,
      stateId: stateId,
      stateName: stateName,
      pincode: pincode,
      batchCode: batchCode,
      batchTransactionType: batchTransactionType.value,
      meetingType: meetingType.value,
      batchSavingAmt: batchSavingAmt,
      batchSubscriptionAmt: batchSubscriptionAmt,
      batchMahasangaName: batchMahasangaName,
      batchmeetingDateAddress: batchmeetingDateAddress,
      batchPanCardNo: batchPanCardNo,
      batchRegistrationNo: batchRegistrationNo,
      shgCategoryId: shgCategoryId,
      shgCategoryName: shgCategoryName,
      institutionId: user.institutionId,
      villageName: villageName.label
        ? villageName.label
        : batchesdata.villageName,
      villageId: villageName.value ? villageName.value : batchesdata.villageId,
      groupType: group.label ? group.label : batchesdata.groupType,
      tankName: tankName.label ? tankName.label : batchesdata.tankName,
      tankNameId: tankName.value ? tankName.value : batchesdata.tankNameId,
    };

    editBatch(finalData);
    for (let i = 0; i < AddedDetails.length; i++) {
      addNewBankDetails(AddedDetails[i]);
    }
    // onUpdateModalChange(true);
    if (
      batchTransactionType.value === "Memberwise" &&
      batchesdata.batchTransactionType === "Batchwise"
    ) {
      const finalData1 = {
        batchId: batchesdata._id,
        batchName: batchName,
        batchIdVal: batchesdata._id,
      };
      getShgMembers(finalData1);
      getSeletedBatchDetails(finalData1);
      setRedirectStatus(true);
    }
    setFormData({
      ...formData,
      isSubmitted: true,
    });
  };

  if (isSubmitted) {
    return <Redirect to="/all-batches" />;
  }
  if (isRedirectStatus) {
    return <Redirect to="/member-transfer" />;
  }
  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align">
        <Tabs selectedIndex={tabIndex}>
          <div className="row col-lg-11 col-md-11 col-sm-12 col-12">
            <TabList>
              <Tab tabfor="0">Batch Info</Tab>
              <Tab tabfor="1">Bank Details</Tab>
            </TabList>
          </div>
          <TabPanel tabId="0">
            <div className=" col-md-12 col-lg-12 col-sm-12 col-12 ">
              <form className="row" onSubmit={(e) => NextBackBtn(e, 1)}>
                <div className="row col-lg-12 col-md-11 col-sm-12 col-12 ">
                  <div className="col-lg-6 col-md-9 col-sm-9 col-12 py-3">
                    <div className="row card-new  pb-3">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <h5>SHG Info </h5>
                      </div>
                      <div className="col-lg-6 col-md-9 col-sm-9 col-12">
                        <label className="label-control">SHG Name* :</label>
                        <input
                          type="text"
                          name="batchName"
                          value={batchName}
                          className="form-control"
                          onChange={(e) => onInputChange(e)}
                          required
                        />
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <label className="label-control">
                          Mahasangha Name :
                        </label>
                        <input
                          type="text"
                          name="batchMahasangaName"
                          value={batchMahasangaName}
                          className="form-control"
                          onChange={(e) => onInputChange(e)}
                        />
                      </div>
                      {/* <div className="col-lg-6 col-md-9 col-sm-9 col-12">
                        <label className="label-control">
                          Contact Person Phone :
                        </label>
                        <input
                          type="number"
                          name="batchPhone"
                          value={batchPhone}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                        />
                      </div> */}
                      {/* <div className="col-lg-6 col-md-9 col-sm-9 col-12">
                        <label className="label-control">
                          Contact Person Name :
                        </label>
                        <input
                          type="text"
                          name="batchContactPerson"
                          value={batchContactPerson}
                          className="form-control"
                          onChange={(e) => onInputChange(e)}
                        />
                      </div> */}
                      {allPermission &&
                      allPermission.includes("ExtraField_Phone_Name_AWAKE") ? (
                        <>
                          <div className="col-lg-6 col-md-9 col-sm-9 col-12">
                            <label className="label-control">
                              Contact Person Phone* :
                            </label>
                            <input
                              type="number"
                              name="batchPhone"
                              value={batchPhone}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange(e)}
                            />
                          </div>
                          <div className="col-lg-6 col-md-9 col-sm-9 col-12">
                            <label className="label-control">
                              Contact Person Name* :
                            </label>
                            <input
                              type="text"
                              name="batchContactPerson"
                              value={batchContactPerson}
                              className="form-control"
                              onChange={(e) => onInputChange(e)}
                            />
                          </div>
                          <div className="col-lg-6 col-md-9 col-sm-9 col-12">
                            <label className="label-control">
                              Contact Person Phone(1)* :
                            </label>
                            <input
                              type="number"
                              name="batchPhone1"
                              value={batchPhone1}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange(e)}
                            />
                          </div>
                          <div className="col-lg-6 col-md-9 col-sm-9 col-12">
                            <label className="label-control">
                              Contact Person Name(1)* :
                            </label>
                            <input
                              type="text"
                              name="batchContactPerson1"
                              value={batchContactPerson1}
                              className="form-control"
                              onChange={(e) => onInputChange(e)}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="col-lg-6 col-md-9 col-sm-9 col-12">
                            <label className="label-control">
                              Contact Person Phone :
                            </label>
                            <input
                              type="number"
                              name="batchPhone"
                              value={batchPhone}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange(e)}
                            />
                          </div>
                          <div className="col-lg-6 col-md-9 col-sm-9 col-12">
                            <label className="label-control">
                              Contact Person Name :
                            </label>
                            <input
                              type="text"
                              name="batchContactPerson"
                              value={batchContactPerson}
                              className="form-control"
                              onChange={(e) => onInputChange(e)}
                            />
                          </div>
                        </>
                      )}
                      <div className="col-lg-6 col-md-9 col-sm-9 col-12">
                        <label className="label-control">
                          Select Incharge* :
                        </label>
                        <Select
                          name="userFullName"
                          options={allincharges}
                          isSearchable={true}
                          value={incharge}
                          placeholder="Select Incharge"
                          onChange={(e) => onInchargeChange(e)}
                          theme={(theme) => ({
                            ...theme,
                            height: 26,
                            minHeight: 26,
                            borderRadius: 1,
                            colors: {
                              ...theme.colors,
                              primary: "black",
                            },
                          })}
                        />
                      </div>
                      {allPermission && allPermission.includes("Co-ordinator") && (
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                          <label className="label-control">
                            Select Co-ordinator* :
                          </label>
                          <Select
                            name="userFullName1"
                            options={allcoordinators}
                            isSearchable={true}
                            value={coordinator}
                            placeholder="Select Co-ordinators"
                            onChange={(e) => onCoordinatorsChange(e)}
                            theme={(theme) => ({
                              ...theme,
                              height: 26,
                              minHeight: 26,
                              borderRadius: 1,
                              colors: {
                                ...theme.colors,
                                primary: "black",
                              },
                            })}
                          />
                        </div>
                      )}
                      <div className="col-lg-6 col-md-9 col-sm-9 col-12">
                        <label className="label-control">
                          SHG Formation Date* :
                        </label>
                        <input
                          type="date"
                          placeholder="dd/mm/yyyy"
                          className="form-control cpp-input datevalidation"
                          name="batchFormationDate"
                          value={startSelectedDate}
                          onChange={(e) => onDateChange(e)}
                          style={{
                            width: "65%",
                          }}
                          required
                        />
                      </div>
                      <div className="col-lg-6 col-md-9 col-sm-9 col-12">
                        <label className="label-control">Meeting Type* :</label>
                        <Select
                          name="meetingType"
                          options={MeetingTypeMethods}
                          isSearchable={false}
                          value={meetingType}
                          placeholder="Select"
                          onChange={(e) => onMeetingChange(e)}
                          theme={(theme) => ({
                            ...theme,
                            height: 26,
                            minHeight: 26,
                            borderRadius: 1,
                            colors: {
                              ...theme.colors,
                              primary: "black",
                            },
                          })}
                        />
                      </div>
                      <div className="col-lg-6 col-md-9 col-sm-9 col-12">
                        <label className="label-control">
                          SHG Transaction Type* :
                        </label>
                        <Select
                          name="batchTransactionType"
                          options={TranscationMethods}
                          isSearchable={false}
                          value={batchTransactionType}
                          placeholder="Select"
                          onChange={(e) => onbatchTransactionTypeChange(e)}
                          theme={(theme) => ({
                            ...theme,
                            height: 26,
                            minHeight: 26,
                            borderRadius: 1,
                            colors: {
                              ...theme.colors,
                              primary: "black",
                            },
                          })}
                        />
                      </div>
                      <div className="col-lg-6 col-md-9 col-sm-9 col-12">
                        <label className="label-control">
                          SHG Monthly Saving Amount* :
                        </label>
                        <input
                          type="number"
                          name="batchSavingAmt"
                          value={batchSavingAmt}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => funcKeyDown(e)}
                          required
                        />
                      </div>
                      <div className="col-lg-6 col-md-9 col-sm-9 col-12">
                        <label className="label-control">
                          SHG Subscription Amount* :
                        </label>
                        <input
                          type="number"
                          name="batchSubscriptionAmt"
                          value={
                            batchSubscriptionAmt ? batchSubscriptionAmt : 0
                          }
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => funcKeyDown(e)}
                          required
                        />
                      </div>
                      <div className="col-lg-6 col-md-9 col-sm-9 col-12">
                        <label className="label-control">SHG Code* :</label>
                        <input
                          type="text"
                          name="batchCode"
                          value={batchCode}
                          className="form-control"
                          onChange={(e) => onInputChange(e)}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row col-lg-6 col-md-9 col-sm-9 col-12">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 py-3 no_padding">
                      <div className="row card-new pb-3">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <h5>Area Info </h5>
                        </div>
                        <div className="row col-lg-12 col-md-9 col-sm-9 col-12 no_padding ">
                          <div className="col-lg-6 col-md-9 col-sm-9 col-12">
                            <label className="label-control">
                              Address line1* :
                            </label>
                            <input
                              type="text"
                              name="batchAddressLine1"
                              value={batchAddressLine1}
                              className="form-control"
                              onChange={(e) => onInputChange(e)}
                              required
                            />
                          </div>
                          <div className="col-lg-6 col-md-9 col-sm-9 col-12">
                            <label className="label-control">
                              Address line2* :
                            </label>
                            <input
                              type="text"
                              name="batchAddressLine2"
                              value={batchAddressLine2}
                              className="form-control"
                              onChange={(e) => onInputChange(e)}
                              required
                            />
                          </div>
                          <div className="col-lg-6 col-md-9 col-sm-9 col-12">
                            <label className="label-control">City* :</label>
                            <input
                              type="text"
                              name="city"
                              value={city}
                              className="form-control"
                              onChange={(e) => onInputChange(e)}
                              required
                            />
                          </div>
                          <div className="col-lg-6 col-md-9 col-sm-9 col-12">
                            <label className="label-control">State* :</label>
                            <Select
                              name="stateName"
                              options={allstates}
                              isSearchable={true}
                              value={state}
                              placeholder="Select State"
                              onChange={(e) => onStateChange(e)}
                              theme={(theme) => ({
                                ...theme,
                                height: 26,
                                minHeight: 26,
                                borderRadius: 1,
                                colors: {
                                  ...theme.colors,
                                  primary: "black",
                                },
                              })}
                            />
                          </div>
                          <div className="col-lg-6 col-md-9 col-sm-9 col-12">
                            <label className="label-control">District* :</label>
                            <Select
                              name="districtName"
                              options={alldistrict}
                              isSearchable={true}
                              value={district}
                              placeholder="Select District"
                              onChange={(e) => ondistrictChange(e)}
                              theme={(theme) => ({
                                ...theme,
                                height: 26,
                                minHeight: 26,
                                borderRadius: 1,
                                colors: {
                                  ...theme.colors,
                                  primary: "black",
                                },
                              })}
                            />
                          </div>
                          {/* <div className="col-lg-6 col-md-9 col-sm-9 col-12">
                            <label className="label-control">Taluk :</label>
                            <input
                              type="text"
                              name="taluku"
                              value={taluku}
                              className="form-control"
                              onChange={(e) => onInputChange(e)}
                            />
                          </div> */}
                          {allPermission &&
                          allPermission.includes("BatchPermission_AWAKE") ? (
                            <>
                              <div className="col-lg-6 col-md-9 col-sm-9 col-12">
                                <label className="label-control">Taluk*:</label>
                                <input
                                  type="text"
                                  name="taluku"
                                  value={taluku}
                                  className="form-control"
                                  onChange={(e) => onInputChange(e)}
                                  required
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="col-lg-6 col-md-9 col-sm-9 col-12">
                                <label className="label-control">Taluk :</label>
                                <input
                                  type="text"
                                  name="taluku"
                                  value={taluku}
                                  className="form-control"
                                  onChange={(e) => onInputChange(e)}
                                />
                              </div>
                            </>
                          )}
                          {allPermission &&
                            allPermission.includes("Blockwise") && (
                              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <label
                                  className="label-control"
                                  style={BlockErrorStyle}
                                >
                                  Select Block* :
                                </label>
                                <Select
                                  name="blockName"
                                  options={allBlocks}
                                  isSearchable={true}
                                  value={block}
                                  placeholder="Select Block"
                                  onChange={(e) => onBlockChange(e)}
                                />
                              </div>
                            )}
                          {allPermission &&
                            allPermission.includes("Villagewise") && (
                              <>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                  <label
                                    className="label-control"
                                    // style={BlockErrorStyle}
                                  >
                                    Select Village :
                                  </label>
                                  <Select
                                    name="villageName"
                                    options={villageDetail}
                                    isSearchable={true}
                                    value={villageName}
                                    placeholder="Select Village"
                                    onChange={(e) => onVillageChange(e)}
                                  />
                                </div>
                              </>
                            )}
                          {allPermission &&
                            allPermission.includes("Donor_Report") && (
                              <>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                  <label
                                    className="label-control"
                                    // style={BlockErrorStyle}
                                  >
                                    Select SHG/JLG :
                                  </label>
                                  <Select
                                    name="group"
                                    options={groupType}
                                    isSearchable={true}
                                    value={group}
                                    placeholder="Select SHG/JLG"
                                    onChange={(e) => setGroup(e)}
                                  />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                  <label
                                    className="label-control"
                                    // style={BlockErrorStyle}
                                  >
                                    Select Tank Name:
                                  </label>
                                  <Select
                                    name="tankName"
                                    options={tankNameDetail}
                                    isSearchable={true}
                                    value={tankName}
                                    placeholder="Select Tank Name"
                                    onChange={(e) => onTankNameChange(e)}
                                  />
                                </div>
                              </>
                            )}
                          <div className="col-lg-6 col-md-9 col-sm-9 col-12">
                            <label className="label-control">Pincode* :</label>
                            <input
                              type="number"
                              name="pincode"
                              value={pincode}
                              className="form-control"
                              onWheel={() => document.activeElement.blur()}
                              onChange={(e) => onInputChange(e)}
                              onKeyDown={(e) => funcKeyDown(e)}
                              required
                            />
                          </div>
                          <div className="col-lg-6 col-md-9 col-sm-9 col-12">
                            <label className="label-control">Ward:</label>
                            <input
                              type="text"
                              name="ward"
                              value={ward}
                              className="form-control"
                              onChange={(e) => onInputChange(e)}
                            />
                          </div>{" "}
                          {allPermission &&
                          allPermission.includes("BatchPermission_AWAKE") ? (
                            <>
                              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <label className="label-control">
                                  Batch Meeting Time and Place and Day* :
                                </label>
                                <input
                                  type="text"
                                  name="batchmeetingDateAddress"
                                  value={batchmeetingDateAddress}
                                  className="form-control"
                                  onChange={(e) => onInputChange(e)}
                                  required
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <label className="label-control">
                                  Batch Meeting Time and Place and Day :
                                </label>
                                <input
                                  type="text"
                                  name="batchmeetingDateAddress"
                                  value={batchmeetingDateAddress}
                                  className="form-control"
                                  onChange={(e) => onInputChange(e)}
                                />
                              </div>
                            </>
                          )}
                          {/* <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <label className="label-control">
                              Batch Meeting Time and Place and Day :
                            </label>
                            <input
                              type="text"
                              name="batchmeetingDateAddress"
                              value={batchmeetingDateAddress}
                              className="form-control"
                              onChange={(e) => onInputChange(e)}
                            />
                          </div> */}
                          {showBatchSection && (
                            <>
                              <div className="col-lg-6 col-md-9 col-sm-9 col-12">
                                <label className="label-control">
                                  Varado* :
                                </label>
                                <Select
                                  name="varadoName"
                                  options={allvarado}
                                  isSearchable={true}
                                  value={varado}
                                  placeholder="Select Varado"
                                  onChange={(e) => onVaradoChange(e)}
                                  theme={(theme) => ({
                                    ...theme,
                                    height: 26,
                                    minHeight: 26,
                                    borderRadius: 1,
                                    colors: {
                                      ...theme.colors,
                                      primary: "black",
                                    },
                                  })}
                                />
                              </div>
                              <div className="col-lg-6 col-md-9 col-sm-9 col-12">
                                <label
                                  className="label-control"
                                  style={ParishErrorStyle}
                                >
                                  Parish* :
                                </label>
                                <Select
                                  name="parishName"
                                  options={allparish}
                                  isSearchable={true}
                                  value={parish}
                                  placeholder="Select Parish"
                                  onChange={(e) => onParishChange(e)}
                                  theme={(theme) => ({
                                    ...theme,
                                    height: 26,
                                    minHeight: 26,
                                    borderRadius: 1,
                                    opacity: 1,
                                    colors: {
                                      ...theme.colors,
                                      primary: "black",
                                    },
                                  })}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12  ">
                    <div className="row card-new pb-3">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <h5>Other Info </h5>
                      </div>
                      <div className="row col-lg-12 col-md-12 col-sm-12 col-12 no_padding ">
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                          <label className="label-control">Pan Card No :</label>
                          <input
                            type="text"
                            name="batchPanCardNo"
                            value={batchPanCardNo}
                            className="form-control"
                            onChange={(e) => onInputChange(e)}
                          />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                          <label className="label-control">
                            SHG Category* :
                          </label>
                          <Select
                            name="shgcategory"
                            options={allSHGCategory}
                            isSearchable={false}
                            value={shgcategory}
                            placeholder="Select"
                            onChange={(e) => onShgcategoryChange(e)}
                            theme={(theme) => ({
                              ...theme,
                              height: 26,
                              minHeight: 26,
                              borderRadius: 1,
                              colors: {
                                ...theme.colors,
                                primary: "black",
                              },
                            })}
                          />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                          <label className="label-control">
                            Registration No :
                          </label>
                          <input
                            type="text"
                            name="batchRegistrationNo"
                            value={batchRegistrationNo}
                            className="form-control"
                            onChange={(e) => onInputChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row col-lg-12 col-md-11 col-sm-9 col-12 Savebutton no_padding"
                  size="lg"
                >
                  <div className="col-lg-8 col-md-6 col-sm-12 col-12">
                    <label className="label-control colorRed">
                      * Indicates mandatory fields, Please fill mandatory fields
                      before Submit
                    </label>
                  </div>
                  <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    {loading ? (
                      <button
                        className="btn sub_form btn_continue blackbrd Save float-right"
                        disabled
                      >
                        Loading...
                      </button>
                    ) : (
                      <input
                        type="submit"
                        name="submit"
                        value="Next"
                        className="btn sub_form btn_continue blackbrd Save float-right"
                      />
                    )}
                    <Link
                      to="/all-batches"
                      className="btn sub_form btn_continue blackbrd float-right"
                      // onClick={onUpdateModalChange}
                    >
                      Cancel
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </TabPanel>
          <TabPanel tabId="1">
            <form onSubmit={(e) => NextBackBtn(e, 2)}>
              <div className="row col-md-12 col-lg-12 col-sm-12 col-12 ">
                <div className="col-lg-6 col-md-12 col-sm-12 col-12 py-3">
                  <div className="row card-new  py-3">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <label className="label-control" style={bankErrorStyle}>
                        Bank Name<span style={{color:""}}>*</span>  :
                      </label>
                      <input
                        type="text"
                        name="batchBankName"
                        value={batchBankName}
                        className="form-control"
                        onChange={(e) => onInputChange1(e)}
                        required
                      />
                      <span style={{color:""}}>{errorBatchBankName}</span>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <label className="label-control">IFSC Code<span style={{color:""}}>*</span>  :</label>
                      <input
                        type="text"
                        name="batchBankIFSC"
                        value={batchBankIFSC}
                        className="form-control"
                        onChange={(e) => onInputChange1(e)}
                        required
                      />
                      <span style={{color:"red"}}>{errorBatchBankIFSC}</span>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <label className="label-control">Account Number<span style={{color:""}}>*</span>  :</label>
                      <input
                        type="number"
                        name="batchBankAccountNumber"
                        value={batchBankAccountNumber}
                        className="form-control"
                        onChange={(e) => onInputChange1(e)}
                        required
                      />
                      <span style={{color:"red"}}>{errorBatchBankAccountNumber}</span>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <label className="label-control">Branch<span style={{color:""}}>*</span> :</label>
                      <input
                        type="text"
                        name="batchBankBranch"
                        value={batchBankBranch}
                        className="form-control"
                        onChange={(e) => onInputChange1(e)}
                        required
                      />
                      <span style={{color:"red"}}>{errorBatchBankBranch}</span>
                    </div>
                    <div className="col-lg-12 col-md-6 col-sm-6 col-12 ">
                      <button
                        className="btn sub_form btn_continue Save "
                        onClick={(e) => onAdd(e)}
                      >
                        ADD
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12 py-3">
                  <div className="row card-new py-3">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className=" body-inner no-padding  table-responsive">
                        <table
                          className="tabllll table table-bordered table-striped table-hover"
                          id="datatable2"
                        >
                          <thead>
                            <tr>
                              <th>Bank Name</th>
                              <th>IFSC Code</th>
                              <th>Account Number</th>
                              <th>Branch</th>
                              <th>Op</th>
                            </tr>
                          </thead>
                          <tbody>
                            {activeBankData &&
                              activeBankData.map((bank, idx) => {
                                // if (staff.staffStatus === "Active")
                                return (
                                  <tr key={idx}>
                                    <td>{bank.batchBankName}</td>
                                    <td>{bank.batchBankIFSC}</td>
                                    <td>{bank.batchBankAccountNumber}</td>
                                    <td>{bank.batchBankBranch}</td>
                                    <td>
                                      <img
                                        className="img_icon_size log"
                                        onClick={() => onUpdateBank(bank, idx)}
                                        src={require("../../static/images/edit_icon.png")}
                                        alt="Edit"
                                        title="Edit"
                                      />
                                    </td>
                                  </tr>
                                );
                              })}

                            {AddedDetails &&
                              AddedDetails.map((AddDetail, idx) => {
                                return (
                                  <tr key={idx}>
                                    <td>{AddDetail.batchBankName}</td>
                                    <td>{AddDetail.batchBankIFSC}</td>
                                    <td>{AddDetail.batchBankAccountNumber}</td>
                                    <td>{AddDetail.batchBankBranch}</td>

                                    <td>
                                      <img
                                        className="img_icon_size log"
                                        onClick={() =>
                                          onRemoveChange(
                                            AddDetail.batchBankName
                                          )
                                        }
                                        src={require("../../static/images/close-buttonRed.png")}
                                        alt="Remove"
                                        title="Remove"
                                      />
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <form className="row" onSubmit={(e) => onUpdate(e)}>
              <div className="col-lg-11 col-md-12 col-sm-12 col-12">
                <input
                  type="submit"
                  name="Save"
                  value="Submit"
                  className="btn sub_form btn_continue Save float-right"
                />
                <button
                  className="btn sub_form btn_continue Save float-right"
                  onClick={(e) => NextBackBtn(e, 0)}
                >
                  Previous
                </button>
              </div>
            </form>
          </TabPanel>
        </Tabs>
        <Modal
          show={showEditModal}
          backdrop="static"
          keyboard={false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h3 className="modal-title text-center">Edit Bank Details</h3>
            </div>
            <div className="col-lg-1">
              <button onClick={handleEditModalClose} className="close">
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <EditBank
              onEditModalChange={onEditModalChange}
              allBankData={userBankData}
              batchData={batchesdata}
              // allleaddata={userDatas1}
              // from="client"
              // BankFilter={BankFilter}
            />
          </Modal.Body>
        </Modal>
      </div>
    </Fragment>
  );
};

EditBatchDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  getActiveShgCategory: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  area: state.area,
  user: state.user,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  editBatch,
  getAllIncharge,
  getAllCoordinators,
  getDistrict,
  getAllTankName,
  getAllVillages,
  getParish,
  getShgMembers,
  getSeletedBatchDetails,
  addNewBankDetails,
  getActiveBankData,
  getBlockDetails,
  getActiveShgCategory,
})(EditBatchDetails);
