import React, { useState } from "react";
import Select from "react-select";
const BatchExpenseTab = ({
  NextBackBtn,
  formData,
  setFormData,
  activeBank,
  allPermission,
}) => {
  const funcKeyDown = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  const [expenseFormData, setExpenseFormData] = useState({
    travelExpensesCash:
      formData.travellingExpenses && formData.travellingExpenses.cash,
    travelExpensesBank:
      formData.travellingExpenses && formData.travellingExpenses.bankAmt,
    travelExpensesBankName: formData.travellingExpenses.bankName
      ? {
          label: formData.travellingExpenses.bankName,
          value: formData.travellingExpenses.bankName,
        }
      : "",
    travelExpensesBankId:formData.travellingExpenses.bankId,
    StationaryCash:
      formData.stationaryExpenses && formData.stationaryExpenses.cash,
    StationaryBank:
      formData.stationaryExpenses && formData.stationaryExpenses.bankAmt,
    StationaryBankName:
      formData.stationaryExpenses && formData.stationaryExpenses.bankName
        ? {
            label: formData.stationaryExpenses.bankName,
            value: formData.stationaryExpenses.bankName,
          }
        : "",
      StationaryBankId:formData.stationaryExpenses.bankId,
    paidToMahasanghaCash:
      formData.paidToMahasangha && formData.paidToMahasangha.cash,
    paidToMahasanghaBank:
      formData.paidToMahasangha && formData.paidToMahasangha.bankAmt,
    paidToMahasanghaBankName:
      formData.paidToMahasangha && formData.paidToMahasangha.bankName
        ? {
            label: formData.paidToMahasangha.bankName,
            value: formData.paidToMahasangha.bankName,
          }
        : "",
      paidToMahasanghaBankId:formData.paidToMahasangha.bankId,
    serviceChargesCash: formData.serviceCharges && formData.serviceCharges.cash,
    serviceChargesBank:
      formData.serviceCharges && formData.serviceCharges.bankAmt,
    serviceChargesBankName:
      formData.serviceCharges && formData.serviceCharges.bankName
        ? {
            label: formData.serviceCharges.bankName,
            value: formData.serviceCharges.bankName,
          }
        : "",
      serviceChargesBankId :formData.serviceCharges.bankId,
    batchDividendDistributedCash:
      formData.batchDividendDistributed &&
      formData.batchDividendDistributed.cash,

    batchDividendDistributedBank:
      formData.batchDividendDistributed &&
      formData.batchDividendDistributed.bankAmt,

    batchDividendDistributedBankName:
      formData.batchDividendDistributed &&
      formData.batchDividendDistributed.bankName
        ? {
            label: formData.batchDividendDistributed.bankName,
            value: formData.batchDividendDistributed.bankName,
          }
        : "",
        batchDividendDistributedBankId:formData.batchDividendDistributed.bankId,
    otherExpensesCash: formData.otherExpenses && formData.otherExpenses.cash,

    otherExpensesBank: formData.otherExpenses && formData.otherExpenses.bankAmt,

    otherExpensesBankName:
      formData.otherExpenses && formData.otherExpenses.bankName
        ? {
            label: formData.otherExpenses.bankName,
            value: formData.otherExpenses.bankName,
          }
        : "",
        otherExpensesBankId:formData.otherExpenses.bankId,
    batchSavingWithdrawalsCash:
      formData.batchSavingWithdrawals && formData.batchSavingWithdrawals.cash,

    batchSavingWithdrawalsBank:
      formData.batchSavingWithdrawals &&
      formData.batchSavingWithdrawals.bankAmt,

    batchSavingWithdrawalsBankName:
      formData.batchSavingWithdrawals &&
      formData.batchSavingWithdrawals.bankName
        ? {
            label: formData.batchSavingWithdrawals.bankName,
            value: formData.batchSavingWithdrawals.bankName,
          }
        : "",
        batchSavingWithdrawalsBankId:formData.batchSavingWithdrawals.bankId,
    batchSecurityDepositCash:
      formData.batchSecurityDeposit && formData.batchSecurityDeposit.cash,

    batchSecurityDepositBank:
      formData.batchSecurityDeposit && formData.batchSecurityDeposit.bankAmt,

    batchSecurityDepositBankName:
      formData.batchSecurityDeposit && formData.batchSecurityDeposit.bankName
        ? {
            label: formData.batchSecurityDeposit.bankName,
            value: formData.batchSecurityDeposit.bankName,
          }
        : "",
        batchSecurityDepositBankId:formData.batchSecurityDeposit.bankId,
    donationToOutsiderCash:
      formData.donationToOutsider && formData.donationToOutsider.cash,

    donationToOutsiderBank:
      formData.donationToOutsider && formData.donationToOutsider.bankAmt,

    donationToOutsiderBankName:
      formData.donationToOutsider && formData.donationToOutsider.bankName
        ? {
            label: formData.donationToOutsider.bankName,
            value: formData.donationToOutsider.bankName,
          }
        : "",
        donationToOutsiderBankId:formData.donationToOutsider.bankId,
    sdSHGToUnionCash: formData.sdSHGToUnion && formData.sdSHGToUnion.cash,
    sdSHGToUnionBank: formData.sdSHGToUnion && formData.sdSHGToUnion.bankAmt,

    sdSHGToUnionBankName:
      formData.sdSHGToUnion && formData.sdSHGToUnion.bankName
        ? {
            label: formData.sdSHGToUnion.bankName,
            value: formData.sdSHGToUnion.bankName,
          }
        : "",
        sdSHGToUnionBankId:formData.sdSHGToUnion.bankId,
    loanGivenToOtherSHGCash:
      formData.loanGivenToOtherSHG && formData.loanGivenToOtherSHG.cash,

    loanGivenToOtherSHGBank:
      formData.loanGivenToOtherSHG && formData.loanGivenToOtherSHG.bankAmt,

    loanGivenToOtherSHGBankName:
      formData.loanGivenToOtherSHG && formData.loanGivenToOtherSHG.bankName
        ? {
            label: formData.loanGivenToOtherSHG.bankName,
            value: formData.loanGivenToOtherSHG.bankName,
          }
        : "",
        loanGivenToOtherSHGBankId:formData.loanGivenToOtherSHG.bankId,
    internalBankExpense: formData.internalBankExpense,
  });

  const {
    travelExpensesBankId,
    travelExpensesBankName,
    travelExpensesBank,
    travelExpensesCash,

    StationaryBankId,
    StationaryBankName,
    StationaryBank,
    StationaryCash,

    paidToMahasanghaBankId,
    paidToMahasanghaBankName,
    paidToMahasanghaBank,
    paidToMahasanghaCash,

    serviceChargesBankId,
    serviceChargesBankName,
    serviceChargesBank,
    serviceChargesCash,

    batchDividendDistributedBankId,
    batchDividendDistributedBankName,
    batchDividendDistributedBank,
    batchDividendDistributedCash,

    otherExpensesBankId,
    otherExpensesBankName,
    otherExpensesBank,
    otherExpensesCash,

    batchSavingWithdrawalsBankId,
    batchSavingWithdrawalsBankName,
    batchSavingWithdrawalsBank,
    batchSavingWithdrawalsCash,

    batchSecurityDepositBankId,
    batchSecurityDepositBankName,
    batchSecurityDepositBank,
    batchSecurityDepositCash,

    donationToOutsiderBankId,
    donationToOutsiderBankName,
    donationToOutsiderBank,
    donationToOutsiderCash,

    sdSHGToUnionBankId,
    sdSHGToUnionBankName,
    sdSHGToUnionBank,
    sdSHGToUnionCash,

    loanGivenToOtherSHGBankId,
    loanGivenToOtherSHGBankName,
    loanGivenToOtherSHGBank,
    loanGivenToOtherSHGCash,

    internalBankExpense,
  } = expenseFormData;

  const onInputChange = (e, labelName = "") => {
    if (labelName == "") {
      setExpenseFormData({
        ...expenseFormData,
        [e.target.name]: e.target.value,
      });
    } else {
      setExpenseFormData({ ...expenseFormData, [labelName]: e });
    }
  };

  const expenseNextTab = (e) => {
    e.preventDefault();
    const expenseMap = {};

    // const finalExpenseData = {
    //   travellingExpenses: {
    //     cash: Number(travelExpensesCash),
    //     bankAmt: Number(travelExpensesBank),
    //     bankName: travelExpensesBankName.label
    //       ? travelExpensesBankName.label
    //       : "",
    //     bankId: travelExpensesBankName.bankId || "",
    //   },

    //   stationaryExpenses: {
    //     cash: Number(StationaryCash),
    //     bankAmt: Number(StationaryBank),
    //     bankName: StationaryBankName.label ? StationaryBankName.label : "",
    //     bankId: StationaryBankName.bankId || "",
    //   },

    //   paidToMahasangha: {
    //     cash: Number(paidToMahasanghaCash),
    //     bankAmt: Number(paidToMahasanghaBank),
    //     bankName: paidToMahasanghaBankName.label
    //       ? paidToMahasanghaBankName.label
    //       : "",
    //     bankId: paidToMahasanghaBankName.bankId || "",
    //   },

    //   serviceCharges: {
    //     cash: Number(serviceChargesCash),
    //     bankAmt: Number(serviceChargesBank),
    //     bankName: serviceChargesBankName.label
    //       ? serviceChargesBankName.label
    //       : "",
    //     bankId: serviceChargesBankName.bankId || "",
    //   },

    //   batchDividendDistributed: {
    //     cash: Number(batchDividendDistributedCash),
    //     bankAmt: Number(batchDividendDistributedBank),
    //     bankName: batchDividendDistributedBankName.label
    //       ? batchDividendDistributedBankName.label
    //       : "",
    //     bankId: batchDividendDistributedBankName.bankId || "",
    //   },

    //   otherExpenses: {
    //     cash: Number(otherExpensesCash),
    //     bankAmt: Number(otherExpensesBank),
    //     bankName: otherExpensesBankName.label
    //       ? otherExpensesBankName.label
    //       : "",
    //     bankId: otherExpensesBankName.bankId || "",
    //   },

    //   batchSavingWithdrawals: {
    //     cash: Number(batchSavingWithdrawalsCash),
    //     bankAmt: Number(batchSavingWithdrawalsBank),
    //     bankName: batchSavingWithdrawalsBankName.label
    //       ? batchSavingWithdrawalsBankName.label
    //       : "",
    //     bankId: batchSavingWithdrawalsBankName.bankId || "",
    //   },

    //   batchSecurityDeposit: {
    //     cash: Number(batchSecurityDepositCash),
    //     bankAmt: Number(batchSecurityDepositBank),
    //     bankName: batchSecurityDepositBankName.label
    //       ? batchSecurityDepositBankName.label
    //       : "",
    //     bankId: batchSecurityDepositBankName.bankId || "",
    //   },

    //   // req flag
    //   donationToOutsider: {
    //     cash: Number(donationToOutsiderCash),
    //     bankAmt: Number(donationToOutsiderBank),
    //     bankName: donationToOutsiderBankName.label
    //       ? donationToOutsiderBankName.label
    //       : "",
    //     bankId: donationToOutsiderBankName.bankId || "",
    //   },

    //   sdSHGToUnion: {
    //     cash: Number(sdSHGToUnionCash),
    //     bankAmt: Number(sdSHGToUnionBank),
    //     bankName: sdSHGToUnionBankName.label ? sdSHGToUnionBankName.label : "",
    //     bankId: sdSHGToUnionBankName.bankId || "",
    //   },

    //   loanGivenToOtherSHG: {
    //     cash: Number(loanGivenToOtherSHGCash),
    //     bankAmt: Number(loanGivenToOtherSHGBank),
    //     bankName: loanGivenToOtherSHGBankName.label
    //       ? loanGivenToOtherSHGBankName.label
    //       : "",
    //     bankId: loanGivenToOtherSHGBankName.bankId || "",
    //   },

    //   internalBankExpense: internalBankExpense,
    // };

    ///////////////////////////////////////////////new expense start///////////////////////////////////
    const Permission =allPermission && allPermission.includes("SHG-to-SHGLoan")  
    const newfinalExpenseData = {
      travellingExpenses: {
        cash: Number(travelExpensesCash),
        bankAmt: Number(travelExpensesBank),
        bankName: travelExpensesBankName.label
          ? travelExpensesBankName.label
          : "",
        bankId: travelExpensesBankName.bankId ?travelExpensesBankName.bankId : travelExpensesBankId || "" ,
      },

      stationaryExpenses: {
        cash: Number(StationaryCash),
        bankAmt: Number(StationaryBank),
        bankName: StationaryBankName.label ? StationaryBankName.label : "",
        bankId: StationaryBankName.bankId ? StationaryBankName.bankId : StationaryBankId || "" ,
      },

      paidToMahasangha: {
        cash: Number(paidToMahasanghaCash),
        bankAmt: Number(paidToMahasanghaBank),
        bankName: paidToMahasanghaBankName.label
          ? paidToMahasanghaBankName.label
          : "",
        bankId: paidToMahasanghaBankName.bankId ? paidToMahasanghaBankName.bankId : paidToMahasanghaBankId || "" ,
      },

      serviceCharges: {
        cash: Number(serviceChargesCash),
        bankAmt: Number(serviceChargesBank),
        bankName: serviceChargesBankName.label
          ? serviceChargesBankName.label
          : "",
        bankId: serviceChargesBankName.bankId ? serviceChargesBankName.bankId : serviceChargesBankId || "" ,
      },

      batchDividendDistributed: {
        cash: Number(batchDividendDistributedCash),
        bankAmt: Number(batchDividendDistributedBank),
        bankName: batchDividendDistributedBankName.label
          ? batchDividendDistributedBankName.label
          : "",
        bankId: batchDividendDistributedBankName.bankId ? batchDividendDistributedBankName.bankId :batchDividendDistributedBankId || ""  ,
      },

      otherExpenses: {
        cash: Number(otherExpensesCash),
        bankAmt: Number(otherExpensesBank),
        bankName: otherExpensesBankName.label
          ? otherExpensesBankName.label
          : "",
        bankId: otherExpensesBankName.bankId ? otherExpensesBankName.bankId : otherExpensesBankId || "" ,
      },

      batchSavingWithdrawals: {
        cash: Number(batchSavingWithdrawalsCash),
        bankAmt: Number(batchSavingWithdrawalsBank),
        bankName: batchSavingWithdrawalsBankName.label
          ? batchSavingWithdrawalsBankName.label
          : "",
        bankId: batchSavingWithdrawalsBankName.bankId ?batchSavingWithdrawalsBankName.bankId : batchSavingWithdrawalsBankId || "" ,
      },

      batchSecurityDeposit: {
        cash: Number(batchSecurityDepositCash),
        bankAmt: Number(batchSecurityDepositBank),
        bankName: batchSecurityDepositBankName.label
          ? batchSecurityDepositBankName.label
          : "",
        bankId: batchSecurityDepositBankName.bankId ? batchSecurityDepositBankName.bankId : batchSecurityDepositBankId || "" ,
      },

      // req flag
      donationToOutsider: {
        cash: Number(donationToOutsiderCash),
        bankAmt: Number(donationToOutsiderBank),
        bankName: donationToOutsiderBankName.label
          ? donationToOutsiderBankName.label
          : "",
        bankId: donationToOutsiderBankName.bankId ? donationToOutsiderBankName.bankId : donationToOutsiderBankId || "" ,
      },

      sdSHGToUnion: {
        cash: Number(sdSHGToUnionCash),
        bankAmt: Number(sdSHGToUnionBank),
        bankName: sdSHGToUnionBankName.label ? sdSHGToUnionBankName.label : "",
        bankId: sdSHGToUnionBankName.bankId ? sdSHGToUnionBankName.bankId :sdSHGToUnionBankId || ""  ,
      },

      loanGivenToOtherSHG: {
        cash: Number(loanGivenToOtherSHGCash),
        bankAmt: Number(loanGivenToOtherSHGBank),
        bankName: loanGivenToOtherSHGBankName.label
          ? loanGivenToOtherSHGBankName.label
          : "",
        bankId: loanGivenToOtherSHGBankName.bankId ?loanGivenToOtherSHGBankName.bankId :loanGivenToOtherSHGBankId || "" ,
      },
       

        internalBankExpense: internalBankExpense  ,
      
    };
   
    for (const cat in newfinalExpenseData) {
      if (cat === "internalBankExpense" && Permission) {
        continue;
    }

    const { bankId, bankAmt, bankName } = newfinalExpenseData[cat] || {};

      if (!bankId) continue;
      if (!expenseMap[bankId]) {
        expenseMap[bankId] = {
          batchBankName: bankName,
          batchBankNameId: bankId,
          indBankInterest: 0,
          indBankCommission: 0,
          indBatchBankDeposit: 0,
          indBatchBankWithdrawals: 0,
        };
      }
      //calcultion of expense
      expenseMap[bankId].indBatchBankWithdrawals += bankAmt;
    }
    const expenseArray = Object.keys(expenseMap).map((bankId) => ({
      batchBankNameId: bankId,
      batchBankName: expenseMap[bankId].batchBankName,
      indBankInterest: 0,
      indBatchBankDeposit: 0,
      indBatchBankWithdrawals: expenseMap[bankId].indBatchBankWithdrawals,
    }));

    
    // merging the income & expense array
    // Initialize a map to hold the merged data
    const mergedMap = {};

    // Process income array
    formData.batchIncomeArray.forEach((item) => {
      const { batchBankNameId, batchBankName, indBatchBankDeposit } = item;

      // Initialize the entry in mergedMap
      if (!mergedMap[batchBankNameId]) {
        mergedMap[batchBankNameId] = {
          batchBankNameId,
          batchBankName,
          indBankInterest: 0,
          indBatchBankDeposit: 0,
          indBatchBankWithdrawals: 0,
          transactionType:"B (I/E)"
        };
      }

      // Add income amounts to deposit field
      mergedMap[batchBankNameId].indBatchBankDeposit += indBatchBankDeposit;
    });

    // // Process expense array
    // expenseArray.forEach((item) => {
    //   const { bankId, indBatchBankWithdrawals } = item;

    //   // If the bankId exists in mergedMap, add the expense amounts
    //   if (mergedMap[bankId]) {
    //     mergedMap[bankId].indBatchBankWithdrawals += indBatchBankWithdrawals;
    //   }
    // });


    expenseArray.forEach((expense) => {
      const {  batchBankNameId, indBatchBankWithdrawals } = expense;

      if (!mergedMap[batchBankNameId]) {
        mergedMap[batchBankNameId] = { ...expense, indBatchBankDeposit: 0 }; // Set deposits to 0 if no income
      } else {
        mergedMap[batchBankNameId].indBatchBankWithdrawals += Number(
          indBatchBankWithdrawals
        );
      }
    });

    // Convert mergedMap to an array
    const mergedArray = Object.values(mergedMap);

    setFormData({
      ...formData,
      ...newfinalExpenseData,
      batchIncomeAndExpenseArray: mergedArray || [],
    });
    //console.log(mergedArray)
    NextBackBtn(5);
  };

  return (
    <>
      <form onSubmit={(e) => expenseNextTab(e)}>
        <div className="row card-new  py-3 col-12">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <h5>Expense</h5>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
            <label className="label-control">Travelling Expense :</label>

            {allPermission && allPermission.includes("batchCashBank") && (
              <>
                <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank List
                    <Select
                      name="travelExpensesBankName"
                      options={activeBank}
                      value={travelExpensesBankName}
                      onChange={(e) =>
                        onInputChange(e, "travelExpensesBankName")
                      }
                      isSearchable={true}
                      placeholder="Select Bank"
                      theme={(theme) => ({
                        ...theme,
                        height: 26,
                        minHeight: 26,
                        borderRadius: 1,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank Amount
                    <input
                      type="number"
                      name="travelExpensesBank"
                      value={travelExpensesBank}
                      className="form-control"
                      onChange={(e) => onInputChange(e)}
                      disabled={travelExpensesBankName ? false : true}
                      onWheel={() => document.activeElement.blur()}
                      onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-6 col-md-12 col-sm-12">
                Cash
                <input
                  type="number"
                  name="travelExpensesCash"
                  value={travelExpensesCash}
                  onChange={(e) => onInputChange(e)}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>
            <label className="label-control">Stationary Expense :</label>

            {allPermission && allPermission.includes("batchCashBank") && (
              <>
                <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    {" "}
                    Bank List
                    <Select
                      name="StationaryBankName"
                      options={activeBank}
                      isSearchable={true}
                      value={StationaryBankName}
                      placeholder="Select Bank"
                      onChange={(e) => onInputChange(e, "StationaryBankName")}
                      theme={(theme) => ({
                        ...theme,
                        height: 26,
                        minHeight: 26,
                        borderRadius: 1,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  </div>{" "}
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank Amount{" "}
                    <input
                      type="number"
                      name="StationaryBank"
                      value={StationaryBank}
                      disabled={StationaryBankName ? false : true}
                      onChange={(e) => onInputChange(e)}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-6 col-md-12 col-sm-12">
                Cash
                <input
                  type="number"
                  name="StationaryCash"
                  value={StationaryCash}
                  onChange={(e) => onInputChange(e)}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>
            <label className="label-control">
              Paid to Union (Mahasangha)&nbsp;
              <img
                className="img_icon_size log "
                src={require("../../../static/images/info.png")}
                alt="Reason"
                style={{ height: "18px", width: "18px" }}
                title="Amount Paid /Given to the Union/Mahasanga/Federation/General HUB"
              />{" "}
              :
            </label>
            {allPermission && allPermission.includes("batchCashBank") && (
              <>
                <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    {" "}
                    Bank List
                    <Select
                      name="paidToMahasanghaBankName"
                      options={activeBank}
                      isSearchable={true}
                      value={paidToMahasanghaBankName}
                      placeholder="Select Bank"
                      onChange={(e) =>
                        onInputChange(e, "paidToMahasanghaBankName")
                      }
                      theme={(theme) => ({
                        ...theme,
                        height: 26,
                        minHeight: 26,
                        borderRadius: 1,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank Amount{" "}
                    <input
                      type="number"
                      name="paidToMahasanghaBank"
                      value={paidToMahasanghaBank}
                      disabled={paidToMahasanghaBankName ? false : true}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      onChange={(e) => onInputChange(e)}
                      onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-6 col-md-12 col-sm-12">
                Cash
                <input
                  type="number"
                  name="paidToMahasanghaCash"
                  value={paidToMahasanghaCash}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onChange={(e) => onInputChange(e)}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>
            <label className="label-control">Service Charge : </label>
            {allPermission && allPermission.includes("batchCashBank") && (
              <>
                <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    {" "}
                    Bank List
                    <Select
                      name="serviceChargesBankName"
                      options={activeBank}
                      isSearchable={true}
                      value={serviceChargesBankName}
                      placeholder="Select Bank"
                      onChange={(e) =>
                        onInputChange(e, "serviceChargesBankName")
                      }
                      theme={(theme) => ({
                        ...theme,
                        height: 26,
                        minHeight: 26,
                        borderRadius: 1,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank Amount{" "}
                    <input
                      type="number"
                      name="serviceChargesBank"
                      value={serviceChargesBank}
                      disabled={serviceChargesBankName ? false : true}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      onChange={(e) => onInputChange(e)}
                      onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-6 col-md-12 col-sm-12">
                Cash
                <input
                  type="number"
                  name="serviceChargesCash"
                  value={serviceChargesCash}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onChange={(e) => onInputChange(e)}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>
            {/* 123 */}
            <label className="label-control">
              Dividend Distributed{" "}
              <img
                className="img_icon_size log "
                src={require("../../../static/images/info.png")}
                alt="Reason"
                style={{ height: "18px", width: "18px" }}
                title="Profit Distribution to Members"
              />{" "}
              :
            </label>
            {allPermission && allPermission.includes("batchCashBank") && (
              <>
                <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank List
                    <Select
                      name="batchDividendDistributedBankName"
                      options={activeBank}
                      isSearchable={true}
                      value={batchDividendDistributedBankName}
                      placeholder="Select Bank"
                      onChange={(e) =>
                        onInputChange(e, "batchDividendDistributedBankName")
                      }
                      theme={(theme) => ({
                        ...theme,
                        height: 26,
                        minHeight: 26,
                        borderRadius: 1,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  </div>{" "}
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank Amount{" "}
                    <input
                      type="number"
                      name="batchDividendDistributedBank"
                      value={batchDividendDistributedBank}
                      disabled={batchDividendDistributedBankName ? false : true}
                      onChange={(e) => onInputChange(e)}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
              {" "}
              <div className="col-lg-6 col-md-12 col-sm-12">
                Cash
                <input
                  type="number"
                  name="batchDividendDistributedCash"
                  value={batchDividendDistributedCash}
                  onChange={(e) => onInputChange(e)}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>

            {allPermission && allPermission.includes("SHG-to-SHGLoan") && (
              <>
                <label className="label-control">Sugamya Bank savings&nbsp;:&nbsp;</label>
                <div className="row col-12  ">
                  <div className="col-lg-6 col-sm-12">
                    <input
                      type="number"
                      name="internalBankExpense"
                      value={internalBankExpense}
                      onChange={(e) => onInputChange(e)}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
            <label className="label-control">Other Expense :</label>

            {allPermission && allPermission.includes("batchCashBank") && (
              <>
                <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    {" "}
                    Bank List
                    <Select
                      name="otherExpensesBankName"
                      options={activeBank}
                      isSearchable={true}
                      value={otherExpensesBankName}
                      placeholder="Select Bank"
                      onChange={(e) =>
                        onInputChange(e, "otherExpensesBankName")
                      }
                      theme={(theme) => ({
                        ...theme,
                        height: 26,
                        minHeight: 26,
                        borderRadius: 1,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  </div>{" "}
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank Amount{" "}
                    <input
                      type="number"
                      name="otherExpensesBank"
                      value={otherExpensesBank}
                      disabled={otherExpensesBankName ? false : true}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      onChange={(e) => onInputChange(e)}
                      onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-6 col-md-12 col-sm-12">
                Cash
                <input
                  type="number"
                  name="otherExpensesCash"
                  value={otherExpensesCash}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onChange={(e) => onInputChange(e)}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>
            <label className="label-control">
              Savings Withdrawal{" "}
              <img
                className="img_icon_size log"
                src={require("../../../static/images/info.png")}
                alt="Reason"
                style={{ height: "18px", width: "18px" }}
                title=" Withdrawal of Saving Amount"
              />{" "}
              :
            </label>
            {allPermission && allPermission.includes("batchCashBank") && (
              <>
                <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank List
                    <Select
                      name="batchSavingWithdrawalsBankName"
                      options={activeBank}
                      isSearchable={true}
                      value={batchSavingWithdrawalsBankName}
                      placeholder="Select Bank"
                      onChange={(e) =>
                        onInputChange(e, "batchSavingWithdrawalsBankName")
                      }
                      theme={(theme) => ({
                        ...theme,
                        height: 26,
                        minHeight: 26,
                        borderRadius: 1,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank Amount{" "}
                    <input
                      type="number"
                      name="batchSavingWithdrawalsBank"
                      value={batchSavingWithdrawalsBank}
                      disabled={batchSavingWithdrawalsBankName ? false : true}
                      onChange={(e) => onInputChange(e)}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-6 col-md-12 col-sm-12">
                Cash
                <input
                  type="number"
                  name="batchSavingWithdrawalsCash"
                  value={batchSavingWithdrawalsCash}
                  onChange={(e) => onInputChange(e)}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>

            <label className="label-control">
              SD SHG To Member{" "}
              <img
                className="img_icon_size log "
                src={require("../../../static/images/info.png")}
                alt="Reason"
                style={{ height: "18px", width: "18px" }}
                title="Security Deposit Amount Received from SHG to Members"
              />{" "}
              :
            </label>
            {allPermission && allPermission.includes("batchCashBank") && (
              <>
                <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank List
                    <Select
                      name="batchSecurityDepositBankName"
                      options={activeBank}
                      isSearchable={true}
                      value={batchSecurityDepositBankName}
                      placeholder="Select Bank"
                      onChange={(e) =>
                        onInputChange(e, "batchSecurityDepositBankName")
                      }
                      theme={(theme) => ({
                        ...theme,
                        height: 26,
                        minHeight: 26,
                        borderRadius: 1,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank Amount{" "}
                    <input
                      type="number"
                      name="batchSecurityDepositBank"
                      value={batchSecurityDepositBank}
                      disabled={batchSecurityDepositBankName ? false : true}
                      onChange={(e) => onInputChange(e)}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-6 col-md-12 col-sm-12">
                Cash
                <input
                  type="number"
                  name="batchSecurityDepositCash"
                  value={batchSecurityDepositCash}
                  onChange={(e) => onInputChange(e)}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>

            <label className="label-control">
              Donation To Outsider&nbsp;
              <img
                className="img_icon_size log "
                src={require("../../../static/images/info.png")}
                alt="Reason"
                style={{ height: "18px", width: "18px" }}
                title=" Donation/Contribution to the Outside from the SHG"
              />&nbsp;:&nbsp;
            </label>
            {allPermission && allPermission.includes("batchCashBank") && (
              <>
                <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank List
                    <Select
                      name="donationToOutsiderBankName"
                      options={activeBank}
                      isSearchable={true}
                      value={donationToOutsiderBankName}
                      placeholder="Select Bank"
                      onChange={(e) =>
                        onInputChange(e, "donationToOutsiderBankName")
                      }
                      theme={(theme) => ({
                        ...theme,
                        height: 26,
                        minHeight: 26,
                        borderRadius: 1,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank Amount{" "}
                    <input
                      type="number"
                      name="donationToOutsiderBank"
                      value={donationToOutsiderBank}
                      disabled={donationToOutsiderBankName ? false : true}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      onChange={(e) => onInputChange(e)}
                     
                    />
                  </div>
                </div>
              </>
            )}

            <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-6 col-md-12 col-sm-12">
                Cash
                <input
                  type="number"
                  name="donationToOutsiderCash"
                  value={donationToOutsiderCash}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onChange={(e) => onInputChange(e)}
                  onKeyDown={(e) =>
                    (e.keyCode === 69 || e.keyCode === 190) &&
                    e.preventDefault()
                  }
                />
              </div>
            </div>

            <label className="label-control">
              SD SHG To Union{" "}
              <img
                className="img_icon_size log "
                src={require("../../../static/images/info.png")}
                alt="Reason"
                style={{ height: "18px", width: "18px" }}
                title=" Security Deposit Amount from SHG to Union"
              />{" "}
              :
            </label>
            {allPermission && allPermission.includes("batchCashBank") && (
              <>
                <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                  {" "}
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank List
                    <Select
                      name="sdSHGToUnionBankName"
                      options={activeBank}
                      isSearchable={true}
                      value={sdSHGToUnionBankName}
                      placeholder="Select Bank"
                      onChange={(e) => onInputChange(e, "sdSHGToUnionBankName")}
                      theme={(theme) => ({
                        ...theme,
                        height: 26,
                        minHeight: 26,
                        borderRadius: 1,
                        colors: {
                          ...theme.colors,
                          primary: "black",
                        },
                      })}
                    />
                  </div>{" "}
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Bank Amount{" "}
                    <input
                      type="number"
                      name="sdSHGToUnionBank"
                      value={sdSHGToUnionBank}
                      disabled={sdSHGToUnionBankName ? false : true}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      onChange={(e) => onInputChange(e)}
                      onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-6 col-md-12 col-sm-12">
                Cash
                <input
                  type="number"
                  name="sdSHGToUnionCash"
                  onChange={(e) => onInputChange(e)}
                  value={sdSHGToUnionCash}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  // onChange={(e) => onInputChange(e)}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>

            {allPermission && allPermission.includes("SHG-to-SHGLoan") ? (
              <>
                <label className="label-control">
                  Loan Given To Other SHG :
                </label>

                {allPermission && allPermission.includes("batchCashBank") && (
                  <>
                    <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        Bank List
                        <Select
                          name="loanGivenToOtherSHGBankName"
                          options={activeBank}
                          isSearchable={true}
                          value={loanGivenToOtherSHGBankName}
                          placeholder="Select Bank"
                          onChange={(e) =>
                            onInputChange(e, "loanGivenToOtherSHGBankName")
                          }
                          theme={(theme) => ({
                            ...theme,
                            height: 26,
                            minHeight: 26,
                            borderRadius: 1,
                            colors: {
                              ...theme.colors,
                              primary: "black",
                            },
                          })}
                        />
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12">
                        Bank Amount{" "}
                        <input
                          type="number"
                          name="loanGivenToOtherSHGBank"
                          value={loanGivenToOtherSHGBank}
                          disabled={loanGivenToOtherSHGBankName ? false : true}
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onChange={(e) => onInputChange(e)}
                          onKeyDown={(e) => funcKeyDown(e)}
                        />
                      </div>
                    </div>
                  </>
                )}

                <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    Cash
                    <input
                      type="number"
                      name="loanGivenToOtherSHGCash"
                      value={loanGivenToOtherSHGCash}
                      className="form-control"
                      onWheel={() => document.activeElement.blur()}
                      onChange={(e) => onInputChange(e)}
                      // onKeyDown={(e) => funcKeyDown(e)}
                    />
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <button
            type="submit"
            className="btn sub_form btn_continue Save float-right"
            // onClick={() => expenseNextTab()}
          >
            Next
          </button>

          <button
            type="button"
            className="btn sub_form btn_continue Save float-right"
            onClick={() => NextBackBtn(3)}
          >
            Previous
          </button>
        </div>
      </form>
    </>
  );
};

export default BatchExpenseTab;
