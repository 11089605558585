import React, { useState, Fragment, useEffect } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  getIncomeAndExpenseReportFinacialYear,
  getBatchOfParish,
  getAllBatchesDropdown,
  getBatchOfDistrict,
} from "../../actions/shg";
import {
  getVarados,
  getParish,
  getStates,
  getDistrict,
  getBlockDetails,
} from "../../actions/area";
import Spinner from "../layout/Spinner";
import { CSVLink } from "react-csv";

const MonthlyIncomeExpenseReport = ({
  auth: { isAuthenticated, user, users },
  shg: {
    batchOfParish,
    allbatchesdropdown,
    incomeandexpensereportFinYear,
    batchOfDistrict,
  },
  area: { varadosData, activeParish, statesData, activeDistrict, getBlock },
  getIncomeAndExpenseReportFinacialYear,
  getVarados,
  getParish,
  getBatchOfParish,
  getAllBatchesDropdown,
  getStates,
  getDistrict,
  getBatchOfDistrict,
  getBlockDetails,
}) => {
  useEffect(() => {
    getVarados();
  }, [getVarados]);
  useEffect(() => {
    getAllBatchesDropdown();
  }, [getAllBatchesDropdown]);
  useEffect(() => {
    getStates();
  }, [getStates]);

  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );
  const allPermission = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.permission &&
    loggedUserInstitutionData.permission.map((permission) =>
      allPermission.push(permission.permissionName)
    );

  const allvarado = [];
  varadosData.map((varado) =>
    allvarado.push({
      vId: varado._id,
      vCode: varado.varadoCode,
      label: varado.varadoName,
      value: varado.varadoName,
    })
  );
  const [varado, getVaradoData] = useState();
  const [varadoID, setVaradoID] = useState();
  const [varadoName, setvaradoName] = useState();
  const [varadoCode, setVaradoCode] = useState("");

  const onVaradoChange = (e) => {
    var varadoID = "";
    var varadoName = "";
    var varadoCode = "";
    getVaradoData(e);

    varadoID = e.vId;
    varadoName = e.value;
    varadoCode = e.vCode;
    setVaradoID(varadoID);
    setvaradoName(varadoName);
    setVaradoCode(varadoCode);
    let varadoVal = {
      varadoInfo: varadoID,
    };
    getParish(varadoVal);
    getParishData("");
    getbatchsData("");
    getIncomeAndExpenseReportFinacialYear("");
    getBatchOfParish("");
  };

  const allparish = [];

  activeParish.map((parish) =>
    allparish.push({
      pId: parish._id,
      pCode: parish.parishCode,
      bCounter: parish.batchCounter,
      label: parish.parishName,
      value: parish.parishName,
    })
  );

  const [parish, getParishData] = useState();
  const [pId, setParishID] = useState();
  const [parishName, setParishName] = useState();
  const [parishCode, setParishCode] = useState("");
  const [batchCounter, setBatchCounter] = useState("");

  const onParishChange = (e) => {
    var pId = "";
    var parishName = "";
    var parishCode = "";
    var batchCounter = "";

    getParishData(e);
    pId = e.pId;
    parishName = e.value;
    parishCode = e.pCode;
    batchCounter = e.bCounter;
    setParishID(pId);
    setParishName(parishName);
    setParishCode(parishCode);
    setBatchCounter(batchCounter);
    var filterData = {
      parishId: pId,
    };
    getBatchOfParish(filterData);
    getIncomeAndExpenseReportFinacialYear("");
    getbatchsData("");
  };
  const allbatches = [];

  batchOfParish.map((batchs) =>
    allbatches.push({
      batchId: batchs._id,
      label: batchs.batchName,
      value: batchs.batchName,
    })
  );

  const [batchs, getbatchsData] = useState();
  const [batchId, setbatchId] = useState();
  const [batchName, setbatchName] = useState();
  let changeData = "";
  const onBatchesChange = (e) => {
    getbatchsData(e);
    setbatchId(e.batchId);
    setbatchName(e.batchName);
    changeData = {
      batchIdVal: e.batchId,
    };
    getIncomeAndExpenseReportFinacialYear(changeData);
  };

  const onClickReset = () => {
    getParishData("");
    getIncomeAndExpenseReportFinacialYear("");
    getVaradoData("");
    getbatchsData("");
    getbatchesData("");
    getParish("");
    getBatchOfParish("");
    getStates("");
    getDistrict("");
    getStateData("");
    getdistrictData("");
    getBatchOfDistrict("");
    setBatchDate("");
    setBatchDates("");
    setBlock("");
  };

  const [fromSelectedDate, setBatchDate] = useState("");
  const [toSelectedDate, setBatchDates] = useState("");
  const onDateChange = (e) => {
    setBatchDate(e.target.value);
    changeData = {
      batchIdVal: batchId,
      fromDate: e.target.value,
      toDate: toSelectedDate,
    };
    getIncomeAndExpenseReportFinacialYear(changeData);
  };

  const allbatchesList = [];
  batchOfDistrict.map((batches) =>
    allbatchesList.push({
      batchesId: batches._id,
      label: batches.batchName,
      value: batches.batchName,
    })
  );
  const [batches, getbatchesData] = useState();
  const [batchesId, setbatchesId] = useState();
  const [batchesName, setbatchesName] = useState();
  const onBatchChange = (e) => {
    var batchesId = "";
    var batchesName = "";
    getbatchesData();
    batchesId = e.batchesId;
    batchesName = e.value;
    setbatchesId(batchesId);
    // setbatchesName(batchesName);

    const changeData = {
      batchIdVal: e.batchesId,
    };
    getIncomeAndExpenseReportFinacialYear(changeData);
  };

  const onDateChanges = (e) => {
    setBatchDates(e.target.value);
    changeData = {
      batchIdVal: batchId,
      fromDate: fromSelectedDate,
      toDate: e.target.value,
    };
    getIncomeAndExpenseReportFinacialYear(changeData);
  };
  const allstates = [];
  statesData.map((state) =>
    allstates.push({
      sId: state._id,
      label: state.stateName,
      value: state.stateName,
    })
  );

  const [state, getStateData] = useState();

  const [stateId, setStateID] = useState();
  const [stateName, setStateName] = useState();

  const onStateChange = (e) => {
    var stateId = "";
    var stateName = "";
    getStateData(e);

    stateId = e.sId;
    stateName = e.value;

    setStateID(stateId);
    setStateName(stateName);
    let stateVal = {
      // userInfo: user,
      stateInfo: stateId,
    };
    getDistrict(stateVal);
    getdistrictData("");
    getBatchOfDistrict("");
    getbatchesData("");
    setBlock("");
  };

  const alldistrict = [];

  activeDistrict.map((district) =>
    alldistrict.push({
      districtId: district._id,
      label: district.districtName,
      value: district.districtName,
    })
  );

  const [district, getdistrictData] = useState();
  // const [bCode, getBCode] = useState();
  const [districtId, setdistrictID] = useState();
  const [districtName, setdistrictName] = useState();

  const ondistrictChange = (e) => {
    var districtId = "";
    var districtName = "";
    getdistrictData(e);

    districtId = e.districtId;
    districtName = e.value;
    getBlockDetails({ districtIdVal: e.districtId });
    setdistrictID(districtId);
    setdistrictName(districtName);
    var filterData = {
      districtId: districtId,
    };
    getBatchOfDistrict(filterData);
    getbatchesData("");
    setBlock("");
  };

  const allBlocks = [];
  getBlock &&
    getBlock.map((block) =>
      allBlocks.push({
        label: block.blockName,
        value: block._id,
      })
    );

  const [block, setBlock] = useState();
  const onBlockChange = (e) => {
    setBlock(e);
    getBatchOfDistrict({
      blockId: e.value,
    });
    getbatchesData("");
    getIncomeAndExpenseReportFinacialYear({ blockId: e.value });
  };

  let batchSavingAmount,
    batchSubAmount,
    batchOtherContribution,
    loanRepaidByOtherSHG,
    bankInterest,
    subsidyReceived,
    batchLoanPaid,
    batchInterestPaid,
    batchOtherLoanInterest,
    totalIncome,
    travellingExpenses,
    stationaryExpenses,
    paidToMahasangha,
    bankCommission,
    serviceCharges,
    batchDividendDistributed,
    otherExpenses,
    loanGivenToOtherSHG,
    batchSavingWithdrawals,
    subsidyDistributed,
    batchOtherLoanPaid,
    batchLoanAmt,
    totalExpense = 0;
  const csvData = [
    [
      "Month",
      "Batch Name",
      "Batch Saving Amount",
      "Batch Loan Repayment to SHG",
      "Batch Loan Interest",
      "Batch Bank Interest",
      "Batch Other Loan Paid",
      "Batch Subscription Amount	",
      "Batch Other Contribution Amount",
      "Loan Repaid By Other SHG",
      "Batch Subsidy Received",
      "Batch Bank Deposit",
      "Batch Internal Loan",
      "Batch Saving Withdrawals",
      "Batch Travelling Expense",
      "Batch Stationary Expense	",
      "Batch Paid To Union (Mahasangha/Federation/General HUB)",
      "Batch Bank Commission",
      "Batch Other Expense",
      "Loan Given To Other SHG",

      "Bank Commission",
      "Service Charges",
      "Batch Dividend Distributed",
      "Batch Savings Distributed",
      "Batch Subsidy Distributed",
    ],
  ];
  incomeandexpensereportFinYear.map((incomeandexpensereport) => {
    let monthName = new Date(
      incomeandexpensereportFinYear.meetingMonthDate
    ).toLocaleString("en-us", { month: "long" });
    batchSavingAmount = incomeandexpensereport.batchSavingAmt;
    batchSubAmount = incomeandexpensereport.batchSubAmt;
    batchInterestPaid = incomeandexpensereport.batchInterestPaid;
    batchLoanPaid = incomeandexpensereport.batchLoanPaid;

    batchOtherLoanPaid = incomeandexpensereport.batchOtherLoanPaid;
    batchOtherLoanInterest = incomeandexpensereport.batchOtherLoanInterest;
    bankInterest = incomeandexpensereport.bankInterest;
    batchOtherContribution = incomeandexpensereport.batchOtherContribution;
    loanRepaidByOtherSHG = incomeandexpensereport.loanRepaidByOtherSHG;
    subsidyReceived = incomeandexpensereport.subsidyReceived;
    batchLoanAmt = incomeandexpensereport.batchLoanAmt;
    travellingExpenses = incomeandexpensereport.travellingExpenses;
    stationaryExpenses = incomeandexpensereport.stationaryExpenses;
    paidToMahasangha = incomeandexpensereport.paidToMahasangha;
    bankCommission = incomeandexpensereport.bankCommission;
    serviceCharges = incomeandexpensereport.serviceCharges;
    batchDividendDistributed = incomeandexpensereport.batchDividendDistributed;
    otherExpenses = incomeandexpensereport.otherExpenses;
    loanGivenToOtherSHG = incomeandexpensereport.loanGivenToOtherSHG;
    batchSavingWithdrawals = incomeandexpensereport.batchSavingWithdrawals;
    subsidyDistributed = incomeandexpensereport.subsidyDistributed;

    totalIncome =
      batchSavingAmount +
      batchSubAmount +
      batchLoanPaid +
      batchInterestPaid +
      batchOtherLoanPaid +
      batchOtherLoanInterest +
      bankInterest +
      batchOtherContribution +
      loanRepaidByOtherSHG +
      subsidyReceived;
    totalExpense =
      batchLoanAmt +
      travellingExpenses +
      stationaryExpenses +
      paidToMahasangha +
      bankCommission +
      serviceCharges +
      batchDividendDistributed +
      otherExpenses +
      loanGivenToOtherSHG +
      batchSavingWithdrawals +
      subsidyDistributed;

    csvData.push([
      monthName,
      incomeandexpensereport.batchName,
      incomeandexpensereport.batchSavingAmt,
      incomeandexpensereport.batchLoanPaid,
      incomeandexpensereport.batchInterestPaid,
      incomeandexpensereport.bankInterest,
      incomeandexpensereport.batchOtherLoanPaid,
      incomeandexpensereport.batchSubAmt,
      incomeandexpensereport.batchOtherContribution,
      incomeandexpensereport.loanRepaidByOtherSHG,
      incomeandexpensereport.subsidyReceived,
      incomeandexpensereport.batchBankDeposit,
      incomeandexpensereport.batchLoanAmt,
      incomeandexpensereport.batchSavingWithdrawals,
      incomeandexpensereport.travellingExpenses,
      incomeandexpensereport.stationaryExpenses,
      incomeandexpensereport.paidToMahasangha,
      incomeandexpensereport.bankCommission,
      incomeandexpensereport.otherExpenses,
      incomeandexpensereport.loanGivenToOtherSHG,
      incomeandexpensereport.serviceCharges,
      incomeandexpensereport.batchDividendDistributed,
      incomeandexpensereport.batchSavingWithdrawals,
      incomeandexpensereport.subsidyDistributed,
    ]);
  });
  csvData.push([""]);
  csvData.push(["Total Income", totalIncome]);
  csvData.push(["Total Expense", totalExpense]);
  const csvFreeZonedData = [
    [
      "Month",
      "Batch Name",
      "Batch Saving Amount",
      "Batch Loan Repayment to SHG",
      "Batch Loan Interest",
      "Batch Bank Interest",
      "Batch Other Loan Paid",
      "Batch Subscription Amount	",
      "Batch Other Contribution Amount",
      "Loan Repaid By Other SHG",
      "Batch Subsidy Received",
      "Batch Bank Deposit",
      "Batch Internal Loan",
      "Batch Saving Withdrawals",
      "Batch Travelling Expense",
      "Batch Stationary Expense	",
      "Batch Paid To Union (Mahasangha/Federation/General HUB)",
      "Batch Bank Commission",
      "Batch Other Expense",
      "Loan Given To Other SHG",
      "Batch Bank Commission",
      "Batch Service Charges",
      "Batch Dividend Distributed",
      "Batch Savings Distributed",
      "Batch Subsidy Distributed",
    ],
  ];

  incomeandexpensereportFinYear.map((incomeandexpensereport) => {
    let monthName = new Date(
      incomeandexpensereport.meetingMonthDate
    ).toLocaleString("en-us", { month: "long" });

    batchSavingAmount = incomeandexpensereport.batchSavingAmt;
    batchSubAmount = incomeandexpensereport.batchSubAmt;
    batchInterestPaid = incomeandexpensereport.batchInterestPaid;
    batchLoanPaid = incomeandexpensereport.batchLoanPaid;
    batchOtherLoanPaid = incomeandexpensereport.batchOtherLoanPaid;
    batchOtherLoanInterest = incomeandexpensereport.batchOtherLoanInterest;
    bankInterest = incomeandexpensereport.bankInterest;
    batchOtherContribution = incomeandexpensereport.batchOtherContribution;
    loanRepaidByOtherSHG = incomeandexpensereport.loanRepaidByOtherSHG;
    subsidyReceived = incomeandexpensereport.subsidyReceived;
    batchLoanAmt = incomeandexpensereport.batchLoanAmt;
    batchSavingWithdrawals = incomeandexpensereport.batchSavingWithdrawals;
    travellingExpenses = incomeandexpensereport.travellingExpenses;
    stationaryExpenses = incomeandexpensereport.stationaryExpenses;
    paidToMahasangha = incomeandexpensereport.paidToMahasangha;
    bankCommission = incomeandexpensereport.bankCommission;
    otherExpenses = incomeandexpensereport.otherExpenses;
    loanGivenToOtherSHG = incomeandexpensereport.loanGivenToOtherSHG;
    serviceCharges = incomeandexpensereport.serviceCharges;
    batchDividendDistributed = incomeandexpensereport.batchDividendDistributed;
    subsidyDistributed = incomeandexpensereport.subsidyDistributed;

    totalIncome =
      batchSavingAmount +
      batchSubAmount +
      batchLoanPaid +
      batchInterestPaid +
      batchOtherLoanPaid +
      batchOtherLoanInterest +
      bankInterest +
      batchOtherContribution +
      loanRepaidByOtherSHG +
      subsidyReceived;
    totalExpense =
      batchLoanAmt +
      travellingExpenses +
      stationaryExpenses +
      paidToMahasangha +
      bankCommission +
      serviceCharges +
      batchDividendDistributed +
      otherExpenses +
      loanGivenToOtherSHG +
      batchSavingWithdrawals +
      subsidyDistributed;

    csvFreeZonedData.push([
      monthName,
      incomeandexpensereport.batchName,
      incomeandexpensereport.batchSavingAmt,
      incomeandexpensereport.batchLoanPaid,
      incomeandexpensereport.batchInterestPaid,
      incomeandexpensereport.bankInterest,
      incomeandexpensereport.batchOtherLoanPaid,
      incomeandexpensereport.batchSubAmt,
      incomeandexpensereport.batchOtherContribution,
      incomeandexpensereport.loanRepaidByOtherSHG,
      incomeandexpensereport.subsidyReceived,
      incomeandexpensereport.batchBankDeposit,
      incomeandexpensereport.batchLoanAmt,
      incomeandexpensereport.batchSavingWithdrawals,
      incomeandexpensereport.travellingExpenses,
      incomeandexpensereport.stationaryExpenses,
      incomeandexpensereport.paidToMahasangha,
      incomeandexpensereport.bankCommission,
      incomeandexpensereport.otherExpenses,
      incomeandexpensereport.loanGivenToOtherSHG,
      incomeandexpensereport.serviceCharges,
      incomeandexpensereport.batchDividendDistributed,
      incomeandexpensereport.batchSavingWithdrawals,
      incomeandexpensereport.subsidyDistributed,
    ]);
  });
  csvFreeZonedData.push([""]);
  csvFreeZonedData.push(["Total Income", totalIncome]);
  csvFreeZonedData.push(["Total Expense", totalExpense]);

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <div className="col-lg-11 col-md-11 col-sm-11 col-11">
          <h2 className="heading_color">Income and Expense Report </h2>
          <hr />
        </div>
        <section className="sub_reg">
          <div className="row col-lg-11 col-md-11 col-sm-11 col-11 no_padding">
            {loggedUserInstitutionData.institutionType === "Zoned" ? (
              <>
                <div className="col-lg-2 col-md-3 col-sm-3 col-11 ">
                  <Select
                    name="varadoName"
                    options={allvarado}
                    isSearchable={true}
                    value={varado}
                    placeholder="Select Varado"
                    onChange={(e) => onVaradoChange(e)}
                    styles={{ zIndex: 1000 }}
                    menuPortalTarget={document.body}
                    menuPosition="fixed"
                  />
                </div>
                <div className="col-lg-2 col-md-3 col-sm-3 col-11 ">
                  <Select
                    name="parishName"
                    options={allparish}
                    isSearchable={true}
                    value={parish}
                    placeholder="Select Parish"
                    onChange={(e) => onParishChange(e)}
                  />
                </div>
                <div className="col-lg-3 col-md-5 col-sm-4 col-11 ">
                  <Select
                    name="batchName"
                    options={allbatches}
                    isSearchable={true}
                    value={batchs}
                    placeholder="Select SHG"
                    onChange={(e) => onBatchesChange(e)}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="col-lg-2 col-md-5 col-sm-4 col-11 mt-1">
                  <Select
                    name="stateName"
                    options={allstates}
                    isSearchable={true}
                    value={state}
                    placeholder="Select State"
                    onChange={(e) => onStateChange(e)}
                  />
                </div>
                <div className="col-lg-2 col-md-5 col-sm-4 col-11 mt-1">
                  <Select
                    name="districtName"
                    options={alldistrict}
                    isSearchable={true}
                    value={district}
                    placeholder="Select District"
                    onChange={(e) => ondistrictChange(e)}
                  />
                </div>

                {allPermission && allPermission.includes("Blockwise") && (
                  <div className="col-lg-2 col-md-5 col-sm-4 col-11 mt-1">
                    <Select
                      name="blockName"
                      options={allBlocks}
                      isSearchable={true}
                      value={block}
                      placeholder="Select Block"
                      onChange={(e) => onBlockChange(e)}
                    />
                  </div>
                )}
                <div className="col-lg-3 col-md-5 col-sm-4 col-3 mt-1">
                  <Select
                    name="batchName"
                    options={allbatchesList}
                    isSearchable={true}
                    value={batches}
                    placeholder="Select SHG"
                    onChange={(e) => onBatchChange(e)}
                  />
                  
                </div>
              </>
            )}
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 py-3">
            <Link to="/all-reports">
              <img
                className="img_icon_size log float-right ml-3"
                src={require("../../static/images/back.png")}
                alt="Back"
                title="Back"
              />
            </Link>
            <img
              className="img_icon_size log float-right ml-3"
              onClick={() => onClickReset()}
              src={require("../../static/images/refresh-icon.png")}
              alt="refresh"
              title="Refresh"
            />
            {loggedUserInstitutionData.institutionType === "Zoned" ? (
              <CSVLink data={csvData}>
                <img
                  className="img_icon_size log float-right ml-4"
                  src={require("../../static/images/excel_icon.png")}
                  alt="Excel-Export"
                  title="Excel-Export"
                />
              </CSVLink>
            ) : (
              <CSVLink data={csvFreeZonedData}>
                <img
                  className="img_icon_size log float-right ml-4"
                  src={require("../../static/images/excel_icon.png")}
                  alt="Excel-Export"
                  title="Excel-Export"
                />
              </CSVLink>
            )}
          </div>
          </div>

        

          <div className="row col-lg-11 col-md-11 col-sm-11 col-11 "></div>
          <div className="row">
            {/*  style={{ border: "1px solid black" }} */}
            <div className="col-lg-6 col-md-11 col-sm-11 col-11 text-center ">
              <div className="firstrowsticky1 body-inner no-padding table-responsive ">
                <h4
                  style={{
                    position: "sticky",
                    left: "0",
                  }}
                >
                  <center>
                    <b>Income</b>
                  </center>
                </h4>
                <table
                  className="table table-bordered table-striped table-hover"
                  id="datatable2"
                >
                  <thead>
                    <tr>
                      <th className="headcolstatic1" style={{ zIndex: "1" }}>
                        Month
                      </th>
                      <th>Saving</th>
                      <th>Loan Repayment to SHG</th>
                      <th>Loan Interest</th>
                      <th>Bank Interest</th>
                      <th>Other Loan Paid</th>
                      <th>Subscription Amount</th>
                      <th>Other Contribution Amount</th>
                      {allPermission &&
                      allPermission.includes("SHG-to-SHGLoan") ? (
                        <th>Loan Repaid By Other SHG</th>
                      ) : (
                        <></>
                      )}
                      <th>Subsidy Received</th>
                      <th>Bank Deposit</th>
                      <th>Total Income</th>
                    </tr>
                  </thead>
                  <tbody>
                    {incomeandexpensereportFinYear &&
                      incomeandexpensereportFinYear.map(
                        (incomeandexpensereportFinYear, idx) => {
                          let batchSavingAmount =
                            incomeandexpensereportFinYear.batchSavingAmt;
                          let batchSubAmount =
                            incomeandexpensereportFinYear.batchSubAmt;
                          let batchInterestPaid =
                            incomeandexpensereportFinYear.batchInterestPaid;
                          let batchLoanPaid =
                            incomeandexpensereportFinYear.batchLoanPaid;
                          let batchOtherLoanPaid =
                            incomeandexpensereportFinYear.batchOtherLoanPaid;
                          let batchOtherLoanInterest =
                            incomeandexpensereportFinYear.batchOtherLoanInterest;
                          let bankInterest =
                            incomeandexpensereportFinYear.bankInterest;
                          let batchOtherContribution =
                            incomeandexpensereportFinYear.batchOtherContribution;
                          let loanRepaidByOtherSHG =
                            incomeandexpensereportFinYear.loanRepaidByOtherSHG;
                          let subsidyReceived =
                            incomeandexpensereportFinYear.subsidyReceived;

                          let totalIncome =
                            Number(batchSavingAmount) +
                            Number(batchSubAmount) +
                            Number(batchLoanPaid) +
                            Number(batchInterestPaid) +
                            Number(batchOtherLoanPaid) +
                            Number(batchOtherLoanInterest) +
                            Number(bankInterest) +
                            Number(batchOtherContribution) +
                            Number(loanRepaidByOtherSHG) +
                            Number(subsidyReceived);
                          let monthName = new Date(
                            incomeandexpensereportFinYear.meetingMonthDate
                          ).toLocaleString("en-us", { month: "long" });
                          return (
                            <>
                              <tr key={idx}>
                                <td className="headcolstatic2">{monthName}</td>
                                <td>
                                  {incomeandexpensereportFinYear.batchSavingAmt}
                                </td>
                                <td>
                                  {incomeandexpensereportFinYear.batchLoanPaid}
                                </td>
                                <td>
                                  {
                                    incomeandexpensereportFinYear.batchInterestPaid
                                  }
                                </td>
                                <td>
                                  {incomeandexpensereportFinYear.bankInterest}
                                </td>
                                <td>
                                  {
                                    incomeandexpensereportFinYear.batchOtherLoanPaid
                                  }
                                </td>
                                <td>
                                  {incomeandexpensereportFinYear.batchSubAmt}
                                </td>
                                <td>
                                  {
                                    incomeandexpensereportFinYear.batchOtherContribution
                                  }
                                </td>
                                {allPermission &&
                                allPermission.includes("SHG-to-SHGLoan") ? (
                                  <td>
                                    {
                                      incomeandexpensereportFinYear.loanRepaidByOtherSHG
                                    }
                                  </td>
                                ) : (
                                  <></>
                                )}

                                <td>
                                  {
                                    incomeandexpensereportFinYear.subsidyReceived
                                  }
                                </td>
                                <td>
                                  {
                                    incomeandexpensereportFinYear.batchBankDeposit
                                  }
                                </td>
                                <td>{totalIncome}</td>
                              </tr>
                            </>
                          );
                        }
                      )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-lg-6 col-md-11 col-sm-11 col-11 text-center ">
              <h4
                style={{
                  position: "sticky",
                  left: "0",
                }}
              >
                <center>
                  <b>Expense</b>
                </center>
              </h4>

              <div className=" body-inner no-padding table-responsive ">
                <table
                  className="table table-bordered table-striped table-hover"
                  id="datatable2"
                >
                  <thead>
                    <tr>
                      <th>Internal Loan</th>
                      <th>Saving Withdrawals</th>
                      <th>Travelling Expense</th>
                      <th>Stationary Expense</th>
                      <th>Paid To Union (Mahasangha/Federation/General HUB)</th>
                      <th>Bank Commission</th>
                      <th>Other Expense </th>
                      {allPermission &&
                      allPermission.includes("SHG-to-SHGLoan") ? (
                        <th>Loan Given To Other SHG</th>
                      ) : (
                        <></>
                      )}

                      <th>Service Charge</th>
                      <th>Dividend Distributed</th>
                      <th>Savings Distributed</th>
                      <th>Subsidy Distributed</th>
                      <th>Total Expense</th>
                    </tr>
                  </thead>
                  <tbody>
                    {incomeandexpensereportFinYear &&
                      incomeandexpensereportFinYear.map(
                        (incomeandexpensereportFinYear, idx) => {
                          let batchLoanAmt =
                            incomeandexpensereportFinYear.batchLoanAmt;
                          let travellingExpenses =
                            incomeandexpensereportFinYear.travellingExpenses;
                          let stationaryExpenses =
                            incomeandexpensereportFinYear.stationaryExpenses;
                          let paidToMahasangha =
                            incomeandexpensereportFinYear.paidToMahasangha;
                          let bankCommission =
                            incomeandexpensereportFinYear.bankCommission;
                          let serviceCharges =
                            incomeandexpensereportFinYear.serviceCharges;
                          let batchDividendDistributed =
                            incomeandexpensereportFinYear.batchDividendDistributed;
                          let otherExpenses =
                            incomeandexpensereportFinYear.otherExpenses;
                          let loanGivenToOtherSHG =
                            incomeandexpensereportFinYear.loanGivenToOtherSHG;
                          let batchSavingWithdrawals =
                            incomeandexpensereportFinYear.batchSavingWithdrawals;
                          let subsidyDistributed =
                            incomeandexpensereportFinYear.subsidyDistributed;

                          let totalExpense =
                            Number(batchLoanAmt) +
                            Number(travellingExpenses) +
                            Number(stationaryExpenses) +
                            Number(paidToMahasangha) +
                            Number(bankCommission) +
                            Number(serviceCharges) +
                            Number(batchDividendDistributed) +
                            Number(otherExpenses) +
                            Number(loanGivenToOtherSHG) +
                            Number(batchSavingWithdrawals) +
                            Number(subsidyDistributed);
                          return (
                            <>
                              <tr key={idx}>
                                <td>
                                  {incomeandexpensereportFinYear.batchLoanAmt}
                                </td>
                                <td>
                                  {
                                    incomeandexpensereportFinYear.batchSavingWithdrawals
                                  }
                                </td>
                                <td>
                                  {
                                    incomeandexpensereportFinYear.travellingExpenses
                                  }
                                </td>
                                <td>
                                  {
                                    incomeandexpensereportFinYear.stationaryExpenses
                                  }
                                </td>
                                <td>
                                  {
                                    incomeandexpensereportFinYear.paidToMahasangha
                                  }
                                </td>
                                <td>
                                  {incomeandexpensereportFinYear.bankCommission}
                                </td>
                                <td>
                                  {incomeandexpensereportFinYear.otherExpenses}
                                </td>
                                {allPermission &&
                                allPermission.includes("SHG-to-SHGLoan") ? (
                                  <td>
                                    {
                                      incomeandexpensereportFinYear.loanGivenToOtherSHG
                                    }
                                  </td>
                                ) : (
                                  <></>
                                )}

                                <td>
                                  {incomeandexpensereportFinYear.serviceCharges}
                                </td>
                                <td>
                                  {
                                    incomeandexpensereportFinYear.batchDividendDistributed
                                  }
                                </td>
                                <td>
                                  {
                                    incomeandexpensereportFinYear.batchSavingWithdrawals
                                  }
                                </td>
                                <td>
                                  {
                                    incomeandexpensereportFinYear.subsidyDistributed
                                  }
                                </td>
                                <td>
                                  <b>{totalExpense}</b>
                                </td>
                              </tr>
                            </>
                          );
                        }
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

MonthlyIncomeExpenseReport.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired,
  getIncomeAndExpenseReportFinacialYear: PropTypes.func.isRequired,
  getParish: PropTypes.func.isRequired,
  getAllBatchesDropdown: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
  area: state.area,
});

export default connect(mapStateToProps, {
  getIncomeAndExpenseReportFinacialYear,
  getVarados,
  getParish,
  getBatchOfParish,
  getAllBatchesDropdown,
  getStates,
  getDistrict,
  getBatchOfDistrict,
  getBlockDetails,
})(MonthlyIncomeExpenseReport);
