import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

export const SummaryBatchTab = ({ memSummaryData, allPermission }) => {
  let {
    batchSubAmt = 0,
    batchSavingAmt = 0,
    batchOtherContribution = 0,
    batchInterestPaid = 0,
    bankInterest = 0,
    subsidyReceived = 0,
    batchMembershipFees = 0,
    batchOtherIncome = 0,
    deactiveMemberSaving = 0,
    seedMoney = 0,
    batchSecurityDepositMemToShg = 0,
    batchLoanPaid = 0,
    otherLoanSanctionedToShg = 0,
    loanRepaidByOtherSHG = 0,

    batchBankDeposit = 0,
    //expense
    travellingExpenses = 0,
    stationaryExpenses = 0,
    paidToMahasangha = 0,
    bankCommission = 0,
    serviceCharges = 0,
    batchDividendDistributed = 0,
    otherExpenses = 0,
    batchSavingWithdrawals = 0,
    batchSecurityDeposit = 0,
    donationToOutsider = 0,
    subsidyDistributed = 0,
    otherLoanPaidToDonor = 0,
    batchOtherLoanInterestDonor = 0,
    batchLoanAmt = 0,
    batchOtherLoanAmt = 0,

    loanGivenToOtherSHG = 0,
    otherLoanSanctionedToMember = 0,
    //3rd tab
    batchBankWithdrawals = 0,
    cashInHand = 0,
    OutstandingBatchLoanAmt = 0,
    OutstandingBatchOtherLoanAmt = 0,
  } = memSummaryData;

  let bankBalance =
      Number(batchBankDeposit) +
      Number(bankInterest) -
      Number(bankCommission) -
      Number(batchBankWithdrawals),
    totIncome =
      Number(batchSavingAmt) +
      Number(batchSubAmt) +
      Number(batchOtherContribution) +
      Number(batchInterestPaid) +
      Number(bankInterest) +
      Number(subsidyReceived) +
      Number(batchMembershipFees) +
      Number(batchOtherIncome) +
      Number(deactiveMemberSaving) +
      Number(seedMoney) +
      Number(batchSecurityDepositMemToShg) +
      Number(batchLoanPaid) +
      Number(otherLoanSanctionedToShg) +
      Number(loanRepaidByOtherSHG),
    totExpense =
      Number(travellingExpenses) +
      Number(stationaryExpenses) +
      Number(paidToMahasangha) +
      Number(bankCommission) +
      Number(serviceCharges) +
      Number(batchDividendDistributed) +
      Number(otherExpenses) +
      Number(batchSavingWithdrawals) +
      Number(batchSecurityDeposit) +
      Number(donationToOutsider) +
      Number(subsidyDistributed) +
      Number(otherLoanPaidToDonor) +
      Number(batchOtherLoanInterestDonor) +
      Number(batchLoanAmt || 0) +
      Number(batchOtherLoanAmt) +
      Number(loanGivenToOtherSHG) +
      Number(otherLoanSanctionedToMember);

  return (
    <>
      <div className="col-lg-4 col-md-12 col-sm-12 col-12">
        <h6>Total Savings&nbsp;:&nbsp;{batchSavingAmt} </h6>
        <h6>Total Subscription&nbsp;:&nbsp;{batchSubAmt} </h6>
        <h6>Total Other Contribution&nbsp;:&nbsp;{batchOtherContribution} </h6>
        <h6>Total Loan Interest Paid to SHG&nbsp;:&nbsp;{batchInterestPaid}</h6>
        <h6>Total Bank Interest&nbsp;:&nbsp;{bankInterest} </h6>
        <h6>Subsidy Received&nbsp;:&nbsp;{subsidyReceived} </h6>
        <h6>Total Membership Fees&nbsp;:&nbsp;{batchMembershipFees}</h6>
        <h6>Total Other Income&nbsp;:&nbsp;{batchOtherIncome} </h6>
        <h6>Total Deactive Member Charity Amount&nbsp;:&nbsp;{deactiveMemberSaving}</h6>
        <h6>Total Seed Money&nbsp;:&nbsp;{seedMoney}</h6>
        <h6>
          Total Security Deposit Member To Shg&nbsp;:&nbsp;{batchSecurityDepositMemToShg}
        </h6>
        <h6>Total Loan Repayment to SHG&nbsp;:&nbsp;{batchLoanPaid}</h6>
        <h6>Total Other Loan Sanctioned To SHG&nbsp;:&nbsp;{otherLoanSanctionedToShg}</h6>
        {allPermission && allPermission.includes("SHG-to-SHGLoan") ? (
          <h6>Total Loan Repaid By Other SHG&nbsp;:&nbsp;{loanRepaidByOtherSHG}</h6>
        ) : (
          <></>
        )}

        <h6>
          <b>Total Income&nbsp;:&nbsp;{totIncome} </b>
        </h6>
      </div>

      <div className="col-lg-4 col-md-12 col-sm-12 col-12">
        <h6>Total Travelling Expenses&nbsp;:&nbsp;{travellingExpenses} </h6>
        <h6>Total Stationary Expenses&nbsp;:&nbsp;{stationaryExpenses} </h6>
        <h6>Total Paid To Union&nbsp;:&nbsp;{paidToMahasangha} </h6>
        <h6>Total Bank Commission&nbsp;:&nbsp;{Number(bankCommission || 0).toFixed(2)}</h6>
        <h6>Total Service Charges&nbsp;:&nbsp;{serviceCharges}</h6>
        <h6>Total Dividend Distributed&nbsp;:&nbsp;{batchDividendDistributed}</h6>
        <h6>Total Other Expenses&nbsp;:&nbsp;{otherExpenses}</h6>
        <h6>Total Saving Withdrawals&nbsp;:&nbsp;{batchSavingWithdrawals}</h6>
        <h6>Total Security Deposit&nbsp;:&nbsp;{batchSecurityDeposit}</h6>
        <h6>Total Donation To Outsider&nbsp;:&nbsp;{donationToOutsider}</h6>
        <h6>Total Subsidy Distributed&nbsp;:&nbsp;{subsidyDistributed}</h6>
        <h6>Total Loan Paid To Donor&nbsp;:&nbsp;{otherLoanPaidToDonor} </h6>
        <h6>
          Total Other Loan Interest to Donor&nbsp;:&nbsp;{batchOtherLoanInterestDonor}
        </h6>
        <h6>Total Internal Loan Amount Given&nbsp;:&nbsp;{batchLoanAmt}</h6>
        <h6>Total Other Loan Amount Given&nbsp;:&nbsp;{otherLoanSanctionedToMember}</h6>
        {allPermission && allPermission.includes("SHG-to-SHGLoan") ? (
          <h6>Total Loan Given To Other SHG&nbsp;:&nbsp;{loanGivenToOtherSHG}</h6>
        ) : (
          <></>
        )}

        <h6>
          <b>Total Expense&nbsp;:&nbsp;{totExpense}</b>
        </h6>
      </div>

      <div className="col-lg-4 col-md-12 col-sm-12 col-12">
        <h6>
          <b>Cash In Hand&nbsp;:&nbsp;{cashInHand}</b>
        </h6>
        <h6>
          <b>Bank Balance&nbsp;:&nbsp;{bankBalance.toFixed(2)}</b>
        </h6>
        <h6>
          <b>Bank Withdrawals&nbsp;:&nbsp;{batchBankWithdrawals} </b>
        </h6>
        <h6>
          <b>
            Total Outstanding Internal Loan Amount&nbsp;:&nbsp;{Number(OutstandingBatchLoanAmt).toFixed(2)}
          </b>
        </h6>
        <h6>
          <b>
            Total Outstanding Other Loan Amount&nbsp;:&nbsp;
            {Number(OutstandingBatchOtherLoanAmt).toFixed(2)}
          </b>
        </h6>
      </div>
    </>
  );
};

SummaryBatchTab.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryBatchTab);
