import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getActiveOtherLoanType } from "../../../actions/shg";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  addBatchLoanSancOnly,
  deleteBatchLoanSancOnly,
} from "../../../actions/shg";
export const BatchOLSanction = ({
  NextBackBtn,
  getActiveOtherLoanType,
  auth: { isAuthenticated, user, users },
  shg: { activeOtherLoanTypes, loanSactionedData },
  meetingBatchInfo,
  getLoanSantionedData,
  formData,
  setFormData,
  allMembers,
  allpurpose,
  addBatchLoanSancOnly,
  deleteBatchLoanSancOnly,
  allPermission,
}) => {
  useEffect(() => {
    getActiveOtherLoanType();
  }, [getActiveOtherLoanType]);

  const funcKeyDown = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  const [loanSanMemberObject, setLoanSanMemberObject] = useState({
    otherLoanType: null,
    memberData: null,
    otherloanSanctionedAmt: "",
    loanReasonId: null,
    loanPurpose: null,
    otherPurpose: null,
    nominee: "",
  });

  useEffect(() => {
    getLoanSantionedData(meetingBatchInfo);
  }, []);

  let {
    otherLoanType,
    memberData,
    otherloanSanctionedAmt,
    loanReasonId,
    loanPurpose,
    otherPurpose,
    nominee,
  } = loanSanMemberObject;

  const onInputChange = (e, label = "") => {
    if (label == "") {
      setLoanSanMemberObject({
        ...loanSanMemberObject,
        [e.target.name]: e.target.value,
      });
    } else {
      setLoanSanMemberObject({ ...loanSanMemberObject, [label]: e });
    }
  };

  const activeOtherLoan = [];
  activeOtherLoanTypes &&
    activeOtherLoanTypes.map((otherLoanType) =>
      activeOtherLoan.push({
        otherLoanTypeId: otherLoanType._id,
        label: otherLoanType.otherLoanType,
        value: otherLoanType.otherLoanType,
      })
    );

  const AddSanctionOthLoan = async () => {
    let newOthLoanData = {
      batchId: formData.batchId,
      memberId: memberData.memberId,
      memberName: memberData.label,
      batchMeetingMonth: formData.batchMeetingMonth,
      batchMeetingYear: formData.batchMeetingYear,
      meetingHeldOnDate: formData.batchMeetingHeldOnDate,

      loanSanctionedAmt: otherloanSanctionedAmt,
      loanReasonId: loanPurpose.loanReasonId,
      loanPurpose: loanPurpose.label,
      otherPurpose: null,
      nominee: nominee,

      loanSanctionedEnteredById: null,
      loanSanctionedEnteredByName: null,
      loanSanctionedEnteredDateTime: null,

      institutionId: formData.institutionId,
      loanSanctionedStatus: "Active",
      isLoanType: "otherLoan",
      loanStatus: "Active",
      intLoanType: "BATCH LOAN",
    };

    await addBatchLoanSancOnly(newOthLoanData);
    getLoanSantionedData(meetingBatchInfo);

    setLoanSanMemberObject({
      memberData: null,
      otherloanSanctionedAmt: "",
      loanReasonId: null,
      loanPurpose: null,
      otherPurpose: null,
      nominee: "",
      otherLoanType: null,
    });
  };

  const onDeleteOthLoan = async (loanData) => {
    await deleteBatchLoanSancOnly(loanData);
    getLoanSantionedData(meetingBatchInfo);
  };

  const onNextOfOLSanc = () => {
    setFormData({
      ...formData,
      otherLoanSanctionedToMember: {
        cash: loanSactionedData
          .filter((ele) => ele.isLoanType != "Internal")
          .reduce((ac, cur) => (ac += Number(cur.loanSanctionedAmt)), 0),
        bankAmt: 0,
        bankName: "",
      },
    });
    NextBackBtn(8);
  };
  return (
    <>
      <div className="row card-new  py-3 col-12">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <h5>Other Loan Sanction to Member Details</h5>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
          <label className="label-control">OL Types* :</label>
          <div className=" col-12 col-lg-12 col-md-12 col-sm-12">
            <Select
              name="otherLoanType"
              options={activeOtherLoan}
              isSearchable={true}
              value={otherLoanType}
              placeholder="Select Mode"
              onChange={(e) => onInputChange(e, "otherLoanType")}
              theme={(theme) => ({
                ...theme,
                height: 26,
                minHeight: 26,
                borderRadius: 1,
                colors: {
                  ...theme.colors,
                  primary: "black",
                },
              })}
            />
          </div>
          <label className="label-control">Nominee :</label>
          <div className="row col-12 col-lg-12 col-md-12 col-sm-12">
            <input
              type="text"
              name="nominee"
              value={nominee}
              className="form-control"
              onWheel={() => document.activeElement.blur()}
              onChange={(e) => onInputChange(e)}
            />
          </div>
          <label className="label-control">
            Other Loan Sanctioned Purpose* :
          </label>
          <div className=" col-12 col-lg-12 col-md-12 col-sm-12">
            {" "}
            <Select
              name="loanPurpose"
              options={allpurpose}
              isSearchable={true}
              value={loanPurpose}
              placeholder="Select Purpose"
              onChange={(e) => onInputChange(e, "loanPurpose")}
              theme={(theme) => ({
                ...theme,
                height: 26,
                minHeight: 26,
                borderRadius: 1,
                colors: {
                  ...theme.colors,
                  primary: "black",
                },
              })}
            />
          </div>
        </div>

        <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
          <label className="label-control">
            OL Sanctioned To Member*&nbsp;
            <img
              className="img_icon_size log "
              src={require("../../../static/images/info.png")}
              alt="Reason"
              style={{ height: "18px", width: "18px" }}
              title=" Other Loan Sanctioned to Member"
            />&nbsp;
            :
          </label>

          <div className="col-12 col-lg-12 col-md-12 col-sm-12">
            <Select
              name="memberData"
              options={allMembers}
              isSearchable={true}
              value={memberData}
              placeholder="Select member"
              onChange={(e) => onInputChange(e, "memberData")}
              theme={(theme) => ({
                ...theme,
                height: 26,
                minHeight: 26,
                borderRadius: 1,
                colors: {
                  ...theme.colors,
                  primary: "black",
                },
              })}
              required
            />
          </div>
          <div className="col-12 col-lg-12 col-md-12 col-sm-12">
            <label className="label-control">
              OL Sanctioned Amount*&nbsp;
              <img
                className="img_icon_size log "
                src={require("../../../static/images/info.png")}
                alt="Reason"
                style={{ height: "18px", width: "18px" }}
                title=" Other Loan Sanctioned Amount"
              />&nbsp;
              :
            </label>

            <input
              type="number"
              name="otherloanSanctionedAmt"
              value={otherloanSanctionedAmt}
              className="form-control"
              onChange={(e) => onInputChange(e)}
              onWheel={() => document.activeElement.blur()}
              onKeyDown={(e) => funcKeyDown(e)}
              required
            />
          </div>
        </div>
        <div>
          <button
            className="btn sub_form btn_continue Save float-right"
            type="button"
            disabled={!loanPurpose || !memberData ? true : false}
            onClick={() => {
              AddSanctionOthLoan();
            }}
          >
            Add
          </button>
        </div>
      </div>

      <div className=" body-inner no-padding  table-responsive">
        <table
          className="tabllll table table-bordered table-striped table-hover"
          id="datatable2"
        >
          <thead>
            <tr>
              <th>Loan Sanctioned-Member</th>
              <th>Loan Sanctioned Amount to Member</th>
              <th>Nominee</th>
              <th>Loan Sanctioned Purpose</th>
              <th>OP</th>
            </tr>
          </thead>
          <tbody>
            {loanSactionedData.map((OthloanData, idx) => {
              if (OthloanData.isLoanType != "Internal")
                return (
                  <tr key={idx}>
                    <td>{OthloanData.memberName}</td>
                    <td>{OthloanData.loanSanctionedAmt}</td>
                    <td>{OthloanData.nominee}</td>
                    <td>{OthloanData.loanPurpose}</td>
                    <td>
                      <img
                        className="img_icon_size log"
                        onClick={() => onDeleteOthLoan(OthloanData)}
                        src={require("../../../static/images/delete.png")}
                        alt="Deactivate"
                        title="Deactivate"
                      />
                    </td>
                  </tr>
                );
            })}
          </tbody>
        </table>
      </div>

      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        <button
          className="btn sub_form btn_continue Save float-right"
          onClick={() => onNextOfOLSanc()}
        >
          Next
        </button>
        <button
          className="btn sub_form btn_continue Save float-right"
          onClick={() => NextBackBtn(6)}
        >
          Previous
        </button>
      </div>
    </>
  );
};

BatchOLSanction.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

const mapDispatchToProps = {
  getActiveOtherLoanType,
  addBatchLoanSancOnly,
  deleteBatchLoanSancOnly,
};

export default connect(mapStateToProps, mapDispatchToProps)(BatchOLSanction);
