import React, { useState, useEffect } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  addBatchLoanSancOnly,
  deleteBatchLoanSancOnly,
} from "../../../actions/shg";

export const BatchLoanSanction = ({
  NextBackBtn,
  allMembers,
  activeBank,
  allpurpose,
  formData,
  setFormData,
  getLoanSantionedData,
  meetingBatchInfo,
  addBatchLoanSancOnly,
  deleteBatchLoanSancOnly,
  shg: { loanSactionedData },
}) => {
  const [loanSanMemberObject, setLoanSanMemberObject] = useState({
    memberData: null,
    loanSanctionedAmt: "",
    loanReasonId: null,
    loanPurpose: null,
    otherPurpose: null,
    nominee: "",
  });

  useEffect(() => {
    getLoanSantionedData(meetingBatchInfo);
  }, []);

  let {
    memberData,
    loanSanctionedAmt,
    loanReasonId,
    loanPurpose,
    otherPurpose,
    nominee,
  } = loanSanMemberObject;

  const onInputChange = (e, label = "") => {
    if (label == "") {
      setLoanSanMemberObject({
        ...loanSanMemberObject,
        [e.target.name]: e.target.value,
      });
    } else {
      setLoanSanMemberObject({ ...loanSanMemberObject, [label]: e });
    }
  };

  const funcKeyDown = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  const AddSanctionIntLoan = async () => {
    let newIntLoanData = {
      batchId: formData.batchId,
      memberId: memberData.memberId,
      memberName: memberData.label,
      batchMeetingMonth: formData.batchMeetingMonth,
      batchMeetingYear: formData.batchMeetingYear,
      meetingHeldOnDate: formData.batchMeetingHeldOnDate,

      loanSanctionedAmt: loanSanctionedAmt,
      loanReasonId: loanPurpose.loanReasonId,
      loanPurpose: loanPurpose.label,
      otherPurpose: null,
      nominee: nominee,

      loanSanctionedEnteredById: null,
      loanSanctionedEnteredByName: null,
      loanSanctionedEnteredDateTime: null,

      institutionId: formData.institutionId,
      loanSanctionedStatus: "Active",
      isLoanType: "Internal",
      loanStatus: "Active",
      intLoanType: "BATCH LOAN",
    };

    await addBatchLoanSancOnly(newIntLoanData);

    getLoanSantionedData(meetingBatchInfo);

    setLoanSanMemberObject({
      memberData: null,
      loanSanctionedAmt: "",
      loanReasonId: null,
      loanPurpose: null,
      otherPurpose: null,
      nominee: "",
    });
  };

  const onDeleteIntLoan = async (loanData) => {
    await deleteBatchLoanSancOnly(loanData);
    getLoanSantionedData(meetingBatchInfo);
  };

  //

  const OnNextIntLoanTab = () => {
    setFormData({
      ...formData,
      batchLoanAmt: {
        cash: loanSactionedData
          .filter((ele) => ele.isLoanType == "Internal")
          .reduce((acu, cur) => (acu += Number(cur.loanSanctionedAmt)), 0),
        bankAmt: 0,
        bankName: "",
      },
    });

    NextBackBtn(7);
  };

  return (
    <>
      <div className="row card-new  py-3 col-12">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <h5>Loan Details</h5>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
          <label className="label-control">Loan Sanctioned-Member* :</label>
          <div className=" col-12 col-lg-12 col-md-12 col-sm-12">
            <Select
              name="memberData"
              options={allMembers}
              isSearchable={true}
              value={memberData}
              placeholder="Select member"
              onChange={(e) => onInputChange(e, "memberData")}
              theme={(theme) => ({
                ...theme,
                height: 26,
                minHeight: 26,
                borderRadius: 1,
                colors: {
                  ...theme.colors,
                  primary: "black",
                },
              })}
              required
            />
          </div>

          <label className="label-control">Loan Sanctioned Purpose* :</label>
          <div className="col-12 col-lg-12 col-md-12 col-sm-12">
            <Select
              name="loanPurpose"
              options={allpurpose}
              isSearchable={true}
              value={loanPurpose}
              placeholder="Select Purpose"
              onChange={(e) => onInputChange(e, "loanPurpose")}
              theme={(theme) => ({
                ...theme,
                height: 26,
                minHeight: 26,
                borderRadius: 1,
                colors: {
                  ...theme.colors,
                  primary: "black",
                },
              })}
            />
          </div>
        </div>

        <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
          <label className="label-control">
            IL Sanctioned Amount*&nbsp;
            <img
              className="img_icon_size log"
              src={require("../../../static/images/info.png")}
              alt="Reason"
              style={{ height: "18px", width: "18px" }}
              title="	Internal Loan Sanctioned to Member"
            />
            &nbsp;:
          </label>
          <div className="row col-12">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <input
                type="number"
                name="loanSanctionedAmt"
                value={loanSanctionedAmt}
                className="form-control"
                onChange={(e) => onInputChange(e)}
                onWheel={() => document.activeElement.blur()}
                onKeyDown={(e) => funcKeyDown(e)}
                required
              />
            </div>
            <label className="label-control">Nominee :</label>
            <div className="row col-12">
              <input
                type="text"
                name="nominee"
                value={nominee}
                className="form-control"
                onWheel={() => document.activeElement.blur()}
                onChange={(e) => onInputChange(e)}
              />
            </div>
          </div>

          <div>
            <button
              className="btn sub_form btn_continue Save float-right"
              type="button"
              disabled={!memberData || !loanPurpose ? true : false}
              onClick={() => AddSanctionIntLoan()}
            >
              Add
            </button>
          </div>
        </div>
      </div>

      <div className=" body-inner no-padding  table-responsive">
        <table
          className="tabllll table table-bordered table-striped table-hover"
          id="datatable2"
        >
          <thead>
            <tr>
              <th>Loan Sanctioned Member</th>
              <th>Loan Sanctioned Amount</th>
              <th>Nominee</th>
              <th>Loan Sanctioned Purpose</th>
              <th>OP</th>
            </tr>
          </thead>
          <tbody>
            {loanSactionedData.map((LoanData, idx) => {
              if (LoanData.isLoanType == "Internal")
                return (
                  <tr key={idx}>
                    <td>{LoanData.memberName}</td>
                    <td>{LoanData.loanSanctionedAmt}</td>
                    <td>{LoanData.nominee}</td>
                    <td>{LoanData.loanPurpose}</td>
                    <td>
                      <img
                        className="img_icon_size log"
                        onClick={() => onDeleteIntLoan(LoanData)}
                        src={require("../../../static/images/delete.png")}
                        alt="Deactivate"
                        title="Deactivate"
                      />
                    </td>
                  </tr>
                );
            })}
          </tbody>
        </table>
      </div>

      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        <button
          className="btn sub_form btn_continue Save float-right"
          onClick={
            () => OnNextIntLoanTab()
            // NextBackBtn(6)
          }
        >
          Next
        </button>
        <button
          className="btn sub_form btn_continue Save float-right"
          onClick={() => NextBackBtn(5)}
        >
          Previous
        </button>
      </div>
    </>
  );
};

BatchLoanSanction.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

const mapDispatchToProps = {
  addBatchLoanSancOnly,
  deleteBatchLoanSancOnly,
};

export default connect(mapStateToProps, mapDispatchToProps)(BatchLoanSanction);
