import React, { useState, Fragment, useEffect } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getAttendenceDetails, getBatchName } from "../../actions/shg";
import { CSVLink } from "react-csv";
import Spinner from "../layout/Spinner";
import { Link } from "react-router-dom";

const AttendenceReport = ({
  auth: { isAuthenticated, user, users },
  shg: { batchNames, attendanceDetails, loadAttendance },
  getAttendenceDetails,
  getBatchName,
}) => {
  useEffect(() => {
    getBatchName();
  }, [attendanceDetails]);
useEffect(()=>{
  getAttendenceDetails({ batchId: "", fromDate: "", toDate: "" });
},[])
  const getMonthAndDate = (newDate) => {
    let { date } = newDate;
    let day = date.split("-")[0];
    let month = date.split("-")[1];
    switch (month) {
      case "01":
        return "JAN" + "-" + day;

      case "02":
        return "FEB" + "-" + day;
      case "03":
        return "MAR" + "-" + day;

      case "04":
        return "APR" + "-" + day;

      case "05":
        return "MAY" + "-" + day;

      case "06":
        return "JUN" + "-" + day;

      case "07":
        return "JULY" + "-" + day;

      case "08":
        return "AUG" + "-" + day;

      case "09":
        return "SEPT" + "-" + day;

      case "10":
        return "OCT" + "-" + day;

      case "11":
        return "NOV" + "-" + day;

      case "12":
        return "DEC" + "-" + day;

      default:
        return "unknows";
    }
  };

  /////////////////////////////////////for memberDetails array start///////////////////////////////////////////////
  // Initialize arrays
  const selectedDatesArray = [];
  attendanceDetails.forEach((member) => {
    if (selectedDatesArray.length === 0) {
      selectedDatesArray.push(member.selectedDates);
    }
  });

  const membersArray = [];
  attendanceDetails.forEach((member) => {
    const existingMember = membersArray.find(
      (m) => m.memberId === member.memberId
    );

    if (existingMember) {
      existingMember.selectedDates = {
        ...existingMember.selectedDates,
        ...member.selectedDates,
      };
    } else {
      membersArray.push({
        memberId: member.memberId,
        MemberName: member.MemberName,
        meetingHeldOnDate: member.meetingHeldOnDate,
        selectedDates: { ...member.selectedDates }, // Copy the selectedDates
      });
    }
  });

  let batchMeetingDates = [];
  // console.log("attendanceDetails",attendanceDetails)
  attendanceDetails.forEach((member) => {
    const memberDates = Object.keys(member.selectedDates || {});

    memberDates.forEach((date) => {
      if (!batchMeetingDates.includes(date)) {
        batchMeetingDates.push(date); // Add the unique date
      }
    });
  });
  ////////////////////////////////////for memberdetails array end//////////////////////////////////////////////////

  let meetingDate = [];

  const [batchName, setBatchName] = useState("");
  const [toSelectedDate, setToSelectedDate] = useState("");
  const [fromSelectedDate, setFromSelectedDate] = useState("");

  const onChangeBatchName = (e) => {
    setBatchName(e);
    document.getElementById("batchName").style.border = "";
    
    // let todayDate = ``
    getAttendenceDetails({ batchId: e.value, fromDate: "", toDate: "" });
  };

  const onFromDateChange = (e) => {
    setFromSelectedDate(e);
    document.getElementById("fromDate").style.border = "";
  };

  const onToDateChange = (e) => {
    if (fromSelectedDate === "") {
      document.getElementById("fromDate").style.border = "2px solid red";
    }
    if (batchName === "") {
      document.getElementById("batchName").style.border = "2px solid red";
    } else {
      getAttendenceDetails({
        batchId: batchName.value,
        fromDate: fromSelectedDate,
        toDate: e,
      });
      setToSelectedDate(e);
    }
  };

  const newbatchNames = [];
  batchNames &&
    batchNames.map((ele) => {
      newbatchNames.push({
        label: ele.batchName,
        value: ele._id,
      });
    });

  const onClickReset = () => {
    setFromSelectedDate("");
    setToSelectedDate("");
    setBatchName("");
    getAttendenceDetails({ batchId: "", fromDate: "", toDate: "" });

  };

  ////////////////////////////////////////////csv export code start/////////////////////////////
  const csvData = [
    // Header row
    [
      "Meeting Dates",
      ...membersArray.map((member) => member.MemberName),
      "Total Attendance",
      "Attendance(%)",
    ],
    ...batchMeetingDates.map((date) => {
      const countPresent = membersArray.reduce((count, member) => {
        const attendanceStatus = member.selectedDates[date];
        return attendanceStatus === "Attended" ? count + 1 : count;
      }, 0);

      const attendancePercentage = (countPresent / membersArray.length) * 100;

      return [
        getMonthAndDate({ date }), // Meeting Date
        ...membersArray.map((member) => {
          const attendanceStatus = member.selectedDates[date];
          return attendanceStatus === "Attended"
            ? "Present"
            : attendanceStatus === "Absent"
            ? "Absent"
            : "No Data";
        }),
        countPresent,
        attendancePercentage.toFixed(2) + "%",
      ];
    }),
    // Total attendance row
    [
      "Total Attendance",
      ...membersArray.map((member) => {
        const totalAttendance = batchMeetingDates.reduce((count, date) => {
          const attendanceStatus = member.selectedDates[date];
          return attendanceStatus === "Attended" ? count + 1 : count;
        }, 0);
        return totalAttendance;
      }),
      batchMeetingDates.reduce(
        (total, date) =>
          total +
          membersArray.reduce((count, member) => {
            const attendanceStatus = member.selectedDates[date];
            return attendanceStatus === "Attended" ? count + 1 : count;
          }, 0),
        0
      ),
      (
        (batchMeetingDates.reduce((total, date) => {
          return (
            total +
            membersArray.reduce((count, member) => {
              const attendanceStatus = member.selectedDates[date];
              return attendanceStatus === "Attended" ? count + 1 : count;
            }, 0)
          );
        }, 0) /
          (batchMeetingDates.length * membersArray.length)) *
        100
      ).toFixed(2) + "%",
    ],
    // Attendance percentage row
    [
      "Attendance Percentage",
      ...membersArray.map((member) => {
        const totalAttendance = batchMeetingDates.reduce((count, date) => {
          const attendanceStatus = member.selectedDates[date];
          return attendanceStatus === "Attended" ? count + 1 : count;
        }, 0);
        return (
          ((totalAttendance / batchMeetingDates.length) * 100).toFixed(2) + "%"
        );
      }),
      (
        (batchMeetingDates.reduce((total, date) => {
          return (
            total +
            membersArray.reduce((count, member) => {
              const attendanceStatus = member.selectedDates[date];
              return attendanceStatus === "Attended" ? count + 1 : count;
            }, 0)
          );
        }, 0) /
          (batchMeetingDates.length * membersArray.length)) *
        100
      ).toFixed(2) + "%",
      "",
    ],
  ];

  /////////////////////////////////////////csv export code end ///////////////////////////////////

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : loadAttendance ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align ">
        <div className="col-lg-11 col-md-11 col-sm-11 col-11">
          <h2 className="heading_color">
            {`Attendence Report of ${batchName && batchName.label}`}
          </h2>
          <hr />
        </div>
        <section className="sub_reg">
          <div className="row col-lg-11 col-md-11 col-sm-11 col-11 no_padding ">
            <div className="col-lg-2 col-md-5 col-sm-4 col-11">
              <Select
                name="batchName"
                id="batchName"
                options={newbatchNames}
                isSearchable={true}
                value={batchName}
                placeholder="Select SHG"
                onChange={(e) => onChangeBatchName(e)}
              />
            </div>
            <div className="row col-lg-10 col-md-10 col-sm-2">
              <div className="col-lg-5 col-md-5 col-sm-5">
                {/* <label className="label-control">From&nbsp;:&nbsp;</label> */}
                <input
                  type="date"
                  placeholder="dd/mm/yyyy"
                  className="form-control cpp-input datevalidation"
                  name="fromDate"
                  id="fromDate"
                  value={fromSelectedDate}
                  onChange={(e) => onFromDateChange(e.target.value)}
                  style={{
                    width: "75%",
                  }}
                />
              </div>
              <div className="col-lg-5 col-md-5 col-sm-5">
                {/* <label className="label-control">To&nbsp;:&nbsp;</label> */}
                <input
                  type="date"
                  placeholder="dd/mm/yyyy"
                  className="form-control cpp-input datevalidation"
                  name="toDate"
                  value={toSelectedDate}
                  onChange={(e) => onToDateChange(e.target.value)}
                  style={{
                    width: "75%",
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-11 col-md-11 col-sm-11 col-11 text-center ">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 py-3 text-right">
                   
                   <CSVLink data={csvData} filename={"attendance_report.csv"}>
                       <img
                         className="img_icon_size log"
                         src={require("../../static/images/excel_icon.png")}
                         alt="Excel-Export"
                         title="Excel-Export"
                       />
                     </CSVLink>
                     <img
                       className="img_icon_size log ml-2 "
                       onClick={() => onClickReset()}
                       src={require("../../static/images/refresh-icon.png")}
                       alt="refresh"
                       title="Refresh"
                     />
                      <Link to="/all-reports">
                       <img
                         className="img_icon_size log ml-2 "
                         src={require("../../static/images/back.png")}
                         alt="Back"
                         title="Back"
                       />
                     </Link>
                   </div>
              <section className="body">
                <div className=" body-inner no-padding table-responsive table-container mt-5 ">
                 
                  <table
                    className="table table-bordered table-striped table-hover"
                    id="datatable2"
                  >
                    <thead>
                      <tr>
                        <th>Meeting Dates</th>
                        {membersArray &&
                          membersArray.map((member, index) => (
                            <th key={index}>{member.MemberName}</th>
                          ))}
                        <th>Total Attendance</th>{" "}
                        <th>Attendance(%)</th>{" "}
                      </tr>
                    </thead>
                    <tbody>
                      {batchMeetingDates &&
                        batchMeetingDates.map((date, dateIndex) => {
                          // Count the number of Present members for this date
                          const countPresent = membersArray.reduce(
                            (count, member) => {
                              const attendanceStatus =
                                member.selectedDates[date];
                              return attendanceStatus === "Attended"
                                ? count + 1
                                : count;
                            },
                            0
                          );
                          const OverAllCount = membersArray.reduce(
                            (count, member) => {
                              const attendanceStatus =
                                member.selectedDates[date];
                              return (attendanceStatus === "Attended" || attendanceStatus !== "Attended")
                                ? count + 1
                                : count;
                            },
                            0
                          );

                          // Calculate attendance percentage for this date
                          const attendancePercentage =
                            (countPresent / membersArray.length) * 100;

                          return (
                            <tr key={dateIndex}>
                              <td>{getMonthAndDate({ date })}</td>

                              {membersArray.map((member, memberIndex) => {
                                const attendanceStatus =
                                  member.selectedDates[date];

                                return (
                                  <td key={memberIndex}>
                                    {attendanceStatus === "Attended" ? (
                                      <button
                                        style={{
                                          width: "24px",
                                          height: "24px",
                                          cursor: "pointer",
                                          backgroundImage: `url(${require("../../static/images/present.png")})`,
                                          backgroundSize: "cover",
                                          backgroundColor: "transparent",
                                          border: "none",
                                          backgroundPosition: "center",
                                        }}
                                      />
                                    ) : attendanceStatus === "Absent" ? (
                                      <button
                                        style={{
                                          width: "24px",
                                          height: "24px",
                                          cursor: "pointer",
                                          backgroundImage: `url(${require("../../static/images/absent.png")})`,
                                          backgroundSize: "cover",
                                          backgroundColor: "transparent",
                                          border: "none",
                                          backgroundPosition: "center",
                                        }}
                                      />
                                    ) : (
                                      "No Data"
                                    )}
                                  </td>
                                );
                              })}
                              <td>{`${countPresent}/${OverAllCount}`}</td>
                              <td>{(attendancePercentage || 0).toFixed(2)}%</td>
                            </tr>
                          );
                        })}

                      {/* Total Attendance and Attendance Percentage for each member */}
                      <tr>
                        <td>
                          <strong>Total Attendance</strong>
                        </td>
                        {membersArray.map((member, index) => {
                          // Calculate the total attendance for each member
                          const totalAttendance = batchMeetingDates.reduce(
                            (count, date) => {
                              const attendanceStatus =
                                member.selectedDates[date];
                              return attendanceStatus === "Attended"
                                ? count + 1
                                : count;
                            },
                            0
                          );
                          const OverAllAttendance = batchMeetingDates.reduce(
                            (count, date) => {
                              const attendanceStatus =
                                member.selectedDates[date];
                              return (attendanceStatus === "Attended" || attendanceStatus !== "Attended")
                                ? count + 1
                                : count;
                            },
                            0
                          );

                          return <td key={index}>{`${totalAttendance}/${OverAllAttendance}`}</td>;
                        })}

                        {/* Overall Total Attendance */}
                        <td>
                          {batchMeetingDates.reduce((total, date) => {
                            return (
                              total +
                              membersArray.reduce((count, member) => {
                                const attendanceStatus =
                                  member.selectedDates[date];
                                return attendanceStatus === "Attended"
                                  ? count + 1
                                  : count;
                              }, 0)
                            );
                          }, 0)}{" "}
                          {/* Total Present count */}
                        </td>
                        <td>
                          {(
                            (batchMeetingDates.reduce((total, date) => {
                              return (
                                total +
                                membersArray.reduce((count, member) => {
                                  const attendanceStatus =
                                    member.selectedDates[date];
                                  return attendanceStatus === "Attended"
                                    ? count + 1
                                    : count;
                                }, 0)
                              );
                            }, 0) /
                              ((batchMeetingDates.length *
                                membersArray.length)) *
                            100) ||0
                          ).toFixed(2)}
                          %{/* Overall Attendance Percentage */}
                        </td>
                      </tr>

                      {/* Attendance Percentage for each member */}
                      <tr>
                        <td>
                          <strong>Attendance Percentage</strong>
                        </td>
                        {membersArray.map((member, index) => {
                          // Calculate the total attendance for each member
                          const totalAttendance = batchMeetingDates.reduce(
                            (count, date) => {
                              const attendanceStatus =
                                member.selectedDates[date];
                              return attendanceStatus === "Attended"
                                ? count + 1
                                : count;
                            },
                            0
                          );

                          // Calculate the attendance percentage for each member
                          const attendancePercentage =
                            (totalAttendance / batchMeetingDates.length) * 100;

                          return (
                            <td key={index}>
                              {(attendancePercentage || 0).toFixed(2)}%
                            </td>
                          );
                        })}

                        {/* Overall Attendance Percentage */}
                        <td>
                          {(
                            (batchMeetingDates.reduce((total, date) => {
                              return (
                                total +
                                membersArray.reduce((count, member) => {
                                  const attendanceStatus =
                                    member.selectedDates[date];
                                  return attendanceStatus === "Attended"
                                    ? count + 1
                                    : count;
                                }, 0)
                              );
                            }, 0) /
                              ((batchMeetingDates.length * membersArray.length)) * 100 ) || 0).toFixed(2)}
                          % {/* Overall Attendance Percentage */}
                        </td>
                        <td>{/* Placeholder for empty column */}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

AttendenceReport.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
  area: state.area,
});

export default connect(mapStateToProps, {
  getAttendenceDetails,
  getBatchName,
})(AttendenceReport);
