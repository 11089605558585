import Select from "react-select";
import { getOtherLoanTypeForBatch,deleteOtherLoanRepayFromBankTransaction,getBankDetails } from "../../../actions/shg";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import Spinner from "../../layout/Spinner";

export const OLRepaymentTab = ({
  NextBackBtn,
  getOtherLoanTypeForBatch,
  deleteOtherLoanRepayFromBankTransaction,
  activeBank,
  formData,
  setFormData,
  membersMeetingData,
  getBankDetails,
  auth: { isAuthenticated, user, users },
  shg: { activeOtherLoanTypes,newBankDetails,otherLoanTypeFromMember,otherLoanBatchTransaction },
}) => {

  let [OLRepaymentArray, setOLRepaymentArray] = useState(formData.OLRepaymentArray || []);
    
  useEffect(()=>{
    setOLRepaymentArray(formData.OLRepaymentArray ? formData.OLRepaymentArray : otherLoanBatchTransaction);
   
  },[otherLoanBatchTransaction])

  useEffect(() => {
    getOtherLoanTypeForBatch({
      mdBatchId: formData.batchId,
      mdDate: formData.batchMeetingHeldOnDate,
    });
  }, []);


  let MemberOther = membersMeetingData.map((ele) => ele.otherLoan).flat();

  /////////////////////////////////////////memberLoan calculation start/////////////////////////////////////////////
  const calculateTotalsOfMember = (memberOther) => {
    // Initialize an object to store the totals by otherLoanTypeId
    const totals = {};
  
    // Iterate over each entry in the memberOther array
    memberOther.forEach((entry) => {
      const { otherLoanTypeId } = entry;
  
      // If the loan type ID is not already in the totals object, initialize it
      if (!totals[otherLoanTypeId]) {
        totals[otherLoanTypeId] = {
          otherLoanTypeId,
          indSubsidyDistributed: 0,
          otherInterestPaid: 0,
          otherLoanPaid: 0,
          otherLoanTakenAmt: 0,
          otherloanPayableInterst: 0,
          otherloanPayablePrincipal: 0,
        };
      }
  
      // Sum the values for each field by combining cash and bankAmt
      totals[otherLoanTypeId].indSubsidyDistributed += entry.indSubsidyDistributed.cash + entry.indSubsidyDistributed.bankAmt;
  
      totals[otherLoanTypeId].otherInterestPaid += entry.otherInterestPaid.cash + parseFloat(entry.otherInterestPaid.bankAmt);
  
      totals[otherLoanTypeId].otherLoanPaid += entry.otherLoanPaid.cash + parseFloat(entry.otherLoanPaid.bankAmt);
  
      totals[otherLoanTypeId].otherLoanTakenAmt += entry.otherLoanTakenAmt.cash + parseFloat(entry.otherLoanTakenAmt.bankAmt);
  
      totals[otherLoanTypeId].otherloanPayableInterst += entry.otherloanPayableInterst.cash + parseFloat(entry.otherloanPayableInterst.bankAmt);
  
      totals[otherLoanTypeId].otherloanPayablePrincipal += entry.otherloanPayablePrincipal.cash + parseFloat(entry.otherloanPayablePrincipal.bankAmt);
    });
  
    return totals;
  };

const totals = calculateTotalsOfMember(MemberOther);
const arrayTot = Object.values(totals)

//////////////////////////////////////////memberLoanCalculation end//////////////////////////////////////////////////
  let [OLTypeId, setOLTypeId] = useState(null);

  let [OLRepaymentObject, setOLRepaymentObject] = useState({
    otherLoanTypeId: null,
    otherLoanType: null,

    otherLoanSanctionedToShgCash: null,
    otherLoanSanctionedToShgBank: null,
   
    otherLoanPaidToShgCash: null,
    otherLoanPaidToShgBank: null,
    otherLoanPaidToShgBankName:"",

    otherLoanInterestShgCash: null,
    otherLoanInterestShgBank: null,
    otherLoanInterestShgBankName:"",

    subsidyDistributedCash: null,
    subsidyDistributedBank: null,
    subsidyDistributedBankName:"",

    //Inputs
    subsidyReceivedCash: 0,
    subsidyReceivedBank: 0,
    subsidyReceivedBankName: null,

    otherLoanPaidToDonorCash: 0,
    otherLoanPaidToDonorBank: 0,
    otherLoanPaidToDonorBankName: null,

    otherLoanInterestDonorCash: 0,
    otherLoanInterestDonorBank: 0,
    otherLoanInterestDonorBankName: null,
  });

  useEffect(() => {
    if (OLTypeId) {
      setOLRepaymentObject({
        otherLoanTypeId: OLTypeId.otherLoanTypeId,
        otherLoanType: OLTypeId.label,

        //oth sanc
        otherLoanSanctionedToShgCash: MemberOther.filter(
          (ele) => ele.otherLoanTypeId == OLTypeId.otherLoanTypeId
        ).reduce(
          (acu, cur) => (acu += Number(cur.otherloanPayablePrincipal.cash)),
          0
        ),

        otherLoanSanctionedToShgBank: MemberOther.filter(
          (ele) => ele.otherLoanTypeId == OLTypeId.otherLoanTypeId
        ).reduce(
          (acu, cur) => (acu += Number(cur.otherloanPayablePrincipal.bankAmt)),
          0
        ),

        //otherLoanPaidToShg
        otherLoanPaidToShgCash: MemberOther.filter(
          (ele) => ele.otherLoanTypeId == OLTypeId.otherLoanTypeId
        ).reduce((acu, cur) => (acu += Number(cur.otherLoanPaid.cash)), 0),

        otherLoanPaidToShgBank: MemberOther.filter(
          (ele) => ele.otherLoanTypeId == OLTypeId.otherLoanTypeId
        ).reduce((acu, cur) => (acu += Number(cur.otherLoanPaid.bankAmt)), 0),
        

        otherLoanPaidToShgBankName: [...new Set(
          MemberOther.filter(
            (ele) => ele.otherLoanTypeId == OLTypeId.otherLoanTypeId
          ).map((ele) => ele.otherLoanPaid.bankName)
        )],

        // otherInterestPaid
        otherLoanInterestShgCash: MemberOther.filter(
          (ele) => ele.otherLoanTypeId == OLTypeId.otherLoanTypeId
        ).reduce((acu, cur) => (acu += Number(cur.otherInterestPaid.cash)), 0),

        otherLoanInterestShgBank: MemberOther.filter(
          (ele) => ele.otherLoanTypeId == OLTypeId.otherLoanTypeId
        ).reduce(
          (acu, cur) => (acu += Number(cur.otherInterestPaid.bankAmt)),
          0
        ),


        otherLoanInterestShgBankName: [...new Set(
          MemberOther.filter(
            (ele) => ele.otherLoanTypeId == OLTypeId.otherLoanTypeId
          ).map((ele) => ele.otherInterestPaid.bankName)
        )],

        //sub dist
        subsidyDistributedCash: MemberOther.filter(
          (ele) => ele.otherLoanTypeId == OLTypeId.otherLoanTypeId
        ).reduce(
          (acu, cur) => (acu += Number(cur.indSubsidyDistributed.cash)),
          0
        ),
        subsidyDistributedBank: MemberOther.filter(
          (ele) => ele.otherLoanTypeId == OLTypeId.otherLoanTypeId
        ).reduce(
          (acu, cur) => (acu += Number(cur.indSubsidyDistributed.bankAmt)),
          0
        ),

        subsidyDistributedBankName: [...new Set(
          MemberOther.filter(
            (ele) => ele.otherLoanTypeId == OLTypeId.otherLoanTypeId
          ).map((ele) => ele.indSubsidyDistributed.bankName)
        )],

        //sub rcv
        subsidyReceivedCash: 0,
        subsidyReceivedBank: 0,
        subsidyReceivedBankName: null,

        otherLoanPaidToDonorCash: 0,
        otherLoanPaidToDonorBank: 0,
        otherLoanPaidToDonorBankName: null,

        otherLoanInterestDonorCash: 0,
        otherLoanInterestDonorBank: 0,
        otherLoanInterestDonorBankName: null,
      });
    }
  }, [OLTypeId]);

  let {
    otherLoanTypeId,
    otherLoanType,
    otherLoanSanctionedToShgCash,
    otherLoanSanctionedToShgBank,

    otherLoanPaidToShgCash,
    otherLoanPaidToShgBank,
    otherLoanPaidToShgBankName,


    otherLoanInterestShgCash,
    otherLoanInterestShgBank,
    otherLoanInterestShgBankName,

    subsidyDistributedCash,
    subsidyDistributedBank,
    subsidyDistributedBankName,

    subsidyReceivedCash,
    subsidyReceivedBank,
    subsidyReceivedBankName,

    otherLoanPaidToDonorCash,
    otherLoanPaidToDonorBank,
    otherLoanPaidToDonorBankName,

    otherLoanInterestDonorCash,
    otherLoanInterestDonorBank,
    otherLoanInterestDonorBankName,
  } = OLRepaymentObject;

  const InputChange = (e, type) => {
    if (type == "select") {
      setOLRepaymentObject({
        ...OLRepaymentObject,
        otherLoanType: e.label,
        otherLoanTypeId: e.otherLoanTypeId,
      });
    } else if (type == "") {
      setOLRepaymentObject({
        ...OLRepaymentObject,
        [e.target.name]: e.target.value,
      });
    } else {
      setOLRepaymentObject({
        ...OLRepaymentObject,
        [type]: e,
      });
    }
  };
  

  const activeOtherLoan = [];
  activeOtherLoanTypes &&
    activeOtherLoanTypes.map((otherLoanType) =>
      activeOtherLoan.push({
        otherLoanTypeId: otherLoanType._id,
        label: otherLoanType.otherLoanType,
        value: otherLoanType.otherLoanType,
      })
    );

const [idAlreadyPresent,setIsAlreadyPresent]= useState(false)
    useEffect(() => {
     
      let idAlreadyPresent = false;
      arrayTot.find((totItem) => {
        idAlreadyPresent = OLRepaymentArray.find((repaymentItem) =>
          repaymentItem.otherLoanTypeId === totItem.otherLoanTypeId &&
          repaymentItem.otherLoanSanctionedToShg && repaymentItem.otherLoanSanctionedToShg.bankAmt  === totItem.otherloanPayablePrincipal
        );
          if(idAlreadyPresent){
          setIsAlreadyPresent(idAlreadyPresent.otherLoanTypeId)
        }
        
        return idAlreadyPresent;
      });
    }, [OLRepaymentArray, arrayTot]); 

const newOtherLoanDetails = [];
const existingLoanTypeIds = new Set();
otherLoanTypeFromMember && otherLoanTypeFromMember.forEach((ele) => {
  if (ele && ele.otherLoanTypes) {
    const loanDetails = ele.otherLoanTypes; 
    const loanTypeId = loanDetails._id;
    if (!existingLoanTypeIds.has(loanTypeId)) {
     
      if(idAlreadyPresent !== loanTypeId ){
        newOtherLoanDetails.push({
          otherLoanTypeId: loanTypeId,
          label: loanDetails.otherLoanType,
          value: loanDetails.otherLoanType
        });
      }
      existingLoanTypeIds.add(loanTypeId);
    }
  }
});


  const funcKeyDown = (e) => {
    ["e", "E", "+", "-","."].includes(e.key) && e.preventDefault();
  };
  /////////////////////////////////////////////calculating the total of new other loan details////////////////////////////

  const calculateTotals = (data) => {
    const bankTotals = {};
    data.forEach((member) => {

      member.otherLoan  && member.otherLoan.forEach((loan) => {
        const bankName = loan.otherLoanTakenAmt.bankName; // Use the bankName from the loan
      
        // Initialize the bank entry if it doesn't exist
   
          if (!bankTotals[bankName]) {
            bankTotals[bankName] = {
              bankId: member.memBankName && member.memBankName.bankId  ,
              totalLoanTakenAmt: 0,
              totalLoanPaid: 0,
              totalSubsidyDistributed: 0,
              totalInterestPaid: 0,
            };
        }
      
        
  
        // Aggregate totals
        bankTotals[bankName].totalLoanTakenAmt += Number(loan.otherLoanTakenAmt.bankAmt);
        bankTotals[bankName].totalLoanPaid += Number(loan.otherLoanPaid.bankAmt);
        bankTotals[bankName].totalSubsidyDistributed += Number(loan.indSubsidyDistributed.bankAmt);
        bankTotals[bankName].totalInterestPaid += Number(loan.otherInterestPaid.bankAmt);
      });
    });
  
    // Convert the object to an array and return only those with totals
    return Object.keys(bankTotals)
      .map((key) => ({
        bankId: bankTotals[key].bankId,
        bankName: key,
        totalLoanTakenAmt: bankTotals[key].totalLoanTakenAmt,
        totalLoanPaid: bankTotals[key].totalLoanPaid,
        totalSubsidyDistributed: bankTotals[key].totalSubsidyDistributed,
        totalInterestPaid: bankTotals[key].totalInterestPaid,
      }))
      .filter(bank => bank.totalLoanTakenAmt > 0); // Exclude banks with no loans
  };
  
  const OLRepaidDetails = calculateTotals(membersMeetingData);

  const totalOtherLoanTakenAmt = membersMeetingData.reduce((total, member) => {
    const memberTotal = member.otherLoan.reduce((subTotal, loan) => {
      // Accessing otherLoanPayablePrincipal correctly
      const payablePrincipal = loan.otherloanPayablePrincipal || {};
      const loanPaid = loan.otherLoanPaid || {};
  
      // Sum up the necessary amounts
      const loanAmount = 
        Number(payablePrincipal.bankAmt || 0) + 
        Number(payablePrincipal.cash || 0) + 
        Number(loanPaid.bankAmt || 0) + 
        Number(loanPaid.cash || 0);
  
      // Access and add indSubsidyDistributed and otherInterestPaid from the loan
      const indSubsidyDistributed = loan.indSubsidyDistributed || {};
      const otherInterestPaid = loan.otherInterestPaid || {};
  
      return subTotal + loanAmount +
        Number(indSubsidyDistributed.bankAmt || 0) + 
        Number(indSubsidyDistributed.cash || 0) +
        Number(otherInterestPaid.bankAmt || 0) + 
        Number(otherInterestPaid.cash || 0);
    }, 0);
  
    // Add the memberTotal to the overall total
    return total + memberTotal;
  }, 0);
  
  
  // Calculate total cash + bankAmt specifically for otherLoanSanctionedToShg
  const totalSanctionedToShgAmount = OLRepaymentArray.reduce((total, item) => {
    // Initialize the sanctioned amount for this item
    let sanctionedAmount = 0;
  
    // Sum values from otherLoanSanctionedToShg
    if (item.otherLoanSanctionedToShg) {
      const sanctionedValues = item.otherLoanSanctionedToShg;
      sanctionedAmount += Number(sanctionedValues.cash || 0) + Number(sanctionedValues.bankAmt || 0);
    }
  
    // Sum values from otherLoanPaidToShg
    if (item.otherLoanPaidToShg) {
      const paidValues = item.otherLoanPaidToShg;
      sanctionedAmount += Number(paidValues.cash || 0) + Number(paidValues.bankAmt || 0);
    }
  
    // Sum values from otherLoanInterestShg
    if (item.otherLoanInterestShg) {
      const interestValues = item.otherLoanInterestShg;
      sanctionedAmount += Number(interestValues.cash || 0) + Number(interestValues.bankAmt || 0);
    }
  
    // Sum values from subsidyDistributed
    if (item.subsidyDistributed) {
      const subsidyValues = item.subsidyDistributed;
      sanctionedAmount += Number(subsidyValues.cash || 0) + Number(subsidyValues.bankAmt || 0);
    }
  
    // Return the total
    return total + sanctionedAmount;
  }, 0);
  
  /////////////////////////////////////////calculating the total of new other loan details end//////////////////////////
  const [combinedArray, setCombinedArray] = useState([]);
  const [combinedOLRepaymentArray,setCombinedOLRepaymentArray] = useState(formData.combinedOLRepaymentArray || [])


  const onOLRepaymentAdd = () => {
    let OLRepaymentBank = [];

    let OLRepaymentFinalArray = {
      otherLoanTypeId: otherLoanTypeId,
      otherLoanType: otherLoanType,

      otherLoanPaidToShg: {
        cash: Number(otherLoanPaidToShgCash),
        bankAmt: Number(otherLoanPaidToShgBank),
        bankName: "",
      },

      otherLoanInterestShg: {
        cash: Number(otherLoanInterestShgCash),
        bankAmt: Number(otherLoanInterestShgBank),
        bankName: "",
      },

      subsidyDistributed: {
        cash: Number(subsidyDistributedCash),
        bankAmt: Number(subsidyDistributedBank),
        bankName: "",
      },

      otherLoanSanctionedToShg: {
        cash: Number(otherLoanSanctionedToShgCash),
        bankAmt: Number(otherLoanSanctionedToShgBank),
        bankName: "",
      },

      otherLoanPaidToDonor: {
        cash: Number(otherLoanPaidToDonorCash),
        bankAmt: Number(otherLoanPaidToDonorBank),
        bankName: otherLoanPaidToDonorBankName
          ? otherLoanPaidToDonorBankName.label
          : "",
      },

      otherLoanInterestDonor: {
        cash: Number(otherLoanInterestDonorCash),
        bankAmt: Number(otherLoanInterestDonorBank),
        bankName: otherLoanInterestDonorBankName
          ? otherLoanInterestDonorBankName.label
          : "",
      },

      subsidyReceived: {
        cash: Number(subsidyReceivedCash),
        bankAmt: Number(subsidyReceivedBank),
        bankName: subsidyReceivedBankName ? subsidyReceivedBankName.label : "",
      },
    };

    ////////////////////////////////////////////////////calculation of OLRepayment start//////////////////////////
    // Assuming formData contains the bank details and memberOtherLoanArray
    const bankDataArray = formData.bankDetails;
    const memberOtherLoanArray = formData.memberOtherLoanArray;

    // Create a new array based on the previous state
    const currentCombinedArray = [...combinedArray];

    // Define the mapping for income and expense fields
    const incomeFields = ["subsidyReceived"];
    const expenseFields = [
      "otherLoanInterestDonor",
      "otherLoanPaidToDonor",
      "otherLoanInterestShg",
    ];

    // Calculate total income based on loan type
    incomeFields.forEach((field) => {
      const data = OLRepaymentFinalArray[field];

      if (data && data.bankAmt > 0) {
        const bankName = data.bankName; // Extracting bank name from data
        const bank = bankDataArray.find((b) => b.bankName === bankName);

        if (bank) {
          const existingEntry = currentCombinedArray.find(
            (entry) => entry.batchBankNameId === bank.bankId
          );

          if (existingEntry) {
            existingEntry.indBatchBankDeposit += data.bankAmt; // Combine totals
          } else {
            const loanDetails = memberOtherLoanArray.find(
              (loan) => loan.batchBankNameId === bank.bankId
            );
            currentCombinedArray.push({
              bankId: bank.bankId,
              batchBankName: bank.bankName,
              batchBankNameId: bank.bankId,
              cash: 0,
              indBankCommission: 0,
              indBankInterest: 0,
              indBatchBankDeposit: data.bankAmt,
              indBatchBankWithdrawals: 0,
              otherLoanTypeId: loanDetails ? loanDetails.otherLoanTypeId : "",
              transactionType: "B (OL)", // Mark as income
            });
          }
        }
      }
    });

    // Process expense data
    expenseFields.forEach((field) => {
      const data = OLRepaymentFinalArray[field];

      if (data && data.bankAmt > 0) {
        const bankName = data.bankName;
        const bank = bankDataArray.find((b) => b.bankName === bankName);

        if (bank) {
          const existingEntry = currentCombinedArray.find(
            (entry) => entry.batchBankNameId === bank.bankId
          );

          if (existingEntry) {
            existingEntry.indBatchBankWithdrawals += data.bankAmt; // Combine totals
          } else {
            const loanDetails = memberOtherLoanArray.find(
              (loan) => loan.batchBankName === bank.label
            );
            currentCombinedArray.push({
              bankId: bank.bankId,
              batchBankName: bank.bankName,
              batchBankNameId: bank.bankId,
              cash: 0,
              indBankCommission: 0,
              indBankInterest: 0,
              indBatchBankDeposit: 0,
              indBatchBankWithdrawals: data.bankAmt,
              otherLoanTypeId: loanDetails ? loanDetails.otherLoanTypeId : "",
              transactionType: "B (OL)", // Mark as expense
            });
          }
        }
      }
    });

    // Update the state with the new combined array
    setCombinedArray(currentCombinedArray);

    ////////////////////////////////////////////////calculation of OLRepayment end///////////////////////////////
    setOLRepaymentArray([...OLRepaymentArray, OLRepaymentFinalArray]);
    const newArray = calculateCombinedArray(
      currentCombinedArray,
      memberOtherLoanArray
    );

    setFormData((prevData) => ({
      ...prevData,
      combinedOLRepaymentArray: newArray.filter((data)=>{
        return (
          data.indBankInterest !== 0 ||
          data.indBankCommission !== 0 ||
          data.indBatchBankDeposit !== 0 ||
          data.indBatchBankWithdrawals !== 0
        );
      }),
    }));
  };


  const calculateCombinedArray = (
    currentCombinedArray,
    memberOtherLoanArray
  ) => {
    const newArray = [];
  
    // Step 1: Process formData.OlRepaymentFields even if currentCombinedArray is not empty
    if (formData && formData.OlRepaymentFields) {
      formData.OlRepaymentFields.forEach((loan) => {
        const {
          bankId,
          bankName,
          otherLoanTypeId,
          totalInterestPaid,
          totalLoanPaid,
          totalSubsidyDistributed,
        } = loan;
  
        // Check if the bankId exists in currentCombinedArray
        const existingBankIndex = currentCombinedArray.findIndex(
          (entry) =>
            entry.bankId === bankId && entry.otherLoanTypeId === otherLoanTypeId
        );
  
  
        if (existingBankIndex !== -1) {
          // If the bankId and otherLoanTypeId already exist in currentCombinedArray, update it
          if (newArray[existingBankIndex]) {
            newArray[existingBankIndex].indBatchBankDeposit =
              (newArray[existingBankIndex].indBatchBankDeposit || 0) +
              totalInterestPaid +
              totalLoanPaid ||
              0;
            newArray[existingBankIndex].indBatchBankWithdrawals =
              (newArray[existingBankIndex].indBatchBankWithdrawals || 0) +
              totalSubsidyDistributed ||
              0;
          }
        } else {
          // If the bankId and otherLoanTypeId do not exist in currentCombinedArray, add it as a new entry
          newArray.push({
            bankId: bankId,
            batchBankName: bankName,
            batchBankNameId: bankId,
            indBankCommission: 0,
            indBankInterest: 0,
            indBatchBankDeposit: totalInterestPaid + totalLoanPaid || 0,
            indBatchBankWithdrawals: totalSubsidyDistributed || 0,
            otherLoanTypeId: otherLoanTypeId,
            transactionType: "B (OL)", // Or any type you want to specify
          });
        }
      });
    }
  
    // Step 2: Process currentCombinedArray as usual (if it's not empty)
    currentCombinedArray.forEach((combinedEntry) => {
      const {
        bankId,
        otherLoanTypeId,
        indBatchBankDeposit,
        indBatchBankWithdrawals,
      } = combinedEntry;
  
      // Find matching entry in memberOtherLoanArray
      const matchingEntry = memberOtherLoanArray.find(
        (memberEntry) =>
          memberEntry.batchBankNameId === bankId &&
          memberEntry.otherLoanTypeId === otherLoanTypeId
      );
  
      if (matchingEntry) {
        // Merge matching entry into newArray
        newArray.push({
          bankId: bankId,
          batchBankName: combinedEntry.batchBankName,
          batchBankNameId: bankId,
          indBankCommission: 0,
          indBankInterest: 0,
          indBatchBankDeposit:
            indBatchBankDeposit + matchingEntry.indBatchBankDeposit, // Sum deposits
          indBatchBankWithdrawals:
            indBatchBankWithdrawals + matchingEntry.indBatchBankWithdrawals, // Sum withdrawals
          otherLoanTypeId: otherLoanTypeId,
          transactionType: "B (OL)", // Or any type you want to specify
        });
      } else {
        // If no match, just take the combinedEntry as is
        newArray.push({
          ...combinedEntry,
          transactionType: "B (OL)", // Maintain original transaction type
        });
      }
    });
  
    // Log to check final result
  
    // Output the result
    setCombinedOLRepaymentArray(newArray.filter((ele)=>{ return (
      ele.indBankInterest !== 0 ||
      ele.indBankCommission !== 0 ||
      ele.indBatchBankDeposit !== 0 ||
      ele.indBatchBankWithdrawals !== 0
    )}));
    setOLRepaymentObject({
      otherLoanTypeId: null,
      otherLoanType: null,
  
      otherLoanSanctionedToShgCash: null,
      otherLoanSanctionedToShgBank: null,
  
      otherLoanPaidToShgCash: null,
      otherLoanPaidToShgBank: null,
  
      otherLoanInterestShgCash: null,
      otherLoanInterestShgBank: null,
  
      subsidyDistributedCash: null,
      subsidyDistributedBank: null,
  
      subsidyReceivedCash: 0,
      subsidyReceivedBank: 0,
      subsidyReceivedBankName: null,
  
      otherLoanPaidToDonorCash: 0,
      otherLoanPaidToDonorBank: 0,
      otherLoanPaidToDonorBankName: null,
  
      otherLoanInterestDonorCash: 0,
      otherLoanInterestDonorBank: 0,
      otherLoanInterestDonorBankName: null,
    });
    return newArray;
  };
  const [showDeleteLoanModal,setShowDeleteLoanModal]=useState(false);
  const [otherLoanDeletionDetails,setOtherLoanDeletionDetails] = useState("")
  const [deletedTrue,setDeletedTrue] = useState(false);
  const DeleteLoan =()=>{
    setDeletedTrue(true)
    deleteOtherLoanRepayFromBankTransaction(otherLoanDeletionDetails)
    setShowDeleteLoanModal(false)
    setOLRepaymentArray(
          OLRepaymentArray.filter((ele) => ele.otherLoanTypeId !== otherLoanDeletionDetails.otherLoanTypeId)
        );
    setOLTypeId("");
    setOLRepaymentObject({
      otherLoanTypeId: null,
      otherLoanType: null,

      otherLoanSanctionedToShgCash: null,
      otherLoanSanctionedToShgBank: null,

      otherLoanPaidToShgCash: null,
      otherLoanPaidToShgBank: null,

      otherLoanInterestShgCash: null,
      otherLoanInterestShgBank: null,

      subsidyDistributedCash: null,
      subsidyDistributedBank: null,

      subsidyReceivedCash: 0,
      subsidyReceivedBank: 0,
      subsidyReceivedBankName: null,

      otherLoanPaidToDonorCash: 0,
      otherLoanPaidToDonorBank: 0,
      otherLoanPaidToDonorBankName: null,

      otherLoanInterestDonorCash: 0,
      otherLoanInterestDonorBank: 0,
      otherLoanInterestDonorBankName: null,
    });
  }

  const onOLRepaymentDelete = async (loanTypeId) => {
    setShowDeleteLoanModal(true)
    let finalData = {
      otherLoanTypeId : loanTypeId,
      batchId :formData.batchId,
      batchMeetingHeldOnDate:formData.batchMeetingHeldOnDate
    }
    
    setOtherLoanDeletionDetails(finalData)
  };

//////////////////////////call for database//////////////////////////////////
useEffect(()=>{
  getBankDetails({mdBatchId:formData.batchId, mdDate:formData.batchMeetingHeldOnDate})
 
},[deletedTrue])
////////////////////////call for database end /////////////////////////////


  const nextOfOLRepayment = () => {
    setFormData({
      ...formData,
      combinedOLRepaymentArray:combinedOLRepaymentArray,
      bankTransactions:newBankDetails && newBankDetails.bankTransactions,
      otherLoanBatchTransaction: OLRepaymentArray,
      subsidyReceived: OLRepaymentArray.reduce(
        (acu, cur) =>
          (acu +=
            Number(cur.subsidyReceived.cash) +
            Number(cur.subsidyReceived.bankAmt)),
        0
      ),
      batchOtherLoanInterestDonor: OLRepaymentArray.reduce(
        (acu, cur) =>
          (acu +=
            Number(cur.otherLoanInterestDonor.cash) +
            Number(cur.otherLoanInterestDonor.bankAmt)),
        0
      ),
      otherLoanPaidToDonor: OLRepaymentArray.reduce(
        (acu, cur) =>
          (acu +=
            Number(cur.otherLoanPaidToDonor.cash) +
            Number(cur.otherLoanPaidToDonor.bankAmt)),
        0
      ),
      otherLoanSanctionedToShg: OLRepaymentArray.reduce(
        (acu, cur) =>
          (acu +=
            Number(cur.otherLoanSanctionedToShg.cash) +
            Number(cur.otherLoanSanctionedToShg.bankAmt)),
        0
      ),
      subsidyDistributed: OLRepaymentArray.reduce(
        (acu, cur) =>
          (acu +=
            Number(cur.subsidyDistributed.cash) +
            Number(cur.subsidyDistributed.bankAmt)),
        0
      ),

      otherLoanPaidToShg: OLRepaymentArray.reduce(
        (acu, cur) =>
          (acu +=
            Number(cur.otherLoanPaidToShg.cash) +
            Number(cur.otherLoanPaidToShg.bankAmt)),
        0
      ),

      batchOtherLoanInterestShg: OLRepaymentArray.reduce(
        (acu, cur) =>
          (acu +=
            Number(cur.otherLoanInterestShg.cash) +
            Number(cur.otherLoanInterestShg.bankAmt)),
        0
      ),
      OLRepaymentArray : OLRepaymentArray
    });
   
    NextBackBtn(8);
  };

  return (
   
    <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
    <>
        <div className="row card-new  py-3">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <h5>Other Loan Repayment</h5>
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
            <label className="label-control">OL Types* :</label>
            <Select
              name="otherLoanType"
              id="otherLoanTypeId"
              options={newOtherLoanDetails}
              isSearchable={true}
              value={OLTypeId}
              onChange={(e) => {
                setOLTypeId(e);
                InputChange(e, "select");
              }}
              placeholder="Select Bank"
              theme={(theme) => ({
                ...theme,
                height: 26,
                minHeight: 26,
                borderRadius: 1,
                colors: {
                  ...theme.colors,
                  primary: "black",
                },
              })}
            />

            <label className="label-control">OL Sanctioned To SHG&nbsp;:&nbsp;</label>
            <div className="row col-12">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                Bank{" "}
                <input
                  type="number"
                  name="otherLoanSanctionedToShgBank"
                  value={otherLoanSanctionedToShgBank}
                  className="form-control"
                  onKeyDown={(e) => funcKeyDown(e)}
                  onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                  disabled
                  required
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                Cash{" "}
                <input
                  type="number"
                  name="otherLoanSanctionedToShgCash"
                  value={otherLoanSanctionedToShgCash}
                  className="form-control"
                  onKeyDown={(e) => funcKeyDown(e)}
                  onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                  disabled
                  required
                />
              </div>
            </div>

            <label className="label-control">OL Principal Paid To SHG&nbsp;:&nbsp;</label>
            <div className="row col-12">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                Bank{" "}
                <input
                  type="number"
                  name="otherLoanPaidToShgBank"
                  value={otherLoanPaidToShgBank}
                  className="form-control"
                  onKeyDown={(e) => funcKeyDown(e)}
                  onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                  disabled
                  required
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                Cash{" "}
                <input
                  type="number"
                  name="otherLoanPaidToShgCash"
                  value={otherLoanPaidToShgCash}
                  className="form-control"
                  onKeyDown={(e) => funcKeyDown(e)}
                  onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                  disabled
                  required
                />
              </div>
            </div>

            <label className="label-control">OL Interest Paid To SHG&nbsp;:&nbsp;</label>
            <div className="row col-12">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                Bank{" "}
                <input
                  type="number"
                  name="otherLoanInterestShgBank"
                  value={otherLoanInterestShgBank}
                  className="form-control"
                  onKeyDown={(e) => funcKeyDown(e)}
                  onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                  disabled
                  required
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                Cash{" "}
                <input
                  type="number"
                  name="otherLoanInterestShgCash"
                  value={otherLoanInterestShgCash}
                  className="form-control"
                  onKeyDown={(e) => funcKeyDown(e)}
                  onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                  disabled
                  required
                />
              </div>
            </div>

            <label className="label-control"> Subsidy Distributed&nbsp;:&nbsp;</label>
            <div className="row col-12">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                Bank{" "}
                <input
                  type="number"
                  name="subsidyDistributedBank"
                  value={subsidyDistributedBank}
                  className="form-control"
                  onKeyDown={(e) => funcKeyDown(e)}
                  onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                  disabled
                  required
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                Cash{" "}
                <input
                  type="number"
                  name="subsidyDistributedCash"
                  value={subsidyDistributedCash}
                  className="form-control"
                  onKeyDown={(e) => funcKeyDown(e)}
                  onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                  disabled
                  required
                />
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
            <label className="label-control">Subsidy Received To SHG&nbsp;:&nbsp;</label>
            <div className="row col-12">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                Bank List
                <Select
                  name="subsidyReceivedBankName"
                  options={activeBank}
                  value={subsidyReceivedBankName}
                  onChange={(e) => {
                    InputChange(e, "subsidyReceivedBankName");
                  }}
                  // onChange={(e) => onotherLoanTypeChange(e)}
                  isSearchable={true}
                  placeholder="Select Bank"
                  theme={(theme) => ({
                    ...theme,
                    height: 26,
                    minHeight: 26,
                    borderRadius: 1,
                    colors: {
                      ...theme.colors,
                      primary: "black",
                    },
                  })}
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                Bank Amount{" "}
                <input
                  type="number"
                  name="subsidyReceivedBank"
                  onChange={(e) => {
                    InputChange(e, "");
                  }}
                  value={subsidyReceivedBank}
                  disabled={subsidyReceivedBankName ? false : true}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>
            <div className="row col-12">
              <div className="col-lg-6">
                {" "}
                Cash{" "}
                <input
                  type="number"
                  name="subsidyReceivedCash"
                  value={subsidyReceivedCash}
                  onChange={(e) => {
                    InputChange(e, "");
                  }}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>

            <label className="label-control">
              OL Principal Paid To Donor&nbsp;:&nbsp;
            </label>
            <div className="row col-12">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                Bank List
                <Select
                  name="otherLoanPaidToDonorBankName"
                  options={activeBank}
                  value={otherLoanPaidToDonorBankName}
                  onChange={(e) => {
                    InputChange(e, "otherLoanPaidToDonorBankName");
                  }}
                  isSearchable={true}
                  placeholder="Select Bank"
                  theme={(theme) => ({
                    ...theme,
                    height: 26,
                    minHeight: 26,
                    borderRadius: 1,
                    colors: {
                      ...theme.colors,
                      primary: "black",
                    },
                  })}
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                {" "}
                Bank Amount{" "}
                <input
                  type="number"
                  name="otherLoanPaidToDonorBank"
                  value={otherLoanPaidToDonorBank}
                  disabled={otherLoanPaidToDonorBankName ? false : true}
                  onChange={(e) => {
                    InputChange(e, "");
                  }}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>
            <div className="row col-12">
              <div className="col-lg-6">
                {" "}
                Cash{" "}
                <input
                  type="number"
                  name="otherLoanPaidToDonorCash"
                  value={otherLoanPaidToDonorCash}
                  onChange={(e) => {
                    InputChange(e, "");
                  }}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>

            <label className="label-control">OL Interest Paid To Donor&nbsp;:&nbsp;</label>
            <div className="row col-12">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                Bank List
                <Select
                  name="otherLoanInterestDonorBankName"
                  options={activeBank}
                  value={otherLoanInterestDonorBankName}
                  onChange={(e) => {
                    InputChange(e, "otherLoanInterestDonorBankName");
                  }}
                  isSearchable={true}
                  placeholder="Select Bank"
                  theme={(theme) => ({
                    ...theme,
                    height: 26,
                    minHeight: 26,
                    borderRadius: 1,
                    colors: {
                      ...theme.colors,
                      primary: "black",
                    },
                  })}
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                {" "}
                Bank Amount{" "}
                <input
                  type="number"
                  name="otherLoanInterestDonorBank"
                  onChange={(e) => {
                    InputChange(e, "");
                  }}
                  value={otherLoanInterestDonorBank}
                  disabled={otherLoanInterestDonorBankName ? false : true}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>
            <div className="row col-12">
              <div className="col-lg-6">
                {" "}
                Cash{" "}
                <input
                  type="number"
                  name="otherLoanInterestDonorCash"
                  value={otherLoanInterestDonorCash}
                  onChange={(e) => {
                    InputChange(e, "");
                  }}
                  className="form-control"
                  onWheel={() => document.activeElement.blur()}
                  onKeyDown={(e) => funcKeyDown(e)}
                />
              </div>
            </div>
          </div>

          <div className="col-12">
            <button
              className="btn sub_form btn_continue Save float-right"
              type="button"
              onClick={() => (OLTypeId ? onOLRepaymentAdd() : "")}
              disabled={OLRepaymentArray.map(
                (ele) => ele.otherLoanTypeId
              ).includes(OLTypeId ? OLTypeId.otherLoanTypeId : "")}
            >
              Add
            </button>
          </div>
        </div>
        <div className=" body-inner no-padding  table-responsive">
          <table
            className="tabllll table table-bordered table-striped table-hover"
            id="datatable2"
          >
            <thead>
              <tr>
                <th>OL Type</th>
                <th>OL Sanctioned To SHG</th>
                <th>OL Principal Paid To SHG</th>
                <th>OL Interest Paid SHG</th>
                <th>Subsidy Received</th>
                <th>Subsidy Distributed</th>
                <th>OL Principal Paid To Donor</th>
                <th>OL Interest Paid To Donor</th>
                <th>OP</th>
              </tr>
            </thead>
            <tbody>
              {OLRepaymentArray &&
                OLRepaymentArray.map((OLdata, idx) => {
                  let totalTakenAmt = 0;
                  totalTakenAmt+= (Number(OLdata.otherLoanSanctionedToShg.cash || 0) +
                  Number(OLdata.otherLoanSanctionedToShg.bankAmt))
                  return (
                    <tr key={idx}>
                      <td>
                        {
                          activeOtherLoan.find(
                            (ele) =>
                              ele.otherLoanTypeId == OLdata.otherLoanTypeId
                          ).label || 0
                        }
                      </td>
                      <td>
                        {Number(OLdata.otherLoanSanctionedToShg.cash) +
                          Number(OLdata.otherLoanSanctionedToShg.bankAmt)}
                      </td>
                      <td>
                        {Number(OLdata.otherLoanPaidToShg.cash) +
                          Number(OLdata.otherLoanPaidToShg.bankAmt)}
                      </td>

                      <td>
                        {Number(OLdata.otherLoanInterestShg.cash) +
                          Number(OLdata.otherLoanInterestShg.bankAmt)}
                      </td>

                      <td>
                        {Number(OLdata.subsidyReceived.cash) +
                          Number(OLdata.subsidyReceived.bankAmt)}
                      </td>

                      <td>
                        {Number(OLdata.subsidyDistributed.cash) +
                          Number(OLdata.subsidyDistributed.bankAmt)}
                      </td>

                      <td>
                        {Number(OLdata.otherLoanPaidToDonor.cash) +
                          Number(OLdata.otherLoanPaidToDonor.bankAmt)}
                      </td>

                      <td>
                        {Number(OLdata.otherLoanInterestDonor.cash) +
                          Number(OLdata.otherLoanInterestDonor.bankAmt)}
                      </td>

                      <td>
                        <img
                          className="img_icon_size log"
                          onClick={() =>
                            onOLRepaymentDelete(OLdata.otherLoanTypeId)
                          }
                          src={require("../../../static/images/delete.png")}
                          alt="Remove"
                          title="Remove"
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <h5 className="text-right" style={{color:"red"}}>{totalOtherLoanTakenAmt != totalSanctionedToShgAmount? "Please update the OL Repayment details" : ""}</h5>
      </>

     
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        {totalOtherLoanTakenAmt != totalSanctionedToShgAmount ? 
        <button
          className="btn sub_form btn_continue Save float-right"
          type="button"
          disabled
          // onClick={() => nextOfOLRepayment()}
        >
          Next
        </button>:  
        <button
          className="btn sub_form btn_continue Save float-right"
          type="button"
          onClick={() => nextOfOLRepayment()}
        >
          Next
        </button> }
        {/* {totalTakenAmt} */}
      
        <button
          className="btn sub_form btn_continue Save float-right"
          type="button"
          onClick={() => NextBackBtn(6)}
        >
          Previous
        </button>
      </div>


{/* other Loan repayment deleting start */}
<Modal
        show={showDeleteLoanModal}
        backdrop="static"
        keyboard={false}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="col-lg-11 ">
            <h3 className="modal-title ">Attention</h3>
          </div>
          <div className="col-lg-1 mr-3">
            <button
              onClick={() => setShowDeleteLoanModal(false)}
              className="close"
            >
              <img
                src={require("../../../static/images/close.png")}
                alt="X"
                style={{ height: "20px", width: "20px" }}
              />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
        <div className="text-center">
          <h5>Are you sure you want to delete the loan details and repayment records?
             This action cannot be reversed.</h5>
          {/* <span> Note: If applicable, please ensure that any consecutive repayments related to this loan in the upcoming batch meetings are deleted as well.</span>  */}
        </div>
        <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
                <input
                  type="button"
                  onClick={() => {DeleteLoan()}}
                  name="submit"
                  value="Delete"
                  className="btn sub_form btn_continue Save float-right"
               
                />
                <input
                  type="button"
                  name="Prtev"
                  value="Cancel"
                  onClick={() => setShowDeleteLoanModal(false)}
                  className="btn sub_form btn_continue Save float-right"
                />
              </div>
        </Modal.Body>
      </Modal>
{/* other loan repayment deleting end */}

    </div>
  );
};

OLRepaymentTab.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  getOtherLoanTypeForBatch,deleteOtherLoanRepayFromBankTransaction,getBankDetails
})(OLRepaymentTab);
