import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { Link, Redirect } from "react-router-dom";
import Resizer from "react-image-file-resizer";
import { connect } from "react-redux";
import DatePicker from "react-multi-date-picker";
import { RadioGroup, RadioButton } from "react-radio-buttons";
import {
  BatchTransaction,
  meetingDates,
  getParticularLoanReason,
  getMembersOfSelectedBatch,
  checkAvailableDate,
  removeMessage,
  getMeetingSummary,
  AddLoanSanctionedDetails,
  getActiveOtherLoanType,
  getActiveBankData,
  sendMettingFile,
  getMemberMeetingSummary,
  getBatchMeetingData,
  getLoanSantionedData,
} from "../../actions/shg";
import SummaryBatchTab from "./batchMeetingTabs/SummaryBatchTab";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Spinner from "../layout/Spinner";
// import FileBase64 from "react-file-base64";
// import PdfPreview from "./PdfPreview";
import ImgPreview from "./ImgPreview";
// import { PDFDocument, PDFImage, PDFCompressionQuality } from "pdf-lib";
import BatchIncomeTab from "./batchMeetingTabs/BatchIncomeTab";
import BatchExpenseTab from "./batchMeetingTabs/BatchExpenseTab";
import BatchOLRepayment from "./batchMeetingTabs/BatchOLRepayment";
import BatchLoanSanction from "./batchMeetingTabs/BatchLoanSanction";
import BatchOLSanction from "./batchMeetingTabs/BatchOLSanction";
import BatchBankDetTab from "./batchMeetingTabs/BatchBankDetTab";

const AddBatchMeetingDetails = ({
  auth: { isAuthenticated, user, users, loading },
  shg: {
    loanreason,
    meetingResponse,
    summaryData,
    summaryDataOp,
    selBatchMem,
    memberClosingAmt,
    activeOtherLoanTypes,
    activeBankData,
    memSummaryData,
    batchData,
  },
  getLoanSantionedData,
  getBatchMeetingData,
  BatchTransaction,
  meetingDates,
  getParticularLoanReason,
  getMembersOfSelectedBatch,
  checkAvailableDate,
  removeMessage,
  getMeetingSummary,
  AddLoanSanctionedDetails,
  getActiveOtherLoanType,
  getActiveBankData,
  sendMettingFile,

  getMemberMeetingSummary,
}) => {
  let bankBalance = 0,
    cashInHandVal = 0;

  let lastMeetingData = JSON.parse(localStorage.getItem("lastMeeingDate"));
  let batchIdValue = JSON.parse(localStorage.getItem("batchIdValue"));
  let selectedBatchData = JSON.parse(localStorage.getItem("selBatchData"));

  // const meetingBatchInfo = {
  //   mdDate: batchIdValue.mdDate, //selectedDate
  //   mdBatchId: batchIdValue.batchId,
  // };

  useEffect(() => {
    removeMessage();
  }, [removeMessage]);

  useEffect(() => {
    getParticularLoanReason();
  }, [getParticularLoanReason]);

  useEffect(() => {
    getMembersOfSelectedBatch(batchIdValue);
  }, [getMembersOfSelectedBatch]);

  useEffect(() => {
    getActiveOtherLoanType();
  }, [getActiveOtherLoanType]);
  useEffect(() => {
    getActiveBankData(batchIdValue);
  }, [getActiveBankData]);

  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );

  let batchTransactionData = JSON.parse(
    localStorage.getItem("batchTransactionData")
  );

  const allPermission = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.permission &&
    loggedUserInstitutionData.permission.map((permission) =>
      allPermission.push(permission.permissionName)
    );

  const [formData, setFormData] = useState({
    // deactiveMemberSaving: null,
    // sdUnionToSHG: null,
    batchId: batchIdValue.batchIdVal, //selectedBatchData[0].batchId
    batchName: batchIdValue.batchNameVal, // selectedBatchData[0].batchName
    batchMeetingHeldOnDate: null,
    batchMeetingMonth: null,
    batchMeetingYear: null,
    batchTransactionType: "Batchwise", // selBatchData[0].batchTransactionType,
    meetingCategory: lastMeetingData.length > 0 ? "M" : "OP",
    meetingNotes: "",
    batchSavingAmt: {
      cash: 0,
      bankAmt: 0,
      bankName: "",
    }, //BC
    batchSubAmt: {
      cash: 0,
      bankAmt: 0,
      bankName: "",
    }, //BC
    batchOtherContribution: {
      cash: 0,
      bankAmt: 0,
      bankName: "",
    }, //BC
    batchMembershipFees: {
      cash: 0,
      bankAmt: 0,
      bankName: "",
    }, //BC
    deactiveMemberSaving: {
      cash: 0,
      bankAmt: 0,
      bankName: "",
    }, //BC
    batchOtherIncome: {
      cash: 0,
      bankAmt: 0,
      bankName: "",
    }, //BC
    seedMoney: {
      cash: 0,
      bankAmt: 0,
      bankName: "",
    }, //BC
    batchSecurityDepositMemToShg: {
      cash: 0,
      bankAmt: 0,
      bankName: "",
    }, //BC
    sdUnionToSHG: {
      cash: 0,
      bankAmt: 0,
      bankName: "",
    }, //BC
    loanRepaidByOtherSHG: {
      cash: 0,
      bankAmt: 0,
      bankName: "",
    }, //BC
    travellingExpenses: {
      cash: 0,
      bankAmt: 0,
      bankName: "",
    }, //BC
    stationaryExpenses: {
      cash: 0,
      bankAmt: 0,
      bankName: "",
    }, //bc,
    paidToMahasangha: {
      cash: 0,
      bankAmt: 0,
      bankName: "",
    }, //bc

    serviceCharges: {
      cash: 0,
      bankAmt: 0,
      bankName: "",
    }, //bc
    batchDividendDistributed: {
      cash: 0,
      bankAmt: 0,
      bankName: "",
    }, //bc
    otherExpenses: {
      cash: 0,
      bankAmt: 0,
      bankName: "",
    }, //bc
    batchSavingWithdrawals: {
      cash: 0,
      bankAmt: 0,
      bankName: "",
    }, //bc
    batchSecurityDeposit: {
      cash: 0,
      bankAmt: 0,
      bankName: "",
    }, //bc
    donationToOutsider: {
      cash: 0,
      bankAmt: 0,
      bankName: "",
    }, //bc
    sdSHGToUnion: {
      cash: 0,
      bankAmt: 0,
      bankName: "",
    }, //bc
    batchLoanAmt: {
      cash: 0,
      bankAmt: 0,
      bankName: "",
    }, //bc
    batchLoanPaid: {
      cash: 0,
      bankAmt: 0,
      bankName: "",
    }, //bc
    batchInterestPaid: {
      cash: 0,
      bankAmt: 0,
      bankName: "",
    }, //bc
    otherLoanSanctionedToMember: {
      cash: 0,
      bankAmt: 0,
      bankName: "",
    }, //bc
    bankCommission: 0, //no
    otherLoanSanctionedToShg: 0, //no
    loanGivenToOtherSHG: 0, //no
    subsidyDistributed: 0, //no
    subsidyReceived: 0, //NO
    bankInterest: 0, //NO
    otherLoanPaidToShg: 0, //no
    otherLoanPaidToDonor: 0, //no
    batchOtherLoanInterestShg: 0, // no
    batchOtherLoanInterestDonor: 0, //no
    otherLoanBatchTransaction: [], // [], //Array
    bankTransactions: [], //[] //array
    cashInHand: 0, //no
    batchBankDeposit: 0, //no
    batchBankWithdrawals: 0, //no
    institutionId: loggedUserInstitutionData.institutionId,
    meetingFileName: null,
    internalBankExpense: 0,
  });

  let {
    meetingNotes,
    batchSavingAmt, //BC
    batchSubAmt, //BC
    batchOtherContribution, //BC
    batchMembershipFees, //BC

    deactiveMemberSaving, //BC

    batchOtherIncome, //BC
    seedMoney, //BC
    batchSecurityDepositMemToShg, //BC
    sdUnionToSHG, //BC

    loanRepaidByOtherSHG, //BC
    travellingExpenses, //BC
    stationaryExpenses, //bc,
    paidToMahasangha, //bc

    serviceCharges, //bc
    batchDividendDistributed, //bc
    otherExpenses, //bc
    batchSavingWithdrawals, //bc
    batchSecurityDeposit, //bc

    donationToOutsider, //bc
    sdSHGToUnion, //bc

    batchLoanAmt, //bc see
    batchLoanPaid, //bc
    batchInterestPaid, //bc

    otherLoanSanctionedToMember,

    bankInterest,

    batchBankDeposit,
    bankCommission,
    batchBankWithdrawals,
  } = formData;

  // useEffect(() => {
  //   if (selectedBatchData.length > 0) {
  //     setFormData({
  //       ...formData,
  //       batchId: selectedBatchData[0].batchId,
  //       batchName: selectedBatchData[0].batchName,
  //     });
  //     //
  //   }
  // }, []); REQ FLAG

  const allpurpose = [];
  loanreason.map((purpose) =>
    allpurpose.push({
      loanReasonId: purpose._id,
      label: purpose.loanPurpose,
      value: purpose.loanPurpose,
    })
  );

  const allbankpurpose = [];
  loanreason.map((bankpurpose) =>
    allbankpurpose.push({
      bankloanpuroseId: bankpurpose._id,
      label: bankpurpose.loanPurpose,
      value: bankpurpose.loanPurpose,
    })
  );
  const activeOtherLoan = [];
  activeOtherLoanTypes &&
    activeOtherLoanTypes.map((otherLoanType) =>
      activeOtherLoan.push({
        otherLoanTypeId: otherLoanType._id,
        label: otherLoanType.otherLoanType,
        value: otherLoanType.otherLoanType,
      })
    );

  const activeBank = [];
  activeBankData &&
    activeBankData.map((bank) => {
      activeBank.push({
        bankId: bank._id,
        label: bank.batchBankName,
        value: bank.batchBankName,
      });
    });

  const [tabIndex, setTabIndex] = useState(0);
  const NextBackBtn = (tabIndex) => {
    setTabIndex(tabIndex);
  };

  const allMembers = [];
  selBatchMem.map((members) =>
    allMembers.push({
      memberIds: members._id,
      label: members.memberName,
      value: members.memberName,
    })
  );

  const [selectedDate, setSelectedDate] = useState("");
  const [isRedirectStatus, setRedirectStatus] = useState(false);

  const [showNewBankBalance, setShowNewBankBalnce] = useState([]);
  const [thisMeetNewBankBalance, setThisMeetNewBankBalance] = useState(0);
  useEffect(() => {
    setThisMeetNewBankBalance(
      showNewBankBalance &&
        showNewBankBalance.reduce(
          (acu, cur) =>
            (acu +=
              Number(cur.indBatchBankDeposit || 0) +
              Number(cur.indBankInterest || 0) -
              (Number(cur.indBankCommission || 0) +
                Number(cur.indBatchBankWithdrawals || 0))),
          0
        )
    );
  }, [showNewBankBalance]);

  const onDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const meetingBatchInfo = {
    mdDate: selectedDate,
    mdBatchId: batchIdValue.batchId,
  };

  ////////////////////////////////attendance details start /////////////////////////////////////////////////////
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [includeMeetings, setIncludeMeetings] = useState(null);
  const [multiDate, setMultiDate] = useState([]);
  const [formattedDates, setFormattedDates] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]); // { memberId: [dates] }

  const getTomorrowDate = (currentDate) => {
    try {
      const tomorrow = new Date(currentDate);
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow.toISOString().split("T")[0];
    } catch (er) {
      const numberOfDays = 2000; //average 5 years from the date of entry
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() - numberOfDays);
      return tomorrow.toISOString().split("T")[0];
    }
  };

  const onChangeMultiDate = (e) => {
    setMultiDate(e);
  };
  /////////////////////////////////////adding default absent value for members start//////////////////////////////
  // Function to initialize selectedDates based on formattedDates and membersOfBatch
  const initializeSelectedDates = () => {
    console.log("Initializing selectedDates...");

    // Map over each member of the batch
    return (
      allMembers &&
      allMembers.map((member) => {
        // Initialize the member's attendance status as "Absent" for all dates
        const memberAttendance = formattedDates.reduce((acc, date) => {
          acc[date] = "Absent"; // Default value for all dates
          return acc;
        }, {});

        // Check if the member already has an entry in selectedDates
        const existingMember = selectedDates.find(
          (entry) => entry.memberId === member.memberIds
        );

        // If member already exists in selectedDates, update attendance based on that
        if (existingMember) {
          // Loop over the member's attendance and update only if it's not "Attended"
          formattedDates.forEach((date) => {
            // If the member already attended, keep the status as "Attended"
            if (existingMember.dates[date] === "Attended") {
              memberAttendance[date] = "Attended"; // Keep it as "Attended"
            } else {
              // Otherwise, mark as "Absent"
              memberAttendance[date] = "Absent";
            }
          });
        } else {
          // If there's no existing attendance data, proceed with the default "Absent"
          if (member.attendance) {
            formattedDates.forEach((date) => {
              if (member.attendance[date] === "Attended") {
                memberAttendance[date] = "Attended"; // Mark as "Attended" if it matches
              }
            });
          }
        }

        // Return the updated member with the attendance status
        return {
          memberId: member.memberIds, // Member's unique identifier
          dates: memberAttendance, // This will contain the updated attendance status for all dates
        };
      })
    );
  };

  // Use useEffect to update selectedDates once formattedDates and membersOfBatch are ready
  useEffect(() => {
    if (formattedDates.length > 0 && allMembers.length > 0) {
      const initialSelectedDates = initializeSelectedDates();
      setSelectedDates(initialSelectedDates);
    }
  }, [formattedDates]);

  ///////////////////////////////////adding default absent value for members end////////////////////////////

  // Function to convert object to Date
  function convertToDate(dateObject) {
    return new Date(
      dateObject.year,
      dateObject.month - 1,
      dateObject.day,
      dateObject.hour,
      dateObject.minute,
      dateObject.second,
      dateObject.millisecond
    );
  }

  // Function to convert and store the dates
  const [showMultiDateSection,setShowMultiDateSection] = useState(true)
  const showMultidates = () => {
    setShowInfoModal(false);
    setShowMultiDateSection(false)
    const formattedDatesArray = [];

    // Loop through each object in the multiDate array
    multiDate.forEach((dateObject, index) => {
      // Convert the dateObject to a Date object
      const date = convertToDate(dateObject);

      // Extract day, month, and year
      const day = String(date.getDate()).padStart(2, "0"); // Ensure 2 digits for day
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure 2 digits and adjust for 0-indexed month
      const year = date.getFullYear();

      // Format the date as dd-mm-yyyy
      const formattedDate = `${day}-${month}-${year}`;

      // Push the formatted date into the formattedDatesArray
      formattedDatesArray.push(formattedDate);
    });
    // Update the state with the new array of formatted dates
    setFormattedDates(formattedDatesArray);
    localStorage.setItem(
      "batchformatedDates",
      JSON.stringify(formattedDatesArray)
    );

    if (selectedDate !== "") {
      const finalData = {
        mdDate: selectedDate,
        mdBatchId: batchIdValue.batchIdVal,
      };

      checkAvailableDate(finalData);
      getMemberMeetingSummary({
        mdDate: selectedDate,
        mdBatchId: batchIdValue.batchIdVal,
        meetingDates: formattedDatesArray,
      });

      const meetingBatchInfos = finalData;
      getMeetingSummary(meetingBatchInfos);

      // if (lastMeetingDate.length === 0) {
      //   setRedirectStatus(true);
      //   localStorage.setItem("selDate", JSON.stringify(finalData));
      // }
    }
  };

  const handleChange = (event) => {
    const newArray = [];
    setIncludeMeetings(event.target.value);
    if (event.target.value === "no") {
      let day = selectedDate.split("-")[2];
      let month = selectedDate.split("-")[1];
      let year = selectedDate.split("-")[0];
      const formattedDate = `${day}-${month}-${year}`;
      newArray.push(formattedDate);
      setFormattedDates(newArray);
      finalCheck();
      setShowInfoModal(false);
    }
  };

  const getMonthAndDate = (date) => {
    let day = date.split("-")[0];
    let month = date.split("-")[1];
    switch (month) {
      case "01":
        return "JAN" + "-" + day;

      case "02":
        return "FEB" + "-" + day;
      case "03":
        return "MAR" + "-" + day;

      case "04":
        return "APR" + "-" + day;

      case "05":
        return "MAY" + "-" + day;

      case "06":
        return "JUN" + "-" + day;

      case "07":
        return "JULY" + "-" + day;

      case "08":
        return "AUG" + "-" + day;

      case "09":
        return "SEPT" + "-" + day;

      case "10":
        return "OCT" + "-" + day;

      case "11":
        return "NOV" + "-" + day;

      case "12":
        return "DEC" + "-" + day;

      default:
        return "unknows";
    }
  };

  const handleDateChange = (memberId, date, isChecked) => {
    setSelectedDates((prevSelectedDates) => {
      const updatedDates = [...prevSelectedDates];

      const memberIndex = updatedDates.findIndex(
        (member) => member.memberId === memberId
      );

      if (memberIndex === -1) {
        updatedDates.push({
          memberId: memberId,
          dates: formattedDates.reduce((acc, date) => {
            acc[date] = "Absent";
            return acc;
          }, {}),
        });
      } else {
        updatedDates[memberIndex].dates[date] = isChecked
          ? "Attended"
          : "Absent";
      }

      return updatedDates; // Return updated state
    });
  };
  //////////////////////////////attendance details end//////////////////////////////////////////////////////////

  const oncheck = () => {
    if (allPermission.includes("Attendance")) {
      if (selectedDate !== "") {

        if(allPermission && allPermission.includes("MultiDate_Attendance")){
          setIncludeMeetings("yes")
        }else{
          const newArray = [];
          let day = selectedDate.split("-")[2];
          let month = selectedDate.split("-")[1];
          let year = selectedDate.split("-")[0];
          const formattedDate = `${day}-${month}-${year}`;
          newArray.push(formattedDate);
          setFormattedDates(newArray);
          finalCheck();
          setShowInfoModal(false);
        }
        // setShowInfoModal(true);
      }
    } else {
      finalCheck();
    }
  };

  const finalCheck = () => {
    if (selectedDate !== "") {
      const finalData = {
        mdDate: selectedDate,
        mdBatchId: batchIdValue.batchIdVal,
      };

      checkAvailableDate(finalData);
      getMemberMeetingSummary({
        mdDate: selectedDate,
        mdBatchId: batchIdValue.batchIdVal,
      });

      const meetingBatchInfos = finalData;
      getMeetingSummary(meetingBatchInfos);

      // if (lastMeetingDate.length === 0) {
      //   setRedirectStatus(true);
      //   localStorage.setItem("selDate", JSON.stringify(finalData));
      // }
    }
  };

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  var lastMeetingDate = "";
  var lastMeetingDateYMD = "";
  if (lastMeetingData && lastMeetingData.length !== 0) {
    var LMD =
      lastMeetingData &&
      lastMeetingData[0] &&
      lastMeetingData[0].mdDate.split(/\D/g);
    lastMeetingDate = [LMD[2], LMD[1], LMD[0]].join("-");
    lastMeetingDateYMD =
      lastMeetingData && lastMeetingData[0] && lastMeetingData[0].mdDate;
  }

  const allMember = [];
  selBatchMem.map((member) =>
    allMember.push({
      memberId: member._id,
      label: member.memberName,
      value: member.memberName,
    })
  );

  useEffect(() => {}, [memSummaryData]);

  if (isRedirectStatus) {
    return <Redirect to="/add-opening-balance-for-batchmeeting-Details" />;
  }

  // if (isSubmitted) {
  //   return <Redirect to="/landing-page" />;
  // }
  let prevBankBalance =
    Number((memSummaryData && memSummaryData.bankInterest) || 0) +
    Number((memSummaryData && memSummaryData.batchBankDeposit) || 0) -
    Number((memSummaryData && memSummaryData.bankCommission) || 0) -
    Number((memSummaryData && memSummaryData.batchBankWithdrawals) || 0);

  // Number(memSummaryData.bankInterest || 0) +
  // Number(memSummaryData.batchBankDeposit) -
  // (Number(memSummaryData.bankCommission) +
  //   Number(memSummaryData.batchBankWithdrawals));

  const newBankBalanceTotal = (
    thisMeetNewBankBalance +
    prevBankBalance +
    Number(formData.bankInterest) +
    Number(formData.batchBankDeposit) -
    (Number(formData.bankCommission) + Number(formData.batchBankWithdrawals))
  ).toFixed(2);
  const [newTrailBankBalance, setNewTrailBankBalance] = useState(
    newBankBalanceTotal
  );
  useEffect(()=>{
setNewTrailBankBalance(Number(newBankBalanceTotal || 0))
  },[newBankBalanceTotal])

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align">
        <div className="row col-lg-12 col-md-11 col-sm-12 col-12">
          <h2 className="">
            Add SHG Meeting Details for {batchIdValue.batchNameVal}
          </h2>
          <div className="col-lg-6 col-md-11 col-sm-12 col-12">
            <h5 className="heading_color float-right">
              Last Meeting Held on : {lastMeetingDate}
            </h5>
          </div>
          <hr />
        </div>

        <div className="row col-lg-12 col-md-12 col-sm-12 col-12 no_padding">
          <div className="col-lg-2 col-md-4 col-sm-11 col-11 ">
            <h4 className="heading_color">Meeting Date* </h4>
          </div>

          <div className="row col-lg-7 col-md-8 col-sm-12 col-12 ">
            <div className="col-lg-3 col-md-6 col-sm-6 col-8 pt-3">
              <input
                type="date"
                placeholder="dd/mm/yyyy"
                className="form-control cpp-input datevalidation"
                name="selectedDate"
                value={selectedDate}
                onChange={(e) => onDateChange(e)}
                style={{
                  width: "100%",
                }}
                required
              />
              &nbsp;
              {meetingResponse && (
                <p style={{ color: "red" }}>{meetingResponse}</p>
              )}
              {selectedDate && selectedDate < lastMeetingDateYMD && (
                <p style={{ color: "red" }}>
                  Date selected cannot be less than last meeting date
                </p>
              )}
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-8">
              {loading ? (
                <button className="btn sub_form btn_continue1" disabled>
                  Loading...
                </button>
              ) : (
                <>
                  <>
                    {lastMeetingData &&
                    lastMeetingData.length !== 0 &&
                    lastMeetingData[0].mdStatus != "Verified" ? (
                      <span className="text-danger">
                        Please Verify the last meeting
                      </span>
                    ) : (
                      <>
                        {" "}
                        <button
                          className="btn sub_form btn_continue "
                          onClick={() => oncheck()}
                        >
                          Check
                        </button>
                      </>
                    )}
                  </>
                </>
              )}
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-11 col-11 ">
            <Link to="/select-add-meeting">
              <img
                className="img_icon_size log float-right"
                src={require("../../static/images/back.png")}
                alt="Back"
                title="Back"
              />
            </Link>
          </div>

          {/* /////////////////////////////additional info start//////////////////////////////////////////////////// */}
          {(allPermission && allPermission.includes("Attendance")) && showMultiDateSection ? (
            <>
              {
              // showInfoModal ? (
              //   <div className="col-lg-12">
              //     <label>Would you like to include additional meetings ?</label>
              //     <br />
              //     <input
              //       type="radio"
              //       id="yes"
              //       name="meetings"
              //       value="yes"
              //       checked={includeMeetings === "yes"}
              //       onChange={(e) => handleChange(e)}
              //     />
              //     <label htmlFor="yes">Yes</label>
              //     &nbsp;
              //     <input
              //       type="radio"
              //       id="no"
              //       name="meetings"
              //       value="no"
              //       checked={includeMeetings === "no"}
              //       onChange={(e) => handleChange(e)}
              //     />
              //     <label htmlFor="no">No</label>
              //   </div>
              // ) : (
              //   <></>
              // )
              }

              <div className="col-lg-12 col-md-12 col-sm-12">
                {includeMeetings === "yes" ? (
                  <div className="row">
                    <div className="col-lg-3">
                      <DatePicker
                        style={{
                          width: "fit-content",
                          height: "fit-content",
                          border: "1px solid black",
                          marginTop: "25px",
                        }}
                        placeholder="Select Dates"
                        type=""
                        value={multiDate}
                        onChange={(e) => onChangeMultiDate(e)}
                      />
                    </div>
                    <div className="col-lg-4">
                      <button
                        className="btn btn_continue"
                        disabled={multiDate.length > 0 ? false : true}
                        onClick={() => showMultidates()}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : (
            <></>
          )}

          {/* ////////////////////////////additional info end//////////////////////////////////////////////////////// */}
        </div>
        {meetingResponse &&
        meetingResponse === "Meeting Available" &&
        selectedDate > lastMeetingDateYMD ? (
          <section className="sub_reg">
            <Tabs selectedIndex={tabIndex}>
              <div className="row col-lg-11 col-md-11 col-sm-12 col-12">
                <TabList>
                  <Tab tabfor="0">Summary </Tab>
                  {allPermission && allPermission.includes("Attendance") ? (
                    <Tab tabfor="1">Attendance </Tab>
                  ) : (
                    <Tab tabfor="1" style={{ display: "none" }}>
                      Attendance{" "}
                    </Tab>
                  )}

                  {allPermission && allPermission.includes("Screenshot") && (batchTransactionData && batchTransactionData.meetingCategory !== "OP") ? (
                    <Tab tabfor="2">MOM</Tab>
                  ) : (
                    <Tab tabfor="2" style={{ display: "none" }}>
                      MOM
                    </Tab>
                  )}
                  <Tab tabfor="3">Income</Tab>
                  <Tab tabfor="4">Expense</Tab>
                  <Tab tabfor="5">OL Sanctioned and Repayment</Tab>
                  <Tab tabfor="6">Loan Sanctioned</Tab>
                  <Tab tabfor="7">OL Mem Sanctioned</Tab>
                  <Tab tabfor="8">Bank Details</Tab>
                </TabList>
              </div>

              <TabPanel tabId="0">
                <form
                  onSubmit={(e) =>
                    NextBackBtn(
                      allPermission && allPermission.includes("Attendance")
                        ? 1
                        :
                         allPermission.includes("Screenshot") &&
                          batchTransactionData &&
                          batchTransactionData.meetingCategory !== "OP"
                        ? 2
                        : 3
                    )
                  }
                >
                  <div className="row col-lg-11 col-md-11 col-sm-11 col-11">
                    {memSummaryData ? (
                      <SummaryBatchTab
                        memSummaryData={memSummaryData || null}
                        allPermission={allPermission}
                      />
                    ) : (
                      <></>
                    )}

                    <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
                      <input
                        type="submit"
                        name="submit"
                        value="Next"
                        className="btn sub_form btn_continue Save float-right"
                      />
                    </div>
                  </div>
                </form>
              </TabPanel>

              {tabIndex !== 0 && (
                <div className="row col-lg-11 col-md-12 col-sm-12 col-12">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12" style={{ backgroundColor: "#144c5a",marginBottom:"1px" }} >
                    <div className="row card-new  py-1"style={{ backgroundColor: "#144c5a" }}>
                    <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                        <label style={{ color: "white" }}>
                          <b>
                            Previous Cash in Hand&nbsp;:&nbsp;
                            {memSummaryData && memSummaryData.cashInHand}
                          </b>
                        </label>
                      </div>
                      <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                        <label style={{ color: "white" }}>
                          <b>
                            Previous Bank Balance&nbsp;:&nbsp;{prevBankBalance.toFixed(2)}
                          </b>
                        </label>
                      </div>
                    
                      <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                        <label style={{ color: "white" }}>
                          <b>
                            New Bank Balance&nbsp;:&nbsp;{
                              Number(newTrailBankBalance || 0).toFixed(2)
                              // (
                              //   thisMeetNewBankBalance +
                              //   prevBankBalance +
                              //   Number(formData.bankInterest) +
                              //   Number(formData.batchBankDeposit) -
                              //   (Number(formData.bankCommission) +
                              //     Number(formData.batchBankWithdrawals))
                              // ).toFixed(2)
                            }
                          </b>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <TabPanel tabId="1">
                <form
                  onSubmit={(e) =>
                    NextBackBtn(
                      allPermission.includes("Screenshot") &&
                        batchTransactionData &&
                        batchTransactionData.meetingCategory !== "OP"
                        ? 2
                        : 3
                    )
                  }
                >
                  <div className="row col-lg-11 col-md-12 col-sm-12 col-12">
                    <table
                      className="table table-bordered table-striped table-hover"
                      id="datatable2"
                    >
                      <thead>
                        <tr>
                          <th>SL No</th>
                          <th>SHG Members</th>
                          {formattedDates &&
                            formattedDates.map((ele) => {
                              return <th>{getMonthAndDate(ele)}</th>;
                            })}
                        </tr>
                      </thead>

                      <tbody>
                        {allMembers &&
                          allMembers.map((memberBatch, idx) => {
                            return (
                              <tr key={idx} className="text-center">
                                <td>{idx + 1}</td>
                                <td>{memberBatch.label}</td>

                                {/* Iterate over formattedDates and add checkboxes */}
                                {formattedDates.map((ele, dateIdx) => (
                                  <td key={dateIdx} className="text-center">
                                    <input
                                      type="checkbox"
                                      style={{
                                        display: "block",
                                        width: "100%",
                                        height: "100%",
                                        cursor: "pointer",
                                      }}
                                      checked={
                                        selectedDates[idx] &&
                                        selectedDates[idx].dates &&
                                        selectedDates[idx].dates[ele] ===
                                          "Attended"
                                      } // Check if the date is attended
                                      onChange={
                                        (e) =>
                                          handleDateChange(
                                            memberBatch.memberIds,
                                            ele,
                                            e.target.checked
                                          ) // Update selected dates for the member
                                      }
                                    />
                                  </td>
                                ))}
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
                      <input
                        type="submit"
                        name="submit"
                        value="Next"
                        className="btn sub_form btn_continue Save float-right"
                      />
                      <button
                            className="btn sub_form btn_continue Save float-right"
                            onClick={() =>
                              NextBackBtn(
                                 0
                              )
                            }
                          >
                            Previous
                          </button>
                    </div>
                    
                  </div>
                </form>
              </TabPanel>
              <TabPanel tabId="2">
                <form onSubmit={(e) => NextBackBtn(3)}>
                  <div className="row col-lg-11 col-md-12 col-sm-12 col-12">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="row card-new  py-3 ">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <h5>MOM</h5>
                        </div>

                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            Meeting Notes* :
                          </label>
                          <textarea
                            name="meetingNotes"
                            value={meetingNotes}
                            id="meetingNotes"
                            className="textarea form-control"
                            rows="4"
                            placeholder="Meeting Notes"
                            onChange={(e) => onInputChange(e)}
                            style={{ width: "100%" }}
                            required
                          ></textarea>
                        </div>

                        {/* <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <label className="label-control">Upload PDF :</label>

                          <div className="row col-lg-12 col-md-12 col-sm-12 col-12">
                            <input
                              type="file"
                              accept="application/pdf"
                              multiple={false}
                              onChange={(event) => handleFileChange(event)}
                            />
                          </div>
                        </div> */}
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <input
                            type="submit"
                            name="submit"
                            value="Next"
                            className="btn sub_form btn_continue Save float-right"
                          />
                          <button
                            className="btn sub_form btn_continue Save float-right"
                            onClick={() =>
                              NextBackBtn(
                                allPermission &&
                                  allPermission.includes("Attendance")
                                  ? 1
                                  : 0
                              )
                            }
                          >
                            Previous
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </TabPanel>
              <TabPanel tabId="3">
                <div className="row col-lg-11 col-md-12 col-sm-12 col-12">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <BatchIncomeTab
                      NextBackBtn={NextBackBtn}
                      formData={formData}
                      setFormData={setFormData}
                      activeBank={activeBank}
                      allPermission={allPermission}
                      batchTransactionData={batchTransactionData}
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel tabId="4">
                <div className="row col-lg-11 col-md-12 col-sm-12 col-12">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
                    <BatchExpenseTab
                      NextBackBtn={NextBackBtn}
                      formData={formData}
                      setFormData={setFormData}
                      activeBank={activeBank}
                      allPermission={allPermission}
                    />
                  </div>
                </div>
              </TabPanel>

              <TabPanel tabId="5">
                <div className="row col-lg-11 col-md-12 col-sm-12 col-12">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
                    <BatchOLRepayment
                      activeBank={activeBank}
                      NextBackBtn={NextBackBtn}
                      formData={formData}
                      setFormData={setFormData}
                      allPermission={allPermission}
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel tabId="6">
                <div className="row col-md-12 col-lg-11 col-sm-12 col-12 ">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
                    <BatchLoanSanction
                      allMembers={allMembers}
                      activeBank={activeBank}
                      NextBackBtn={NextBackBtn}
                      formData={{
                        ...formData,
                        institutionId: user.institutionId,
                        batchMeetingHeldOnDate: selectedDate,
                        batchMeetingMonth: selectedDate.split("-")[1],
                        batchMeetingYear: selectedDate.split("-")[0],
                      }}
                      setFormData={setFormData}
                      getLoanSantionedData={getLoanSantionedData}
                      meetingBatchInfo={{
                        mdDate: selectedDate,
                        mdBatchId: batchIdValue.batchIdVal,
                      }}
                      allpurpose={allpurpose}
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel tabId="7">
                <div className="row col-lg-11 col-md-12 col-sm-12 col-12">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <BatchOLSanction
                      NextBackBtn={NextBackBtn}
                      formData={{
                        ...formData,
                        institutionId: user.institutionId,
                        batchMeetingHeldOnDate: selectedDate,
                        batchMeetingMonth: selectedDate.split("-")[1],
                        batchMeetingYear: selectedDate.split("-")[0],
                      }}
                      getLoanSantionedData={getLoanSantionedData}
                      meetingBatchInfo={{
                        mdDate: selectedDate,
                        mdBatchId: batchIdValue.batchIdVal,
                      }}
                      setFormData={setFormData}
                      allMembers={allMembers}
                      activeBank={activeBank}
                      allpurpose={allpurpose}
                      allPermission={allPermission}
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel tabId="8">
                <div className="row col-lg-11 col-md-12 col-sm-12 col-12">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <BatchBankDetTab
                      activeBank={activeBank}
                      batchData={null}
                      setShowNewBankBalnce={setShowNewBankBalnce}
                      NextBackBtn={NextBackBtn}
                      formData={formData}
                      setFormData={setFormData}
                      selectedBatchData={selectedBatchData}
                      selectedDate={selectedDate}
                      allPermission={allPermission}
                      newBankBalanceTotal={newBankBalanceTotal}
                      setNewTrailBankBalances={setNewTrailBankBalance}
                      prevBankBalance={prevBankBalance}
                      PrevCashInHand={
                        memSummaryData && memSummaryData.cashInHand
                      }
                      selectedDates={selectedDates}
                      formattedDate={formattedDates}
                    />
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </section>
        ) : (
          <Fragment />
        )}
        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
          <label className="label-control colorRed">
            * Indicates mandatory fields, Please fill mandatory fields before
            Submit
          </label>
        </div>
      </div>
    </Fragment>
  );
};

AddBatchMeetingDetails.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  BatchTransaction,
  meetingDates,
  getParticularLoanReason,
  getMembersOfSelectedBatch,
  checkAvailableDate,
  removeMessage,
  getMeetingSummary,
  AddLoanSanctionedDetails,
  getActiveOtherLoanType,
  getActiveBankData,
  sendMettingFile,
  getMemberMeetingSummary,
  getBatchMeetingData,
  getLoanSantionedData,
})(AddBatchMeetingDetails);
