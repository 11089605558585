import React from "react";

const AllMemberTab = ({
  membersMeetingData,
  batchTransactionData,
  allRights,
  user,
  onOpenEditModal,
}) => {
  let savingAmtTot = 0;
  let loanPaidTot = 0;
  let interestPaidTot = 0;
  let otherLoanPaidTot = 0;
  let otherInterestPaidTot = 0;
  let subscriptionAmtTot = 0;
  let otherContributionTot = 0;
  let loanTakenAmtTotPri = 0;
  let loanTakenAmtTotInt = 0;
  let loanTakenAmtTot = 0;
  let otherLoanTakenAmtTot = 0;
  let otherLoanTakenPrincipal = 0;
  let otherLoanTakenIntrest = 0;
  let indSubsidyDistributedTot = 0;
  let batchOtherIncomeTot = 0;
  let memSecurityDepositToShg = 0;
  let membershipFeesTot = 0;
  let memSeedMoneyTot = 0;
  let savingWithdrawalsTot = 0;
  let dividendDistributedTot = 0;
  let securityDepositTot = 0;
  let totalDepositeAmount = 0;
  let totalSavingAmount = 0;
  let totalSubscription = 0;
  let totalOtherIncome = 0;
  let SDMTS = 0;
  let otherContributionAmount = 0;
  let seedMoney = 0;
  let membershipFees = 0;

  const sumOf = (value) => {
  
    try {
      return Number(value.cash || 0) + Number(value.bankAmt || 0);
    } catch (er) {
      return 11;
    }
  };
////////////////////////////////////////calculation of new Bank Balance start///////////////////////////////////////
const calculateDeposits = (data) => {
  // Define income and expense fields as arrays
  const incomeFields = [
    'membershipFees',          // Loan taken amount
    'subscriptionAmt',         // Subscription amount
    'otherIncome',   
    'savingAmt',
    'otherContribution',
    'memSecurityDepositToShg',
    'memSeedMoney',
    { field: 'internalRepayment', isArray: true },  // Array field, needs special handling
    { field: 'otherLoan', isArray: true },          // Array field, needs special handling
  ];

  const expenseFields = [
    'savingWithdrawals',       // Saving withdrawals
    'dividendDistributed',
    'securityDeposit',
    { field: 'internalLoan', isArray: true },  // Array field, needs special handling
    { field: 'otherLoan', isArray: true },     // Array field, needs special handling
  ];

  // Helper function to safely get a numeric value (cash or bankAmt)
  const getAmount = (item, field) => {
    if (item && item[field]) {
      return Number(item[field].bankAmt || 0);
    }else{

      return 0;
    }
  };

  // Function to process internalLoan array field
  const processInternalLoan = (acc, item, field, type) => {
    if (Array.isArray(item[field])) {
      item[field].forEach((arrayItem) => {
        // Handle expenses from internalLoan (loanPayablePrincipal, loanPayableInterest)
        if (type === 'expense') {
          acc.expense += getAmount(arrayItem, 'loanPayablePrincipal');
          acc.expense += getAmount(arrayItem, 'loanPayableInterest');
        } else if (type === 'income') {
          // Handle income from internalLoan (loanTakenAmt or similar fields)
          // acc.income += getAmount(arrayItem, 'TotalPayableAmt');  // Example: TotalPayableAmt as income
        }
      });
    }
  };

  // Function to process internalRepayment array field
  const processInternalRepayment = (acc, item, field, type) => {
    if (Array.isArray(item[field])) {
      item[field].forEach((arrayItem) => {
        // Handle expenses from internalRepayment (internalPrincipalPaid, internalIntrestPaid)
        if (type === 'expense') {
          // Example expense handling can go here
        } else if (type === 'income') {
          acc.income += getAmount(arrayItem, 'internalPrincipalPaid');
          acc.income += getAmount(arrayItem, 'internalIntrestPaid');
        }
      });
    }
  };

  // Function to process otherLoan array field
  const processOtherLoan = (acc, item, field, type) => {
    if (Array.isArray(item[field])) {
      item[field].forEach((arrayItem) => {
        // Handle expenses from otherLoan (otherloanPayableInterst, otherloanPayablePrincipal)
        if (type === 'expense') {
             acc.expense +=getAmount(arrayItem, 'indSubsidyDistributed');
        } else if (type === 'income') {
          // Handle income from otherLoan (otherLoanPaid, otherInterestPaid)
          acc.income += getAmount(arrayItem, 'otherLoanPaid');
          acc.income += getAmount(arrayItem, 'otherInterestPaid');
        }
      });
    }
  };

  // Step 1: Group data by bankId and calculate the total income and expense for each bankId
  const bankSummary = data.reduce(function(acc, item) {
    const bankId = item.memBankName && item.memBankName.bankId ? item.memBankName.bankId : "";  // Traditional check for bankId
    const bankName = item.memBankName && item.memBankName.value ? item.memBankName.value : "";

    if (!bankId) return acc; // Skip if bankId doesn't exist

    // Initialize accumulator for this bankId if not already initialized
    if (!acc[bankId]) {
      acc[bankId] = { income: 0, expense: 0, bankName: bankName };
    }

    // Step 2: Add income amounts based on incomeFields array
    incomeFields.forEach(function(field) {
      if (field.isArray) {
        // Handle the array field (special case)
        if (field.field === 'internalLoan') {
          processInternalLoan(acc[bankId], item, field.field, 'income');
        } else if (field.field === 'otherLoan') {
          processOtherLoan(acc[bankId], item, field.field, 'income');
        } else if (field.field === 'internalRepayment') {
          processInternalRepayment(acc[bankId], item, field.field, 'income');
        }
      } else {
        // Normal field processing
        const amount = getAmount(item, field);
        acc[bankId].income += amount;
      }
    });

    // Step 3: Add expense amounts based on expenseFields array
    expenseFields.forEach(function(field) {
      if (field.isArray) {
        // Handle the array field (special case)
        if (field.field === 'internalLoan') {
          processInternalLoan(acc[bankId], item, field.field, 'expense');
        } else if (field.field === 'otherLoan') {
          processOtherLoan(acc[bankId], item, field.field, 'expense');
        }
      } else {
        // Normal field processing
        const amount = getAmount(item, field);
        acc[bankId].expense += amount;
      }
    });

    return acc;
  }, {});

  // Step 4: Create a new array with `deposit` field based on the difference
  const result = Object.keys(bankSummary).map(function(bankId) {
    const { income, expense, bankName } = bankSummary[bankId];
    return {
      bankId: bankId,
      bankName,
      income,
      expense,
      deposit: income - expense,  // Deposit is income minus expense
    };
  });

  return result;
};

// Example usage:
const result = calculateDeposits(membersMeetingData);
//////////////////////////////////////calculation of new bank Balance end/////////////////////////////////////////


  return (
    <div>
      <div className="body-inner no-padding table-responsive table-container">
        <table
          className="table table-bordered table-striped table-hover"
          id="datatable2"
        >
          <thead>
            <tr>
              <th>SL No</th>
              <th className="" style={{ zIndex: "999" }}>
                Members Name
              </th>
              <th>Saving</th>
              <th>IL Sanctioned Pri Amount</th>
              <th>IL Sanctioned Int Amount</th>
              <th>IL Principal Paid</th>
              <th>IL Interest Paid</th>
              <th>OL Sanctioned Principal</th>
              <th>OL Sanctioned Interest</th>
              <th>OL Principal Paid</th>
              <th>OL Interest Paid</th>
              <th>Subscription Amt</th>
              <th>Other Contribution</th>
              <th>Other Income</th>
              <th>SD Member</th>
              <th>Membership Fees</th>
              <th>Seed Money</th>
              <th>Saving Withdrawal</th>
              <th>Dividend Distributed</th>
              <th>Security Deposit</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {membersMeetingData &&
              membersMeetingData.map((memTransactionData, idx) => {
                savingAmtTot += sumOf(memTransactionData.savingAmt);

                loanTakenAmtTotPri += memTransactionData.internalLoan.reduce(
                  (acu, ele) =>
                    (acu +=
                      Number(ele.loanPayablePrincipal.cash) +
                      Number(ele.loanPayablePrincipal.bankAmt)),
                  0
                );

                loanTakenAmtTotInt += memTransactionData.internalLoan.reduce(
                  (acu, ele) =>
                    (acu +=
                      Number(ele.loanPayableInterest.cash) +
                      Number(ele.loanPayableInterest.bankAmt)),
                  0
                );
                loanTakenAmtTot += sumOf(memTransactionData.loanTakenAmt);
                //internal paid

                loanPaidTot += memTransactionData.internalRepayment.reduce(
                  (acu, cur) => (acu += sumOf(cur.internalPrincipalPaid)),
                  0
                );

                interestPaidTot += memTransactionData.internalRepayment.reduce(
                  (acu, cur) => (acu += sumOf(cur.internalIntrestPaid)),
                  0
                );

                otherLoanPaidTot += memTransactionData.otherLoan.reduce(
                  (acu, cur) =>
                    (acu += Number(
                      Number(cur.otherLoanPaid.cash) +
                        Number(cur.otherLoanPaid.bankAmt)
                    )),
                  0
                );
                otherInterestPaidTot += memTransactionData.otherLoan.reduce(
                  (acu, cur) =>
                    (acu += Number(
                      Number(cur.otherInterestPaid.cash) +
                        Number(cur.otherInterestPaid.bankAmt)
                    )),
                  0
                );
                subscriptionAmtTot += sumOf(memTransactionData.subscriptionAmt);
                otherContributionTot += sumOf(
                  memTransactionData.otherContribution
                );

                // otherLoanTakenAmtTot += memTransactionData.otherLoanTakenAmt;
                otherLoanTakenPrincipal += memTransactionData.otherLoan.reduce(
                  (acu, cur) =>
                    (acu += Number(
                      Number(cur.otherloanPayablePrincipal.cash) +
                        Number(cur.otherloanPayablePrincipal.bankAmt)
                    )),
                  0
                );
                otherLoanTakenIntrest += memTransactionData.otherLoan.reduce(
                  (acu, cur) =>
                    (acu += Number(
                      Number(cur.otherloanPayableInterst.cash) +
                        Number(cur.otherloanPayableInterst.bankAmt)
                    )),
                  0
                );

                // otherLoanTakenPrincipal += memTransactionData.otherLoan.reduce(
                //   (acu, cur) => (acu += cur.otherloanPayablePrincipal),
                //   0
                // );
                // otherLoanTakenIntrest += memTransactionData.otherLoan.reduce(
                //   (acu, cur) => (acu += cur.otherloanPayableInterst),
                //   0
                // );
                // indSubsidyDistributedTot +=
                //   memTransactionData.subsidyDistributed;
                batchOtherIncomeTot += sumOf(memTransactionData.otherIncome);
                memSecurityDepositToShg +=sumOf(memTransactionData.memSecurityDepositToShg)
                membershipFeesTot += sumOf(memTransactionData.membershipFees);
                memSeedMoneyTot += sumOf(memTransactionData.memSeedMoney);
                savingWithdrawalsTot += sumOf(
                  memTransactionData.savingWithdrawals
                );
                dividendDistributedTot += sumOf(
                  memTransactionData.dividendDistributed
                );
                securityDepositTot += sumOf(memTransactionData.securityDeposit);

                return (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td className="sticky-column">
                      {memTransactionData.memberName}
                    </td>
                    <td>{sumOf(memTransactionData.savingAmt)}</td>
                    <td>
                      {memTransactionData.internalLoan.reduce(
                        (acu, ele) =>
                          (acu +=
                            Number(ele.loanPayablePrincipal.cash) +
                            Number(ele.loanPayablePrincipal.bankAmt)),
                        0
                      )}
                    </td>
                    <td>
                      {memTransactionData.internalLoan.reduce(
                        (acu, ele) =>
                          (acu +=
                            Number(ele.loanPayableInterest.cash) +
                            Number(ele.loanPayableInterest.bankAmt)),
                        0
                      )}
                    </td>
                    {/* <td>{sumOf(memTransactionData.loanTakenAmt)}</td> */}
                    {/* <td>{sumOf(memTransactionData.loanPaid)}</td> */}
                    <td>
                      {memTransactionData.internalRepayment.reduce(
                        (acu, cur) => (acu += sumOf(cur.internalPrincipalPaid)),
                        0
                      )}
                    </td>
                    {/* <td>{sumOf(memTransactionData.interestPaid)}</td> */}{" "}
                    <td>
                      {memTransactionData.internalRepayment.reduce(
                        (acu, cur) => (acu += sumOf(cur.internalIntrestPaid)),
                        0
                      )}
                    </td>
                    <td>
                      {memTransactionData.otherLoan.reduce(
                        (acu, cur) =>
                          (acu += Number(
                            Number(cur.otherloanPayablePrincipal.cash) +
                              Number(cur.otherloanPayablePrincipal.bankAmt)
                          )),
                        0
                      )}
                    </td>
                    <td>
                      {memTransactionData.otherLoan.reduce(
                        (acu, cur) =>
                          (acu += Number(
                            Number(cur.otherloanPayableInterst.cash) +
                              Number(cur.otherloanPayableInterst.bankAmt)
                          )),
                        0
                      )}
                    </td>
                    <td>
                      {memTransactionData.otherLoan.reduce(
                        (acu, cur) =>
                          (acu += Number(
                            Number(cur.otherLoanPaid.cash) +
                              Number(cur.otherLoanPaid.bankAmt)
                          )),
                        0
                      )}
                      {/* {memTransactionData.otherLoanPaid || 0} */}
                    </td>
                    <td>
                      {memTransactionData.otherLoan.reduce(
                        (acu, cur) =>
                          (acu += Number(
                            Number(cur.otherInterestPaid.cash) +
                              Number(cur.otherInterestPaid.bankAmt)
                          )),
                        0
                      )}
                      {/* {memTransactionData.otherInterestPaid || 0} */}
                    </td>
                    <td>{sumOf(memTransactionData.subscriptionAmt)}</td>
                    <td>{sumOf(memTransactionData.otherContribution)}</td>
                    <td>{sumOf(memTransactionData.otherIncome)}</td>
                    <td>{sumOf(memTransactionData.memSecurityDepositToShg)}</td>
                    <td>{sumOf(memTransactionData.membershipFees)}</td>
                    <td>{sumOf(memTransactionData.memSeedMoney)}</td>
                    <td>{sumOf(memTransactionData.savingWithdrawals)}</td>
                    <td>{sumOf(memTransactionData.dividendDistributed)}</td>
                    <td>{sumOf(memTransactionData.securityDeposit)}</td>
                    <td>
                      {batchTransactionData &&
                      batchTransactionData.verifiedById ? (
                        <>
                          {allRights &&
                          allRights.includes(
                            user.userGroupName + "EditReport"
                          ) ? (
                            <>
                              <img
                                className="img_icon_size log"
                                onClick={() =>
                                  onOpenEditModal(memTransactionData, idx)
                                }
                                src={require("../../../static/images/edit_icon.png")}
                                alt="Edit"
                                title="Edit"
                              />
                            </>
                          ) : (
                            <>Not editable</>
                          )}
                        </>
                      ) : (
                        <>
                          <img
                            className="img_icon_size log"
                            onClick={() =>
                              onOpenEditModal(memTransactionData, idx)
                            }
                            src={require("../../../static/images/edit_icon.png")}
                            alt="Edit"
                            title="Edit"
                          />
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
            <tr>
              <td></td>
              <td className="sticky-column">
                <b>TOTAL</b>
              </td>
              <td>{savingAmtTot}</td>
              <td>{loanTakenAmtTotPri}</td>
              <td>{loanTakenAmtTotInt}</td>
              <td>{loanPaidTot || 0}</td>
              <td>{interestPaidTot || 0}</td>
              <td>{otherLoanTakenPrincipal}</td>
              <td>{otherLoanTakenIntrest}</td>
              <td>{otherLoanPaidTot || 0}</td>
              <td>{otherInterestPaidTot || 0}</td>
              <td>{subscriptionAmtTot || 0}</td>
              <td>{otherContributionTot || 0}</td>
              <td>{batchOtherIncomeTot || 0}</td>
              <td>{memSecurityDepositToShg || 0}</td>
              <td>{membershipFeesTot || 0}</td>
              <td>{memSeedMoneyTot || 0}</td>
              <td>{savingWithdrawalsTot || 0}</td>
              <td>{dividendDistributedTot || 0}</td>
              <td>{securityDepositTot || 0}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AllMemberTab;
