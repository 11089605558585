import React, { useState, Fragment, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  AddBatch,
  checkBatchCode,
  unsetCheckCode,
  getActiveShgCategory,
} from "../../actions/shg";
import { getAllIncharge, getAllCoordinators } from "../../actions/user";
import Spinner from "../layout/Spinner";
import { Modal } from "react-bootstrap";
import {
  getVarados,
  getStates,
  getDistrict,
  getParish,
  getBlockDetails,
  getAllVillages,
  getAllTankName,
} from "../../actions/area";

const AddBatchDetails = ({
  auth: { isAuthenticated, user, users, loading },
  area: {
    varadosData,
    statesData,
    activeDistrict,
    activeParish,
    getBlock,
    villageData,
  },
  user: { allincharge, allcoordinator },
  shg: { shgcodeCheck, activeShgCategories },
  getActiveShgCategory,
  getVarados,
  getAllVillages,
  getAllTankName,
  AddBatch,
  getStates,
  getAllIncharge,
  getAllCoordinators,
  getDistrict,
  getParish,
  checkBatchCode,
  unsetCheckCode,
  getBlockDetails,
}) => {
  useEffect(() => {
    getVarados();
  }, [getVarados]);
  useEffect(() => {
    getStates();
  }, [getStates]);
  useEffect(() => {
    getBlockDetails();
  }, [getBlockDetails]);
  useEffect(() => {
    getAllIncharge();
  }, []);
  useEffect(() => {
    getActiveShgCategory();
  }, [getActiveShgCategory]);
  useEffect(() => {
    getAllCoordinators();
  }, [getAllCoordinators]);
  useEffect(() => {
    unsetCheckCode();
  }, [unsetCheckCode]);
  useEffect(() => {
    getAllVillages();
  }, []);
  useEffect(() => {
    getAllTankName();
  }, []);

  let village = JSON.parse(localStorage.getItem("village"));
  let tank = JSON.parse(localStorage.getItem("tankName"));

  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );

  const allPermission = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.permission &&
    loggedUserInstitutionData.permission.map((permission) =>
      allPermission.push(permission.permissionName)
    );

  const TranscationMethods = [
    { value: "Memberwise", label: "Memberwise" },
    { value: "Batchwise", label: "Batchwise" },
  ];
  const MeetingTypeMethods = [
    { value: "Monthly", label: "Monthly" },
    { value: "Weekly", label: "Weekly" },
    { value: "Fortnightly", label: "Fortnightly" },
    { value: "Daily", label: "Daily" },
  ];

  //formData
  const [formData, setFormData] = useState({
    batchName: "",
    batchFormationDate: "",
    batchPhone: "",
    batchPhone1: "",
    batchInchargeId: "",
    shgCategoryId: "",
    shgCategoryName: "",
    userFullName: "",
    batchInchargeName: "",
    batchContactPerson: "",
    batchContactPerson1: "",
    batchAddressLine1: "",
    batchAddressLine2: "",
    varadoID: "",
    varadoName: "",
    varadoCode: "",
    pId: "",
    parishName: "",
    parishCode: "",
    batchCounter: "",
    ward: "",
    city: "",
    bCode: "",
    taluku: "",
    districtId: "",
    districtName: "",
    stateId: "",
    stateName: "",
    pincode: "",
    batchCode: "",
    batchTransactionType: "",
    meetingType: "",
    batchSavingAmt: "",
    batchSubscriptionAmt: "",
    batchPanCardNo: "",
    batchRegistrationNo: "",
    // batchBankName: "",
    // batchBankAccountNumber: "",
    // batchBankBranch: "",
    // batchBankIFSC: "",
    batchMahasangaName: "",
    batchmeetingDateAddress: "",
    isSubmitted: false,
  });

  const {
    batchName,
    batchFormationDate,
    batchPhone,
    batchPhone1,
    batchContactPerson,
    batchContactPerson1,
    batchAddressLine1,
    batchAddressLine2,
    ward,
    city,
    taluku,
    pincode,
    batchCode,
    batchTransactionType,
    meetingType,
    batchSavingAmt,
    batchSubscriptionAmt,
    batchPanCardNo,
    batchRegistrationNo,
    // batchBankName,
    // batchBankAccountNumber,
    // batchBankBranch,
    // batchBankIFSC,
    batchMahasangaName,
    batchmeetingDateAddress,
    isSubmitted,
  } = formData;

  // code for next previous tabing starts
  const [tabIndex, setTabIndex] = useState(0);

  const NextBackBtn = (e, tabIndex) => {
    e.preventDefault();
    if (checkErrors()) {
      setTabIndex(tabIndex);
    }
  };

  const [showHide, setShowHide] = useState({
    showBatchSection:
      loggedUserInstitutionData &&
      loggedUserInstitutionData.institutionType === "Zoned"
        ? true
        : false,
  });

  const { showBatchSection } = showHide;

  const [error, setError] = useState({
    InchargeIdChecker: false,
    InchargeIdErrorStyle: {},
    shgCategoryIdChecker: false,
    shgCategoryIdErrorStyle: {},
    CoordinatorIdChecker: false,
    CoordinatorIdErrorStyle: {},
    MeetingIdChecker: false,
    MeetingTypeErrorStyle: {},
    TranscationIdChecker: false,
    TranscationIdErrorStyle: {},
    StateIdChecker: false,
    StateErrorStyle: {},
    DistrictIdChecker: false,
    DistrictErrorStyle: {},
    VaradoIdChecker: false,
    VaradoErrorStyle: {},
    ParishIdChecker: false,
    ParishErrorStyle: {},
    CheckIdChecker: false,
    CheckErrorStyle: {},
    BlockIdChecker: false,
    BlockErrorStyle: {},
    bankChecker: false,
    bankErrorStyle: {},
  });

  const {
    InchargeIdChecker,
    InchargeIdErrorStyle,
    shgCategoryIdChecker,
    shgCategoryIdErrorStyle,
    CoordinatorIdChecker,
    CoordinatorIdErrorStyle,
    MeetingIdChecker,
    MeetingTypeErrorStyle,
    TranscationIdChecker,
    TranscationIdErrorStyle,
    StateIdChecker,
    StateErrorStyle,
    DistrictIdChecker,
    DistrictErrorStyle,
    VaradoIdChecker,
    VaradoErrorStyle,
    ParishIdChecker,
    ParishErrorStyle,
    CheckIdChecker,
    CheckErrorStyle,
    BlockIdChecker,
    BlockErrorStyle,
    bankChecker,
    bankErrorStyle,
  } = error;

  ///////////////////////village and shg or jlj  dropdown start///////////////////////////////
  const [villageName, onVillageChange] = useState("");
  const villageDetail = [];
  village &&
    village.map((ele) => {
      villageDetail.push({
        label: ele.villageName,
        value: ele._id,
      });
    });

  const [tankName, onTankNameChange] = useState("");
  const tankNameDetail = [];
  tank &&
    tank.map((ele) => {
      tankNameDetail.push({
        label: ele.tankName,
        value: ele._id,
      });
    });

  const groupType = [
    {
      label: "SHG",
      value: "SHG",
    },
    {
      label: "JLG",
      value: "JLG",
    },
  ];
  const [group, setGroup] = useState("");

  /////////////////////village dropdown jlj end////////////////////////////////

  //Required Validation ends

  const onbatchTransactionTypeChange = (e) => {
    //Required Validation starts
    setError({
      ...error,
      TranscationIdChecker: true,
      TranscationIdErrorStyle: { color: "#000" },
    });
    //Required Validation ends
    if (e) {
      setFormData({
        ...formData,
        batchTransactionType: e,
      });
    }
  };
  const onMeetingChange = (e) => {
    //Required Validation starts
    setError({
      ...error,
      MeetingIdChecker: true,
      MeetingTypeErrorStyle: { color: "#000" },
    });
    //Required Validation ends
    if (e) {
      setFormData({
        ...formData,
        meetingType: e,
      });
    }
  };

  const allvarado = [];
  varadosData.map((varado) =>
    allvarado.push({
      vId: varado._id,
      vCode: varado.varadoCode,
      label: varado.varadoName,
      value: varado.varadoName,
    })
  );

  const [varado, getVaradoData] = useState();
  const [varadoID, setVaradoID] = useState();
  const [varadoName, setvaradoName] = useState();
  const [varadoCode, setVaradoCode] = useState("");

  const onVaradoChange = (e) => {
    setError({
      ...error,
      VaradoIdChecker: true,
      VaradoErrorStyle: { color: "#000" },
    });
    getVaradoData(e);
    setVaradoID(e.vId ? e.vId : null);
    setvaradoName(e.value ? e.value : "");
    setVaradoCode(e.vCode ? e.vCode : "");
    getParish({
      varadoInfo: e.vId,
    });
  };

  const allstates = [];
  statesData.map((state) =>
    allstates.push({
      sId: state._id,
      label: state.stateName,
      value: state.stateName,
    })
  );

  const [state, getStateData] = useState();
  const [stateId, setStateID] = useState();
  const [stateName, setStateName] = useState();

  const onStateChange = (e) => {
    setError({
      ...error,
      StateIdChecker: true,
      StateErrorStyle: { color: "#000" },
      DistrictIdChecker: false,
      DistrictErrorStyle: {},
      BlockIdChecker: false,
      BlockErrorStyle: {},
    });

    //Required Validation end
    getStateData(e);
    setStateID(e.sId ? e.sId : null);
    setStateName(e.value ? e.value : "");
    getDistrict({
      stateInfo: e.sId,
    });
    getdistrictData("");
    setdistrictID(null);
    setdistrictName("");
    getBlockDetails("");
    setBlock("");
    setBlockID(null);
    setBlockName("");
  };

  const allSHGCategory = [];
  activeShgCategories.map((shgcategory) =>
    allSHGCategory.push({
      shgCategoryId: shgcategory._id,
      label: shgcategory.shgCategoryName,
      value: shgcategory.shgCategoryName,
    })
  );

  const [shgcategory, getShgcategoryData] = useState();
  const [shgCategoryId, setShgCategoryID] = useState();
  const [shgCategoryName, setShgCategoryName] = useState();

  const onShgcategoryChange = (e) => {
    //Required Validation starts
    setError({
      ...error,
      shgCategoryIdChecker: true,
      shgCategoryIdErrorStyle: { color: "#000" },
    });

    //Required Validation ends
    getShgcategoryData(e);
    setShgCategoryID(e.shgCategoryId ? e.shgCategoryId : null);
    setShgCategoryName(e.value ? e.value : "");
  };

  const allincharges = [];
  allincharge.map((incharge) =>
    allincharges.push({
      batchInchargeId: incharge._id,
      label: incharge.userFullName,
      value: incharge.userFullName,
    })
  );

  const [incharge, getinchargeData] = useState();
  const [batchInchargeId, setinchargeID] = useState();
  const [userFullName, setinchargeName] = useState();

  const onInchargeChange = (e) => {
    //Required Validation starts
    setError({
      ...error,
      InchargeIdChecker: true,
      InchargeIdErrorStyle: { color: "#000" },
    });

    //Required Validation ends
    getinchargeData(e);
    setinchargeID(e.batchInchargeId ? e.batchInchargeId : null);
    setinchargeName(e.value ? e.value : "");
  };

  //co-ordinator

  const allcoordinators = [];
  allcoordinator.map((coordinator) =>
    allcoordinators.push({
      batchcoordinatorId: coordinator._id,
      label: coordinator.userFullName,
      value: coordinator.userFullName,
    })
  );

  const [coordinator, getcoordinatorData] = useState();
  const [batchcoordinatorId, setcoordinatorID] = useState();
  const [userFullName1, setcoordinatorName] = useState();

  const onCoordinatorsChange = (e) => {
    setError({
      ...error,
      CoordinatorIdChecker: true,
      CoordinatorIdErrorStyle: { color: "#000" },
    });
    getcoordinatorData(e);
    setcoordinatorID(e.batchcoordinatorId ? e.batchcoordinatorId : null);
    setcoordinatorName(e.value ? e.value : "");
  };

  const allparish = [];
  activeParish.map((parish) =>
    allparish.push({
      pId: parish._id,
      pCode: parish.parishCode,
      bCounter: parish.batchCounter,
      label: parish.parishName,
      value: parish.parishName,
    })
  );

  const [parish, getParishData] = useState();
  const [pId, setParishID] = useState();
  const [parishName, setParishName] = useState();
  const [parishCode, setParishCode] = useState("");
  const [batchCounter, setBatchCounter] = useState("");

  const onParishChange = (e) => {
    setError({
      ...error,
      ParishIdChecker: true,
      ParishErrorStyle: { color: "#000" },
    });
    getParishData(e);
    setParishID(e.pId ? e.pId : null);
    setParishName(e.value ? e.value : "");
    setParishCode(e.pCode ? e.pCode : "");
    setBatchCounter(e.bCounter ? e.bCounter : "");
  };

  const alldistrict = [];
  activeDistrict.map((district) =>
    alldistrict.push({
      districtId: district._id,
      label: district.districtName,
      value: district.districtName,
    })
  );

  const allBlocks = [];
  getBlock.map((block) =>
    allBlocks.push({
      blockId: block._id,
      label: block.blockName,
      value: block.blockName,
    })
  );

  //BLOCK START
  const [block, setBlock] = useState();
  const [blockId, setBlockID] = useState();
  const [blockName, setBlockName] = useState();
  const onBlockChange = (e) => {
    setError({
      ...error,
      BlockIdChecker: true,
      BlockErrorStyle: { color: "#000" },
    });
    setBlock(e);
    setBlockID(e.blockId ? e.blockId : null);
    setBlockName(e.value ? e.value : "");
  };
  //BLOCK END

  const [district, getdistrictData] = useState();
  const [districtId, setdistrictID] = useState();
  const [districtName, setdistrictName] = useState();

  const ondistrictChange = (e) => {
    setError({
      ...error,
      DistrictIdChecker: true,
      DistrictErrorStyle: { color: "#000" },
      BlockIdChecker: false,
      BlockErrorStyle: {},
    });
    getdistrictData(e);
    setdistrictID(e.districtId ? e.districtId : null);
    setdistrictName(e.value ? e.value : "");
    getBlockDetails({ districtIdVal: e.districtId });
    setBlock("");
    setBlockID(null);
    setBlockName("");
  };

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  let bCode = "";
  bCode = varadoCode + parishCode + batchCounter;

  const [startSelectedDate, setBatchDate] = useState("");
  const onDateChange = (e) => {
    setBatchDate(e.target.value);
  };
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const handleUpdateModalClose = () => setShowUpdateModal(false);
  const [userData, setUserData] = useState(null);

  const onEdit = (e) => {
    setShowUpdateModal(true);
    setUserData(e);
  };
  const onUpdateModalChange = (e) => {
    if (e) {
      handleUpdateModalClose();
    }
  };
  //Required Validation Starts
  const [checknullerror, setChecknullerror] = useState("none");
  // const [checkcode, setcheckcode] = useState("");

  const onCheck = (e) => {
    if (batchCode === "") {
      //alert("");
      setChecknullerror("block");
    } else {
      setChecknullerror("none");
      setError({
        ...error,
        CheckIdChecker: true,
        CheckErrorStyle: { color: "#000" },
      });
      e.preventDefault();
      const finalData = {
        batchCode: batchCode,
      };
      checkBatchCode(finalData);
      // var checkcode = "";
      // checkcode = 1;
      // setcheckcode(checkcode);
    }
  };

  const [addData, setFormDatas] = useState({
    batchBankName: "",
    batchBankAccountNumber: "",
    batchBankBranch: "",
    batchBankIFSC: "",
  });

  const {
    batchBankName,
    batchBankAccountNumber,
    batchBankBranch,
    batchBankIFSC,
  } = addData;

  const onInputChange1 = (e) => {
    setFormDatas({ ...addData, [e.target.name]: e.target.value });
  };
  const [AddedDetails, AddDetails] = useState([]);

  const onAdd = (e) => {


    if(batchBankIFSC && batchBankAccountNumber && batchBankBranch &&batchBankName ){

    const loanList = AddedDetails.filter(
      (AddDetails) => AddDetails.batchBankName === batchBankName
    );
    e.preventDefault();
    if (loanList.length === 0) {
      if (addData && addData.batchBankName) {
        const addData = {
          batchBankName: batchBankName,
          batchBankAccountNumber: batchBankAccountNumber,
          batchBankBranch: batchBankBranch,
          batchBankIFSC: batchBankIFSC,
        };
        setFormDatas({
          ...addData,
          batchBankName: "",
          batchBankAccountNumber: "",
          batchBankBranch: "",
          batchBankIFSC: "",
        });
        let temp = [];
        temp.push(...AddedDetails, addData);
        AddDetails(temp);
        // setError({
        //   ...error,
        //   bankErrorStyle: { color: "#000" },
        // });
      }
    }
  }else{
    alert("Enter all bank details")
  }
  };

  const onRemoveChange = (batchBankName) => {
    const removeList = AddedDetails.filter(
      (AddDetails) => AddDetails.batchBankName !== batchBankName
    );
    AddDetails(removeList);
  };

  const funcKeyDown = (e) => {
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  };

  const checkErrors = () => {
    if (!InchargeIdChecker) {
      setError({
        ...error,
        InchargeIdErrorStyle: { color: "#F00" },
      });
      return false;
    }
    if (allPermission && allPermission.includes("Co-ordinator")) {
      if (!CoordinatorIdChecker) {
        setError({
          ...error,
          CoordinatorIdErrorStyle: { color: "#F00" },
        });
        return false;
      }
    }

    if (!MeetingIdChecker) {
      setError({
        ...error,
        MeetingTypeErrorStyle: { color: "#F00" },
      });
      return false;
    }

    if (!TranscationIdChecker) {
      setError({
        ...error,
        TranscationIdErrorStyle: { color: "#F00" },
      });
      return false;
    }

    if (loggedUserInstitutionData.institutionType === "FreeZone") {
      if (!CheckIdChecker) {
        setError({
          ...error,
          CheckErrorStyle: { color: "#F00" },
        });
        return false;
      }
    }

    if (!StateIdChecker) {
      setError({
        ...error,
        StateErrorStyle: { color: "#F00" },
      });
      return false;
    }

    if (!DistrictIdChecker) {
      setError({
        ...error,
        DistrictErrorStyle: { color: "#F00" },
      });
      return false;
    }
    if (!shgCategoryIdChecker) {
      setError({
        ...error,
        shgCategoryIdErrorStyle: { color: "#F00" },
      });
      return false;
    }
    if (loggedUserInstitutionData.institutionType === "Zoned") {
      if (!VaradoIdChecker) {
        setError({
          ...error,
          VaradoErrorStyle: { color: "#F00" },
        });
        return false;
      }
    }
    if (loggedUserInstitutionData.institutionType === "Zoned") {
      if (!ParishIdChecker) {
        setError({
          ...error,
          ParishErrorStyle: { color: "#F00" },
        });
        return false;
      }
    }

    if (allPermission && allPermission.includes("Blockwise")) {
      if (!BlockIdChecker) {
        setError({
          ...error,
          BlockErrorStyle: { color: "#F00" },
        });
        return false;
      }
    }
    return true;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const finalData = {
      batchName: batchName,
      batchFormationDate: startSelectedDate,
      batchPhone: batchPhone,
      batchPhone1: batchPhone1,
      batchInchargeId: batchInchargeId,
      batchInchargeName: userFullName,
      shgCategoryId: shgCategoryId,
      shgCategoryName: shgCategoryName,
      CoordinatorId: batchcoordinatorId,
      CoordinatorName: userFullName1,
      batchContactPerson: batchContactPerson,
      batchContactPerson1: batchContactPerson1,
      batchAddressLine1: batchAddressLine1,
      batchAddressLine2: batchAddressLine2,
      vId: varadoID,
      varadoName: varadoName,
      pId: pId,
      parishName: parishName,
      ward: ward,
      city: city,
      taluku: taluku,
      districtId: districtId,
      districtName: districtName,
      stateId: stateId,
      stateName: stateName,
      blockId: blockId,
      blockName: blockName,
      pincode: pincode,
      batchCode: bCode || batchCode,
      batchTransactionType: batchTransactionType.value,
      meetingType: meetingType.value,
      batchSavingAmt: batchSavingAmt,
      batchSubscriptionAmt: batchSubscriptionAmt,
      institutionId: user.institutionId,
      batchCounter: batchCounter,
      memberCounter: "01",
      batchMahasangaName: batchMahasangaName,
      batchmeetingDateAddress: batchmeetingDateAddress,
      batchPanCardNo: batchPanCardNo,
      batchRegistrationNo: batchRegistrationNo,
      banks: AddedDetails,
      batchEnteredById: user._id,
      batchEnteredByName: user.userName,
    };
    setShowUpdateModal(true);
    setUserData(finalData);
  };

  const onSaveSubmit = () => {
    const finalData = {
      batchName: batchName,
      batchFormationDate: startSelectedDate,
      batchPhone: batchPhone,
      batchPhone1: batchPhone1,
      batchInchargeId: batchInchargeId,
      batchInchargeName: userFullName,
      shgCategoryId: shgCategoryId,
      shgCategoryName: shgCategoryName,
      CoordinatorId: batchcoordinatorId,
      CoordinatorName: userFullName1,
      batchContactPerson: batchContactPerson,
      batchContactPerson1: batchContactPerson1,
      batchAddressLine1: batchAddressLine1,
      batchAddressLine2: batchAddressLine2,
      vId: varadoID,
      varadoName: varadoName,
      pId: pId,
      parishName: parishName,
      ward: ward,
      city: city,
      taluku: taluku,
      districtId: districtId,
      districtName: districtName,
      stateId: stateId,
      stateName: stateName,
      blockId: blockId,
      blockName: blockName,
      pincode: pincode,
      batchCode: bCode || batchCode,
      batchTransactionType: batchTransactionType.value,
      meetingType: meetingType.value,
      batchSavingAmt: batchSavingAmt,
      batchSubscriptionAmt: batchSubscriptionAmt,
      batchPanCardNo: batchPanCardNo,
      batchRegistrationNo: batchRegistrationNo,
      batchBankName: batchBankName,
      batchBankAccountNumber: batchBankAccountNumber,
      batchBankBranch: batchBankBranch,
      batchBankIFSC: batchBankIFSC,
      institutionId: user.institutionId,
      batchCounter: batchCounter,
      memberCounter: "01",
      batchMahasangaName: batchMahasangaName,
      batchmeetingDateAddress: batchmeetingDateAddress,
      banks: AddedDetails,
      batchEnteredById: user._id,
      batchEnteredByName: user.userName,
      villageName: villageName.label,
      villageId: villageName.value,
      tankName: tankName.label,
      tankNameId: tankName.value,
      groupType: group.label,
    };

    AddBatch(finalData);

    setFormData({
      ...formData,
      isSubmitted: true,
    });
    setBatchDate("");
    getinchargeData("");
    getdistrictData("");
    getParishData("");
    getVaradoData("");
    getStateData("");
  };
  if (isSubmitted) {
    return <Redirect to="/all-batches" />;
  }
  var ED = startSelectedDate.split(/\D/g);
  var shgformDate = [ED[2], ED[1], ED[0]].join("-");
  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className="container container_align">
        <div className="col-lg-11 col-md-11 col-sm-12 col-12">
          <h2 className="heading_color">Add SHG Details </h2>
          <hr />
        </div>

        <section className="sub_reg">
          <Tabs selectedIndex={tabIndex}>
            <div className="row col-lg-11 col-md-11 col-sm-12 col-12">
              <TabList>
                <Tab tabfor="0">Batch Info</Tab>
                <Tab tabfor="1">Bank Details</Tab>
              </TabList>
            </div>

            <TabPanel tabId="0">
              <div className=" col-md-12 col-lg-12 col-sm-12 col-12 ">
                <form onSubmit={(e) => NextBackBtn(e, 1)}>
                  <div className="row col-lg-12 col-md-12 col-sm-12 col-12 ">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12 py-3">
                      <div className="row card-new pb-3">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                          <h5>SHG Info </h5>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                          <label className="label-control">SHG Name* :</label>
                          <input
                            type="text"
                            name="batchName"
                            value={batchName}
                            className="form-control"
                            onChange={(e) => onInputChange(e)}
                            required
                          />
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                          <label className="label-control">
                            Mahasangha Name :
                          </label>
                          <input
                            type="text"
                            name="batchMahasangaName"
                            value={batchMahasangaName}
                            className="form-control"
                            onChange={(e) => onInputChange(e)}
                          />
                        </div>

                        {allPermission &&
                        allPermission.includes(
                          "ExtraField_Phone_Name_AWAKE"
                        ) ? (
                          <>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                              <label className="label-control">
                                Contact Person Phone* :
                              </label>
                              <input
                                type="number"
                                min="0"
                                name="batchPhone"
                                value={batchPhone}
                                className="form-control"
                                onWheel={() => document.activeElement.blur()}
                                onChange={(e) => onInputChange(e)}
                                onKeyDown={(e) => funcKeyDown(e)}
                                required
                              />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                              <label className="label-control">
                                Contact Person Name* :
                              </label>
                              <input
                                type="text"
                                name="batchContactPerson"
                                value={batchContactPerson}
                                className="form-control"
                                onChange={(e) => onInputChange(e)}
                                required
                              />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                              <label className="label-control">
                                Contact Person Phone(1)* :
                              </label>
                              <input
                                type="number"
                                min="0"
                                name="batchPhone1"
                                value={batchPhone1}
                                className="form-control"
                                onWheel={() => document.activeElement.blur()}
                                onChange={(e) => onInputChange(e)}
                                onKeyDown={(e) => funcKeyDown(e)}
                                required
                              />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                              <label className="label-control">
                                Contact Person Name(1)* :
                              </label>
                              <input
                                type="text"
                                name="batchContactPerson1"
                                value={batchContactPerson1}
                                className="form-control"
                                onChange={(e) => onInputChange(e)}
                                required
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                              <label className="label-control">
                                Contact Person Phone :
                              </label>
                              <input
                                type="number"
                                min="0"
                                name="batchPhone"
                                value={batchPhone}
                                className="form-control"
                                onWheel={() => document.activeElement.blur()}
                                onChange={(e) => onInputChange(e)}
                                onKeyDown={(e) => funcKeyDown(e)}
                              />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                              <label className="label-control">
                                Contact Person Name :
                              </label>
                              <input
                                type="text"
                                name="batchContactPerson"
                                value={batchContactPerson}
                                className="form-control"
                                onChange={(e) => onInputChange(e)}
                              />
                            </div>
                          </>
                        )}
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                          <label
                            className="label-control"
                            style={InchargeIdErrorStyle}
                          >
                            Select Incharge* :
                          </label>
                          <Select
                            name="userFullName"
                            options={allincharges}
                            isSearchable={true}
                            value={incharge}
                            placeholder="Select Incharge"
                            onChange={(e) => onInchargeChange(e)}
                            theme={(theme) => ({
                              ...theme,
                              height: 26,
                              minHeight: 26,
                              borderRadius: 1,
                              colors: {
                                ...theme.colors,
                                primary: "black",
                              },
                            })}
                          />
                        </div>
                        {allPermission &&
                          allPermission.includes("Co-ordinator") && (
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                              <label
                                className="label-control"
                                style={CoordinatorIdErrorStyle}
                              >
                                Select Co-ordinator* :
                              </label>
                              <Select
                                name="userFullName1"
                                options={allcoordinators}
                                isSearchable={true}
                                value={coordinator}
                                placeholder="Select Co-ordinators"
                                onChange={(e) => onCoordinatorsChange(e)}
                                theme={(theme) => ({
                                  ...theme,
                                  height: 26,
                                  minHeight: 26,
                                  borderRadius: 1,
                                  colors: {
                                    ...theme.colors,
                                    primary: "black",
                                  },
                                })}
                              />
                            </div>
                          )}
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                          <label className="label-control">
                            SHG Formation Date* :
                          </label>
                          <input
                            type="date"
                            placeholder="dd/mm/yyyy"
                            className="form-control cpp-input datevalidation"
                            name="batchFormationDate"
                            value={startSelectedDate}
                            onChange={(e) => onDateChange(e)}
                            style={{
                              width: "65%",
                            }}
                            required
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                          <label
                            className="label-control"
                            style={MeetingTypeErrorStyle}
                          >
                            Meeting Type* :
                          </label>
                          <Select
                            name="meetingType"
                            options={MeetingTypeMethods}
                            isSearchable={false}
                            value={meetingType}
                            placeholder="Select"
                            onChange={(e) => onMeetingChange(e)}
                            theme={(theme) => ({
                              ...theme,
                              height: 26,
                              minHeight: 26,
                              borderRadius: 1,
                              colors: {
                                ...theme.colors,
                                primary: "black",
                              },
                            })}
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                          <label
                            className="label-control"
                            style={TranscationIdErrorStyle}
                          >
                            SHG Transaction Type* :
                          </label>
                          <Select
                            name="batchTransactionType"
                            options={TranscationMethods}
                            isSearchable={false}
                            value={batchTransactionType}
                            placeholder="Select Meeting Type"
                            onChange={(e) => onbatchTransactionTypeChange(e)}
                            theme={(theme) => ({
                              ...theme,
                              height: 26,
                              minHeight: 26,
                              borderRadius: 1,
                              colors: {
                                ...theme.colors,
                                primary: "black",
                              },
                            })}
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                          <label className="label-control">
                          SHG Monthly Saving Amount* :
                          </label>
                          <input
                            type="number"
                            min="0"
                            name="batchSavingAmt"
                            value={batchSavingAmt}
                            className="form-control"
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => onInputChange(e)}
                            onKeyDown={(e) => funcKeyDown(e)}
                            required
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                          <label className="label-control">
                            SHG Subscription Amount* :
                          </label>
                          <input
                            type="number"
                            min="0"
                            name="batchSubscriptionAmt"
                            value={batchSubscriptionAmt}
                            className="form-control"
                            onWheel={() => document.activeElement.blur()}
                            onChange={(e) => onInputChange(e)}
                            onKeyDown={(e) => funcKeyDown(e)}
                            required
                          />
                        </div>
                        {loggedUserInstitutionData.institutionType ===
                        "Zoned" ? (
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <label className="label-control">SHG Code* :</label>
                            <input
                              type="text"
                              name="batchCode"
                              value={bCode}
                              className="form-control"
                              onChange={(e) => onInputChange(e)}
                              editable={false}
                            />
                          </div>
                        ) : (
                          <>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                              <label className="label-control">
                                SHG Code*:
                              </label>
                              <input
                                type="text"
                                name="batchCode"
                                value={batchCode}
                                className="form-control"
                                onChange={(e) => onInputChange(e)}
                                required
                              />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12 py-5">
                              <button
                                className="btn sub_form  "
                                onClick={(e) => onCheck(e)}
                                style={CheckErrorStyle}
                              >
                                Check
                              </button>
                            </div>
                          </>
                        )}
                        <div className="col-lg-12 col-md-4 col-sm-4 col-12">
                          {batchCode !== "" ? (
                            <>
                              <label className="text-success">
                                {shgcodeCheck && shgcodeCheck.msg}
                              </label>
                            </>
                          ) : (
                            <></>
                          )}
                          <label
                            style={{ display: checknullerror }}
                            className="colorRed"
                          >
                            Please fill SHG code
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12 py-3 no_padding">
                        <div className="row card-new pb-3">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <h5>Area Info </h5>
                          </div>
                          <div className="row ccol-lg-12 col-md-12 col-sm-12 col-12 no_padding ">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                              <label className="label-control">
                                Address line1* :
                              </label>
                              <input
                                type="text"
                                name="batchAddressLine1"
                                value={batchAddressLine1}
                                className="form-control"
                                onChange={(e) => onInputChange(e)}
                                required
                              />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                              <label className="label-control">
                                Address line2* :
                              </label>
                              <input
                                type="text"
                                name="batchAddressLine2"
                                value={batchAddressLine2}
                                className="form-control"
                                onChange={(e) => onInputChange(e)}
                                required
                              />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                              <label className="label-control">City* :</label>
                              <input
                                type="text"
                                name="city"
                                value={city}
                                className="form-control"
                                onChange={(e) => onInputChange(e)}
                                required
                              />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                              <label
                                className="label-control"
                                style={StateErrorStyle}
                              >
                                State* :
                              </label>
                              <Select
                                name="stateName"
                                options={allstates}
                                isSearchable={true}
                                value={state}
                                placeholder="Select State"
                                onChange={(e) => onStateChange(e)}
                                theme={(theme) => ({
                                  ...theme,
                                  height: 26,
                                  minHeight: 26,
                                  borderRadius: 1,
                                  colors: {
                                    ...theme.colors,
                                    primary: "black",
                                  },
                                })}
                              />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                              <label
                                className="label-control"
                                style={DistrictErrorStyle}
                              >
                                District* :
                              </label>
                              <Select
                                name="districtName"
                                options={alldistrict}
                                isSearchable={true}
                                value={district}
                                placeholder="Select District"
                                onChange={(e) => ondistrictChange(e)}
                                theme={(theme) => ({
                                  ...theme,
                                  height: 26,
                                  minHeight: 26,
                                  borderRadius: 1,
                                  colors: {
                                    ...theme.colors,
                                    primary: "black",
                                  },
                                })}
                              />
                            </div>
                            {/* <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                              <label className="label-control">Taluk :</label>
                              <input
                                type="text"
                                name="taluku"
                                value={taluku}
                                className="form-control"
                                onChange={(e) => onInputChange(e)}
                              />
                            </div> */}
                            {allPermission &&
                            allPermission.includes("BatchPermission_AWAKE") ? (
                              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                <label className="label-control">
                                  Taluk* :
                                </label>
                                <input
                                  type="text"
                                  name="taluku"
                                  value={taluku}
                                  className="form-control"
                                  onChange={(e) => onInputChange(e)}
                                  required
                                />
                              </div>
                            ) : (
                              <>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                  <label className="label-control">
                                    Taluk :
                                  </label>
                                  <input
                                    type="text"
                                    name="taluku"
                                    value={taluku}
                                    className="form-control"
                                    onChange={(e) => onInputChange(e)}
                                  />
                                </div>
                              </>
                            )}
                            {allPermission &&
                              allPermission.includes("Blockwise") && (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                  <label
                                    className="label-control"
                                    style={BlockErrorStyle}
                                  >
                                    Select Block* :
                                  </label>
                                  <Select
                                    name="blockName"
                                    options={allBlocks}
                                    isSearchable={true}
                                    value={block}
                                    placeholder="Select Block"
                                    onChange={(e) => onBlockChange(e)}
                                  />
                                </div>
                              )}
                            {allPermission &&
                              allPermission.includes("Villagewise") && (
                                <>
                                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <label
                                      className="label-control"
                                      //style={BlockErrorStyle}
                                    >
                                      Select Village :
                                    </label>
                                    <Select
                                      name="village"
                                      options={villageDetail}
                                      isSearchable={true}
                                      value={villageName}
                                      placeholder="Select Village"
                                      onChange={(e) => onVillageChange(e)}
                                    />
                                  </div>
                                </>
                              )}
                            {allPermission &&
                              allPermission.includes("Donor_Report") && (
                                <>
                                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <label
                                      className="label-control"
                                      // style={BlockErrorStyle}
                                    >
                                      Select SHG/JLG :
                                    </label>
                                    <Select
                                      name="group"
                                      options={groupType}
                                      isSearchable={true}
                                      value={group}
                                      placeholder="Select SHG/JLG"
                                      onChange={(e) => setGroup(e)}
                                    />
                                  </div>
                                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                    <label
                                      className="label-control"
                                      // style={BlockErrorStyle}
                                    >
                                      Select Tank Name :
                                    </label>
                                    <Select
                                      name="tankName"
                                      options={tankNameDetail}
                                      isSearchable={true}
                                      value={tankName}
                                      placeholder="Select Tank Name"
                                      onChange={(e) => onTankNameChange(e)}
                                    />
                                  </div>
                                </>
                              )}
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                              <label className="label-control">
                                Pincode* :
                              </label>
                              <input
                                type="number"
                                name="pincode"
                                value={pincode}
                                className="form-control"
                                onWheel={() => document.activeElement.blur()}
                                onChange={(e) => onInputChange(e)}
                                onKeyDown={(e) => funcKeyDown(e)}
                                required
                              />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                              <label className="label-control">Ward :</label>
                              <input
                                type="text"
                                name="ward"
                                value={ward}
                                className="form-control"
                                onChange={(e) => onInputChange(e)}
                              />
                            </div>{" "}
                            {showBatchSection && (
                              <>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                  <label
                                    className="label-control"
                                    style={VaradoErrorStyle}
                                  >
                                    Varado* :
                                  </label>
                                  <Select
                                    name="varadoName"
                                    options={allvarado}
                                    isSearchable={true}
                                    value={varado}
                                    placeholder="Select Varado"
                                    onChange={(e) => onVaradoChange(e)}
                                    theme={(theme) => ({
                                      ...theme,
                                      height: 26,
                                      minHeight: 26,
                                      borderRadius: 1,
                                      colors: {
                                        ...theme.colors,
                                        primary: "black",
                                      },
                                    })}
                                  />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                  <label
                                    className="label-control"
                                    style={ParishErrorStyle}
                                  >
                                    Parish* :
                                  </label>
                                  <Select
                                    name="parishName"
                                    options={allparish}
                                    isSearchable={true}
                                    value={parish}
                                    placeholder="Select Parish"
                                    onChange={(e) => onParishChange(e)}
                                    theme={(theme) => ({
                                      ...theme,
                                      height: 26,
                                      minHeight: 26,
                                      borderRadius: 1,
                                      colors: {
                                        ...theme.colors,
                                        primary: "black",
                                      },
                                    })}
                                  />
                                </div>
                              </>
                            )}
                            {/* <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                              <label className="label-control">
                                Batch Meeting Time and Place and Day :
                              </label>
                              <input
                                type="text"
                                name="batchmeetingDateAddress"
                                value={batchmeetingDateAddress}
                                className="form-control"
                                onChange={(e) => onInputChange(e)}
                              />
                            </div> */}
                            {allPermission &&
                            allPermission.includes("BatchPermission_AWAKE") ? (
                              <>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                  <label className="label-control">
                                    Batch Meeting Time and Place and Day* :
                                  </label>
                                  <input
                                    type="text"
                                    name="batchmeetingDateAddress"
                                    value={batchmeetingDateAddress}
                                    className="form-control"
                                    onChange={(e) => onInputChange(e)}
                                    required
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                  <label className="label-control">
                                    Batch Meeting Time and Place and Day :
                                  </label>
                                  <input
                                    type="text"
                                    name="batchmeetingDateAddress"
                                    value={batchmeetingDateAddress}
                                    className="form-control"
                                    onChange={(e) => onInputChange(e)}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="row card-new pb-3">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <h5>Other Info </h5>
                          </div>
                          <div className="row col-lg-12 col-md-12 col-sm-12 col-12 no_padding ">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                              <label className="label-control">
                                Pan Card No :
                              </label>
                              <input
                                type="text"
                                name="batchPanCardNo"
                                value={batchPanCardNo}
                                className="form-control"
                                onChange={(e) => onInputChange(e)}
                              />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                              <label
                                className="label-control"
                                style={shgCategoryIdErrorStyle}
                              >
                                SHG Category* :
                              </label>
                              <Select
                                name="shgcategory"
                                options={allSHGCategory}
                                isSearchable={false}
                                value={shgcategory}
                                placeholder="Select"
                                onChange={(e) => onShgcategoryChange(e)}
                                theme={(theme) => ({
                                  ...theme,
                                  height: 26,
                                  minHeight: 26,
                                  borderRadius: 1,
                                  colors: {
                                    ...theme.colors,
                                    primary: "black",
                                  },
                                })}
                              />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                              <label className="label-control">
                                Registration No :
                              </label>
                              <input
                                type="text"
                                name="batchRegistrationNo"
                                value={batchRegistrationNo}
                                className="form-control"
                                onChange={(e) => onInputChange(e)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <input
                        type="submit"
                        name="submit"
                        value="Next"
                        className="btn sub_form btn_continue Save float-right"
                      />
                      <Link
                        className="btn sub_form btn_continue Save float-right"
                        to="/all-batches"
                      >
                        Cancel
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </TabPanel>
            <TabPanel tabId="1">
              <form onSubmit={(e) => NextBackBtn(e, 2)}>
                <div className="row col-md-12 col-lg-12 col-sm-12 col-12 ">
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12 py-3">
                    <div className="row card-new  py-3">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="label-control" style={bankErrorStyle}>
                          Bank Name<span style={{color:""}}>*</span>:
                        </label>
                        <input
                          type="text"
                          name="batchBankName"
                          value={batchBankName}
                          className="form-control"
                          onChange={(e) => onInputChange1(e)}
                          required
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="label-control">IFSC Code<span style={{color:""}}>*</span>&nbsp;:</label>
                        <input
                          type="text"
                          name="batchBankIFSC"
                          value={batchBankIFSC}
                          className="form-control"
                          onChange={(e) => onInputChange1(e)}
                          required
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="label-control">
                          Account Number<span style={{color:""}}>*</span>&nbsp;:
                        </label>
                        <input
                          type="number"
                          name="batchBankAccountNumber"
                          value={batchBankAccountNumber}
                          onWheel={() => document.activeElement.blur()}
                          onKeyDown={(e) => funcKeyDown(e)}
                          className="form-control"
                          onChange={(e) => onInputChange1(e)}
                          required
                        />
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="label-control">Branch<span style={{color:""}}>*</span>&nbsp;:</label>
                        <input
                          type="text"
                          name="batchBankBranch"
                          value={batchBankBranch}
                          className="form-control"
                          onChange={(e) => onInputChange1(e)}
                          required
                        />
                      </div>
                      <div className="col-lg-12 col-md-6 col-sm-6 col-12">
                        <button
                          className="btn sub_form btn_continue Save"
                          onClick={(e) => onAdd(e)}
                        >
                          ADD
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12 py-3">
                    <div className="row card-new py-3">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className=" body-inner no-padding  table-responsive">
                          <table
                            className="tabllll table table-bordered table-striped table-hover"
                            id="datatable2"
                          >
                            <thead>
                              <tr>
                                <th>Bank Name</th>
                                <th>IFSC Code</th>
                                <th>Account Number</th>
                                <th>Branch</th>
                                <th>Remove</th>
                              </tr>
                            </thead>
                            <tbody>
                              {AddedDetails &&
                                AddedDetails.map((AddDetail, idx) => {
                                  return (
                                    <tr key={idx}>
                                      <td>{AddDetail.batchBankName}</td>
                                      <td>{AddDetail.batchBankIFSC}</td>
                                      <td>
                                        {AddDetail.batchBankAccountNumber}
                                      </td>
                                      <td>{AddDetail.batchBankBranch}</td>
                                      <td>
                                        <img
                                          className="img_icon_size log"
                                          onClick={() =>
                                            onRemoveChange(
                                              AddDetail.batchBankName
                                            )
                                          }
                                          src={require("../../static/images/close-buttonRed.png")}
                                          alt="Remove"
                                          title="Remove"
                                        />
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <form className="row" onSubmit={(e) => onSubmit(e)}>
                <div className="col-lg-11 col-md-12 col-sm-12 col-12">
                  <input
                    type="submit"
                    name="Save"
                    value="Submit"
                    className="btn sub_form btn_continue Save float-right"
                  />
                  <button
                    className="btn sub_form btn_continue Save float-right"
                    onClick={(e) => NextBackBtn(e, 0)}
                  >
                    Previous
                  </button>
                </div>
              </form>
            </TabPanel>
          </Tabs>
          <div className="col-lg-12 col-md-6 col-sm-12 col-12">
            <label className="label-control colorRed">
              * Indicates mandatory fields, Please fill mandatory fields before
              Submit
            </label>
          </div>
        </section>
        <Modal
          show={showUpdateModal}
          backdrop="static"
          keyboard={false}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <div className="col-lg-10">
              <h3 className="modal-title text-center">
                Preview of Add SHG Details
              </h3>
            </div>
            <div className="col-lg-2">
              <button onClick={handleUpdateModalClose} className="close">
                <img
                  src={require("../../static/images/close.png")}
                  alt="X"
                  style={{ height: "20px", width: "20px" }}
                />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="row col-lg-12 col-md-11 col-sm-12 col-12 ">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12 py-3">
                <div className="row card-new">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <h5>SHG Info </h5>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                    <label className="">SHG Name : {batchName}</label>
                  </div>
                  {/* <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                    <label className="label-control-sm">
                      Contact Person Phone : {batchPhone}
                    </label>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                    <label className="">
                      Contact Person Name : {batchContactPerson}
                    </label>
                  </div> */}
                  {allPermission &&
                  allPermission.includes("ExtraField_Phone_Name_AWAKE") ? (
                    <>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="label-control-sm">
                          Contact Person Phone : {batchPhone}
                        </label>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="">
                          Contact Person Name : {batchContactPerson}
                        </label>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="label-control-sm">
                          Contact Person Phone(1) : {batchPhone1}
                        </label>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="label-control-sm">
                          Contact Person Name(1) : {batchContactPerson1}
                        </label>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="label-control-sm">
                          Contact Person Phone : {batchPhone}
                        </label>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="">
                          Contact Person Name : {batchContactPerson}
                        </label>
                      </div>
                    </>
                  )}
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                    <label className="label-control-sm">
                      Incharge : {userFullName}
                    </label>
                  </div>
                  {allPermission.includes("Co-ordinator") && (
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                      <label className="label-control-sm">
                        Co-ordinator : {userFullName1}
                      </label>
                    </div>
                  )}
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                    <label className="label-control-sm">
                      SHG Formation Date : {shgformDate}
                    </label>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                    <label className="label-control-sm">
                      Meeting Type : {meetingType.value}
                    </label>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                    <label className="label-control-sm">
                      SHG Transcation Type : {batchTransactionType.value}
                    </label>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                    <label className="label-control-sm">
                      SHG Monthly Saving Amount : {batchSavingAmt}
                    </label>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                    <label className="label-control-sm">
                      SHG Subscription Amount : {batchSubscriptionAmt}
                    </label>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                    <label className="label-control-sm">
                      SHG Code : {bCode || batchCode}
                    </label>
                  </div>
                </div>
              </div>
              <div className="row col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 py-3 no_padding">
                  <div className="row card-new pb-3">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <h5>Area Info </h5>
                    </div>
                    <div className="row ccol-lg-12 col-md-12 col-sm-12 col-12 no_padding ">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="label-control-sm">
                          Address line1 : {batchAddressLine1}
                        </label>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="label-control-sm">
                          Address line2 : {batchAddressLine2}
                        </label>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="label-control-sm">
                          City : {city}
                        </label>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="label-control-sm">
                          State : {stateName}
                        </label>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="label-control-sm">
                          District:{districtName}
                        </label>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="label-control-sm">
                          Taluk : {taluku}
                        </label>
                      </div>
                      {allPermission && allPermission.includes("Blockwise") && (
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                          <label className="label-control">
                            Block : {blockName}
                          </label>
                        </div>
                      )}
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="label-control-sm">
                          Pincode : {pincode}
                        </label>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="label-control-sm">
                          Ward : {ward}
                        </label>
                      </div>
                      {showBatchSection && (
                        <>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <label className="label-control-sm">
                              Varado : {varadoName}
                            </label>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <label className="label-control-sm">
                              Parish : {parishName}
                            </label>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 py-3 no_padding">
                  <div className="row card-new pb-3">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <h5>Other Info </h5>
                    </div>
                    <div className="row ccol-lg-12 col-md-12 col-sm-12 col-12 no_padding ">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="label-control-sm">
                          Pan Card No : {batchPanCardNo}
                        </label>
                      </div>
                    </div>
                    <div className="row ccol-lg-12 col-md-12 col-sm-12 col-12 no_padding ">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="label-control-sm">
                          SHG Category Name: {shgCategoryName}
                        </label>
                      </div>
                    </div>
                    <div className="row ccol-lg-12 col-md-12 col-sm-12 col-12 no_padding ">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <label className="label-control-sm">
                          Registration No: {batchRegistrationNo}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className=" body-inner no-padding  table-responsive">
                  <table
                    className="tabllll table table-bordered table-striped table-hover tbl-preview"
                    id="datatable2"
                  >
                    <thead>
                      <tr>
                        <th>Bank Name</th>
                        <th>IFSC Code</th>
                        <th>Account Number</th>
                        <th>Branch</th>
                      </tr>
                    </thead>
                    <tbody>
                      {AddedDetails &&
                        AddedDetails.map((AddDetail, idx) => {
                          return (
                            <tr key={idx}>
                              <td>{AddDetail.batchBankName}</td>
                              <td>{AddDetail.batchBankIFSC}</td>
                              <td>{AddDetail.batchBankAccountNumber}</td>
                              <td>{AddDetail.batchBankBranch}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div
              className="row col-lg-12 col-md-11 col-sm-12 col-12 Savebutton no_padding"
              size="lg"
            >
              <div className="col-lg-12 col-md-6 col-sm-12 col-12">
                {loading ? (
                  <button
                    className="btn sub_form btn_continue blackbrd Save float-right"
                    disabled
                  >
                    Loading...
                  </button>
                ) : (
                  <button
                    variant="success"
                    className="btn sub_form btn_continue blackbrd Save float-right"
                    onClick={() => onSaveSubmit()}
                  >
                    Save
                  </button>
                )}
                <Link
                  to="#"
                  className="btn sub_form btn_continue blackbrd float-right"
                  onClick={handleUpdateModalClose}
                >
                  Cancel
                </Link>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </Fragment>
  );
};

AddBatchDetails.propTypes = {
  auth: PropTypes.object.isRequired,
  AddBatch: PropTypes.func.isRequired,
  getVarados: PropTypes.func.isRequired,
  getStates: PropTypes.func.isRequired,
  getAllIncharge: PropTypes.func.isRequired,
  getAllCoordinators: PropTypes.func.isRequired,
  getDistrict: PropTypes.func.isRequired,
  getParish: PropTypes.func.isRequired,
  shg: PropTypes.object.isRequired,
  checkBatchCode: PropTypes.func.isRequired,
  unsetCheckCode: PropTypes.func.isRequired,
  getBlockDetails: PropTypes.func.isRequired,
  getActiveShgCategory: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  area: state.area,
  user: state.user,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  AddBatch,
  getAllVillages,
  getAllTankName,
  getVarados,
  getStates,
  getAllIncharge,
  getAllCoordinators,
  getActiveShgCategory,
  getDistrict,
  getParish,
  checkBatchCode,
  unsetCheckCode,
  getBlockDetails,
})(AddBatchDetails);
