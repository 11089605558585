import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Modal } from "react-bootstrap";

const ShgOtherLoan = ({
  activeOtherLoan,
  activeOtherLoanTypes,
  setOtherLoan,
  otherLoan,
  allpurpose,
  selectedBank,
}) => {
  const [selectedOtherLoan, setSelectedotherLoan] = useState(null);
  let activeOtherLoanNames = activeOtherLoan.map((ele) => ele.otherLoanType);

  const handleKeyPress = (e) => {
    // Allow only numbers, decimal point, and prevent negative sign
    ["e", "E", "+", "-","." ].includes(e.key) && e.preventDefault();
  };
  //Basic fileds
  const [sancPriCash, setSancPriCash] = useState(0);
  const [sancPriBank, setSancPriBank] = useState(0);

  const onChangeSetSancPriCash =(e)=>{
    setSancPriCash(e.target.value);
    // const validAmountRegex = /^\d*\.?\d{0,2}$/;
    // const value = e.target.value;
    //         // Prevent entering a dot as the first character, validate input, and restrict to 2 decimal places
    //     if (((validAmountRegex.test(value)) && value[0] !== '.')) {
    //       
    //     }    
  }
  const onChangeSetSancPriBank=(e)=>{
    setSancPriBank(e.target.value);
    // const validAmountRegex = /^\d*\.?\d{0,2}$/;
    // const value = e.target.value;
    //         // Prevent entering a dot as the first character, validate input, and restrict to 2 decimal places
    //     if (((validAmountRegex.test(value)) && value[0] !== '.')) {
    //       
    //     }    
  }

  const [sancIntCash, setSancIntCash] = useState(0);
  const [sancIntBank, setSancIntBank] = useState(0);

  const onChangeSetSancIntCash= (e)=>{
    setSancIntCash(e.target.value);
    // const validAmountRegex = /^\d*\.?\d{0,2}$/;
    // const value = e.target.value;
    //         // Prevent entering a dot as the first character, validate input, and restrict to 2 decimal places
    //     if (((validAmountRegex.test(value)) && value[0] !== '.')) {
    //      
    //     } 
  }
  const onChangeSetSancIntBank = (e)=>{
    setSancIntBank(e.target.value);
    // const validAmountRegex = /^\d*\.?\d{0,2}$/;
    // const value = e.target.value;
    //         // Prevent entering a dot as the first character, validate input, and restrict to 2 decimal places
    //     if (((validAmountRegex.test(value)) && value[0] !== '.')) {
    //       
    //     } 
  }

  const [subDistCash, setSubDistCash] = useState("");
  const [subDistBank, setSubDistBank] = useState("");

  const onChangeSetSubDistCash = (e)=>{
    setSubDistCash(e.target.value);
    // const validAmountRegex = /^\d*\.?\d{0,2}$/;
    // const value = e.target.value;
    //         // Prevent entering a dot as the first character, validate input, and restrict to 2 decimal places
    //     if (((validAmountRegex.test(value)) && value[0] !== '.')) {
    //       
    //     } 
  }
  const onChangeSetSubDistBank = (e)=>{
    setSubDistBank(e.target.value);
    // const validAmountRegex = /^\d*\.?\d{0,2}$/;
    // const value = e.target.value;
    //         // Prevent entering a dot as the first character, validate input, and restrict to 2 decimal places
    //     if (((validAmountRegex.test(value)) && value[0] !== '.')) {
    //       
    //     } 
  }


  const [duration, setDuration] = useState("");

  const onDurationChange= (e)=>{
    setDuration(e.target.value);
    // const validAmountRegex = /^\d*\.?\d{0,2}$/;
    // const value = e.target.value;
    //         // Prevent entering a dot as the first character, validate input, and restrict to 2 decimal places
    //     if (((validAmountRegex.test(value)) && value[0] !== '.')) {
    //       
    //     } 
  }
  const [intPaidCash, setIntPaidCash] = useState(0);
  const [intPaidBank, setIntPaidBank] = useState(0);

  const onChangeSetIntPaidCash = (e) =>{
    setIntPaidCash(e.target.value);
    // const validAmountRegex = /^\d*\.?\d{0,2}$/;
    // const value = e.target.value;
    //         // Prevent entering a dot as the first character, validate input, and restrict to 2 decimal places
    //     if (((validAmountRegex.test(value)) && value[0] !== '.')) {
    //       
    //     } 
  }
  const onChangeSetIntPaidBank = (e)=>{
    setIntPaidBank(e.target.value);
    // const validAmountRegex = /^\d*\.?\d{0,2}$/;
    // const value = e.target.value;
    //         // Prevent entering a dot as the first character, validate input, and restrict to 2 decimal places
    //     if (((validAmountRegex.test(value)) && value[0] !== '.')) {
    //       
    //     } 
  }

  const [priPaidCash, setPriPaidCash] = useState(0);
  const [priPaidBank, setPriPaidBank] = useState(0);

  const onChangeSetPriPaidCash = (e)=>{
    setPriPaidCash(e.target.value);
    // const validAmountRegex = /^\d*\.?\d{0,2}$/;
    // const value = e.target.value;
    //         // Prevent entering a dot as the first character, validate input, and restrict to 2 decimal places
    //     if (((validAmountRegex.test(value)) && value[0] !== '.')) {
    //      
    //     } 
  }
  const onChangeSetPriPaidBank = (e) =>{
    setPriPaidBank(e.target.value);
    // const validAmountRegex = /^\d*\.?\d{0,2}$/;
    // const value = e.target.value;
    //         // Prevent entering a dot as the first character, validate input, and restrict to 2 decimal places
    //     if (((validAmountRegex.test(value)) && value[0] !== '.')) {
          
        // } 
  }

  const [nominee, setNominee] = useState("");
  const [loanPurpose, setLoanPurpose] = useState("");

  //end of basic

  //seleted Oth Loan

  let ActiveLoanData = selectedOtherLoan
    ? activeOtherLoan.find(
        (ele) => ele.otherLoanType == selectedOtherLoan.label
      ) || null
    : null;

  let ActivePayablePri = ActiveLoanData
    ? ActiveLoanData.loanPayablePrincipal
    : 0;

  let AcvtivePaidPri = ActiveLoanData
    ? ActiveLoanData.loanRepayment.reduce(
        (acu, cur) => (acu += cur.loanPrincipalPaid),
        0
      )
    : 0;


  let outStandingPri = Number(ActivePayablePri || 0) - Number(AcvtivePaidPri || 0);
  const [selectedBankValidation,setShowSelectedBankValidation]=useState(false);
  const [principalIsLessValidation,setPrincipalIsLessValidation] = useState("")

  const addOtherLoan = (e) => {
    e.preventDefault();
    if (
      (sancPriBank != 0 ||
        sancIntBank != 0 ||
        subDistBank != 0 ||
        priPaidBank != "" ||
        intPaidBank != "") &&
      selectedBank.length == 0
    ) {
      // document.getElementById("banks").style.border = "2px solid red";
      setShowSelectedBankValidation(true);
    } else {
      // document.getElementById("banks").style.border = "none";
      setShowSelectedBankValidation(false);
      const newOtherLoan = {
        othLoanID: activeOtherLoan.find(
          (ele) => ele.otherLoanType == selectedOtherLoan.label
        )
          ? activeOtherLoan.find(
              (ele) => ele.otherLoanType == selectedOtherLoan.label
            )._id
          : "New OthLoan",

        otherLoanTypeId: selectedOtherLoan.value,
        otherLoanType: selectedOtherLoan.label,

        //Sanctin Data
        otherloanPayablePrincipal: {
          cash: sancPriCash || 0,
          bankAmt: sancPriBank || 0,
          bankName: sancPriBank != 0 ? selectedBank && selectedBank.value : "",
        },
        otherloanPayableInterst: {
          cash: sancIntCash || 0,
          bankAmt: sancIntBank || 0,
          bankName: sancIntBank != 0 ? selectedBank && selectedBank.value : "",
        },
        otherLoanTakenAmt: {
          cash: Number(sancPriCash || 0) + Number(sancIntCash || 0),
          bankAmt: Number(sancPriBank || 0) + Number(sancIntBank || 0),
          bankName: sancPriBank != 0 ? selectedBank && selectedBank.value : "",
        },

        otherLoanDuration: duration,

        indSubsidyDistributed: {
          cash: Number(subDistCash || 0),
          bankAmt: Number(subDistBank || 0),
          bankName: subDistBank != 0 ? selectedBank && selectedBank.value : "",
        },
        externalNominee: nominee,
        otherLoanReasonId: loanPurpose.loanReasonId,
        otherloanpurpose: loanPurpose.label,

        // "otherLoanOtherPurpose": null,

        //Repayment
        otherLoanPaid: {
          cash: priPaidCash || 0,
          bankAmt: priPaidBank || 0,
          bankName: priPaidBank != 0 ? selectedBank && selectedBank.value : "",
        },

        otherInterestPaid: {
          cash: intPaidCash,
          bankAmt: intPaidBank,
          bankName: intPaidBank != 0 ? selectedBank && selectedBank.value : "",
        },
      };

      let totalSanctionedPrincipal =
        Number(sancPriCash || 0) + Number(sancPriBank || 0);
      let totalRepaidPrincipal =
        Number(priPaidCash || 0) + Number(priPaidBank || 0);
      if (activeOtherLoanNames.includes(selectedOtherLoan.label)) {
        setPrincipalIsLessValidation("");
        setShowSelectedBankValidation(false);
        setOtherLoan([...otherLoan, newOtherLoan]);
      } else {
        if (totalRepaidPrincipal > totalSanctionedPrincipal) {
          setPrincipalIsLessValidation("Sanctioned amount is less than repaid");
          setShowSelectedBankValidation(true);
        } else {
          setPrincipalIsLessValidation("");
          setShowSelectedBankValidation(false);
          setOtherLoan([...otherLoan, newOtherLoan]);

        }
      }

      setSancPriCash("");
      setSancPriBank("");
      setSancIntCash("");
      setSancIntBank("");
      setSubDistCash("");
      setSubDistBank("");
      setLoanPurpose("");
      setDuration("");
      setNominee("");
      setPriPaidCash("");
      setPriPaidBank("");
      setIntPaidCash("");
      setIntPaidBank("");
      setSelectedotherLoan("");
      setIsValidLoanReason(true);
    }

    // const isLoanReasonValid = !!loanPurpose;
    // if (!isLoanReasonValid) {
    //   console.error("Form is not valid. Please select Loan Reason.");
    //   setIsValidLoanReason(false);
    //   return;
    // }
  };

  const infoIcon = (titleValue) => {
    return (
      <>
        <img
          className="img_icon_size log mx-2"
          // src={require("../../../static/images/info.png")}
          src={require("../../static/images/info.png")}
          alt="Reason"
          style={{ height: "18px", width: "18px" }}
          title={titleValue}
        />
      </>
    );
  };
  const getSum = (cash, bank) => {
    const cashValue = isNaN(cash) ? 0 : Number(cash);
    const bankValue = isNaN(bank) ? 0 : Number(bank);
    const total =  cashValue + bankValue;
    return total
  };
  const [isValidLoanReason, setIsValidLoanReason] = useState(true);
  const onSetIntLoanPurpose1 = (selectedOption) => {
    setLoanPurpose(selectedOption);
    setIsValidLoanReason(!!selectedOption);
  };
  return (
    <>
    <form onSubmit={(e) => addOtherLoan(e)}>
      <div className="row col-lg-12 col-md-12 col-sm-12">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <label className="label-control mx-2">Active Loans :</label>
          {activeOtherLoan.map((ele, index) => {
            return (
              <label className="label-control">
                {ele.otherLoanType}{" "}
                {index < activeOtherLoan.length - 1 ? ", " : ""}
              </label>
            );
          })}
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 my-1">
          <label
            className="label-control"
            // style={otherLoanTypesErrorStyle}
          >
            Other Loan Types : <i className="colorRed">*</i>
          </label>
          <Select
            name="otherLoanTypes"
            options={activeOtherLoanTypes.map((ele) => {
              return {
                label: ele.otherLoanType,
                value: ele._id,
              };
            })}
            isSearchable={true}
            value={selectedOtherLoan}
            placeholder="Select Other Loan Type"
            onChange={(e) => setSelectedotherLoan(e)}
            theme={(theme) => ({
              ...theme,
              height: 26,
              minHeight: 26,
              borderRadius: 1,
              colors: {
                ...theme.colors,
                primary: "black",
              },
            })}
          />
        </div>
      </div>

      <>
        {selectedOtherLoan == null ? (
          <></>
        ) : (
          <>
            {activeOtherLoanNames.includes(selectedOtherLoan.label) ? (
              <>{/* <h3>Select a Loan Type to sanction or repay</h3> */}</>
            ) : (
              <div className="row col-lg-12 col-md-12 col-sm-12">
                <div className="col-lg-6 col-md-6 col-sm-12 cardUpdate">
                  <label className="label-control">
                    Loan Sanctioned Principal{" "}
                    {infoIcon(
                      "OL Sanctioned Principal Cash and Bank Total value"
                    )}
                    :&nbsp;(
                    {getSum(sancPriCash, sancPriBank)})
                    <i className="colorRed ">*</i>
                  </label>
                  <div className="row col-12">
                    <div className="row col-lg-6">
                      <div className="col-lg-2 mt-2">Bank </div>
                      <div className="col-lg-10">
                        <input
                          type="number"
                          name="othLoanSancPrincipalBank"
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          value={sancPriBank}
                          onChange={(e) => onChangeSetSancPriBank(e)}
                          onKeyPress={handleKeyPress}
                          disabled={(selectedBank && selectedBank.length == 0) ? true : false}
                          required={selectedBank ? true : false}
                          //required
                        />
                      </div>
                    </div>
                    <div className=" row col-lg-6">
                      <div className="col-lg-2 mt-2">Cash </div>{" "}
                      <div className="col-lg-10">
                        <input
                          type="number"
                          name="othLoanSancPrincipalCash"
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          value={sancPriCash}
                          onChange={(e) => onChangeSetSancPriCash(e)}
                          onKeyPress={handleKeyPress}
                          // onKeyDown={(e) => funcKeyDown(e)}
                          // disabled={svngAmtBKName ? false : true}
                          title="Enter Amount Greater Than 0"
                          //pattern="[1-9][0-9]*"
                          min={sancPriBank ? "0" : "1"}
                          required
                        />
                      </div>
                    </div>

                  </div>

                  <label className="label-control">
                    Loan Receivable Interest{" "}
                    {infoIcon(
                      "OL Sanctioned Interest Cash and Bank Total value"
                    )}
                    :&nbsp;(
                    {getSum(sancIntCash, sancIntBank)})
                    <i className="colorRed ">*</i>
                  </label>
                  <div className="row col-12">
                    <div className=" row col-lg-6">
                      <div className="col-lg-2 mt-2">Bank </div>
                      <div className="col-lg-10">
                        <input
                          type="number"
                          name="othLoanSancInterestBank"
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onKeyPress={handleKeyPress}
                          value={sancIntBank}
                          onChange={(e) => onChangeSetSancIntBank(e)}
                          disabled={(selectedBank && selectedBank.length == 0) ? true : false}
                          required={selectedBank ? true : false}
                        />
                      </div>
                    </div>
                    <div className=" row col-lg-6">
                      <div className="col-lg-2 mt-2">Cash </div>{" "}
                      <div className="col-lg-10">
                        <input
                          type="number"
                          name="othLoanSancInterestCash"
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onKeyPress={handleKeyPress}
                          value={sancIntCash}
                          onChange={(e) => onChangeSetSancIntCash(e)}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <label className="label-control">
                    Subsidy Distributed{" "}
                    {infoIcon("Subsidy Distributed Cash and Bank Total value")}
                    :&nbsp;(
                    {getSum(subDistCash, subDistBank)}){" "}
                  </label>

                  <div className="row col-12">
                    <div className=" row col-lg-6">
                      <div className="col-lg-2 mt-2">Bank </div>
                      <div className="col-lg-10">
                        <input
                          type="number"
                          name="othSubsidyDistributedBank"
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onKeyPress={handleKeyPress}
                          value={subDistBank}
                          onChange={(e) => onChangeSetSubDistBank(e)}
                          disabled={(selectedBank && selectedBank.length == 0) ? true : false}
                        />
                      </div>
                    </div>
                    <div className=" row col-lg-6">
                      <div className="col-lg-2 mt-2">Cash </div>{" "}
                      <div className="col-lg-10">
                        <input
                          type="number"
                          name="othSubsidyDistributedCash"
                          className="form-control"
                          onWheel={() => document.activeElement.blur()}
                          onKeyPress={handleKeyPress}
                          value={subDistCash}
                          onChange={(e) => onChangeSetSubDistCash(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12 cardUpdate">
                  <label className="label-control">
                    Loan Reason :<i className="colorRed "></i>
                  </label>
                  <Select
                    name="otherLoanTypes"
                    options={allpurpose}
                    isSearchable={true}
                    value={loanPurpose}
                    placeholder="Select Loan Purpose"
                    onChange={(e) => setLoanPurpose(e)}
                    theme={(theme) => ({
                      ...theme,
                      height: 26,
                      minHeight: 26,
                      borderRadius: 1,
                      colors: {
                        ...theme.colors,
                        primary: "black",
                      },
                    })}
                  />
                  {!isValidLoanReason && (
                    <div style={{ color: "red", marginTop: "5px" }}>
                      Please select Loan Reason
                    </div>
                  )}
                  <label className="label-control">
                    Duration (In Months) :<i className="colorRed">*</i>{" "}
                  </label>
                  <input
                    type="number"
                    name="othDuration"
                    className="form-control"
                    placeholder="Enter Duration"
                    onWheel={() => document.activeElement.blur()}
                    onKeyPress={handleKeyPress}
                    title="Enter Month Greater Than 0"
                    pattern="[1-9][0-9]*"
                    min="1"
                    value={duration}
                    onChange={(e) => {
                      onDurationChange(e);
                    }}
                    required
                  />

                  <label className="label-control">Nominee : </label>
                  <input
                    type="text"
                    name="othNominee"
                    className="form-control"
                    placeholder="Enter Nominee"
                    onWheel={() => document.activeElement.blur()}
                    value={nominee}
                    onChange={(e) => {
                      setNominee(e.target.value);
                    }}
                  />
                </div>
              </div>
            )}

            <div className="col-lg-12 col-md-12 col-sm-12">
              <h4 className="mx-3">Repayment :</h4>
            </div>

            <div className="row col-lg-12 col-md-12 col-sm-12">
              <div className="col-lg-6 col-md-6 col-sm-12 cardUpdate">
                <label
                  className="label-control"
                  // style={otherLoanTypesErrorStyle}
                >
                  Other Principal Paid{" "}
                  {infoIcon("OL Principal Paid Cash and Bank Total value")}
                  :&nbsp;(
                  {getSum(priPaidCash, priPaidBank)})
                  <i className="colorRed ">*</i>
                </label>
                <div className="row col-12">
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Bank </div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="othPrincipalPaidBank"
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        value={priPaidBank}
                        onChange={(e) => onChangeSetPriPaidBank(e)}
                        onKeyPress={handleKeyPress}
                        disabled={(selectedBank && selectedBank.length == 0) ? true : false}
                        required={selectedBank ? true : false}
                      />
                    </div>
                  </div>
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Cash </div>{" "}
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="othPrincipalPaidCash"
                        className="form-control"
                        onKeyPress={handleKeyPress}
                        onWheel={() => document.activeElement.blur()}
                        value={priPaidCash}
                        onChange={(e) => onChangeSetPriPaidCash(e)}
                        required
                      />
                    </div>
                  </div>
                </div>
                <label
                  className="label-control"
                  // style={otherLoanTypesErrorStyle}
                >
                  Other Total Paid :
                </label>
                <div className="row col-12">
                  <input
                    type="number"
                    name="othTotalPaid"
                    value={
                      Number(priPaidCash) +
                      Number(priPaidBank) +
                      Number(intPaidCash) +
                      Number(intPaidBank)
                    }
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    //  onChange={(e) => onInputChange(e)}
                    // onKeyDown={(e) => funcKeyDown(e)}
                    disabled
                    // required
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 cardUpdate">
                <label
                  className="label-control"
                  // style={otherLoanTypesErrorStyle}
                >
                  Other Interest Paid{" "}
                  {infoIcon("OL Interest Paid Cash and Bank Total value")}
                  :&nbsp;(
                  {getSum(intPaidCash, intPaidBank)})
                  <i className="colorRed ">*</i>
                </label>
                <div className="row col-12">
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Bank </div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="othInterestPaidBank"
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onKeyPress={handleKeyPress}
                        value={intPaidBank}
                        onChange={(e) => onChangeSetIntPaidBank(e)}
                        disabled={(selectedBank && selectedBank.length == 0) ? true : false}
                        required={selectedBank ? true : false}
                        // onKeyDown={(e) => funcKeyDown(e)}
                        // required
                      />
                    </div>
                  </div>
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Cash </div>{" "}
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="othInterestPaidCash"
                        className="form-control"
                        onWheel={() => document.activeElement.blur()}
                        onKeyPress={handleKeyPress}
                        value={intPaidCash}
                        onChange={(e) => onChangeSetIntPaidCash(e)}
                        //  onChange={(e) => onInputChange(e)}
                        // onKeyDown={(e) => funcKeyDown(e)}
                        // disabled={svngAmtBKName ? false : true}
                        required
                      />
                    </div>
                  </div>
                </div>

                <label
                  className="label-control"
                  // style={otherLoanTypesErrorStyle}
                >
                  Other Remianing Loan (Principal) &nbsp;:
                </label>
                <div className="row col-12">
                  <input
                    type="number"
                    name="othRemainingLoan"
                    className="form-control"
                    onWheel={() => document.activeElement.blur()}
                    value={outStandingPri}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <button
                type="Submit"
                className="btn sub_form btn_continue blackbrd Save float-right"
                disabled={
                  duration > 0 && getSum(sancPriCash, sancPriBank) == 0
                    ? true
                    : false
                  // getSum(priPaidCash, priPaidBank) > outStandingPri
                }
                style={
                  outStandingPri > 0 &&
                  getSum(priPaidCash, priPaidBank) > outStandingPri
                    ? { display: "none" }
                    : {}
                }
              >
                ADD
              </button>
            </div>
          </>
        )}
      </>
    </form>

  {/* ////////////////////////bank warning modal////////////////////// */}
  <Modal
  show={selectedBankValidation}
  backdrop=""
  keyboard={false}
  size="md"
  aria-labelledby="contained-modal-title-vcenter"
  centered
>
  <Modal.Header>
    <div className="col-lg-11 ">
      <h3 className="modal-title " style={{fontWeight:"bold"}}>Attention</h3>
    </div>
   
  </Modal.Header>
  <Modal.Body>
  <div className="text-center">
  {principalIsLessValidation != "" ? principalIsLessValidation : "You have entered an amount in the bank field. Please select the bank to proceed." } 
  </div>
  <div className="">
          <input
            type="button"
            name="Prtev"
            value="Ok"
            onClick={() => setShowSelectedBankValidation(false)}
            className="btn sub_form btn_continue Save float-right"
          />
        </div>
  </Modal.Body>
</Modal>
</>
  );
};

export default ShgOtherLoan;
