import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Select from "react-select";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ShgInternalLoan from "../../layout/ShgInternalLoan";
import {
  getParticularLoanReason,
  getCurMemMettingLoanSanc,
  getExistingMemberILData,
  getExistingMemberOLData,
  getActiveOtherLoanType,
  addMemberMeetingData,
  getIndividualMemberMeetingData,
} from "../../../actions/shg";

import LoanPayment from "../../layout/LoanPayment";
import ShgOtherLoan from "../../layout/ShgOtherLoan";

export const AddMemberModel = ({
  memberData,
  allPermission,
  activeBank,
  getParticularLoanReason,
  getCurMemMettingLoanSanc,
  getExistingMemberILData,
  getExistingMemberOLData,
  getActiveOtherLoanType,
  setMemberMeetingDetailModal,
  addMemberMeetingData,
  getIndividualMemberMeetingData,
  getAllMembersOfParticularBatch,
  selectedDates,
  shg: {
    memMeetingData,
    loanreason,
    curMemMettingLoanSanc,
    exstMemILData,
    activeOtherLoanTypes,
    exstMemOLData,
  },
  auth: { isAuthenticated, user, users },
}) => {
  // code for next previous tabing starts
  const [selectedBank, setSelectedbank] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  useEffect(() => {
    getParticularLoanReason();
    getCurMemMettingLoanSanc(memberData);
    getExistingMemberILData(memberData);
    getExistingMemberOLData(memberData);
    getIndividualMemberMeetingData(memberData);
    getActiveOtherLoanType();
  }, []);

  const [NewInternalLoan, setNewInternalLoan] = useState([]);
  const [editCurLoan, setEditCurLoan] = useState(false);
  const [showPayment, setShowpayment] = useState(false);
  const [paymentArray, setPaymentArray] = useState([]);
  const [internalTypeName, setInternalTypeName] = useState("");
  const [internalLoanId, setInternalLoanId] = useState("");
  const [showRepaymentModal, setShowRepaymentModal] = useState(false);
  const [loanRepaymentData, setLoanRepaymentData] = useState([]);
  const [otherLoan, setOtherLoan] = useState([]);
  const [clickedInternalLoan, setClickedInternalLoan] = useState("");

  const [addDisabled, setAddDisabled] = useState(false);

  const [formData, setFormData] = useState({
    savingAmtCash: "",
    savingAmtBank: "",

    subscriptionAmtCash: "",
    subscriptionAmtBank: "",

    otherIncomeCash: "",
    otherIncomeBank: "",

    securityDepositMemToShgCash: "",
    securityDepositMemToShgBank: "",

    TotSavingTillDate: "",

    otherContributionCash: "",
    otherContributionBank: "",

    seedMoneyCash: "",
    seedMoneyBank: "",

    membershipFeesCash: "",
    membershipFeesBank: "",

    //expense
    savingWithdrawalsCash: "",
    savingWithdrawalsBank: "",

    securityDepositCash: "",
    securityDepositBank: "",

    dividendDistributedCash: "",
    dividendDistributedBank: "",
  });

  let {
    savingAmtCash,
    savingAmtBank,

    subscriptionAmtCash,
    subscriptionAmtBank,

    otherIncomeCash,
    otherIncomeBank,

    securityDepositMemToShgCash,
    securityDepositMemToShgBank,

    TotSavingTillDate,

    otherContributionCash,
    otherContributionBank,

    seedMoneyCash,
    seedMoneyBank,

    membershipFeesCash,
    membershipFeesBank,

    //expense
    savingWithdrawalsCash,
    savingWithdrawalsBank,

    securityDepositCash,
    securityDepositBank,

    dividendDistributedCash,
    dividendDistributedBank,
  } = formData;

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [allPurpose, setallPurpose] = useState([]);

  useEffect(() => {
    if (loanreason.length > 0)
      setallPurpose(
        loanreason.map((ele) => {
          return {
            loanReasonId: ele._id,
            label: ele.loanPurpose,
            value: ele.loanPurpose,
          };
        })
      );
  }, [loanreason]);

  const clickPay = (loanData) => {
    setShowpayment(true);
    setInternalTypeName(loanData.intLoanType);
    setInternalLoanId(loanData._id || "New Loan");
    setClickedInternalLoan(loanData);
  };

  const dateCheck = (thisdate, passedDate) =>
    new Date(thisdate) <= new Date(passedDate);

  const onDeleteInternalPayment = (data) => {
    setPaymentArray(
      paymentArray.filter((e) => e.internalLoanType != data.internalLoanType)
    );
  };

  const getInternalLoanOnly = (loanArray) => {
    try {
      return loanArray.filter((ele) => ele.isLoanType == "Internal");
    } catch (er) {
      return [];
    }
  };

  const getActiveInternalLoanfromNow = (loanArray) => {
    try {
      return loanArray.filter(
        (ele) =>
          ele.isLoanType == "Internal" &&
          dateCheck(ele.meetingHeldOnDate, memberData.meetingHeldOnDate)
      );
    } catch (er) {
      console.log("some error",er.message)
      return [];
    }
  };

  const getActiveOtherLoanfromNow = (loanArray) => {
    try {
      return loanArray.filter((ele) => ele.isLoanType != "Internal");
    } catch (er) {
      return [];
    }
  };

  const indianFormat = (dateData) => {
    try {
      return dateData
        .split("-")
        .reverse("")
        .join("-");
    } catch (er) {
      return "";
    }
  };

  //FINAL submit
  const isBankName = (passedBankValue) => {
    return passedBankValue != 0 ? selectedBank && selectedBank.value : "";
  };

  const onFinalSubmit = async () => {
    // e.preventDefault();
        
// Find the record within selectedDates array that matches the memberId
// const foundRecord = selectedDates && selectedDates.find(record => record.memberId === memberData.memberId);

// Get the dates, if the record is found
// const memberDates = foundRecord ? foundRecord.dates : undefined;

    const finalData = {
      batchId: memberData.batchId,
      memberId: memberData.memberId,
      memberName: memberData.memberName,
      meetingHeldOnDate: memberData.meetingHeldOnDate,
      meetingMonth: memberData.meetingHeldOnDate.split("-")[1],
      meetingYear: memberData.meetingHeldOnDate.split("-")[0],
      // selectedDates:memberDates,
      //Basic
      savingAmt: {
        bankName: isBankName(savingAmtBank),
        bankAmt: savingAmtBank,
        cash: savingAmtCash,
      },

      subscriptionAmt: {
        bankName: isBankName(subscriptionAmtBank),
        bankAmt: subscriptionAmtBank,
        cash: subscriptionAmtCash,
      },

      otherIncome: {
        bankName: isBankName(otherIncomeBank),
        bankAmt: otherIncomeBank,
        cash: otherIncomeCash,
      },

      memSecurityDepositToShg: {
        bankName: isBankName(securityDepositMemToShgBank),
        bankAmt: securityDepositMemToShgBank,
        cash: securityDepositMemToShgCash,
      },

      otherContribution: {
        bankName: isBankName(otherContributionBank),
        bankAmt: otherContributionBank,
        cash: otherContributionCash,
      },

      memSeedMoney: {
        bankName: isBankName(seedMoneyBank),
        bankAmt: seedMoneyBank,
        cash: seedMoneyCash,
      },

      membershipFees: {
        bankName: isBankName(membershipFeesBank),
        bankAmt: membershipFeesBank,
        cash: membershipFeesCash,
      },

      //Expense
      savingWithdrawals: {
        bankName: isBankName(savingWithdrawalsBank),
        bankAmt: savingWithdrawalsBank,
        cash: savingWithdrawalsCash,
      },

      securityDeposit: {
        bankName: isBankName(securityDepositBank),
        bankAmt: securityDepositBank,
        cash: securityDepositCash,
      },

      dividendDistributed: {
        bankName: isBankName(dividendDistributedBank),
        bankAmt: dividendDistributedBank,
        cash: dividendDistributedCash,
      },
      attendance: null,
      // Internal loan Sanc

      internalLoan: NewInternalLoan,

      loanTakenAmt: {
        cash: NewInternalLoan.reduce(
          (acu, cur) =>
            (acu +=
              Number(cur.loanPayablePrincipal.cash) +
              Number(cur.loanPayableInterest.cash)),
          0
        ),
        bankAmt: NewInternalLoan.reduce(
          (acu, cur) =>
            (acu +=
              Number(cur.loanPayablePrincipal.bankAmt) +
              Number(cur.loanPayableInterest.bankAmt)),
          0
        ),
        bankName: selectedBank ? selectedBank.value : "",
      },

      //Internal loan repayment

      internalRepayment: paymentArray,

      loanPaid: {
        cash: paymentArray.reduce(
          (acu, cur) => (acu += Number(cur.internalPrincipalPaid.cash)),
          0
        ),
        bankAmt: paymentArray.reduce(
          (acu, cur) => (acu += Number(cur.internalPrincipalPaid.bankAmt)),
          0
        ),
        bankName: selectedBank ? selectedBank.value : "",
      },
      interestPaid: {
        cash: paymentArray.reduce(
          (acu, cur) => (acu += Number(cur.internalIntrestPaid.cash)),
          0
        ),

        bankAmt: paymentArray.reduce(
          (acu, cur) => (acu += Number(cur.internalIntrestPaid.bankAmt)),
          0
        ),
        bankName: selectedBank ? selectedBank.value : "",
      },

      //other loan
      otherLoan: otherLoan,

      //pri + intrest
      otherLoanTakenAmt: otherLoan.reduce(
        (acu, ele) =>
          (acu +=
            Number(ele.otherLoanTakenAmt.cash) +
            Number(ele.otherLoanTakenAmt.bankAmt)),
        0
      ),

      otherLoanPaid: otherLoan.reduce(
        (acu, ele) =>
          (acu +=
            Number(ele.otherLoanPaid.cash) + Number(ele.otherLoanPaid.bankAmt)),
        0
      ),

      otherInterestPaid: otherLoan.reduce(
        (acu, ele) =>
          (acu +=
            Number(ele.otherInterestPaid.cash) +
            Number(ele.otherInterestPaid.bankAmt)),
        0
      ),
      subsidyDistributed: {
        cash: otherLoan.reduce(
          (acu, ele) => (acu += Number(ele.indSubsidyDistributed.cash)),
          0
        ),

        bankAmt: otherLoan.reduce(
          (acu, ele) => (acu += Number(ele.indSubsidyDistributed.bankAmt)),
          0
        ),
        bankName: selectedBank ? selectedBank.value : "",
      },
    };

    // console.log("finalData",finalData)
    await addMemberMeetingData(finalData);

    await getAllMembersOfParticularBatch({
      mdBatchId: memberData.batchId,
      mdDate: memberData.meetingHeldOnDate,
    });

    setMemberMeetingDetailModal(false);
  };

  const paymentSVG = (loan, RepaymentArray) => {
    return (
      <>
        {RepaymentArray.some(
          (paid) => paid.internalLoanType == loan.intLoanType
        ) ? (
          <>Paid</>
        ) : (
          <>
            <span
              className="ml-4"
              style={{ cursor: "pointer" }}
              onClick={() => {
                clickPay(loan);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                class="bi bi-cash-coin"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"
                />
                <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z" />
                <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z" />
                <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z" />
              </svg>
            </span>
          </>
        )}
      </>
    );
  };

  const onDeleteOthLoan = (othLoanData) => {
    setOtherLoan(
      otherLoan.filter(
        (ele) => ele.otherLoanType != othLoanData.otherLoanType
      ) || []
    );
  };

  const infoIcon = (titleValue) => {
    return (
      <>
        <img
          className="img_icon_size log mx-2"
          src={require("../../../static/images/info.png")}
          alt="Reason"
          style={{ height: "18px", width: "18px" }}
          title={titleValue}
        />
      </>
    );
  };
  const getSum = (cash, bank) => {
    const cashValue = isNaN(cash) ? 0 : Number(cash);
    const bankValue = isNaN(bank) ? 0 : Number(bank);
    return cashValue + bankValue;
  };

  const internalDelete = (loan) => {
    setNewInternalLoan(
      NewInternalLoan.filter((ele) => ele.intLoanType !== loan.intLoanType)
    );
  };
  const handleKeyPress = (e) => {
    // Allow only numbers, decimal point, and prevent negative sign
    ["e", "E", "+", "-","." ].includes(e.key) && e.preventDefault();
  };
  return (
    <div>
      <Tabs selectedIndex={tabIndex}>
        <div className="row col-lg-11 col-md-11 col-sm-12 col-12">
          <TabList>
            <Tab tabfor="0">Basic</Tab>
            <Tab tabfor="1">Expense</Tab>
            <Tab tabfor="2">New IL Sanc</Tab>
            <Tab tabfor="3">IL Repayment</Tab>
            <Tab tabfor="4">Other Loan</Tab>
          </TabList>
        </div>

        <div className="row col-12"style={{ backgroundColor: "#144c5a" }}>
          <div className="col-lg-3 col-sm-12">
            <label className="label-control" style={{ color: "white" }}>
              Meeting Date&nbsp;:&nbsp;{indianFormat(memberData.meetingHeldOnDate)}
            </label>
          </div>
          <div className="col-lg-3 col-sm-12">
            <label className="label-control" style={{ color: "white" }}>
              Member Name&nbsp;:&nbsp;{memberData.memberName}
            </label>
          </div>
          <div className="col-lg-3 col-sm-12">
            <Select
              options={activeBank}
              className="mt-2"
              value={selectedBank}
              placeholder="Select Bank"
              onChange={(e) => setSelectedbank(e)}
            />
          </div>
          <div className="col-lg-3 col-sm-12">
            {allPermission && allPermission.includes("Attendance") ? (
              // <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              //   <label className="label-control mx-2"style={{ color: "white" }}>Is Present&nbsp;:&nbsp;</label>
              //   <input
              //     type="checkbox"
              //     id="Unconfirmed"
              //     // checked={attendance}
              //     // onChange={handlePresent}
              //     style={{ display: "inline-block" }}
              //   />
              // </div>
              <></>
            ) : (
              <></>
            )}
          </div>
        </div>

        <TabPanel tabId="0">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setTabIndex(1);
            }}
          >
            <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
                <label className="label-control">Savings Amount{infoIcon("Savings amount Cash and Bank Total value")}:&nbsp;(
                  {getSum(savingAmtCash, savingAmtBank)})
                  <i className="colorRed">*</i>
                </label>

                <div className="row col-12">
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Bank </div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="savingAmtBank"
                        value={savingAmtBank}
                        disabled={!selectedBank ? true : false}
                        required={selectedBank ? true : false}
                        className="form-control"
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => handleKeyPress(e)}
                        onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                        // required
                      />
                    </div>
                  </div>

                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Cash</div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="savingAmtCash"
                        value={savingAmtCash}
                        className="form-control"
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => handleKeyPress(e)}
                        onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                        required
                      />
                    </div>
                  </div>
                </div>

                <label className="label-control">
                  Subscription Amount{infoIcon("Subscription amount Cash and Bank Total value")}:
                  &nbsp;(
                  {getSum(subscriptionAmtCash, subscriptionAmtBank)})
                  <i className="colorRed">*</i>
                </label>

                <div className="row col-12">
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Bank </div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="subscriptionAmtBank"
                        value={subscriptionAmtBank}
                        disabled={!selectedBank ? true : false}
                        required={selectedBank ? true : false}
                        className="form-control"
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => handleKeyPress(e)}
                        onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                        // required
                      />
                    </div>
                  </div>

                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Cash</div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="subscriptionAmtCash"
                        value={subscriptionAmtCash}
                        className="form-control"
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => handleKeyPress(e)}
                        onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                        required
                      />
                    </div>
                  </div>
                </div>

                <label className="label-control">
                  Other Income{infoIcon("Other Income Cash and Bank Total value")}:&nbsp;(
                  {getSum(otherIncomeCash, otherIncomeBank)})
                </label>

                <div className="row col-12">
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Bank </div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="otherIncomeBank"
                        value={otherIncomeBank}
                        disabled={
                          user &&
                          user.institutionId ===
                            "63c0e52501d22dcc13f12c32"
                            ? true
                            : (selectedBank === null || (selectedBank && selectedBank.length == 0)) ? true : false
                        }                        className="form-control"
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => handleKeyPress(e)}
                        onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                        // required
                        // required={selectedBank ? true : false}
                      />
                    </div>
                  </div>

                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Cash</div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="otherIncomeCash"
                        value={otherIncomeCash}
                        className="form-control"
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => handleKeyPress(e)}
                        onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                        // required
                        disabled={
                          user &&
                          user.institutionId ===
                            "63c0e52501d22dcc13f12c32"
                            ? true
                           : false
                        }
                      />
                    </div>
                  </div>
                </div>

                <label className="label-control">
                  Security Deposit Member To SHG{infoIcon(
                    "Security Deposit Member To SHG Cash and Bank Total value"
                  )}
                  :&nbsp;(
                  {getSum(
                    securityDepositMemToShgCash,
                    securityDepositMemToShgBank
                  )}
                  )
                </label>

                <div className="row col-12">
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Bank </div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="securityDepositMemToShgBank"
                        value={securityDepositMemToShgBank}
                        disabled={!selectedBank ? true : false}
                        // required={selectedBank ? true : false}
                        className="form-control"
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => handleKeyPress(e)}
                        onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                        // required
                      />
                    </div>
                  </div>

                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Cash</div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="securityDepositMemToShgCash"
                        value={securityDepositMemToShgCash}
                        className="form-control"
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => handleKeyPress(e)}
                        onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                        // required
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
                {/* <label className="label-control">
                  Total Saving
                 
                </label>
                <div className="row col-12">
                  <input
                    type="number"
                    name="TotSavingTillDate"
                  
                    // value={TotSavingTillDate}
                    placeholder="Enter Cash Amount"
                    className="form-control"
                    disabled
                    // required
                  />
                </div> */}

                <label className="label-control">
                  Other Contribution Amount{infoIcon(
                    "Other Contribution Amount Cash and Bank Total value"
                  )}
                  :&nbsp;(
                  {getSum(otherContributionCash, otherContributionBank)})
                </label>

                <div className="row col-12">
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Bank </div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="otherContributionBank"
                        value={otherContributionBank}
                        disabled={
                          user &&
                          user.institutionId ===
                            "63c0e52501d22dcc13f12c32"
                            ? true
                            : (selectedBank === null || (selectedBank && selectedBank.length == 0)) ? true : false
                        }                        // required={selectedBank ? true : false}
                        className="form-control"
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => handleKeyPress(e)}
                        onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                        // required
                      />
                    </div>
                  </div>

                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Cash</div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="otherContributionCash"
                        value={otherContributionCash}
                        className="form-control"
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => handleKeyPress(e)}
                        onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                        // required
                        disabled={
                          user &&
                          user.institutionId ===
                            "63c0e52501d22dcc13f12c32"
                            ? true
                            :  false
                        }
                      />
                    </div>
                  </div>
                </div>

                <label className="label-control">
                  Seed Money{infoIcon("Seed Money Cash and Bank Total value")}
                  :&nbsp;(
                  {getSum(seedMoneyCash, seedMoneyBank)})
                </label>
                <div className="row col-12">
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Bank </div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="seedMoneyBank"
                        value={seedMoneyBank}
                        className="form-control"
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => handleKeyPress(e)}
                        onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                        disabled={
                          user &&
                          user.institutionId ===
                            "63c0e52501d22dcc13f12c32"
                            ? true
                            : (selectedBank === null || (selectedBank && selectedBank.length == 0)) ? true : false
                        }                        // required={selectedBank ? true : false}
                        // required
                      />
                    </div>
                  </div>
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Cash </div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="seedMoneyCash"
                        value={seedMoneyCash}
                        className="form-control"
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => handleKeyPress(e)}
                        onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                        disabled={
                          user &&
                          user.institutionId ===
                            "63c0e52501d22dcc13f12c32"
                            ? true:
                             false
                        }
                        // required
                      />
                    </div>
                  </div>
                </div>

                <label className="label-control">
                  Membership Fees{infoIcon("Membership Fees Cash and Bank Total value")}
                  :&nbsp;(
                  {getSum(membershipFeesCash, membershipFeesBank)})
                </label>

                <div className="row col-12">
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Bank</div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="membershipFeesBank"
                        value={membershipFeesBank}
                        className="form-control"
                        onChange={(e) => onInputChange(e)}
                        disabled={!selectedBank ? true : false}
                        onKeyDown={(e) => handleKeyPress(e)}
                        onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                        // required={selectedBank ? true : false}
                        // required
                      />
                    </div>
                  </div>
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Cash </div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="membershipFeesCash"
                        value={membershipFeesCash}
                        className="form-control"
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => handleKeyPress(e)}
                        onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                        // required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <label className="label-control colorRed">
                *Indicates mandatory fields, Please fill mandatory fields before
                Submit
              </label>
            </div>
            <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
              <input
                type="submit"
                name="submit"
                value="Next"
                className="btn sub_form btn_continue Save float-right"
              />
            </div>
          </form>
        </TabPanel>

        <TabPanel tabId="1">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setTabIndex(2);
            }}
          >
            <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
                <label className="label-control">
                  Savings Withdrawal{infoIcon("Savings Withdrawal Cash and Bank Total value")}
                  :&nbsp;(
                  {getSum(savingWithdrawalsCash, savingWithdrawalsBank)})
                </label>

                <div className="row col-12">
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Bank</div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="savingWithdrawalsBank"
                        value={savingWithdrawalsBank}
                        className="form-control"
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => handleKeyPress(e)}
                        onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                        disabled={!selectedBank ? true : false}
                        // required
                      />
                    </div>
                  </div>
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Cash </div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="savingWithdrawalsCash"
                        value={savingWithdrawalsCash}
                        className="form-control"
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => handleKeyPress(e)}
                        onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                        // required
                      />
                    </div>
                  </div>
                </div>

                <label className="label-control">
                  Security Deposit SHG To Member{infoIcon(
                    "Security Deposit SHG To Member Cash and Bank Total value"
                  )}
                  :&nbsp;({getSum(securityDepositCash, securityDepositBank)})
                </label>

                <div className="row col-12">
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Bank</div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="securityDepositBank"
                        value={securityDepositBank}
                        className="form-control"
                        onChange={(e) => onInputChange(e)}
                        disabled={!selectedBank ? true : false}
                        onKeyDown={(e) => handleKeyPress(e)}
                        onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                        // required
                      />
                    </div>
                  </div>
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Cash </div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="securityDepositCash"
                        value={securityDepositCash}
                        className="form-control"
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => handleKeyPress(e)}
                        onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                        // required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12 cardUpdate">
                <label className="label-control">
                  Dividend Distributed{infoIcon("Dividend Distributed Cash and Bank Total value")}
                  :&nbsp;({getSum(dividendDistributedCash, dividendDistributedBank)})
                </label>

                <div className="row col-12">
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Bank</div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="dividendDistributedBank"
                        value={dividendDistributedBank}
                        className="form-control"
                        onChange={(e) => onInputChange(e)}
                        disabled={!selectedBank ? true : false}
                        onKeyDown={(e) => handleKeyPress(e)}
                        onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                        // required
                      />
                    </div>
                  </div>
                  <div className=" row col-lg-6">
                    <div className="col-lg-2 mt-2">Cash</div>
                    <div className="col-lg-10">
                      <input
                        type="number"
                        name="dividendDistributedCash"
                        value={dividendDistributedCash}
                        className="form-control"
                        onChange={(e) => onInputChange(e)}
                        onKeyDown={(e) => handleKeyPress(e)}
                        onWheel={(e) => e.currentTarget.blur()} // Prevent scroll
                        // required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <label className="label-control colorRed">
                *Indicates mandatory fields, Please fill mandatory fields before
                Submit
              </label>
            </div>
            <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
              <input
                type="submit"
                name="submit"
                value="Next"
                className="btn sub_form btn_continue Save float-right"
              />
              <input
                type="button"
                name="Prtev"
                value="Previous"
                onClick={() => setTabIndex(0)}
                className="btn sub_form btn_continue Save float-right"
              />
            </div>
          </form>
        </TabPanel>

        <TabPanel tabId="2">
          <div>
            <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
              <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
                <h5>Loans in this meeting:</h5>
                <div className="body-inner no-padding table-responsive">
                  <table
                    className="tabllll table table-bordered table-striped table-hover mt-2"
                    id="datatable2"
                  >
                    <thead>
                      <tr>
                        <th>Info</th>
                        <th>IL Type</th>
                        <th>Sanc On</th>
                        <th>Sanc Prin</th>
                        <th>Sanc Int</th>
                        <th>Loan Status</th>
                        <th>Operation</th>
                      </tr>
                    </thead>
                    <tbody>
                      {NewInternalLoan &&
                        NewInternalLoan.map((loan, idx) => {
                          return (
                            <tr key={idx}>
                              <td></td>
                              <td>{loan.intLoanType}</td>
                              <td>
                                {indianFormat(memberData.meetingHeldOnDate)}
                              </td>
                              <td>
                                <span className="mx-4">
                                  {/* Total ( */}
                                  {Number(loan.loanPayablePrincipal.cash) +
                                    Number(loan.loanPayablePrincipal.bankAmt)}
                                  {/* )
                                </span>
                                <span className="mx-4">
                                  Bank(
                                  {Number(loan.loanPayablePrincipal.bankAmt)})
                                </span>
                                <span className="mx-4">
                                  Cash{Number(loan.loanPayablePrincipal.cash)}*/}
                                </span>
                              </td>
                              <td>
                                <span className="mx-4">
                                  {/* Total( */}
                                  {Number(loan.loanPayableInterest.cash) +
                                    Number(loan.loanPayableInterest.bankAmt)}
                                  {/* )
                                </span>
                                <span className="mx-4">
                                  Bank(
                                  {Number(loan.loanPayableInterest.bankAmt)})
                                </span>
                                <span className="mx-4">
                                  Cash(
                                  {Number(loan.loanPayableInterest.cash)}) */}
                                </span>
                              </td>
                              <td>Active</td>
                              <td>
                                <span
                                  style={{ cursor: "pointer", color: "red" }}
                                  onClick={() => {
                                    internalDelete(loan);
                                  }}
                                >
                                  <img
                                    src={require("../../../static/images/delete.png")}
                                    alt="help"
                                    id="img_tool_admin"
                                    className="pass_admin_help_icon_question mx-2"
                                  />
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <>
              {editCurLoan ? (
                <>
                  {/* <EditCurLoan
                      ILData={ILData}
                      setEditCurLoan={setEditCurLoan}
                      user={user}
                    /> */}
                </>
              ) : (
                <>
                  <ShgInternalLoan
                    Internalonly={getInternalLoanOnly(curMemMettingLoanSanc)}
                    NewInternalLoan={NewInternalLoan}
                    setNewInternalLoan={setNewInternalLoan}
                    internalLoanData={{}}
                    allPurpose={allPurpose}
                    selectedBank={selectedBank}
                  />
                </>
              )}
            </>

            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <label className="label-control colorRed">
                *Indicates mandatory fields, Please fill mandatory fields before
                Submit
              </label>
            </div>
            <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
              <input
                type="button"
                onClick={() => setTabIndex(3)}
                name="submit"
                value="Next"
                className="btn sub_form btn_continue Save float-right"
              />
              <input
                type="button"
                name="Prtev"
                value="Previous"
                onClick={() => setTabIndex(1)}
                className="btn sub_form btn_continue Save float-right"
              />
            </div>
          </div>
          {/* <div>tab 2</div> */}
        </TabPanel>

        <TabPanel tabId="3">
          <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
            <h5>Active Loans</h5>
          </div>
          <div className="body-inner no-padding table-responsive">
            <table
              className="tabllll table table-bordered table-striped table-hover mt-2"
              id="datatable2"
            >
              <thead>
                <tr>
                  <th>Info</th>
                  <th>IL Type</th>
                  <th>Sanc On</th>
                  <th>Sanc Prin</th>
                  <th>Total Prin Paid</th>
                  <th>Remaining Prin</th>
                  <th>Operation</th>
                </tr>
              </thead>
              <tbody>
                {getActiveInternalLoanfromNow(curMemMettingLoanSanc).map(
                  (loan, idx) => {
                    let paidPrincipal = loan.loanRepayment.reduce(
                      (acu, cur) => (acu += cur.loanPrincipalPaid),
                      0
                    );

                    let remaingLoan = loan.loanPayablePrincipal - paidPrincipal;
                    return (
                      <tr key={idx}>
                        <td>
                          <span
                            className="ml-1"
                            onClick={() => {
                              setShowRepaymentModal(true);
                              setLoanRepaymentData(loan.loanRepayment || []);
                            }}
                          >
                            {
                              <img
                                className="img_icon_size log mb-1"
                                src={require("../../../static/images/information.png")}
                                alt="Repayment Info"
                              />
                            }
                          </span>
                        </td>
                        <td>{loan.intLoanType}</td>
                        <td>{indianFormat(loan.meetingHeldOnDate)}</td>
                        <td>{loan.loanPayablePrincipal}</td>
                        <td>{paidPrincipal}</td>
                        <td>{remaingLoan}</td>
                        <td>
                          {paymentArray.some(
                            (item) => item.internalLoanType == loan.intLoanType
                          ) ||
                          (exstMemILData &&
                            exstMemILData.internalRepayment &&
                            exstMemILData.internalRepayment.some(
                              (item) =>
                                item.internalLoanType == loan.intLoanType
                            )) ? (
                            <>Paid</>
                          ) : (
                            <>{paymentSVG(loan, paymentArray)}</>
                          )}
                        </td>
                      </tr>
                    );
                  }
                )}

                {NewInternalLoan &&
                  NewInternalLoan.map((loan, idx) => {
                    return (
                      <tr key={idx}>
                        <td></td>
                        <td>{loan.intLoanType}</td>
                        <td>{indianFormat(memberData.meetingHeldOnDate)}</td>
                        <td>
                          {Number(loan.loanPayablePrincipal.cash) +
                            Number(loan.loanPayablePrincipal.bankAmt)}
                        </td>
                        <td>0</td>
                        <td>
                          {Number(loan.loanPayablePrincipal.cash) +
                            Number(loan.loanPayablePrincipal.bankAmt)}
                        </td>
                        <td>{paymentSVG(loan, paymentArray)}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>

          {showPayment ? (
            <LoanPayment
              setShowpayment={setShowpayment}
              setPaymentArray={setPaymentArray}
              paymentArray={paymentArray}
              internalTypeName={internalTypeName}
              meetingHeldOnDate={memberData.meetingHeldOnDate}
              selectedBank={selectedBank}
              internalLoanId={internalLoanId}
              curMemMettingLoanSanc={curMemMettingLoanSanc}
              clickedInternalLoan={clickedInternalLoan}
            />
          ) : (
            <></>
          )}

          <div className=" body-inner no-padding table-responsive">
            <div className="row col-lg-12 col-md-9 col-sm-9 col-12">
              <h5> Repayment Details</h5>
            </div>

            <table
              className="tabllll table table-bordered table-striped table-hover mt-2"
              id="datatable2"
            >
              <thead>
                <tr>
                  <th>IL Type</th>
                  <th>Paid Principal</th>
                  <th>Paid Interest</th>
                  {/* <th>Penalty</th> */}
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {paymentArray &&
                  paymentArray.map((paid, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{paid.internalLoanType}</td>
                        <td>
                          <span className="mx-4">
                            Total (
                            {Number(paid.internalPrincipalPaid.cash) +
                              Number(paid.internalPrincipalPaid.bankAmt)}
                            )
                          </span>
                          <span className="mx-4">
                            Bank({Number(paid.internalPrincipalPaid.bankAmt)})
                          </span>
                          <span className="mx-4">
                            Cash({Number(paid.internalPrincipalPaid.cash)})
                          </span>
                        </td>
                        <td>
                          <span className="mx-4">
                            Total(
                            {Number(paid.internalIntrestPaid.cash) +
                              Number(paid.internalIntrestPaid.bankAmt)}
                            )
                          </span>
                          <span className="mx-4">
                            Bank({Number(paid.internalIntrestPaid.bankAmt)})
                          </span>
                          <span className="mx-4">
                            Cash({Number(paid.internalIntrestPaid.cash)})
                          </span>
                        </td>
                        {/* <td>{paid.penalty}</td> */}
                        <td>
                          <span onClick={() => onDeleteInternalPayment(paid)}>
                            <img
                              src={require("../../../static/images/delete.png")}
                              alt="help"
                              id="img_tool_admin"
                              className="pass_admin_help_icon_question"
                            />
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <label className="label-control colorRed">
              *Indicates mandatory fields, Please fill mandatory fields before
              Submit
            </label>
          </div>
          <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
            <input
              type="button"
              name="submit"
              value="Next"
              onClick={() => setTabIndex(4)}
              className="btn sub_form btn_continue Save float-right"
            />
            <input
              type="button"
              name="Prtev"
              value="Previous"
              onClick={() => setTabIndex(2)}
              className="btn sub_form btn_continue Save float-right"
            />
          </div>
        </TabPanel>
        <TabPanel tabId="4">
          <div>
            <ShgOtherLoan
              activeOtherLoan={getActiveOtherLoanfromNow(
                curMemMettingLoanSanc
              ).filter(
                (ele) =>
                  ele.loanStatus !== "Closed" && ele.loanStatus !== "Oclosed"
              )}
              activeOtherLoanTypes={activeOtherLoanTypes}
              setOtherLoan={setOtherLoan}
              otherLoan={otherLoan}
              allpurpose={allPurpose}
              selectedBank={selectedBank}
            />

            <div className=" body-inner no-padding table-responsive">
              <table
                className="tabllll table table-bordered table-striped table-hover mt-2"
                id="datatable2"
              >
                <thead>
                  <tr>
                    <th>OL Type</th>
                    <th>OL Sanctioned Principal</th>
                    <th>OL Sanctioned Interest</th>
                    <th>Duration</th>
                    <th>OL Total Sanctioned Amount</th>
                    <th>OL Purpose</th>
                    <th>Subsidy Distributed</th>
                    <th>Nominee</th>
                    <th>OL Principal Paid</th>
                    <th>OL Interest Paid</th>

                    <th>Remove</th>
                  </tr>
                </thead>
                <tbody>
                  {otherLoan.map((memberData, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{memberData.otherLoanType}</td>
                        <td>
                          {Number(memberData.otherloanPayablePrincipal.cash) +
                            Number(
                              memberData.otherloanPayablePrincipal.bankAmt
                            )}
                        </td>
                        <td>
                          {Number(memberData.otherloanPayableInterst.cash) +
                            Number(memberData.otherloanPayableInterst.bankAmt)}
                        </td>
                        <td>{memberData.otherLoanDuration}</td>
                        <td>
                          {Number(memberData.otherLoanTakenAmt.cash) +
                            Number(memberData.otherLoanTakenAmt.bankAmt)}
                        </td>
                        <td>{memberData.otherloanpurpose}</td>
                        <td>
                          {Number(memberData.indSubsidyDistributed.cash) +
                            Number(memberData.indSubsidyDistributed.bankAmt)}
                        </td>
                        <td>{memberData.externalNominee}</td>
                        <td>
                          {Number(memberData.otherLoanPaid.cash) +
                            Number(memberData.otherLoanPaid.bankAmt)}
                        </td>
                        <td>
                          {Number(memberData.otherInterestPaid.cash) +
                            Number(memberData.otherInterestPaid.bankAmt)}
                        </td>

                        <td>
                          <img
                            className="img_icon_size log"
                            onClick={() => onDeleteOthLoan(memberData)}
                            src={require("../../../static/images/delete.png")}
                            alt="Deactivate"
                            title="Deactivate"
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {/* <div className="row col-lg-12 col-md-9 col-sm-9 col-12">loan</div> */}
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-4">
              <label className="label-control colorRed mt-2">
                *Indicates mandatory fields, Please fill mandatory fields before
                Submit
              </label>
            </div>
            <div className="col-md-12 col-lg-11 col-sm-12 col-12 text-left">
              <input
                type="submit"
                name="submit"
                onClick={() => {
                  setAddDisabled(true);
                  onFinalSubmit();
                }}
                value="ADD"
                className="btn sub_form btn_continue Save float-right"
                disabled={addDisabled}
              />
              <input
                type="button"
                name="Prtev"
                value="Previous"
                onClick={() => setTabIndex(3)}
                className="btn sub_form btn_continue Save float-right"
              />
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

AddMemberModel.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
});

export default connect(mapStateToProps, {
  getParticularLoanReason,
  getCurMemMettingLoanSanc,
  getActiveOtherLoanType,
  getExistingMemberILData,
  getExistingMemberOLData,
  addMemberMeetingData,
  getIndividualMemberMeetingData,
})(AddMemberModel);
