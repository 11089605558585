import React, { useState, Fragment, useEffect } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  getBatchwiseReport,
  getBatchOfParish,
  getAllBatchesDropdown,
  batchAndMeetingDetails,
  getBatchOfDistrict,
  getYearcount,
} from "../../actions/shg";
import {
  getVarados,
  getParish,
  getStates,
  getDistrict,
  getBlockDetails,
} from "../../actions/area";
import Spinner from "../layout/Spinner";
import { CSVLink } from "react-csv";

const BatchwiseReport = ({
  auth: { isAuthenticated, user, users },
  shg: {
    yearcount,
    batchwisereport,
    batchwisereportSum,
    batchOfParish,
    allbatchesdropdown,
    batchOfDistrict,
    principal_interest_sum,
    other_interest_sum,
    loadStatus,
  },
  area: { varadosData, activeParish, statesData, activeDistrict, getBlock },
  getBatchwiseReport,
  getYearcount,
  getVarados,
  getParish,
  getBatchOfParish,
  getAllBatchesDropdown,
  batchAndMeetingDetails,
  getStates,
  getDistrict,
  getBlockDetails,
  getBatchOfDistrict,
}) => {
  const [batchsyear, getbatchsyearData] = useState("");
  let selBatchwiseReport = JSON.parse(
    localStorage.getItem("selBatchwiseReport")
  );
  let yearData = JSON.parse(sessionStorage.getItem("yearDetails"));
  useEffect(() => {}, [loadStatus]);
  useEffect(() => {
    getBatchwiseReport(selBatchwiseReport);
  }, [getBatchwiseReport]);
  useEffect(() => {
    getVarados();
  }, [getVarados]);
  useEffect(() => {
    getAllBatchesDropdown();
  }, [getAllBatchesDropdown]);
  useEffect(() => {
    getStates();
  }, [getStates]);
  useEffect(() => {
    getYearcount({
      institutionId: user && user.institutionId,
    });
  }, []);

  let loggedUserInstitutionData = JSON.parse(
    localStorage.getItem("loggedUserInstitutionData")
  );
  const [showHide] = useState({
    showBatchSection:
      loggedUserInstitutionData &&
      loggedUserInstitutionData.institutionType === "Zoned"
        ? true
        : false,
  });

  const yeardropdown = [];
  yearData &&
    yearData.map((ele) => {
      yeardropdown.push({
        label: ele._id,
        value: ele.YearData,
      });
    });
  const { showBatchSection } = showHide;
  const allvarado = [];
  varadosData.map((varado) =>
    allvarado.push({
      vId: varado._id,
      vCode: varado.varadoCode,
      label: varado.varadoName,
      value: varado.varadoName,
    })
  );

  const [varado, getVaradoData] = useState(
    selBatchwiseReport &&
      selBatchwiseReport.varadoVal && {
        vId: selBatchwiseReport.varadoVal,
        label: selBatchwiseReport.varadoName,
        value: selBatchwiseReport.varadoName,
      }
  );
  const onVaradoChange = (e) => {
    getVaradoData(e);
    let varadoVal = {
      varadoInfo: e.vId,
    };
    getParish(varadoVal);
    getBatchwiseReport({ varadoVal: e.vId, varadoName: e.value });
    getParishData("");
    getbatchesData("");
    getBatchOfParish("");
  };

  const allparish = [];
  activeParish.map((parish) =>
    allparish.push({
      pId: parish._id,
      pCode: parish.parishCode,
      bCounter: parish.batchCounter,
      label: parish.parishName,
      value: parish.parishName,
    })
  );

  const [parish, getParishData] = useState(
    selBatchwiseReport &&
      selBatchwiseReport.parishId && {
        pId: selBatchwiseReport.parishId,
        label: selBatchwiseReport.parishName,
        value: selBatchwiseReport.parishName,
      }
  );

  const onParishChange = (e) => {
    getParishData(e);
    var filterData = {
      parishId: e.pId,
    };
    getBatchOfParish(filterData);
    getBatchwiseReport({
      varadoVal: varado.vId,
      varadoName: varado.value,
      parishId: e.pId,
      parishName: e.value,
    });
    getbatchsData("");
  };

  const onClickReset = () => {
    getParishData("");
    getBatchwiseReport("");
    getVaradoData("");
    getbatchsData("");
    getbatchesData("");
    getParish("");
    getBatchOfParish("");
    getStates("");
    getDistrict("");
    getStateData("");
    getdistrictData("");
    getBatchOfDistrict("");
    setBlock("");
    getbatchsyearData("");
  };

  const allbatches = [];
  batchOfParish.map((batchs) =>
    allbatches.push({
      batchId: batchs._id,
      label: batchs.batchName,
      value: batchs.batchName,
    })
  );

  const [batchs, getbatchsData] = useState(
    selBatchwiseReport && selBatchwiseReport.batchIdVal
      ? {
          batchId: selBatchwiseReport.batchIdVal,
          label: selBatchwiseReport.batchName,
          value: selBatchwiseReport.batchName,
        }
      : ""
  );
  const onBatchesChange = (e) => {
    getbatchsData(e);

    let transactionTypeChange = {
      varadoVal: varado.vId,
      varadoName: varado.value,
      parishId: parish.pId,
      parishName: parish.value,

      batchIdVal: e.batchId,
      batchName: e.value,
    };
    getBatchwiseReport(transactionTypeChange);
  };

  const allbatchesList = [];
  batchOfDistrict.map((batches) =>
    allbatchesList.push({
      batchesId: batches._id,
      label: batches.batchName,
      value: batches.batchName,
    })
  );
  const [batches, getbatchesData] = useState(
    selBatchwiseReport && selBatchwiseReport.batchIdVal
      ? {
          batchesId: selBatchwiseReport.batchIdVal,
          label: selBatchwiseReport.batchName,
          value: selBatchwiseReport.batchName,
        }
      : ""
  );
  const onBatchChange = (e) => {
    getbatchesData();
    const changeData = {
      stateInfo: state.sId,
      stateName: state.label,
      districtIdVal: district.districtId,
      districtName: district.label,
      blockId: block.value,
      blockName: block.label,
      batchIdVal: e.batchesId,
      batchName: e.value,
    };
    getBatchwiseReport(changeData);
    getbatchsyearData("");
  };
  const [year, setyear] = useState();
  // yearcount &&
  //   yearcount && {
  //     label: yearcount._id,
  //     value: yearcount.YearData,
  //   }
  const onYearChange = (e) => {
    var year = e;
    getbatchsyearData(year);
    getBatchwiseReport({ year: year.label });
    getStateData("");
    getdistrictData("");
    setBlock("");
    getbatchesData("");
  };

  const handleGoToAllVerifiedMeeting = (batchReport) => {
    const batchIdData = {
      batchIdVal: batchReport._id,
      batchName: batchReport.batchName,
    };
    // verifiedMeetingList(batchIdData);
    batchAndMeetingDetails(batchIdData);
  };
  const allstates = [];
  statesData.map((state) =>
    allstates.push({
      sId: state._id,
      label: state.stateName,
      value: state.stateName,
    })
  );

  const [state, getStateData] = useState(
    selBatchwiseReport && selBatchwiseReport.stateInfo
      ? {
          sId: selBatchwiseReport.stateInfo,
          label: selBatchwiseReport.stateName,
          value: selBatchwiseReport.stateName,
        }
      : ""
  );

  const onStateChange = (e) => {
    getStateData(e);
    let stateVal = {
      stateInfo: e.sId,
      stateName: e.label,
    };
    getDistrict(stateVal);
    getBatchwiseReport(stateVal);
    getdistrictData("");
    getBatchOfDistrict("");
    getbatchesData("");
    setBlock("");
    getbatchsyearData("");
  };

  const alldistrict = [];
  activeDistrict.map((district) =>
    alldistrict.push({
      districtId: district._id,
      label: district.districtName,
      value: district.districtName,
    })
  );

  const [district, getdistrictData] = useState(
    selBatchwiseReport && selBatchwiseReport.districtIdVal
      ? {
          districtId: selBatchwiseReport.districtIdVal,
          label: selBatchwiseReport.districtName,
          value: selBatchwiseReport.stateName,
        }
      : ""
  );
  const ondistrictChange = (e) => {
    getdistrictData(e);
    getBlockDetails({ districtIdVal: e.districtId });
    getBatchOfDistrict({
      districtId: e.districtId,
    });
    getBatchwiseReport({
      stateInfo: state.sId,
      stateName: state.label,
      districtIdVal: e.districtId,
      districtName: e.label,
    });
    getbatchesData("");
    setBlock("");
    getbatchsyearData("");
  };

  const allBlocks = [];
  getBlock &&
    getBlock.map((block) =>
      allBlocks.push({
        label: block.blockName,
        value: block._id,
      })
    );

  const [block, setBlock] = useState(
    selBatchwiseReport && selBatchwiseReport.blockId
      ? {
          label: selBatchwiseReport.blockName,
          value: selBatchwiseReport.blockId,
        }
      : ""
  );
  const onBlockChange = (e) => {
    setBlock(e);
    getBatchOfDistrict({
      blockId: e.value,
    });
    getBatchwiseReport({
      stateInfo: state.sId,
      stateName: state.label,
      districtIdVal: district.districtId,
      districtName: district.label,
      blockId: e.value,
      blockName: e.label,
    });
    getbatchesData("");
    getbatchsyearData("");
  };

  const MemcountArray = batchwisereport.map(
    (ele) => Number(ele.memberCounter) - 1
  );
  const totalMemCount = MemcountArray.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );

  const allPermission = [];
  loggedUserInstitutionData &&
    loggedUserInstitutionData.permission &&
    loggedUserInstitutionData.permission.map((permission) =>
      allPermission.push(permission.permissionName)
    );
  const outstandingAmt = (sanAmt, paidAmt) => {
    return Number(sanAmt) - Number(paidAmt);
  };
  //////////////////////////////////////////////////////CSV PUSH
  let csvData = [];
  let csvFreeZonedData = [];
  if (allPermission && allPermission.includes("Yearwise Dropdown")) {
    csvData = [
      [
        "Year",
        "SHG Name",
        "SHG Code",
        "Parish",
        "Total Saving",
        "Total Internal Loan Amount Given",
        "Internal Loan Paid",
        "Total Other Loan Amount Given",
        "Other Loan Paid",
        "Outstanding Internal Loan Amount",
        "Outstanding Other Loan Amount",
        "Total No. of Meetings Conducted",
      ],
    ];
    batchwisereport.map((batchwisereport) => {
      return csvData.push([
        batchsyear === ""
          ? new Date().getFullYear()
          : batchsyear && batchsyear.value,
        batchwisereport.batchName,
        batchwisereport.batchCode,
        batchwisereport.parishName,
        batchwisereport.batchSavingAmt,
        batchwisereport.batchLoanAmt,
        batchwisereport.batchLoanPaid,
        batchwisereport.otherLoanSanctionedToMember,
        batchwisereport.otherLoanPaidToShg,
        batchwisereport.OutstandingBatchLoanAmt,
        batchwisereport.OutstandingBatchOtherLoanAmt,
        batchwisereport.batchMeetingHeldOnDate,
      ]);
    });
    if (batchwisereportSum && batchwisereportSum[0]) {
      csvData.push([
        "Total",
        "",
        "",
        "",
        batchwisereportSum[0].batchSavingAmt,

        batchwisereportSum[0].batchLoanAmt,
        batchwisereportSum[0].batchLoanPaid,

        batchwisereportSum[0].otherLoanSanctionedToMember,
        batchwisereportSum[0].otherLoanPaidToShg,
        batchwisereportSum[0].OutstandingBatchLoanAmt,

        batchwisereportSum[0].OutstandingBatchOtherLoanAmt,
        batchwisereportSum[0].batchMeetingHeldOnDate,
        totalMemCount,
      ]);
    }
    csvFreeZonedData = [
      [
        "Year",
        "SHG Name",
        "SHG Code",
        "Saving",
        "Internal Loan Amount Given",
        "Internal Loan Paid",
        "Other Loan Amount Given",
        "Other Loan Paid",
        "Outstanding Internal Loan Amount",
        "Outstanding Other Loan Amount",
        "Total No. of Meetings Conducted",
        "Member Count",
      ],
    ];
    batchwisereport.map((batchwisereport) => {
      var total = Number(batchwisereport.memberCounter) - 1;
      return csvFreeZonedData.push([
        batchsyear === ""
          ? new Date().getFullYear()
          : batchsyear && batchsyear.value,
        batchwisereport.batchName,
        batchwisereport.batchCode,
        batchwisereport.batchSavingAmt,
        batchwisereport.batchLoanAmt,
        batchwisereport.batchLoanPaid,
        batchwisereport.otherLoanSanctionedToMember,
        batchwisereport.otherLoanPaidToShg,
        batchwisereport.OutstandingBatchLoanAmt,
        batchwisereport.OutstandingBatchOtherLoanAmt,
        batchwisereport.batchMeetingHeldOnDate,
        total,
      ]);
    });
    if (batchwisereportSum && batchwisereportSum[0]) {
      csvFreeZonedData.push([
        "Total",
        "",
        "",
        "",
        batchwisereportSum[0].batchSavingAmt,

        batchwisereportSum[0].batchLoanAmt,
        batchwisereportSum[0].batchLoanPaid,

        batchwisereportSum[0].otherLoanSanctionedToMember,
        batchwisereportSum[0].otherLoanPaidToShg,
        batchwisereportSum[0].OutstandingBatchLoanAmt,

        batchwisereportSum[0].OutstandingBatchOtherLoanAmt,
        batchwisereportSum[0].batchMeetingHeldOnDate,
        totalMemCount,
      ]);
    }
  } else {
    csvData = [
      [
        "SHG Name",
        "SHG Code",
        "Parish",
        "Total Saving",
        "Total Internal Loan Amount Given",
        "Internal Loan Paid",
        "Total Other Loan Amount Given",
        "Other Loan Paid",
        "Outstanding Internal Loan Amount",
        "Outstanding Other Loan Amount",
        "Total No. of Meetings Conducted",
        "Member Count",
      ],
    ];
    var TOTOutstandingBatchLoanAmt = 0;
    var TOTOutstandingBatchOtherLoanAmt = 0;
    batchwisereport.map((batchwisereport) => {
      var total = Number(batchwisereport.memberCounter) - 1;

      TOTOutstandingBatchLoanAmt += outstandingAmt(
        batchwisereport.batchLoanAmt,
        batchwisereport.totIntLoanPaid
      );
      TOTOutstandingBatchOtherLoanAmt += outstandingAmt(
        batchwisereport.otherLoanSanctionedToMember,
        batchwisereport.totOthLoanPaid
      );

      return csvData.push([
        batchwisereport.batchName,
        batchwisereport.batchCode,
        batchwisereport.parishName,
        batchwisereport.batchSavingAmt,
        batchwisereport.batchLoanAmt,
        batchwisereport.totIntLoanPaid,
        // batchwisereport.batchLoanPaid,
        batchwisereport.otherLoanSanctionedToMember,
        // batchwisereport.otherLoanPaidToShg,
        batchwisereport.totOthLoanPaid,
        outstandingAmt(
          batchwisereport.batchLoanAmt,
          batchwisereport.totIntLoanPaid
        ),
        outstandingAmt(
          batchwisereport.otherLoanSanctionedToMember,
          batchwisereport.totOthLoanPaid
        ),
        // batchwisereport.OutstandingBatchLoanAmt,
        // batchwisereport.OutstandingBatchOtherLoanAmt,
        batchwisereport.batchMeetingHeldOnDate,
        total,
      ]);
    });
    if (batchwisereportSum && batchwisereportSum[0]) {
      csvData.push([
        "Total",
        "",
        "",
        batchwisereportSum[0].batchSavingAmt,
        batchwisereportSum[0].batchLoanAmt,
        // batchwisereportSum[0].batchLoanPaid,
        batchwisereportSum[0].totIntLoanPaid,
        batchwisereportSum[0].otherLoanSanctionedToMember,
        // batchwisereportSum[0].otherLoanPaidToShg,
        batchwisereportSum[0].totOthLoanPaid,
        TOTOutstandingBatchLoanAmt,
        TOTOutstandingBatchOtherLoanAmt,

        // batchwisereportSum[0].OutstandingBatchLoanAmt,
        // batchwisereportSum[0].OutstandingBatchOtherLoanAmt,
        batchwisereportSum[0].batchMeetingHeldOnDate,
        totalMemCount,
      ]);
    }
    csvFreeZonedData = [
      [
        "SHG Name",
        "SHG Code",
        "Saving",
        "Internal Loan Amount Given",
        "Internal Loan Paid",
        "Other Loan Amount Given",
        "Other Loan Paid",
        "Outstanding Internal Loan Amount",
        "Outstanding Other Loan Amount",
        "Total No. of Meetings Conducted",
        "Member Count",
      ],
    ];
    var TOTOutstandingBatchLoanAmt = 0;
    var TOTOutstandingBatchOtherLoanAmt = 0;
    batchwisereport.map((batchwisereport) => {
      var total = Number(batchwisereport.memberCounter) - 1;

      TOTOutstandingBatchLoanAmt += outstandingAmt(
        batchwisereport.batchLoanAmt,
        batchwisereport.totIntLoanPaid
      );
      TOTOutstandingBatchOtherLoanAmt += outstandingAmt(
        batchwisereport.otherLoanSanctionedToMember,
        batchwisereport.totOthLoanPaid
      );

      return csvFreeZonedData.push([
        batchwisereport.batchName,
        batchwisereport.batchCode,
        batchwisereport.batchSavingAmt,
        batchwisereport.batchLoanAmt,
        batchwisereport.totIntLoanPaid, //123
        batchwisereport.otherLoanSanctionedToMember,
        batchwisereport.totOthLoanPaid,

        outstandingAmt(
          batchwisereport.batchLoanAmt,
          batchwisereport.totIntLoanPaid
        ),
        outstandingAmt(
          batchwisereport.otherLoanSanctionedToMember,
          batchwisereport.totOthLoanPaid
        ),
        // batchwisereport.OutstandingBatchLoanAmt,
        // batchwisereport.OutstandingBatchOtherLoanAmt,
        batchwisereport.batchMeetingHeldOnDate,
        total,
      ]);
    });

    if (batchwisereportSum && batchwisereportSum[0]) {
      csvFreeZonedData.push([
        "Total",
        "",
        batchwisereportSum[0].batchSavingAmt,
        batchwisereportSum[0].batchLoanAmt,
        batchwisereportSum[0].totIntLoanPaid,
        batchwisereportSum[0].otherLoanSanctionedToMember,
        batchwisereportSum[0].totOthLoanPaid,

        TOTOutstandingBatchLoanAmt,
        TOTOutstandingBatchOtherLoanAmt,

        batchwisereportSum[0].batchMeetingHeldOnDate,
        totalMemCount,
      ]);
    }
  }

  const get_the_Interest = (allData, loanType) => {
    try {
      if (loanType === "internal") {
        let interest = principal_interest_sum.find((ele) => ele._id == allData);

        if (!interest.internalInterestReceivable) {
        }
        return interest.internalInterestReceivable;
      } else {
        let interest = other_interest_sum.find((ele) => ele._id == allData);

        if (!interest.otherInterestReceivable) {
        }
        return interest.otherInterestReceivable;
      }
    } catch (error) {
      return 0;
    }
  };

  //////////////////////////////////////////////////////////////////END of CSV PUSH
  ///////////////////////////////////////TOTAL
  // const totSUm = batchwisereport.reduce((acu, cur) => {
  //   acu + cur.memberCounter - 1, 0;
  // });

  return !isAuthenticated || !user || !users ? (
    <Spinner />
  ) : (
    <Fragment>
      {loadStatus ? (
        <Spinner />
      ) : (
        <div className="container container_align ">
          <div className="col-lg-11 col-md-11 col-sm-11 col-11">
            <h2 className="heading_color">Batchwise Report</h2>
            <hr />
          </div>
          <section className="sub_reg">
            <div className="row col-lg-11 col-md-11 col-sm-11 col-11 no_padding">
              {showBatchSection && (
                <>
                  <div
                    className="col-lg-3 col-md-3 col-sm-3 col-11 mt-1"
                    style={{ zIndex: "999" }}
                  >
                    <Select
                      name="varadoName"
                      options={allvarado}
                      isSearchable={true}
                      value={varado}
                      placeholder="Select Varado"
                      onChange={(e) => onVaradoChange(e)}
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-sm-3 col-11 mt-1">
                    <Select
                      name="parishName"
                      options={allparish}
                      isSearchable={true}
                      value={parish}
                      placeholder="Select Parish"
                      onChange={(e) => onParishChange(e)}
                    />
                  </div>
                </>
              )}
              {loggedUserInstitutionData.institutionType === "Zoned" ? (
                <div className="col-lg-3 col-md-3 col-sm-3 col-11 mt-1">
                  <Select
                    name="batchName"
                    options={allbatches}
                    isSearchable={true}
                    value={batchs}
                    placeholder="Select SHG"
                    onChange={(e) => onBatchesChange(e)}
                  />
                </div>
              ) : (
                <>
                  <div className="col-lg-2 col-md-5 col-sm-4 col-11 mt-1">
                    <Select
                      name="stateName"
                      options={allstates}
                      isSearchable={true}
                      value={state}
                      placeholder="Select State"
                      // style={{ zindex: 1000 }}
                      onChange={(e) => onStateChange(e)}
                      menuPortalTarget={document.body} // Use this to control z-index
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Set the desired z-index
                      }}
                    />
                  </div>
                  <div className="col-lg-2 col-md-5 col-sm-4 col-11 mt-1">
                    <Select
                      name="districtName"
                      options={alldistrict}
                      isSearchable={true}
                      value={district}
                      placeholder="Select District"
                      onChange={(e) => ondistrictChange(e)}
                    />
                  </div>
                  {allPermission && allPermission.includes("Blockwise") && (
                    <div className="col-lg-2 col-md-5 col-sm-4 col-11 mt-1">
                      <Select
                        name="blockName"
                        options={allBlocks}
                        isSearchable={true}
                        value={block}
                        placeholder="Select Block"
                        onChange={(e) => onBlockChange(e)}
                      />
                    </div>
                  )}
                  <div className="col-lg-2 col-md-5 col-sm-4 col-11 mt-1">
                    <Select
                      name="batchName"
                      options={allbatchesList}
                      isSearchable={true}
                      value={batches}
                      placeholder="Select SHG"
                      onChange={(e) => onBatchChange(e)}
                    />
                  </div>
                  <div className="col-lg-2 col-md-5 col-sm-4 col-11 mt-1"></div>
                  {/* |<br />| */}
                  {allPermission &&
                    allPermission.includes("Yearwise Dropdown") && (
                      <div className="col-lg-2 col-md-5 col-sm-4 col-11 mt-1">
                        <Select
                          name="batchsyear"
                          options={yeardropdown}
                          isSearchable={true}
                          value={batchsyear}
                          placeholder="Select Year"
                          onChange={(e) => onYearChange(e)}
                        />
                      </div>
                    )}
                </>
              )}
              <div className="col-lg-12 col-md-3 col-sm-4 col-11 py-3">
                <Link to="/all-reports">
                  <img
                    className="img_icon_size log float-right ml-3"
                    src={require("../../static/images/back.png")}
                    alt="Back"
                    title="Back"
                  />
                </Link>
                <img
                  className="img_icon_size log float-right ml-3"
                  onClick={() => onClickReset()}
                  src={require("../../static/images/refresh-icon.png")}
                  alt="refresh"
                  title="Refresh"
                />
                {loggedUserInstitutionData.institutionType === "Zoned" ? (
                  <CSVLink data={csvData}>
                    <img
                      className="img_icon_size log float-right ml-4"
                      src={require("../../static/images/excel_icon.png")}
                      alt="Excel-Export"
                      title="Excel-Export"
                    />
                  </CSVLink>
                ) : (
                  <CSVLink data={csvFreeZonedData}>
                    <img
                      className="img_icon_size log float-right ml-4"
                      src={require("../../static/images/excel_icon.png")}
                      alt="Excel-Export"
                      title="Excel-Export"
                    />
                  </CSVLink>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-11 col-md-11 col-sm-11 col-11 text-center ">
                <section className="body">
                  <div className=" body-inner no-padding table-responsive table-container mt-5 ">
                    <table
                      className="table table-bordered table-striped table-hover"
                      id="datatable2"
                    >
                      <thead>
                        <tr>
                          <th className="sticky-column">SHG Name</th>
                          <th>SHG Code</th>
                          {showBatchSection && (
                            <>
                              <th>Parish</th>
                            </>
                          )}
                          <th title="Total Savings">Savings</th>
                          <th title="Total Internal Loan Amount Given">
                            IL Given (P)
                          </th>
                          <th>IL Interest Receivable (I)</th>
                          <th title="Internal Loan Paid">
                            IL Paid (P)
                          </th>
                          <th title="Internal Loan Paid">
                            IL Paid (I)
                          </th>
                          <th title="Outstanding Internal Loan Amount">
                            O/S IL Principal{" "}
                          </th>
                          <th title="Outstanding Internal Loan Amount">
                            O/S IL Interest{" "}
                          </th>
                          <th title="Total Other Loan Amount Given">
                            OL Given (P)
                          </th>
                          <th title="Total Other Loan Amount Given">
                            OL Interest Receivable (I)
                          </th>
                          <th title="Total Other Loan Amount Given">
                            OL Paid (P)
                          </th>
                          <th title="Total Other Loan Amount Given">
                            OL Paid (I)
                          </th>
                          {/* <th title=" Other Loan Paid">Other Loan Paid </th> */}
                          <th title="Outstanding Other Loan Amount">
                            O/S OL (P)
                          </th>
                          <th title="Outstanding Other Loan Amount">
                            O/S OL (I)
                          </th>

                          <th>Meetings Conducted</th>
                          <th>Member Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {batchwisereportSum &&
                          batchwisereportSum.map((batchReport, idx) => {
                            return (
                              <tr key={idx} className="b">
                                <td className="sticky-column">
                                  <b>Total</b>
                                </td>
                                {showBatchSection && (
                                  <>
                                    <td></td>
                                  </>
                                )}
                                <td></td>
                                <td>{batchReport.batchSavingAmt}</td>
                                <td>{batchReport.batchLoanAmt}</td>
                                <td>
                                  {batchwisereport
                                    .reduce(
                                      (acu, cur) =>
                                        (acu += get_the_Interest(
                                          cur._id,
                                          "internal"
                                        )),
                                      0
                                    )
                                    .toFixed(2)}
                                </td>
                                <td>
                                  {batchwisereport
                                    .reduce(
                                      (acu, curr) =>
                                        (acu +=
                                          curr.totalInternalPrincipalPaid),
                                      0
                                    )
                                    .toFixed(2)}
                                </td>
                                <td>
                                  {batchwisereport
                                    .reduce(
                                      (acu, curr) =>
                                        (acu += curr.totalInternalInterestPaid),
                                      0
                                    )
                                    .toFixed(2)}
                                </td>
                                <td>
                                  {batchwisereport
                                    .reduce(
                                      (acu, curr) =>
                                        (acu +=
                                          curr.outstandingInternalPricipal),
                                      0
                                    )
                                    .toFixed(2)}
                                </td>
                                <td>
                                  {batchwisereport
                                    .reduce(
                                      (acu, cur) =>
                                        (acu += get_the_Interest(
                                          cur._id,
                                          "internal"
                                        )),
                                      0
                                    )
                                    .toFixed(2) -
                                    batchwisereport
                                      .reduce(
                                        (acu, curr) =>
                                          (acu +=
                                            curr.totalInternalInterestPaid),
                                        0
                                      )
                                      .toFixed(2)}
                                </td>
                                <td>
                                  {batchwisereport
                                    .reduce(
                                      (acu, curr) =>
                                        (acu += curr.otherLoanSanctionedToShg),
                                      0
                                    )
                                    .toFixed(2)}
                                </td>
                                <td>
                                  {batchwisereport
                                    .reduce(
                                      (acu, curr) =>
                                        (acu += curr.batchOtherLoanInterestShg),
                                      0
                                    )
                                    .toFixed(2)}
                                </td>
                                <td>
                                  {batchwisereport
                                    .reduce(
                                      (acu, curr) =>
                                        (acu += curr.otherLoanPaidToShg),
                                      0
                                    )
                                    .toFixed(2)}
                                </td>
                                <td>
                                  {batchwisereport
                                    .reduce(
                                      (acu, cur) =>
                                        (acu += get_the_Interest(
                                          cur._id,
                                          "other"
                                        )),
                                      0
                                    )
                                    .toFixed(2)}
                                </td>
                                <td>
                                  {batchwisereport
                                    .reduce(
                                      (acu, curr) =>
                                        (acu += curr.outstandingOtherPricipal),
                                      0
                                    )
                                    .toFixed(2)}
                                </td>
                                <td>
                                  {batchwisereport
                                    .reduce(
                                      (acu, cur) =>
                                        (acu += get_the_Interest(
                                          cur._id,
                                          "other"
                                        )),
                                      0
                                    )
                                    .toFixed(2) -
                                    batchwisereport
                                      .reduce(
                                        (acu, curr) =>
                                          (acu +=
                                            curr.batchOtherLoanInterestShg),
                                        0
                                      )
                                      .toFixed(2)}
                                </td>
                                <td>
                                  {batchwisereport
                                    .reduce(
                                      (acu, curr) =>
                                        (acu += curr.batchMeetingHeldOnDate),
                                      0
                                    )
                                    .toFixed(2)}
                                </td>
                                <td>{totalMemCount}</td>
                              </tr>
                            );
                          })}

                        {batchwisereport &&
                          batchwisereport.map((batchReport, idx) => {
                            var total = Number(batchReport.memberCounter) - 1;
                            return (
                              <tr key={idx}>
                                <td className="sticky-column">
                                  <Link
                                    to="/all-verified-meeting"
                                    className="btnLink "
                                    onClick={() =>
                                      handleGoToAllVerifiedMeeting(batchReport)
                                    }
                                  >
                                    {batchReport.batchName}
                                  </Link>
                                </td>
                                <td>{batchReport.batchCode}</td>
                                {showBatchSection && (
                                  <>
                                    <td>{batchReport.parishName}</td>
                                  </>
                                )}
                                <td>{batchReport.batchSavingAmt}</td>
                                <td>{batchReport.batchLoanAmt}</td>
                                <td>
                                  {get_the_Interest(
                                    batchReport._id,
                                    "internal"
                                  )}
                                </td>
                                {/* <td>{batchReport.totIntLoanPaid}</td> */}
                                <td>
                                  {batchReport.totalInternalPrincipalPaid}
                                </td>
                                <td>{batchReport.totalInternalInterestPaid}</td>
                                <td>
                                  {batchReport.outstandingInternalPricipal}
                                </td>
                                <td>
                                  {get_the_Interest(
                                    batchReport._id,
                                    "internal"
                                  ) - batchReport.totalInternalInterestPaid}
                                </td>
                                <td>{batchReport.otherLoanSanctionedToShg}</td>
                                <td>{batchReport.batchOtherLoanInterestShg}</td>
                                <td>{batchReport.otherLoanPaidToShg}</td>
                                <td>
                                  {get_the_Interest(batchReport._id, "other")}
                                </td>
                                <td>{batchReport.outstandingOtherPricipal}</td>
                                <td>
                                  {get_the_Interest(batchReport._id, "other") -
                                    batchReport.batchOtherLoanInterestShg}
                                </td>
                                {/* <td>{Number(batchReport.totOthLoanPaid).toFixed(2)}</td> */}
                                {/* <td>{outstandingAmt(batchReport.batchLoanAmt,batchReport.totIntLoanPaid)}</td> */}
                                {/* <td>
                               {outstandingAmt(
                                 batchReport.otherLoanSanctionedToMember,
                                 batchReport.totOthLoanPaid
                               )}
                             </td> */}
                                <td>{batchReport.batchMeetingHeldOnDate}</td>
                                <td>{total}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </section>
              </div>
              <div className="col-lg-11 col-md-6 col-sm-11 col-11 align_right">
                <label>
                  <b>No. of SHGs : {batchwisereport.length}</b>
                </label>
              </div>
            </div>
          </section>
        </div>
      )}
    </Fragment>
  );
};

BatchwiseReport.propTypes = {
  auth: PropTypes.object.isRequired,
  shg: PropTypes.object.isRequired,
  area: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  shg: state.shg,
  area: state.area,
});

export default connect(mapStateToProps, {
  getBatchwiseReport,
  getVarados,
  getParish,
  getBatchOfParish,
  getAllBatchesDropdown,
  batchAndMeetingDetails,
  getStates,
  getYearcount,
  getDistrict,
  getBatchOfDistrict,
  getBlockDetails,
})(BatchwiseReport);
